import { AppleMusicArtwork } from "./appleMusic";
import { SlugifyTrackType } from "./slugify";

export enum SpotifyActionType {
  CREATE_APPLE_MUSIC_PLAYLIST_ON_SPOTIFY_FAIL = "create_apple_music_playlist_on_spotify_fail",
  CREATE_APPLE_MUSIC_PLAYLIST_ON_SPOTIFY_START = "create_apple_music_playlist_on_spotify_start",
  CREATE_APPLE_MUSIC_PLAYLIST_ON_SPOTIFY_SUCCESS = "create_apple_music_playlist_on_spotify_success",
  GET_MATCHING_SPOTIFY_TRACKS_START = "get_matching_spotify_tracks_start",
  GET_MATCHING_SPOTIFY_TRACKS_FAIL = "get_matching_spotify_tracks_fail",
  GET_MATCHING_SPOTIFY_TRACKS_SUCCESS = "get_matching_spotify_tracks_success",
  CLEAN_SPOTIFY_PLAYLIST = "clean_spotify_playlist",
  SET_SPOTIFY_CONNECTED_STATUS = "set_spotify_connected_status"
}
export interface SpotifyState {
  clientCredentialsTokenData: {
    access_token?: string;
    expires?: string;
  };
  isConnected: boolean;
  myPlaylists: SpotifyPlaylist[];
  trackSearch: SpotifyPlaylist[];
}

export interface SpotifyArtist {
  external_urls: Record<string, string>;
  href: string;
  id: string;
  name: string;
  type: string;
  uri: string;
}

export interface SpotifyPlaylist {
  collaborative: boolean;
  description: string;
  external_urls: Record<string, string>;
  href: string;
  id: string;
  images: SpotifyImage[];
  name: string;
  owner: {
    display_name: string;
    href: string;
    id: string;
    type: string;
    uri: string;
  };
  primary_color: string | null;
  public: boolean;
  snapshot_id: string;
  tracks: {
    href: string;
    total: number;
  };
  type: string;
  uri: string;
}

export interface SpotifyImage {
  height: number;
  url: string;
  width: number;
}

export interface SpotifyInnerTrack {
  album: {
    album_type: string;
    artists: SpotifyArtist;
    external_urls: Record<string, string>;
    href: string;
    id: string;
    images: SpotifyImage[];
    name: string;
    release_date: string;
    release_date_precision: string;
    total_tracks: number;
    uri: string;
  };
  artists: SpotifyArtist[];
  disc_number: number;
  duration_ms: number;
  explicit: boolean;
  external_ids: Record<string, string>;
  external_urls: Record<string, string>;
  href: string;
  id: string;
  is_local: false;
  is_playable: true;
  name: string;
  popularity: number;
  preview_url: string;
  track_number: number;
  type: string;
  uri: string;
}

export interface SpotifyTrack {
  track: {
    album: {
      album_type: string;
      artists: SpotifyArtist;
      external_urls: Record<string, string>;
      href: string;
      id: string;
      images: SpotifyImage[];
      name: string;
      release_date: string;
      release_date_precision: string;
      total_tracks: number;
      uri: string;
    };
    artists: SpotifyArtist[];
    disc_number: number;
    duration_ms: number;
    explicit: boolean;
    external_ids: Record<string, string>;
    external_urls: Record<string, string>;
    href: string;
    id: string;
    is_local: false;
    is_playable: true;
    name: string;
    popularity: number;
    preview_url: string;
    track_number: number;
    type: string;
    uri: string;
  };
  added_by: {
    id: string;
  };
  matchedBy: string;
  appleMusicInfo: {
    albumArt: string;
    appleMusicId: string;
    appleMusicIsrc: string;
    addedBy: string;
    album: string;
    artist: string;
    title: string;
    sharedByFriend: string[];
    slurpStatus: string;
  };
  isDeleted: boolean;
  isFound: boolean;
  trackType: SlugifyTrackType;
  playlist_name: string;
  slurpedBy?: string[];
  sharedByFriend?: string[];
}

export interface SpotifyTokenData {
  access_token: string;
  expires: string;
  refresh_token: string;
}

export interface SpotifyUserData {
  country: string;
  images: SpotifyImage[];
  product: string;
  followers: { total: number; href: string | null };
  explicit_content: {
    filter_locked: boolean;
    filter_enabled: boolean;
  };
  external_urls: Record<string, string>;
  href: string;
  id: string;
  display_name: string;
  type: string;
  uri: string;
  email: string;
}

// unmatched
export interface SpotifyUnmatchedTrack {
  trackType: SlugifyTrackType;
  added_by: { id: string };
  appleMusicInfo: {
    albumArt: AppleMusicArtwork;
    appleMusicId: string;
    appleMusicIsrc: string;
    addedBy: string;
    album: string;
    artist: string;
    title: string;
  };
  sharedByFriend?: string[]
}

// matched
export interface SpotifyMatchedTrack {
  track: SpotifyTrack;
  trackType: SlugifyTrackType;
  added_by: { id: string };
  matchedBy: string;
  appleMusicInfo: {
    albumArt: AppleMusicArtwork;
    appleMusicId: string;
    appleMusicIsrc: string;
    addedBy: string;
    album: string;
    artist: string;
    title: string;
  };
}
