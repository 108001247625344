import drummer from "./18516-drummer.json";
// import spinningDisk from "./12746-spinning-disk.json";
import folkMusician from "./11266-folk-musician.json";
// import chica from "./4704-chica.json";
import ladyGuitarPlayer from "./4906-lady-guitar-player.json";
import djGeet from "./6177-dj-geet.json";
import mrCookie from "./8437-mr-cookie-accordion.json";
import violinMusic from "./22104-violin-music-with-note.json";
import hedgehog from "./8439-hedgehog-listening-music.json";
import guitarist from "./29340-guitarist.json";
// import couch from "./28022-quarantine-concert-2020.json";
import { isMobile } from "react-device-detect";

const lotties = [
  { name: "drummer", data: drummer, height: 1080, width: 1570 },
  // { name: "spinningDisk", data: spinningDisk, height: 480, width: 480 },
  { name: "folkMusician", data: folkMusician, height: 500, width: 500 },
  // { name: "chica", data: chica, height: 480, width: 870 },
  { name: "djGeet", data: djGeet, height: 1080, width: 1970 },
  { name: "mrCookie", data: mrCookie, height: 600, width: 600 },
  { name: "hedgehog", data: hedgehog, height: 460, width: 406 },
  { name: "guitarist", data: guitarist, height: 800, width: 800 },
  // { name: "couch", data: couch, height: 1350, width: 1800 },
  {
    name: "ladyGuitarPlayer",
    data: ladyGuitarPlayer,
    height: 1000,
    width: 1000
  },
  { name: "violinMusic", data: violinMusic, height: 1000, width: 1000 }
];

if (isMobile) {
  lotties.unshift();
}

const lottie = lotties[Math.floor(Math.random() * lotties.length)];

export default lottie;
