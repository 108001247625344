import Button from "../../../components/shared/Button";
import Modal from "react-bootstrap/Modal";
import CookiesAccordion from "./CookiesAccordion";
import { useHistory } from "react-router-dom";
import { CookiesActionType } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  acceptAllCookies,
  saveCookiesPreferences
} from "../../../actions/cookiesActions";
import { useContext } from "react";
import { ThemeContext } from "../../../contexts/ColorThemeProvider";

// window.dataLayer = window.dataLayer || [];
export default function CookiesModal() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);
  const { draftCookiesConsent, initialCookieState, isCookiesModalOpen } =
    useAppSelector((state) => state.cookies);

  // function gtag(...args: any) {
  //   window.dataLayer.push(args);
  // }

  // function updateConsent() {
  //   const { isAdvertisingEnabled, isPerformanceEnabled } = draftCookiesConsent;
  //   gtag("consent", "update", {
  //     ad_storage: isAdvertisingEnabled ? "granted" : "denied",
  //     analytics_storage: isPerformanceEnabled ? "granted" : "denied"
  //   });
  // }

  return (
    <Modal
      show={isCookiesModalOpen}
      size="lg"
      onHide={() => {
        dispatch({
          type: CookiesActionType.SET_COOKIES_MODAL_VISIBILITY,
          payload: false
        });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Cookies Preferences</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <CookiesAccordion />
        <div className="d-flex justify-content-center w-100 mt-3">
          <Button
            btnText="View Our Privacy Policy"
            fn={() => history.push("/privacy-policy")}
            color="info"
            size="sm"
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <button
            className="btn btn-link text-body-secondary text-decoration-none"
            onClick={() => {
              dispatch({
                type: CookiesActionType.SET_COOKIES_MODAL_VISIBILITY,
                payload: false
              });
            }}
          >
            close
          </button>
          <div className="d-flex align-items-center">
            <button
              className={`btn btn-sm btn-${
                isDark ? "outline-secondary" : "secondary"
              }`}
              disabled={
                JSON.stringify(draftCookiesConsent) ===
                JSON.stringify(initialCookieState)
              }
              id="save-cookies-modal-button"
              onClick={() => {
                dispatch(saveCookiesPreferences());
                // updateConsent();
                dispatch({
                  type: CookiesActionType.SET_COOKIES_MODAL_VISIBILITY,
                  payload: false
                });
              }}
            >
              Save Preferences
            </button>

            <button
              className={`btn btn-sm btn-${
                isDark ? "outline-primary" : "primary"
              } ms-2`}
              id="accept-all-cookies-modal-button"
              onClick={() => {
                dispatch(acceptAllCookies());
                // updateConsent();
                dispatch({
                  type: CookiesActionType.SET_COOKIES_MODAL_VISIBILITY,
                  payload: false
                });
              }}
            >
              Accept All
            </button>
            {/* <Button
              btnText="Accept All"
              className="ms-2"
              color="primary"
              fn={() => {
                dispatch(acceptAllCookies());
                updateConsent();
                dispatch({
                  type: CookiesActionType.SET_COOKIES_MODAL_VISIBILITY,
                  payload: false
                });
              }}
              size="sm"
            /> */}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
