import { useCallback } from "react";
import { getRandomTeacher } from "../../functions";

export const TutorialPage1 = () => {
  const teacher = useCallback(getRandomTeacher(), []);
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center text-center tutorial-page"
      style={{ height: "480px" }}
    >
      <div className="tutorial-emoji">{teacher}</div>
      <div
        className="d-flex d-sm-none tutorial-title"
        style={{ fontSize: "28px" }}
      >
        What is Slugify?
      </div>
      <div className="d-none d-sm-flex tutorial-title">What is Slugify?</div>
      <p className="tutorial-lead">
        Slugify is a fun app that does one thing well. It allows friends to
        share music!
      </p>
      <p className="tutorial-lead">
        Slugify isn&apos;t a replacement for Apple Music or Spotify. These
        services are great on their own. Think of Slugify as a music exchange
        across subscriptions.
      </p>
      <p className="tutorial-lead mb-0">
        Apple Music and Spotify subscribers can exchange tunes even when
        they&apos;re not using the same music service.
      </p>
    </div>
  );
};
