import $ from "jquery";
import { getPrimaryMusicStreamName } from "../functions";
import { useAppSelector } from "../hooks";
import StatusMessage from "./shared/StatusMessage";

const isDev = process.env.NODE_ENV === "development";

interface Props {
  playlist: any;
  response: any;
}
export const CreateMatchedPlaylistModal = ({ playlist, response }: Props) => {
  const {
    newUser: { primaryMusicStream }
  } = useAppSelector((state) => state);

  if (isDev) {
    console.log("fix these types", playlist, response);
  }
  const getPlaylistUrl = () => {
    if (response?.playlist) {
      if (primaryMusicStream === "appleMusic") {
        return `https://music.apple.com/library/playlist/${response.playlist.id}`;
      } else {
        return response.playlist.external_urls.spotify;
      }
    } else {
      if (primaryMusicStream === "appleMusic") {
        //
      } else {
        return "https://open.spotify.com/collection/playlists";
      }
    }
    //
  };

  const renderBody = () => {
    if (!response || !playlist) {
      return (
        <StatusMessage
          title="What is this?"
          emoticon="🤷🏻‍♀️"
          subtitle={`Something's not right`}
          size="sm"
        />
      );
    } else if (response.result) {
      if (response.result === "noNewTracks") {
        return (
          <StatusMessage
            title="You're up-to-date!"
            emoticon="🙆🏻‍♀️"
            subtitle={`All tracks are already in ${
              playlist.name
            } on ${getPrimaryMusicStreamName(primaryMusicStream)}${
              response.deletedTracks && response.deletedTracks > 1 ? "," : "."
            }`}
            subtitle2={
              response.deletedTracks
                ? `and ${response.deletedTracks} ${
                    response.deletedTracks > 1 ? "tracks have" : "track has"
                  } been removed.`
                : ""
            }
            size="sm"
          />
        );
      } else {
        return (
          <StatusMessage
            title="Uh ohs!"
            emoticon="🤷🏻‍♀️"
            subtitle={response.error}
            size="sm"
          />
        );
      }
    } else if (response.unmatchedTrackCount === 0) {
      return (
        <StatusMessage
          title="️Success!"
          emoticon="️💯"
          subtitle={`${
            primaryMusicStream === "appleMusic"
              ? response.playlist.attributes.name
              : response.playlist.name
          } has been synched to ${getPrimaryMusicStreamName(
            primaryMusicStream
          )}.`}
          subtitle2="Do you wanna check it out now?"
          size="sm"
        />
      );
    } else {
      return (
        <StatusMessage
          title="️Partial Success"
          emoticon="️🥒"
          subtitle={`${
            primaryMusicStream === "appleMusic"
              ? response.playlist.attributes.name
              : response.playlist.name
          } has been synched to ${getPrimaryMusicStreamName(
            primaryMusicStream
          )} with ${response.unmatchedTrackCount} unmatched ${
            response.unmatchedTrackCount === 1 ? "track" : "tracks"
          } `}
          subtitle2="Do you wanna check it out now?"
          size="sm"
        />
      );
    }
  };

  return (
    <div className="modal fade" id="create-matched-playlist-modal">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <div aria-hidden="true">&times;</div>
            </button>
          </div>
          <div className="modal-body">
            <div className="lead d-flex flex-column">{renderBody()}</div>
          </div>
          <div className="d-flex justify-content-between modal-footer">
            {response?.playlist ? (
              <>
                <button
                  type="button"
                  className="btn btn-link text-body-secondary text-decoration-none"
                  data-bs-dismiss="modal"
                >
                  No Thanks
                </button>
                <a
                  className="btn btn-primary"
                  href={getPlaylistUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    $("#create-matched-playlist-modal").modal("hide");
                  }}
                >
                  Sure!
                </a>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-link text-body-secondary text-decoration-none"
                  data-bs-dismiss="modal"
                >
                  No Thanks
                </button>
                <a
                  className="btn btn-primary"
                  href={getPlaylistUrl()}
                  onClick={() => {
                    $("#create-matched-playlist-modal").modal("hide");
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open {getPrimaryMusicStreamName(primaryMusicStream)}
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
