import { Auth } from "aws-amplify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getRandomTipper } from "../functions";
import { useCallback } from "react";
import { userCustomizationSchema } from "../validators";
import Button from "../components/shared/Button";
import { useAppDispatch, useAppSelector } from "../hooks";

export const UserCustomization = () => {
  const dispatch = useAppDispatch();
  const {
    auth: {
      attributes: {
        "custom:appleEmail": appleEmail,
        "custom:googleEmail": googleEmail,
        preferred_username: currentEmail,
        nickname
      }
    }
  } = useAppSelector((state) => state);

  const tipper = useCallback(getRandomTipper(), []);

  return (
    <Formik
      initialValues={{
        currentEmail: currentEmail ? currentEmail : "",
        nickname: nickname ? nickname : "",
        appleEmail: appleEmail ? appleEmail : "",
        googleEmail: googleEmail ? googleEmail : ""
      }}
      validationSchema={userCustomizationSchema}
      onSubmit={async (values, actions) => {
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const payload = {
            nickname: values.nickname
          };
          if (values.appleEmail) {
            payload["custom:appleEmail"] = values.appleEmail;
          }
          if (values.googleEmail) {
            payload["custom:googleEmail"] = values.googleEmail;
          }
          await Auth.updateUserAttributes(cognitoUser, payload);
          dispatch({ type: "UPDATE_USER_ATTRIBUTES", payload });
          actions.setSubmitting(false);
        } catch (error) {
          actions.setSubmitting(false);
          console.warn(error);
        }
      }}
    >
      {({ errors, values, isSubmitting }) => {
        return (
          <div className="border border-user-settings rounded my-3 p-3 text-body-secondary d-flex flex-column align-items-center w-100 animate__animated animate__fadeIn">
            <Form>
              <div className="slug-subtitle text-center">
                User Customization
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                <div className="mb-3 w-100">
                  <label className="form-label" htmlFor="nicknameInput">
                    Nickname
                  </label>
                  <Field
                    aria-describedby="nicknameHelp"
                    className="form-control"
                    id="nicknameInput"
                    name="nickname"
                    placeholder="Slugbert"
                    type="text"
                  />
                  {errors.nickname ? (
                    <div className="formikErrorMessage">
                      <ErrorMessage name="nickname" id="nicknameHelp" />
                    </div>
                  ) : (
                    <small
                      id="nicknameHelp"
                      className="form-text text-body-secondary fieldHelperText"
                    >
                      That which we call a rose by any other name would smell as
                      sweet
                    </small>
                  )}
                </div>
                <span
                  className="text-center mb-3"
                  role="img"
                  aria-label="service person emoji"
                  style={{ fontSize: "56px" }}
                >
                  {tipper}
                </span>
                <div className="lead text-center mb-3">
                  If you want to sign in with Apple or Google, you need to enter
                  the respective email addresses for those accounts below. You
                  only need to do this if those addresses are different from
                  your Slugify email address.
                </div>
                <div className="mb-3 w-100">
                  <label className="form-label" htmlFor="currentEmailInput">
                    Slugify Email
                  </label>
                  <Field
                    aria-describedby="currentEmailHelp"
                    className="form-control"
                    disabled
                    id="currentEmailInput"
                    name="currentEmail"
                    type="text"
                  />
                  {errors.currentEmail ? (
                    <div className="formikErrorMessage">
                      <ErrorMessage name="currentEmail" id="currentEmailHelp" />
                    </div>
                  ) : (
                    <small
                      id="currentEmailHelp"
                      className="form-text text-body-secondary fieldHelperText"
                    >
                      This is your Slugify email address
                    </small>
                  )}
                </div>
                <div className="mb-3 w-100">
                  <label className="form-label" htmlFor="appleEmailInput">
                    Apple Email
                  </label>
                  <Field
                    aria-describedby="appleEmailHelp"
                    className="form-control"
                    id="appleEmailInput"
                    name="appleEmail"
                    placeholder="slugbert@icloud.com"
                    type="text"
                  />
                  {errors.appleEmail ? (
                    <div className="formikErrorMessage">
                      <ErrorMessage name="appleEmail" id="appleEmailHelp" />
                    </div>
                  ) : (
                    <small
                      id="appleEmailHelp"
                      className="form-text text-body-secondary fieldHelperText"
                    >
                      This should match your Apple ID
                    </small>
                  )}
                </div>
                <div className="w-100">
                  <label className="form-label" htmlFor="googleEmailInput">
                    Google Email
                  </label>
                  <Field
                    aria-describedby="googleEmailHelp"
                    className="form-control"
                    id="googleEmailInput"
                    name="googleEmail"
                    placeholder="slugbert@gmail.com"
                    type="text"
                  />
                  {errors.googleEmail ? (
                    <div className="formikErrorMessage">
                      <ErrorMessage name="googleEmail" id="googleEmailHelp" />
                    </div>
                  ) : (
                    <small
                      id="googleEmailHelp"
                      className="form-text text-body-secondary fieldHelperText"
                    >
                      This should match your Google email address
                    </small>
                  )}
                </div>
                <div className="d-flex justify-content-center mt-3 w-100">
                  <Button
                    block
                    btnText="Save Customization"
                    color="primary"
                    icon="icon-save"
                    isDisabled={!values.nickname}
                    isSpinning={isSubmitting}
                    size="sm"
                    spinnerSize={18}
                    spinnerType="puff"
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
