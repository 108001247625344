import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks";

const isDev = process.env.NODE_ENV === "development";

interface Props {
  children: JSX.Element[];
}
const PrivateRoute = ({ children, ...rest }: Props) => {
  const location = useLocation();
  const { username } = useAppSelector((state) => state.auth);
  const isAuthenticated = !!username;
  if (isDev) {
    console.log("📍 Location", location.pathname);
    console.log(
      "🦜 Private route says user is isAuthenticated?: ",
      isAuthenticated
    );
    console.log(
      isAuthenticated
        ? "🎯 Private should render private page"
        : "⛔ Private route should redirect to sign in page"
    );
    console.log("🤷🏽‍♀️ I sometimes run twice!  But why?!");
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
  // return (
  //   <Route
  //     {...rest}
  //     component={props => (
  //       <div>
  //         <NavBar />
  //         <Component {...props} />
  //       </div>
  //     )}
  //   />
  // );
};

export default PrivateRoute;
