import { AppleMusicTrack } from "./appleMusic";
import {
  SpotifyImage,
  SpotifyPlaylist,
  SpotifyTokenData,
  SpotifyTrack,
  SpotifyUserData
} from "./spotify";

export interface AlertData {
  alertType?: string;
  message?: string;
  emoticon?: string;
  title?: string;
}
export interface AutoSlurpFriend {
  isNotificationsEnabled: boolean;
  playlists: [
    {
      slurpFromPlaylist: AutoSlurpFriendPlaylist;
      slurpToPlaylist: AutoSlurpFriendPlaylist;
    }
  ];
  scheduleDays: [
    {
      label: string;
      value: string;
    }
  ];
  scheduleTime: {
    label: string;
    value: string;
  };
  scheduleTimezone: {
    label: string;
    value: string;
  };
  username: string;
}

export interface AutoSlurpFriendPlaylist {
  id: string;
  name: string;
}

export interface ExpoPushTokenData {
  token: string;
  username: string;
}

export interface Friend {
  id: string;
  userId: string;
  username: string;
  owner: string;
  primaryMusicStream: string;
  musicStreamId: string;
  createdAt: string;
  updatedAt: string;
  user: SlugifyUser;
}

// export interface Lottie {
//   data: any;
//   height: string;
//   width: string;
// }
export interface PublicPlaylist {
  owner: string;
  name: string;
  id: string;
}

export interface SharedPlaylist {
  owner: string;
  name: string;
  id: string;
}

export interface SelectedPlaylist {
  name: string;
  id: string;
}

export interface SlugifyMobileSlurpDestination {
  name: string;
  id: string;
  type: string;
}

export interface SlugifyState {
  addDeleteFriendStatus: string;
  alert?: AlertData;
  appleMusicPlaylists: string[];
  isInitializing: boolean;
  isDoneLoading: boolean;
  isMatching: boolean;
  isUpdatingFilteredTracks: boolean;
  filteredTracks: SpotifyTrack[] | AppleMusicTrack[];
  isLoading: boolean;
  loadingStatus: string;
  showMyTracks: boolean;
  showSlurpedTracks: boolean;
  showTutorial: boolean;
  slurpCounterCurrent: number;
  slurpCounterTarget: number;
  slurpedTracks: SpotifyPlaylist[];
  spotifyPlaylists: SpotifyPlaylist[];
  spotifyTracks: SpotifyTrack[];
  spotifyUserIds: string[];
  unmatchedTracks: UnmatchedAppleMusicTrack[];
  youtubePlaylists: gapi.client.youtube.Playlist[];
  youtubeTracks: gapi.client.youtube.PlaylistItem[];
}

export interface SlugifySlurpedTrack {
  id: string;
  appleMusicId: string | null;
  appleMusicTrackAlbum: string | null;
  appleMusicTrackArtist: string | null;
  appleMusicTrackIsrc: string | null;
  appleMusicTrackTitle: string | null;
  createdAt: string;
  matchedMy: string | null;
  originalTrackAddedBy: string;
  owner: string;
  slugifySlurpedTrackUserId: string;
  slurpToPlaylistId: string;
  spotifyId: string | null;
  spotifyTrackAlbum: string | null;
  spotifyTrackArtists: string | null;
  spotifyTrackIsrc: string | null;
  spotifyTrackTitle: string | null;
  toBeDeletedOn: string;
  trackType: string;
  updatedAt: string;
  username: string;
}

export interface SlugifyUser {
  appleMusicSlugifyPlaylist: string;
  autoSlurpFriends: Friend[];
  expoPushTokenData: string;
  homeMenu: string;
  friends: Friend[];
  myFriendsSharedPlaylists: SharedPlaylist[];
  myFriendsPublicPlaylists: PublicPlaylist[];
  spotifyTokenData: SpotifyTokenData;
  spotifyUserData: SpotifyUserData;
  youTubeUserData: any;
  screenAppearance: string;
  id: string;
  username: string;
  owner: string;
  primaryMusicStream: string;
  sharedPlaylists: SharedPlaylist[];
  publicPlaylists: PublicPlaylist[];
  slugifyMobileSlurpDestination: SlugifyMobileSlurpDestination;
  createdAt: string;
  updatedAt: string;
}

export interface UnmatchedAppleMusicTrack {
  track_type: string;
  added_by: {
    id: string;
  };
  appleMusicInfo: {
    albumArt: string;
    appleMusicId: string;
    appleMusicIsrc: string;
    addedBy: string;
    album: string;
    artist: string;
    title: string;
  };
  isDeleted: boolean;
  isFound: boolean;
}

export interface UnmatchedSpotifyTrack {
  spotifyId: string;
  spotifyIsrc: string;
  added_by: string;
  album: string;
  playlist_name: string;
  albumArt: { height: number; url: string; width: number };
  trackType: string;
}

export interface SpotifyNativeTrack {
  spotifyId: string;
  spotifyIsrc: string;
  added_by: string;
  album: string;
  playlist_name: string;
  title: string;
  artist: string;
  albumArt: string | SpotifyImage;
}

export enum SlugifyActionType {
  CONNECT_APPLE_MUSIC_POST_INIT_START = "connect_apple_music_post_init_start",
  CONNECT_APPLE_MUSIC_POST_INIT_SUCCESS = "connect_apple_music_post_init_success",
  CREATE_NEW_SPOTIFY_SLUGIFY_PLAYLIST = "create_new_spotify_slugify_playlist",
  GET_MUSIC_STREAM_IDS_SUCCESS = "get_music_stream_ids_success",
  GET_ALL_TRACKS_FROM_SPOTIFY_PLAYLISTS_SUCCESS = "get_all_tracks_from_spotify_playlists_success",
  GET_SLURPED_TRACKS_FAIL = "get_slurped_tracks_fail",
  GET_SLURPED_TRACKS_START = "get_slurped_tracks_start",
  GET_SLURPED_TRACKS_SUCCESS = "get_slurped_tracks_success",
  GET_ALL_SPOTIFY_USERS_PLAYLISTS_FAIL = "get_all_spotify_users_playlists_fail",
  GET_ALL_SPOTIFY_USERS_PLAYLISTS_START = "get_all_spotify_users_playlists_start",
  GET_ALL_SPOTIFY_USERS_PLAYLISTS_SUCCESS = "get_all_spotify_users_playlists_success",
  UPDATE_SLUGIFY_LOADING_STATUS = "update_slugify_loading_status",
  UPDATE_FILTERED_TRACKS_START = "update_filtered_tracks_start",
  UPDATE_FILTERED_TRACKS_FAIL = "update_filtered_tracks_fail",
  UPDATE_FILTERED_TRACKS_SUCCESS = "update_filtered_tracks_success",
  UPDATE_TRACK_SLURP_STATUSES_SUCCESS = "update_track_slurp_statuses_success",
  SET_COOKIES_TOAST_VISIBILITY = "set_cookies_toast_visibility",
  SET_COOKIES_MODAL_VISIBILITY = "set_cookies_modal_visibility",
  SET_SLURP_COUNTER_TARGET = "set_slurp_counter_target",
  SET_SLURP_COUNTER_CURRENT = "set_slurp_counter_current",
  SET_SLURP_COUNTER = "set_slurp_counter",
  RESET_SLURP_COUNTER = "reset_slurp_counter_current"
}

export enum SlugifyTrackType {
  // APPLE_MUSIC_MATCHED_FROM_SPOTIFY = "apple music track matched from spotify",
  APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY = "apple music track matched from spotify",
  APPLE_MUSIC_NATIVE = "apple music native",
  APPLE_MUSIC_UNMATCHED_FROM_SPOTIFY = "apple music unmatched from spotify",
  SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC = "spotify track matched from apple music",
  SPOTIFY_NATIVE = "spotify native",
  SPOTIFY_UNMATCHED_FROM_APPLE_MUSIC = "spotify unmatched from apple music",
  YOUTUBE_TRACK_MATCHED_FROM_APPLE_MUSIC = "youtube track matched from apple music",
  YOUTUBE_TRACK_MATCHED_FROM_SPOTIFY = "youtube track matched from spotify",
  YOUTUBE_NATIVE = "youtubeNative",
  YOUTUBE_UNMATCHED_FROM_APPLE_MUSIC = "youtube track unmatched from apple music",
  YOUTUBE_UNMATCHED_FROM_SPOTIFY = "youtube track unmatched from spotify"
}

export enum MatchedByType {
  // APPLE_MUSIC_MATCHED_FROM_SPOTIFY = "apple music track matched from spotify",
  ARTIST_TITLE = "artist/title",
  ISRC = "isrc"
}
