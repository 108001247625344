// eslint-disable jsx-a11y/anchor-is-valid
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateSlugifyUser } from "../actions";
import { Spinner } from "./shared/Spinner";
import { Form } from "react-bootstrap";

export const MySharedPlaylists = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    spotify: { myPlaylists: mySpotifyPlaylists },
    newUser: {
      primaryMusicStream,
      publicPlaylists: myAppleMusicPlaylists,
      sharedPlaylists
    }
  } = useSelector((state) => state);

  const [isSwitched, setIsSwitched] = useState([]);
  const [isUpdating, setIsUpdating] = useState([]);

  useEffect(() => {
    const myPlaylistIds =
      primaryMusicStream === "appleMusic"
        ? myAppleMusicPlaylists
            .filter(
              (item) =>
                item.name !== "Slugify" && !item.name.includes("Slugify Direct")
            )
            .map((item) => item.id)
        : mySpotifyPlaylists
            .filter(
              (item) =>
                item.name !== "Slugify" && !item.name.includes("Slugify Direct")
            )
            .map((item) => item.id);

    const zombieSharedPlaylistIds = sharedPlaylists
      .filter((item) => !myPlaylistIds.includes(item.id))
      .map((item) => item.id);
    if (zombieSharedPlaylistIds.length) {
      console.log(
        "🧟‍♀️ User has Zombie Shared Playlists: ",
        zombieSharedPlaylistIds
      );
      const updatedSharedPlaylists = sharedPlaylists.filter(
        (item) => !zombieSharedPlaylistIds.includes(item.id)
      );
      console.log("🔫 Killing zombies!", updatedSharedPlaylists);
      dispatch(updateSlugifyUser({ sharedPlaylists: updatedSharedPlaylists }))
        .then(() => console.log("🧠 Zombies have been killed!"))
        .catch((error) => console.warn(error));
    }
  }, [
    dispatch,
    myAppleMusicPlaylists,
    mySpotifyPlaylists,
    primaryMusicStream,
    sharedPlaylists
  ]);
  useEffect(() => {
    setIsSwitched(
      sharedPlaylists ? sharedPlaylists.map((item) => item.id) : []
    );
  }, [sharedPlaylists]);

  const handleSharePlaylist = async (playlist) => {
    try {
      setIsUpdating([...isUpdating, playlist.name]);
      if (sharedPlaylists.map((item) => item.name).includes(playlist.name)) {
        const payload = sharedPlaylists.filter(
          (item) => item.name !== playlist.name
        );
        setIsSwitched(payload.map((item) => item.id));
        await dispatch(
          updateSlugifyUser({
            sharedPlaylists: [
              ...payload.map((item) => ({ id: item.id, name: item.name }))
            ]
          })
        );
        dispatch({
          type: "DELETE_USER_SHARED_PLAYLIST",
          payload: { id: playlist.id, name: playlist.name }
        });
      } else {
        const payload = [
          ...sharedPlaylists,
          { id: playlist.id, name: playlist.name }
        ];
        setIsSwitched(payload.map((item) => item.id));
        await dispatch(
          updateSlugifyUser({
            sharedPlaylists: [
              ...payload.map((item) => ({ id: item.id, name: item.name }))
            ]
          })
        );
      }
      setIsUpdating(isUpdating.filter((item) => item !== playlist.name));
    } catch (error) {
      console.warn(error);
      setIsUpdating(isUpdating.filter((item) => item !== playlist.name));
    }
  };

  const renderPlaylists = () => {
    const playlists =
      primaryMusicStream === "appleMusic"
        ? myAppleMusicPlaylists.filter(
            (item) =>
              item.name !== "Slugify" && !item.name.includes("Slugify Direct")
          )
        : mySpotifyPlaylists.filter(
            (item) =>
              item.name !== "Slugify" &&
              !item.name.includes("Slugify Direct") &&
              item.public
          );

    return playlists.map((item) => (
      <li
        key={item.id}
        className="list-group-item d-flex align-items-center justify-content-between"
      >
        <div>{item.name}</div>
        <div className="form-group mb-0">
          <div className="custom-control custom-switch">
            <Form.Check
              type="checkbox"
              className="custom-control-input"
              id={`input-${item.name}`}
              checked={isSwitched.includes(item.id)}
              onChange={() => {
                handleSharePlaylist(item);
              }}
              label={
                isUpdating.map((playlist) => playlist).includes(item.name) ? (
                  <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ height: 24, width: 50 }}
                  >
                    <Spinner size={8} color="muted" type="beat" />
                  </div>
                ) : sharedPlaylists
                    .map((playlist) => playlist.name)
                    .includes(item.name) ? (
                  "Shared"
                ) : (
                  "Share"
                )
              }
            />
          </div>
        </div>
      </li>
    ));
  };

  return (
    <div
      className="container d-flex flex-column align-items-center w-100 mt-3 mt-sm-5 animate__animated animate__fadeIn"
      style={{ maxWidth: 800 }}
    >
      <div className="slug-title text-body-secondary text-center">
        My Shared Playlists
      </div>
      <ul className="list-group w-100 mt-3 mt-sm-4">{renderPlaylists()}</ul>
      <button
        className="btn btn-link text-body-secondary my-3 my-sm-4 text-decoration-none"
        onClick={() => history.goBack()}
      >
        back
      </button>
    </div>
  );
};
