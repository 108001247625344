// import { useCallback } from "react";
import Lottie from "react-lottie-player";
import randomLottie from "../../lotties";

interface Props {
  smallScreen?: boolean;
}
export default function LottieLoader({ smallScreen = false }: Props) {
  function getLottie() {
    const getLottieHeight = (height: number, width: number) => {
      const newWidth = 300;
      const aspectRatio = width / height;
      return newWidth / aspectRatio;
    };
    const getLottieWidth = (height: number, width: number) => {
      const newHeight = 480;
      const aspectRatio = height / width;
      return newHeight / aspectRatio;
    };
    switch (randomLottie.name) {
      case "drummer":
        return {
          data: randomLottie.data,
          height: smallScreen ? getLottieHeight(1080, 1570) : 480,
          width: smallScreen ? 300 : getLottieWidth(1080, 1570)
        };
      case "folkMusician":
        return {
          data: randomLottie.data,
          height: smallScreen ? getLottieHeight(500, 500) : 480,
          width: smallScreen ? 300 : getLottieWidth(500, 500)
        };
      case "chica":
        return {
          data: randomLottie.data,
          height: smallScreen ? getLottieHeight(480, 870) : 480,
          width: smallScreen ? 300 : getLottieWidth(480, 870)
        };
      case "couch":
        return {
          data: randomLottie.data,
          height: smallScreen ? getLottieHeight(1350, 1800) : 480,
          width: smallScreen ? 300 : getLottieWidth(1350, 1800)
        };
      case "guitarist":
        return {
          data: randomLottie.data,
          height: smallScreen ? getLottieHeight(600, 600) : 480,
          width: smallScreen ? 300 : getLottieWidth(600, 600)
        };
      case "hedgehog":
        return {
          data: randomLottie.data,
          height: smallScreen ? getLottieHeight(460, 406) : 480,
          width: smallScreen ? 300 : getLottieWidth(460, 406)
        };
      case "djGeet":
        return {
          data: randomLottie.data,
          height: smallScreen ? getLottieHeight(1080, 1970) : 480,
          width: smallScreen ? 300 : getLottieWidth(1080, 1970)
        };
      case "mrCookie":
        return {
          data: randomLottie.data,
          height: smallScreen ? getLottieHeight(600, 600) : 480,
          width: smallScreen ? 300 : getLottieWidth(600, 600)
        };
      case "ladyGuitarPlayer":
        return {
          data: randomLottie.data,
          height: smallScreen ? getLottieHeight(1000, 1000) : 480,
          width: smallScreen ? 300 : getLottieWidth(1000, 1000)
        };
      case "spinningDisk":
        return {
          data: randomLottie.data,
          height: smallScreen ? getLottieHeight(480, 480) : 480,
          width: smallScreen ? 300 : getLottieWidth(480, 480)
        };
      case "violinMusic":
        return {
          data: randomLottie.data,
          height: smallScreen ? getLottieHeight(1080, 1080) : 480,
          width: smallScreen ? 300 : getLottieWidth(1080, 1080)
        };
      default:
        console.warn("No match");
        break;
    }
  }

  const lottie = getLottie();

  if (!lottie) return null;

  return (
    <div style={{ height: lottie.height, width: lottie.width }}>
      <Lottie
        loop
        animationData={lottie.data}
        play
        style={{ width: lottie.width, height: lottie.height }}
      />
    </div>
  );
}
