export interface CookiesState {
  isCookiesModalOpen: boolean,
  isCookiesToastVisible: boolean,
  cookiesConsent: CookiesConsentState;
  draftCookiesConsent: CookiesConsentState;
  initialCookieState: CookiesConsentState;
}

export interface CookiesConsentState {
  isAdvertisingEnabled: boolean;
  isFunctionalEnabled: boolean;
  isPerformanceEnabled: boolean;
}

export enum CookiesActionType {
  ACCEPT_ALL_COOKIES_START = "accept_all_cookies_start",
  ACCEPT_ALL_COOKIES_FAIL = "accept_all_cookies_fail",
  ACCEPT_ALL_COOKIES_SUCCESS = "accept_all_cookies_success",
  SAVE_COOKIES_PREFERENCES_START = "save_cookies_preferences_start",
  SAVE_COOKIES_PREFERENCES_FAIL = "save_cookies_preferences_fail",
  SAVE_COOKIES_PREFERENCES_SUCCESS = "save_cookies_preferences_success",
  SET_COOKIES_TOAST_VISIBILITY = "set_cookies_toast_visibility",
  SET_COOKIES_MODAL_VISIBILITY = "set_cookies_modal_visibility",
  SET_INITIAL_COOKIE_CONSENT_STATE = "set_initial_cookie_consent_state",
  SET_ALL_COOKIE_CONSENT_STATES = "set_all_cookie_consent_states",
  SET_COOKIES_CONSENT = "set_cookies_consent",
  SET_COOKIES_DRAFT_CONSENT = "set_cookies_draft_consent"
}
