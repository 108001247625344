import { API } from "aws-amplify";
import dayjs from "dayjs";
import { v1 as uuid } from "uuid";

export const addNewSlugTubeComment = (
  item,
  comment,
  setComment,
  setSpinning
) => async (dispatch, getState) => {
  try {
    dispatch({ type: "ADD_NEW_SLUGTUBE_COMMENT_START" });
    const currentUser = getState().auth.username;
    const currentComments = getState().slugTubeComments.filter(
      (comment) => comment.itemId === item.id
    );
    const newComment = {
      commentId: uuid(),
      commenter: currentUser,
      text: comment,
      itemId: item.id,
      createdAt: dayjs().format(),
      updatedAt: dayjs().format()
    };

    const updatedItem = { ...item, comments: [...currentComments, newComment] };
    await API.put("SlugBucketAPI", "/slugTube", {
      body: { ...updatedItem }
    });
    dispatch({
      type: "ADD_NEW_SLUGTUBE_COMMENT_SUCCESS",
      payload: newComment
    });
    setComment("");
    setSpinning(false);
  } catch (e) {
    console.log(e);
    dispatch({ type: "ADD_NEW_SLUGTUBE_COMMENT_FAIL", payload: e });
  }
};
export const setInitialItemComments = (item) => async (dispatch) => {
  try {
    dispatch({ type: "GET_INITIAL_ITEM_COMMENTS_START" });
    if (item.comments) {
      dispatch({
        type: "GET_INITIAL_ITEM_COMMENTS_SUCCESS",
        payload: item.comments
      });
    } else {
      dispatch({
        type: "GET_INITIAL_ITEM_COMMENTS_SUCCESS",
        payload: []
      });
    }
  } catch (e) {
    console.log(e.response);
    dispatch({ type: "GET_INITIAL_ITEM_COMMENTS_FAIL", payload: e });
  }
};
export const getSlugTubeLinks = (setLoading) => async (dispatch) => {
  dispatch({ type: "GET_SLUGTUBE_VIDEO_LINKS_FROM_DB_START" });
  try {
    const response = await API.get("SlugBucketAPI", "/slugTube");
    dispatch({
      type: "GET_SLUGTUBE_VIDEO_LINKS_FROM_DB_SUCCESS",
      payload: response
    });
    setLoading(false);
  } catch (e) {
    console.log("ERROR: ", e);
    dispatch({ type: "GET_SLUGTUBE_VIDEO_LINKS_FROM_DB_FAIL", payload: e });
  }
};

export const markAsWatched = (id) => async (dispatch, getState) => {
  const currentUserName = getState().auth.username;
  const currentUserEmail = getState().auth.email;
  const currentUserData = getState().user;
  const currentSlugTubeData = await getState().slugTube;
  try {
    dispatch({ type: "MARK_VIDS_WATCHED_START" });
    let uniqueVids = [];
    if (!id) {
      uniqueVids = [
        ...new Set([
          ...currentUserData.watchedSlugTubeVids,
          ...currentSlugTubeData
            .filter((item) => !item.user.includes(currentUserEmail))
            .map((item) => item.id)
        ])
      ];
    } else {
      uniqueVids = [...new Set([...currentUserData.watchedSlugTubeVids, id])];
    }
    await API.put("SlugBucketAPI", "/users", {
      body: {
        ...currentUserData,
        username: currentUserName,
        watchedSlugTubeVids: [...uniqueVids]
      }
    });
    dispatch({
      type: "MARK_VIDS_WATCHED_SUCCESS",
      payload: uniqueVids
    });
  } catch (e) {
    console.log("ERROR: ", e);
    dispatch({ type: "MARK_VIDS_WATCHED_FAIL", payload: e });
  }
};

export const markAsUnwatched = (id) => async (dispatch, getState) => {
  const currentUserName = getState().auth.username;
  const currentUserData = getState().user;
  try {
    const watchedVids = currentUserData.watchedSlugTubeVids.filter(
      (item) => item !== id
    );
    await API.put("SlugBucketAPI", "/users", {
      body: {
        ...currentUserData,
        username: currentUserName,
        watchedSlugTubeVids: [...watchedVids]
      }
    });
    dispatch({
      type: "MARK_VIDS_UNWATCHED_SUCCESS",
      payload: watchedVids
    });
  } catch (e) {
    console.log("ERROR: ", e);
    dispatch({ type: "MARK_VIDS_UNWATCHED_FAIL", payload: e });
  }
};
