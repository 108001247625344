import { API } from "aws-amplify";
import { SlugifyTrackType } from "../types";
import { handleUserAlert } from "./slugifyActions";
import { getMyFriendsExpoKeys } from "./slugifyGraphqlActions";

export const handlePushNotification =
  (message, isTestMessage = false, notMyTracks = []) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "HANDLE_PUSH_NOTIFICATION_START" });
      const {
        auth: { username, userId },
        newUser: { friends, expoPushTokenData }
      } = getState();
      let slurpedFriends = [];
      if (notMyTracks.length) {
        let userIdsOfSlurpedTracks = notMyTracks.map((item) => {
          if (item.trackType === SlugifyTrackType.SPOTIFY_NATIVE) {
            return item.added_by.id;
          }
          if (
            item.trackType ===
            SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC
          ) {
            return item.added_by.id;
          }
          if (item.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE) {
            return item.addedBy;
          }
          if (
            item.trackType ===
            SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY
          ) {
            return item.spotifyInfo.added_by;
          }
          return [];
        });
        userIdsOfSlurpedTracks = [...new Set(userIdsOfSlurpedTracks)];
        slurpedFriends = friends.filter((friend) =>
          userIdsOfSlurpedTracks.includes(friend.musicStreamId)
        );

        if (!slurpedFriends.length && !isTestMessage) {
          dispatch({ type: "HANDLE_PUSH_NOTIFICATION_SUCCESS" });
          console.log("🤷 No friends to send notifications to!");
          return Promise.resolve("slurpedFriends is empty");
        }
      }
      const myToken = [expoPushTokenData.token];
      const friendTokens = await dispatch(
        slurpedFriends.length
          ? getMyFriendsExpoKeys(slurpedFriends)
          : getMyFriendsExpoKeys()
      );
      console.log(
        "📟 Sending push notifications...",
        isTestMessage ? myToken : friendTokens
      );
      await API.post("SlugBucketAPI", "/expo-push", {
        body: {
          message,
          sender: username,
          tokens: JSON.stringify(isTestMessage ? myToken : friendTokens)
        }
      });
      console.log("📟 Expo Notifications sent!");
      const response = await API.post("SlugBucketAPI", `/notify`, {
        body: {
          message,
          friends: message.includes("just slurped your tracks")
            ? slurpedFriends
            : friends,
          userId,
          isTestMessage
        }
      });
      dispatch({ type: "HANDLE_PUSH_NOTIFICATION_SUCCESS" });
      console.log("📟 One Signal Notifications sent!");
      return Promise.resolve(response);
    } catch (error) {
      dispatch({
        type: "HANDLE_PUSH_NOTIFICATION_FAIL",
        payload: new Error(error)
      });
      console.warn(error);
      dispatch(handleUserAlert(["HANDLE_PUSH_NOTIFICATION"]));
      return Promise.resolve(error);
    }
  };
