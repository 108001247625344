/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSlugifyFriend = /* GraphQL */ `
  query GetSlugifyFriend($id: ID!) {
    getSlugifyFriend(id: $id) {
      id
      userId
      username
      owner
      primaryMusicStream
      musicStreamId
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const listSlugifyFriends = /* GraphQL */ `
  query ListSlugifyFriends(
    $filter: ModelSlugifyFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlugifyFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        username
        owner
        primaryMusicStream
        musicStreamId
        createdAt
        updatedAt
        user {
          id
          username
          appleMusicSlugifyPlaylist
          autoSlurpFriends
          expoPushTokenData
          homeMenu
          isSubscribedOneSignal
          owner
          primaryMusicStream
          publicPlaylists
          sharedPlaylists
          screenAppearance
          slugifyMobileSlurpDestination
          spotifyTokenData
          spotifyUserData
          youTubeUserData
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSlugifyUser = /* GraphQL */ `
  query GetSlugifyUser($id: ID!) {
    getSlugifyUser(id: $id) {
      id
      username
      appleMusicSlugifyPlaylist
      autoSlurpFriends
      expoPushTokenData
      homeMenu
      isSubscribedOneSignal
      owner
      primaryMusicStream
      publicPlaylists
      sharedPlaylists
      screenAppearance
      friends {
        items {
          id
          userId
          username
          owner
          primaryMusicStream
          musicStreamId
          createdAt
          updatedAt
        }
        nextToken
      }
      slugifyMobileSlurpDestination
      spotifyTokenData
      spotifyUserData
      youTubeUserData
      createdAt
      updatedAt
      slurpedTracks {
        items {
          id
          username
          appleMusicId
          appleMusicTrackAlbum
          appleMusicTrackArtist
          appleMusicTrackIsrc
          appleMusicTrackTitle
          matchedBy
          originalTrackAddedBy
          owner
          slurpToPlaylistId
          spotifyId
          spotifyTrackAlbum
          spotifyTrackArtists
          spotifyTrackIsrc
          spotifyTrackTitle
          toBeDeletedOn
          trackType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSlugifyUsers = /* GraphQL */ `
  query ListSlugifyUsers(
    $filter: ModelSlugifyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlugifyUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSlugifySlurpedTrack = /* GraphQL */ `
  query GetSlugifySlurpedTrack($id: ID!) {
    getSlugifySlurpedTrack(id: $id) {
      id
      username
      appleMusicId
      appleMusicTrackAlbum
      appleMusicTrackArtist
      appleMusicTrackIsrc
      appleMusicTrackTitle
      matchedBy
      originalTrackAddedBy
      owner
      slurpToPlaylistId
      spotifyId
      spotifyTrackAlbum
      spotifyTrackArtists
      spotifyTrackIsrc
      spotifyTrackTitle
      toBeDeletedOn
      trackType
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const listSlugifySlurpedTracks = /* GraphQL */ `
  query ListSlugifySlurpedTracks(
    $filter: ModelSlugifySlurpedTrackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlugifySlurpedTracks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        appleMusicId
        appleMusicTrackAlbum
        appleMusicTrackArtist
        appleMusicTrackIsrc
        appleMusicTrackTitle
        matchedBy
        originalTrackAddedBy
        owner
        slurpToPlaylistId
        spotifyId
        spotifyTrackAlbum
        spotifyTrackArtists
        spotifyTrackIsrc
        spotifyTrackTitle
        toBeDeletedOn
        trackType
        createdAt
        updatedAt
        user {
          id
          username
          appleMusicSlugifyPlaylist
          autoSlurpFriends
          expoPushTokenData
          homeMenu
          isSubscribedOneSignal
          owner
          primaryMusicStream
          publicPlaylists
          sharedPlaylists
          screenAppearance
          slugifyMobileSlurpDestination
          spotifyTokenData
          spotifyUserData
          youTubeUserData
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
