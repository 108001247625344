import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks";

const isDev = process.env.NODE_ENV === "development";

interface Props {
  children: JSX.Element[];
}
const PublicRoute = ({ children, ...rest }: Props) => {
  const location = useLocation();
  const { username } = useAppSelector((state) => state.auth);
  const isAuthenticated = !!username;
  if (isDev) {
    console.log("📍 Location: ", location.pathname);
    console.log("🦜 Public route says user isAuthenticated: ", isAuthenticated);
    console.log(
      !isAuthenticated
        ? "🎯 Public route should render public page"
        : "⛔ Public route should redirect to home page"
    );
    console.log("🤷‍♀️ I sometimes run twice!  But why?!");
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default PublicRoute;
