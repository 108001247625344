import { trimText } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import axios from "axios";
import { useEffect, useState } from "react";
import StatusMessage from "./shared/StatusMessage";
import { getAppleMusicInstance } from "../actions";

const SlugBucketAppleMusicModal = () => {
  const dispatch = useDispatch();

  const {
    appleMusic: {
      myPlaylists: playlists,
      trackSearch: { artist, title, searchResults }
    }
  } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(true);
  const [playlistsAffected, setPlaylistsAffected] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [tracksAddSuccess, setTracksAddSuccess] = useState(false);

  useEffect(() => {
    if (artist) {
      setIsLoading(false);
      $("#slug-bucket-apple-music-modal").modal("handleUpdate");
    }
  }, [artist]);

  const addTracksToPlaylist = async (playlistId) => {
    try {
      setPlaylistsAffected([...playlistsAffected, playlistId]);
      const data = {};
      const tracks = selectedItems.map((item) => ({
        id: item,
        type: "songs"
      }));
      data.data = [...tracks];
      const { music, token } = await dispatch(getAppleMusicInstance());
      // MusicKit({
      //   developerToken: token,
      //   app: {
      //     name: "Slugify",
      //     build: "1978.4.1"
      //   }
      // });
      const appleMusicUserToken = await music.authorize();
      console.log("🔑 Is Apple Music Authorized? ", !!appleMusicUserToken);
      dispatch({
        type: "SET_APPLE_MUSIC_CONNECTED_STATUS",
        payload: !!appleMusicUserToken
      });
      const postOptions = {
        method: "POST",
        url: `https://api.music.apple.com/v1/me/library/playlists/${playlistId}/tracks`,
        headers: {
          Authorization: "Bearer " + token,
          "Music-User-Token": appleMusicUserToken,
          "Content-Type": "application/json"
        },
        data
      };
      axios(postOptions).then(() => {
        setTracksAddSuccess(true);
        setSelectedItems([]);
      });
    } catch (error) {
      console.warn(error.response || error);
    }
  };

  useEffect(() => {
    const cleanUpModal = () => {
      setTracksAddSuccess(false);
      setSelectedItems([]);
      setPlaylistsAffected([]);
      setIsLoading(true);
      dispatch({ type: "DELETE_CURRENT_APPLE_MUSIC_SEARCH_RESULTS" });
    };
    $("#slug-bucket-apple-music-modal").on("hidden.bs.modal", () => {
      cleanUpModal();
    });
  }, [dispatch]);

  const renderPlaylists = () => {
    if (playlists.length) {
      return (
        <>
          <div className="mt-3 mb-1 font-weight-bold">Playlists</div>
          <ul className="list-group flush animate__animated animate__fadeIn">
            {playlists
              .filter(
                (item) => item.attributes.name.toLowerCase() !== "slugify"
              )
              .map((item) => (
                <li
                  key={item.id}
                  className="d-flex align-items-center list-group-item"
                >
                  <div className="me-auto">{item.attributes.name}</div>
                  {tracksAddSuccess && playlistsAffected.includes(item.id) ? (
                    <button
                      className="d-flex align-items-center justify-content-center btn btn-success"
                      style={{ cursor: "default" }}
                    >
                      <i className="icon-playlist_add_check" />
                    </button>
                  ) : (
                    <button
                      className="d-flex align-items-center justify-content-center btn btn-secondary"
                      disabled={!selectedItems.length}
                      onClick={() => addTracksToPlaylist(item.id)}
                    >
                      <i className="icon-playlist_add slug-icon" />
                    </button>
                  )}
                </li>
              ))}
          </ul>
        </>
      );
    }
  };

  const renderTracks = () => {
    if (isLoading) {
      return (
        <div className="d-flex flex-column align-items-center text-center">
          <StatusMessage
            titleAnimation="animate__pulse"
            emoticon="🐌"
            title="Slurping Apple Music..."
            isInfinite
          />
        </div>
      );
    }
    if (searchResults && !searchResults.length) {
      return (
        <div className="d-flex flex-column align-items-center text-center">
          <span
            role="img"
            aria-label="Couldn't find shit!"
            className="display-4"
          >
            💩
          </span>
          <p className="lead mt-3">
            The Slug couldn&apos;t find shit on Apple Music!
          </p>
        </div>
      );
    }
    if (searchResults && searchResults.length) {
      return (
        <>
          <div className="mb-1 font-weight-bold">
            Matched {trickOrTracks(searchResults)}
          </div>
          <ul className="list-group flush animate__animated animate__fadeIn">
            {searchResults.map((item) => (
              <li
                key={item.id}
                className={`d-flex align-items-center list-group-item ${
                  selectedItems.includes(item.id)
                    ? "selected-list-item"
                    : "bg-light"
                }`}
                onClick={() => toggleSelect(item.id)}
              >
                <div className="d-flex align-items-center selectable-list-item w-100">
                  <div className="d-flex align-items-center me-auto w-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <a
                        className="d-block d-sm-none"
                        title="Play on Apple Music"
                        href={item.attributes.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="d-block d-sm-none"
                          src={item.attributes.artwork.url
                            .replace(/{w}/, "48")
                            .replace(/{h}/, "48")}
                          alt={`${item.attributes.albumName} album cover`}
                          height="48px"
                          width="48px"
                        />
                      </a>
                      <a
                        className="d-none d-sm-block"
                        title="Play on Apple Music"
                        href={item.attributes.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="d-none d-sm-block"
                          src={item.attributes.artwork.url
                            .replace(/{w}/, "64")
                            .replace(/{h}/, "64")}
                          alt={`${item.attributes.albumName} album cover`}
                          height="64px"
                          width="64px"
                        />
                      </a>
                    </div>
                    <div className="d-flex flex-column align-items-sm-start ms-3">
                      <small className="d-block d-sm-none font-weight-bold">
                        {item.attributes.name
                          ? trimText(25, item.attributes.name)
                          : null}
                      </small>
                      <span className="d-none d-sm-block font-weight-bold">
                        {item.attributes.name
                          ? trimText(40, item.attributes.name)
                          : null}
                      </span>
                      <small>
                        <div className="d-block d-sm-none">
                          {item.attributes.artistName
                            ? trimText(25, item.attributes.artistName)
                            : null}
                        </div>
                        <div className="d-none d-sm-block">
                          {item.attributes.artistName
                            ? trimText(60, item.attributes.artistName)
                            : null}
                        </div>
                        <div className="d-block d-sm-none">
                          {item.attributes.albumName
                            ? trimText(25, item.attributes.albumName)
                            : null}
                        </div>
                        <div className="d-none d-sm-block">
                          {item.attributes.albumName
                            ? trimText(60, item.attributes.albumName)
                            : null}
                        </div>
                      </small>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      );
    }
  };

  const toggleSelect = (id) => {
    if (selectedItems.includes(id)) {
      const temp = [...selectedItems];
      const index = temp.indexOf(id);
      temp.splice(index, 1);
      setSelectedItems([...temp]);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const trickOrTracks = (arr) => {
    if (arr && arr.length) {
      switch (arr.length) {
        case 0:
          return "tracks";
        case 1:
          return "track";
        default:
          return "tracks";
      }
    }
  };

  return (
    <div className="modal fade" id="slug-bucket-apple-music-modal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="d-flex align-items-center modal-title">
              <div className="d-flex flex-column">
                <span className="d-block d-sm-none font-weight-bold">
                  {title ? trimText(23, title) : ""}&nbsp;
                </span>
                <span className="d-none d-sm-block font-weight-bold">
                  {title ? trimText(40, title) : ""}&nbsp;
                </span>
                <span className="d-block d-sm-none font-weight-normal">
                  {artist ? trimText(25, artist) : ""}
                </span>
                <span className="d-none d-sm-block font-weight-normal">
                  {artist ? trimText(40, artist) : ""}
                </span>
              </div>
              {/* <img
                className="d-none d-sm-block ms-2"
                src={spotifyLogo}
                alt="Spotify"
                height="28.8px"
                width="96px"
              /> */}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="d-flex flex-column modal-body">
            {renderTracks()}
            {searchResults && searchResults.length ? renderPlaylists() : null}
          </div>
          <div className="d-flex justify-content-center modal-footer">
            <button
              type="button"
              className="btn btn-link text-body-secondary text-decoration-none"
              data-bs-dismiss="modal"
            >
              close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlugBucketAppleMusicModal;
