import { useState, useEffect } from "react";
import { doSignOutCognitoUser } from "../../actions";
import { Link, useLocation } from "react-router-dom";
import NavBarDropDown from "./NavBarDropDown";
import { useAppSelector } from "../../hooks";
import DarkModeToggleButton from "./DarkModeToggleButton";
import NavBarCollapse from "./NavBarCollapse";
import CookiesModal from "../../pages/Home/components/CookiesModal";

export default function NavBar() {
  const location = useLocation();
  const { pathname } = location;

  const {
    auth: {
      username: currentUsername,
      attributes: { fullName, nickname }
    },
    newUser: { homeMenu: initialHome, myFriendsSharedPlaylists }
  } = useAppSelector((state) => state);

  const [animation, setAnimation] = useState("");
  const [currentPath, setCurrentPath] = useState("/");
  const [home, setHome] = useState(initialHome);
  const [isSpinning, setIsSpinning] = useState(false);

  useEffect(() => {
    setCurrentPath(pathname);
    setHome(initialHome);
  }, [pathname, initialHome]);

  useEffect(() => {
    if (pathname === "/settings" && home !== initialHome) {
      setAnimation("animate__animated animate__jackInTheBox");
    }
  }, [home, initialHome, pathname]);

  const renderBrand = () => {
    switch (home) {
      case "slugify":
        return "Slugify";
      case "slugbucket":
        return "Slug Bucket";
      // case "slugtube":
      //   return "SlugTube";
      default:
        return "Slugify";
    }
  };

  function renderName() {
    if (nickname) {
      return nickname;
    }
    if (fullName) {
      return fullName.split(" ")[0];
    }
    return currentUsername;
  }

  return (
    <>
      <nav className="d-flex align-items-center navbar navbar-expand-md navbar-light bg-body-tertiary">
        <div className="container">
          <Link className={`navbar-brand ${animation}`} to="/">
            {renderBrand()}
          </Link>
          <button
            className="navbar-toggler justify-self-end"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbar">
            <ul className="navbar-nav ms-auto">
              {home !== "slugify" ? (
                <li
                  className={`nav-item ${
                    currentPath === "/slugify" ? "active" : ""
                  } mx-sm-3 ${animation}`}
                >
                  <Link
                    className="d-flex align-items-center nav-link"
                    to="/slugify"
                  >
                    Slugify
                  </Link>
                </li>
              ) : null}
              {home !== "slugbucket" &&
                process.env.NODE_ENV !== "production" && (
                  <li
                    className={`nav-item ${
                      currentPath === "/slugbucket" ? "active" : ""
                    } mx-sm-3 ${animation}`}
                    data-bs-toggle="collapse"
                    data-bs-target="#navbar"
                  >
                    <Link className="nav-link" to="/slugbucket">
                      Slug Bucket (fix me)
                    </Link>
                  </li>
                )}
              {myFriendsSharedPlaylists && myFriendsSharedPlaylists.length > 0 && (
                <li
                  className={`d-none d-sm-flex nav-item ${
                    currentPath === "/friends-playlists" ||
                    currentPath === "/shared-playlist-tracks"
                      ? "active"
                      : ""
                  } mx-sm-3`}
                >
                  <Link className="nav-link" to="/friends-playlists">
                    Friend&apos;s Playlists
                  </Link>
                </li>
              )}
              <NavBarCollapse
                animation={animation}
                currentPath={currentPath}
                // home={home}
                isSpinning={isSpinning}
                myFriendsSharedPlaylists={myFriendsSharedPlaylists}
                setIsSpinning={setIsSpinning}
              />
              <div className="d-none d-md-flex">
                <NavBarDropDown
                  doSignOutCognitoUser={doSignOutCognitoUser}
                  renderName={renderName}
                />
                <DarkModeToggleButton />
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <CookiesModal
      // handleAcceptAll={handleAcceptAll}
      // handleSave={handleSave}
      // isCookiesModalOpen={isCookiesModalOpen}
      // setIsCookiesModalOpen={setIsCookiesModalOpen}
      // setIsCookiesToastVisible={setIsCookiesToastVisible}
      // initialCookieState={initialCookieState}
      // state={cookiesState}
      // setState={setCookiesState}
      />
    </>
  );
}

// export default withRouter(NavBar);
