interface Props {
  color: string;
  emoji: string;
}
export default function Icon({ color, emoji }: Props) {
  const CIRCLE_SIZE = 72;
  const ICON_SIZE = 38;
  return (
    <div
      // className={`d-flex align-items-center justify-content-center bg-${color}`}
      className={"d-flex align-items-center justify-content-center"}
      style={{
        backgroundColor: color,
        borderRadius: "50%",
        height: CIRCLE_SIZE,
        width: CIRCLE_SIZE
      }}
    >
      <span style={{ fontSize: ICON_SIZE }}>{emoji}</span>
    </div>
  );
}
