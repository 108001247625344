import { useContext, useEffect, useState } from "react";
import {
  removeAppleMusicUserData,
  slugifyPostInit,
  getAppleMusicInstance,
  signOutYouTubeUser,
  // setYouTubeSignInStatus,
  updateSlugifyUser,
  gapiAuthenticate,
  // getMyYouTubeChannel,
  createYouTubeUserDataPayload
} from "../actions";
import appleMusicIcon from "../images/icon_apple_music_new_red.png";
import dayjs from "dayjs";
import DisconnectSpotifyModal from "./DisconnectSpotifyModal";
import spotify from "../images/Spotify_Logo_RGB_Green.png";
import { v1 as uuid } from "uuid";
import { Spinner } from "./shared/Spinner";
import { isMobile } from "react-device-detect";
// import youTubeIconDark from "../images/yt_logo_rgb_light_text.png";
// import youTubeIconLight from "../images/yt_logo_rgb_dark_text.png";

import googleSignInDark from "../images/btn_google_signin_dark_normal_web@2x.png";
import googleSignInLight from "../images/btn_google_signin_light_normal_web@2x.png";
import googleSignInDarkDisabled from "../images/btn_google_signin_dark_disabled_web@2x.png";
import googleSignInLightDisabled from "../images/btn_google_signin_light_disabled_web@2x.png";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  AppleMusicActionType,
  SlugifyActionType,
  YouTubeActionType
} from "../types";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface Props {
  formIsDirty: boolean;
  isDoingYouTubeAuth: boolean;
  setIsDoingYouTubeAuth: (b: boolean) => void;
}
export default function Connect({
  isDoingYouTubeAuth,
  formIsDirty,
  setIsDoingYouTubeAuth
}: Props) {
  const isDev = process.env.NODE_ENV === "development";
  console.log("%cIS DEV?", "color:orange", isDev);
  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);
  const {
    appleMusic: { isConnected: isAppleMusicConnected },
    spotify: { isConnected: isSpotifyConnected },
    newUser: {
      primaryMusicStream,
      spotifyTokenData: { expires: currentSpotifyTokenExpires },
      spotifyUserData: { display_name: displayName }
    },
    slugify: { loadingStatus },
    youtube: currentYouTubeUserData
  } = useAppSelector((state) => state);

  const [isConnectingToAppleMusic, setIsConnectingToAppleMusic] =
    useState(false);
  const [isDisconnectingFromAppleMusic, setIsDisconnectingFromAppleMusic] =
    useState(false);

  useEffect(() => {
    if (primaryMusicStream === "appleMusic") {
      dispatch(getAppleMusicInstance())
        .then(({ music }) => {
          dispatch({
            type: AppleMusicActionType.SET_APPLE_MUSIC_CONNECTED_STATUS,
            payload: music.isAuthorized
          });
        })
        .catch((error) => console.warn(error));
    }
  }, [dispatch, primaryMusicStream]);

  async function handleSpotifyAuth() {
    try {
      const LOCAL_REDIRECT_URI = "https://localhost:3000/spotify-callback/";
      const SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
      const REDIRECT_URI = isDev
        ? LOCAL_REDIRECT_URI
        : process.env.REACT_APP_SPOTIFY_REDIRECT_URI;
      if (!SPOTIFY_CLIENT_ID)
        throw new Error("No Spotify Client ID in env vars!");
      if (!REDIRECT_URI)
        throw new Error("No Spotify Redirect URI in env vars!");
      const scopes =
        "user-read-private user-read-email playlist-modify-private playlist-modify-public playlist-read-private playlist-read-collaborative";
      const state = uuid();
      const url =
        "https://accounts.spotify.com/authorize" +
        "?response_type=code" +
        "&client_id=" +
        SPOTIFY_CLIENT_ID +
        (scopes ? "&scope=" + encodeURIComponent(scopes) : "") +
        "&redirect_uri=" +
        encodeURIComponent(REDIRECT_URI) +
        "&state=" +
        encodeURIComponent(state);
      window.location.href = url;
    } catch (error) {
      console.log("%cCould not authorize Spotify", "color:yellow", error);
    }
  }

  const handleAppleMusicAuthorize = async () => {
    try {
      setIsConnectingToAppleMusic(true);
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Authorizing Apple Music..."
      });
      const { music } = await dispatch(getAppleMusicInstance());
      const appleMusicUserToken = await music.authorize();
      console.log("🔑 Is Apple Music Authorized? ", !!appleMusicUserToken);
      dispatch({
        type: AppleMusicActionType.SET_APPLE_MUSIC_CONNECTED_STATUS,
        payload: !!appleMusicUserToken
      });
      await dispatch(slugifyPostInit());
      dispatch({
        type: AppleMusicActionType.SET_APPLE_MUSIC_CONNECTED_STATUS,
        payload: true
      });
      setIsConnectingToAppleMusic(false);
    } catch (error) {
      if (error === 1) {
        handleCancelAppleMusicAuthorization();
        console.warn("🙅 User cancelled Apple Authorization");
      } else {
        console.warn("Apple Authorization Error", error);
      }
    }
  };

  const handleAppleMusicUnauthorize = async () => {
    try {
      setIsDisconnectingFromAppleMusic(true);
      await window.MusicKit.getInstance().unauthorize();
      await dispatch(removeAppleMusicUserData());
      dispatch({
        type: AppleMusicActionType.SET_APPLE_MUSIC_CONNECTED_STATUS,
        payload: false
      });
      setIsDisconnectingFromAppleMusic(false);
    } catch (error) {
      setIsDisconnectingFromAppleMusic(false);
      console.warn(error);
    }
  };

  const handleCancelAppleMusicAuthorization = () => {
    try {
      setIsConnectingToAppleMusic(false);
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: ""
      });
    } catch (error) {
      console.warn(error);
    }
  };

  const handleYouTubeAuth = async () => {
    try {
      if (currentYouTubeUserData.user.id) {
        console.log("%cResetting!", "color:orange");
        await dispatch(signOutYouTubeUser());
        dispatch({ type: YouTubeActionType.RESET_YOUTUBE_USER });
        await dispatch(updateSlugifyUser({ youTubeUserData: {} }));
      } else {
        setIsDoingYouTubeAuth(true);
        if (!gapi) throw new Error("No gapi!");
        if (!gapi.auth2) throw new Error("No gapi.auth2!");
        await dispatch(gapiAuthenticate());
        // const payload = await dispatch(setYouTubeSignInStatus());
        // const channel = await dispatch(getMyYouTubeChannel());
        // if (channel) {
          await dispatch(slugifyPostInit());
        // }
        const payload = await dispatch(createYouTubeUserDataPayload())
        // console.log("payload", payload);
        // console.log("channel", channel);
        console.log("%cPayload","color:orange", payload);
        await dispatch(updateSlugifyUser({ youTubeUserData: { ...payload } }));
        setIsDoingYouTubeAuth(false);
      }
    } catch (error) {
      console.warn("Error in handleYouTubeAuth", error);
      setIsDoingYouTubeAuth(false);
    }
  };

  const renderAppleMusicButton = () => {
    if (isAppleMusicConnected) {
      return (
        <button
          className={`d-flex align-items-center justify-content-center btn btn-sm btn-${
            isDark ? "outline-danger" : "danger"
          }`}
          disabled={isDisconnectingFromAppleMusic}
          id="apple-music-button-unauthorize"
          onClick={() => handleAppleMusicUnauthorize()}
          title={"Disconnect from Apple Music"}
          style={{ height: 32, width: 100 }}
        >
          {isDisconnectingFromAppleMusic ? (
            <div className="mx-0">
              <Spinner size={8} color="danger" type="beat" />
            </div>
          ) : (
            <span>Disconnect</span>
          )}
        </button>
      );
    } else if (isConnectingToAppleMusic) {
      return (
        <button
          className={`d-flex align-items-center justify-content-center btn btn-sm btn-${
            isDark ? "outline-danger" : "danger"
          } `}
          id="apple-music-button-authorize"
          onClick={() => handleCancelAppleMusicAuthorization()}
          style={{ height: 32 }}
          title={"Cancel authorization"}
        >
          <div className="mx-0">
            <Spinner size={18} color="light" type="puff" />
          </div>
          <span className="ms-2">Cancel</span>
        </button>
      );
    } else if (!isAppleMusicConnected) {
      return (
        <button
          className={`btn btn-sm ${
            isDark ? "btn-outline-primary" : "btn-primary"
          }`}
          // className={`btn btn-sm ${
          //   primaryMusicStream === "spotify" ||
          //   isSpotifyConnected ||
          //   primaryMusicStream === "youtube"
          //     ? "btn-outline-primary"
          //     : "btn-outline-primary"
          // }`}
          disabled={
            primaryMusicStream === "spotify" ||
            isSpotifyConnected ||
            primaryMusicStream === "youtube"
          }
          id="apple-music-button-authorize"
          onClick={() => handleAppleMusicAuthorize()}
          title={
            primaryMusicStream !== "appleMusic"
              ? "Set your music stream to Apple Music to connect"
              : "Connect to Apple Music"
          }
        >
          Connect
        </button>
      );
    } else {
      return null;
    }
  };

  const renderSpotifyButton = () => {
    if (isSpotifyConnected) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <small className="me-3 text-info border rounded border-info px-1">
            {displayName}
          </small>
          <button
            className={`btn btn-sm ${
              isDark
                ? // primaryMusicStream === "appleMusic" ||
                  // isAppleMusicConnected ||
                  // primaryMusicStream === "youtube"
                  "btn-outline-danger"
                : "btn-danger"
            }`}
            data-bs-toggle="modal"
            data-bs-target="#disconnect-spotify-modal"
            disabled={
              primaryMusicStream === "appleMusic" ||
              isAppleMusicConnected ||
              primaryMusicStream === "youtube"
            }
            title={
              primaryMusicStream !== "spotify" ? "" : "Disconnect from Spotify"
            }
          >
            {dayjs().isAfter(dayjs(currentSpotifyTokenExpires))
              ? "Expired"
              : "Disconnect"}
          </button>
        </div>
      );
    }
    return (
      <button
        className={`btn btn-sm ${
          isDark ? "btn-outline-primary" : "btn-primary"
        }`}
        disabled={
          primaryMusicStream === "appleMusic" ||
          isAppleMusicConnected ||
          primaryMusicStream === "youtube"
        }
        onClick={() => handleSpotifyAuth()}
        title={
          primaryMusicStream !== "spotify"
            ? "Set your music stream to Spotify to connect"
            : "Connect to Spotify"
        }
        // style={{ width: 80 }}
      >
        Connect
      </button>
    );
  };

  const disconnectYouTubePopover = (
    <Popover id="disconnectYouTubePopover">
      <Popover.Header>Remove Slugify Access</Popover.Header>
      <Popover.Body>
        <p className="mb-0">
          To fully disconnect Slugify from your Google account:
        </p>
        <ol>
          <li>
            Go{" "}
            <a
              href="https://myaccount.google.com/u/1/security"
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </a>
          </li>
          <li>Make sure you are signed in to the correct Google account</li>
          <li>
            Scroll down to <strong>Third-party apps with account access</strong>
          </li>
          <li>
            Click on <strong>Slugify</strong>
          </li>
          <li>
            Under <strong>Third-party apps with account access</strong> Click on{" "}
            <strong>Slugify</strong> again
          </li>
          <li>
            Click <strong>Remove Access</strong>
          </li>
        </ol>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="w-100">
      <DisconnectSpotifyModal />
      <div className="d-flex flex-column align-items-center container px-0 my-3 w-100 animate__animated animate__fadeIn">
        <ul className="list-group w-100">
          {primaryMusicStream === "appleMusic" && (
            <li className="list-group-item px-0 w-100">
              <div className="d-flex align-items-center px-2 px-sm-3">
                <div className="d-flex align-items-center me-auto">
                  <img
                    src={appleMusicIcon}
                    alt="Apple Music Icon"
                    height="36px"
                    width="36px"
                  />
                  <span
                    className="ms-2 appleMusic-font"
                    style={{ fontSize: 20 }}
                  >
                    Apple Music
                  </span>
                </div>
                {!isMobile && loadingStatus && isConnectingToAppleMusic ? (
                  <div className="d-none d-sm-flex me-3 text-body-secondary">
                    {loadingStatus}
                  </div>
                ) : null}
                {renderAppleMusicButton()}
              </div>
            </li>
          )}
          {primaryMusicStream === "spotify" && (
            <li className="list-group-item px-0 w-100">
              <div className="d-flex flex-column px-2 px-sm-3">
                <div className="d-flex justify-content-between align-items-center">
                  <img
                    src={spotify}
                    alt="spotify logo"
                    height="36px"
                    width="120px"
                  />
                  {renderSpotifyButton()}
                </div>
              </div>
            </li>
          )}
          {primaryMusicStream === "youtube" && !formIsDirty && (
            <li className="d-flex align-items-center justify-content-between list-group-item px-2 px-sm-3 w-100">
              <div className="d-flex align-items-center ms-2 me-auto">
                <span>
                  {currentYouTubeUserData.user.id
                    ? "Sign Out from Google"
                    : "Sign in to Connect Slugify to YouTube"}
                </span>
                {currentYouTubeUserData.user.id && (
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={disconnectYouTubePopover}
                    rootClose
                  >
                    <span
                      className="icon-info slug-icon text-info ms-1 mb-0"
                      style={{ cursor: "pointer" }}
                    />
                  </OverlayTrigger>
                )}
              </div>
              {isDoingYouTubeAuth && (
                <div className="d-flex">
                  <small className="text-body-secondary">
                    {loadingStatus && loadingStatus !== "Ready to rock!!"
                      ? loadingStatus
                      : "Just a sec"}
                  </small>
                  <div className="mx-3">
                    <Spinner size={6} color={"danger"} type="beat" />
                  </div>
                </div>
              )}
              {
                // (!isDoingYouTubeAuth && !currentYouTubeUserData) ||
                !isDoingYouTubeAuth && !currentYouTubeUserData.user.id ? (
                  <img
                    alt="Sign In With YouTube"
                    className="my-1"
                    height={42}
                    onClick={() => handleYouTubeAuth()}
                    src={
                      isDoingYouTubeAuth
                        ? isDark
                          ? googleSignInDarkDisabled
                          : googleSignInLightDisabled
                        : isDark
                        ? googleSignInDark
                        : googleSignInLight
                    }
                    style={{ cursor: "pointer" }}
                    width={"auto"}
                  />
                ) : (
                  // <img
                  //   alt="Youtube Icon"
                  //   className="my-1"
                  //   height={24}
                  //   onClick={() => handleYouTubeAuth()}
                  //   src={isDark ? youTubeIconDark : youTubeIconLight}
                  //   style={{ cursor: "pointer" }}
                  //   width={"auto"}
                  // />
                  // <button
                  //   className={`btn btn-sm btn-${
                  //     isDark ? "outline-primary" : "primary"
                  //   } ms-auto`}
                  //   disabled={isAppleMusicConnected || isSpotifyConnected}
                  //   onClick={() => handleYouTubeAuth()}
                  //   // ref={googleAuthButton}
                  // >
                  //   Connect
                  // </button>
                  <div className="d-flex align-items-center">
                    {!isDoingYouTubeAuth &&
                      currentYouTubeUserData &&
                      currentYouTubeUserData.user &&
                      currentYouTubeUserData.user.imageUrl && (
                        <a
                          className="text-decoration-none"
                          href="https://myaccount.google.com"
                          rel="noopener noreferrer"
                          target="_blank"
                          title="Manage your Google Account"
                        >
                          <img
                            height="30px"
                            width="30px"
                            src={currentYouTubeUserData.user.imageUrl}
                            alt="Google Account"
                            style={{ borderRadius: "50%" }}
                          />
                        </a>
                      )}
                    {!isDoingYouTubeAuth && (
                      <button
                        className={`btn btn-sm btn-outline-danger ms-2`}
                        onClick={() => handleYouTubeAuth()}
                      >
                        Sign Out
                      </button>
                    )}
                  </div>
                )
              }
            </li>
          )}
        </ul>
        {/* <div>
          <small>
            Apple Music and Spotify users can also connect to YouTube
          </small>
        </div> */}
      </div>
    </div>
  );
}
