import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { trimText } from "../functions";
import spotifyIcon from "../images/Spotify_Icon_RGB_Green.png";

interface Props {
  item: any;
  showButton?: boolean;
  showMyTracks?: boolean;
  showSlurpedTracks?: boolean;
}
export default function SlugifySpotifyMusicItem({
  item,
  showButton = false,
  showMyTracks,
  showSlurpedTracks
}: Props) {
  const { statusBg, toolTipText } = useMemo(() => {
    let slurpedBy = null;
    let toolTipText = null;

    let statusBg = "bg-body-tertiary";
    switch (item.slurpStatus) {
      case "fullySlurped":
        statusBg = "bg-success-subtle";
        break;
      case "partiallySlurped":
        statusBg = "bg-danger-subtle";
        break;
      default:
        break;
    }
    if (item.slurpedBy && item.slurpedBy.length) {
      if (item.slurpedBy.length === 1) {
        slurpedBy = item.slurpedBy[0];
      } else {
        const entries = item.slurpedBy.slice(0, -1);
        const lastEntry = item.slurpedBy.slice(-1);
        slurpedBy = entries.join(", ") + ` & ${lastEntry}`;
      }
    }
    if (
      (!showMyTracks && showSlurpedTracks) ||
      (!showMyTracks && !showSlurpedTracks)
    ) {
      if (item.sharedByFriend && item.sharedByFriend.length) {
        toolTipText = `Shared by ${item.sharedByFriend[0]}`;
      }
    } else if (
      (showMyTracks && showSlurpedTracks) ||
      (showMyTracks && !showSlurpedTracks)
    ) {
      toolTipText = slurpedBy ? `Slurped by ${slurpedBy}` : "unslurped";
    }
    return { statusBg, toolTipText };
  }, [item, showMyTracks, showSlurpedTracks]);

  return (
    <OverlayTrigger
      key={item.track.id}
      placement="top"
      overlay={<Tooltip id={`tooltip-${item.track.id}`}>{toolTipText}</Tooltip>}
    >
      <li
        className={`list-group-item ${statusBg} animate__animated animate__fadeIn mt-2 py-2 px-3`}
      >
        <div className="d-flex align-items-center">
          {showButton ? (
            <>
              <a
                className="d-block d-sm-none"
                href={item.track.external_urls.spotify}
                target="_blank"
                title="Listen on Spotify"
                rel="noopener noreferrer"
              >
                <img
                  src={item.track.album.images[2].url}
                  alt={`${item.track.album.name} album art`}
                  height="48px"
                  width="48px"
                />
              </a>
              <a
                className="d-none d-sm-block"
                href={item.track.external_urls.spotify}
                target="_blank"
                title="Listen on Spotify"
                rel="noopener noreferrer"
              >
                <img
                  src={item.track.album.images[2].url}
                  alt={`${item.track.album.name} album art`}
                  height="64px"
                  width="64px"
                />
              </a>
            </>
          ) : (
            <>
              <div className="d-block d-sm-none">
                <img
                  src={item.track.album.images[2].url}
                  alt={`${item.track.album.name} album art`}
                  height="48px"
                  width="48px"
                />
              </div>
              <div className="d-none d-sm-block">
                <img
                  src={item.track.album.images[2].url}
                  alt={`${item.track.album.name} album art`}
                  height="64px"
                  width="64px"
                />
              </div>
            </>
          )}
          <div className="d-flex flex-column ms-3 flex-grow-1">
            <small className="d-block d-sm-none font-weight-bold me-auto">
              {trimText(20, item.track.name)}
            </small>
            <div className="d-none d-sm-block font-weight-bold me-auto">
              {item.track.name}
            </div>
            <small className="d-flex d-sm-none font-weight-normal">
              {trimText(20, item.track.artists[0].name)}
            </small>
            <small className="d-none d-sm-flex font-weight-normal">
              {item.track.artists[0].name}
            </small>
          </div>
          {showButton ? (
            <a
              className="d-none d-sm-block"
              href={item.track.external_urls.spotify}
              target="_blank"
              title="Listen on Spotify"
              rel="noopener noreferrer"
            >
              <span
                role="img"
                aria-label="radio"
                style={{ fontSize: "28px", cursor: "pointer" }}
                title="Listen on Spotify"
              >
                <img
                  src={spotifyIcon}
                  alt="Listen on Spotify"
                  style={{ height: 42, width: 42 }}
                />
              </span>
            </a>
          ) : null}
        </div>
      </li>
    </OverlayTrigger>
  );
}
