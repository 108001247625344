import { useEffect, useState } from "react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { FederatedSignInButton } from "./shared/FederatedSignInButton";
import { useAppSelector } from "../hooks";
import { useHistory, useLocation } from "react-router-dom";
import Button from "./shared/Button";
import LoadingPage from "./LoadingPage";
import StatusMessage from "./shared/StatusMessage";

const isDev = process.env.NODE_ENV === "development";

export default function AuthCallback() {
  const {
    auth: { username },
    errors
  } = useAppSelector((state) => state);

  const [errorMsg, setErrorMsg] = useState("");
  const [oneOffError, setOneOffError] = useState("");
  const history = useHistory();
  const location = useLocation();

  const paramsString = location.search;
  const params = new URLSearchParams(paramsString);
  useEffect(() => {
    const error_description = params.get("error_description");
    // const { error_description } = qs.parse(location.search.slice(1));
    if (errors.COGNITO_USER_SIGNUP === "invalid_grant") {
      setErrorMsg("This is a bug.  And I don't know how to fix it yet.");
    } else if (
      error_description &&
      typeof error_description === "string" &&
      error_description.includes("User does not exist with email")
    ) {
      setErrorMsg("You need an account before signing in with Social login");
    } else if (
      error_description &&
      typeof error_description === "string" &&
      error_description.includes(
        "Already found an entry for username SignInWithApple"
      )
    ) {
      setOneOffError("Apple");
    } else if (
      error_description &&
      typeof error_description === "string" &&
      error_description.includes("Already found an entry for username Google")
    ) {
      setOneOffError("Google");
    } else {
      setErrorMsg(error_description as string);
    }
  }, [errors, location]);

  // const { error } = qs.parse(location.search.slice(1));
  const error = params.get("error");
  if (error && typeof error === "string" && error.includes("cancelled")) {
    if (isDev) {
      console.log("%cUser cancelled sign in.", "color:yellow");
    }
    history.push("/");
    return null;
  } else if (!error && errors.COGNITO_USER_SIGNUP === "invalid_grant") {
    // wtf?
    if (isDev) {
      console.log("%cInvalid grant error.", "color:yellow", errors);
    }
  } else if (!error) {
    if (isDev) {
      console.log("%cNo errors.  Showing loading page...", "color:lime");
    }
    return <LoadingPage />;
  }

  return (
    <div className="d-flex container flex-column align-items-center justify-content-center text-center vh-100">
      {oneOffError ? (
        <>
          <StatusMessage title="Account Linked" emoticon="️️️️🤸‍♀️" size="md" />
          <div className="d-flex flex-column align-items-center">
            <p className="lead text-dark">
              You can now sign in with {oneOffError}!
            </p>
            {oneOffError === "Apple" ? (
              <>
                <p className="lead text-dark">
                  You&apos;ll need sign in once more and you&apos;re done.
                </p>
                <div
                  className="d-flex justify-content-center"
                  style={{ maxWidth: "300px" }}
                >
                  <FederatedSignInButton
                    btnText="Continue with Apple"
                    provider={CognitoHostedUIIdentityProvider.Apple}
                  />
                </div>
              </>
            ) : (
              <div
                className="d-flex justify-content-center"
                style={{ maxWidth: "300px" }}
              >
                <FederatedSignInButton
                  btnText="Continue with Google"
                  provider={CognitoHostedUIIdentityProvider.Google}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center container px-3 px-sm-0 vh-100">
          <span role="img" aria-label="error emoji">
            {errorMsg && errorMsg.includes("You need an account") ? (
              <StatusMessage title="Not so fast" emoticon="👮🏽‍♀️" size="sm" />
            ) : (
              <StatusMessage
                title="Something's not right"
                emoticon="💣"
                size="sm"
              />
            )}
          </span>
          {errorMsg && errorMsg.includes("You need an account") ? (
            <div className="d-flex flex-column align-items-center text-center mt-3">
              <p className="lead text-dark">
                You need to have a Slugify account before signing in with Apple
                or Google
              </p>
              <p className="lead text-dark mb-4">
                If you have one, sign back in and add those addys under User
                Customization in Settings.
              </p>
            </div>
          ) : (
            <>
              <div className="lead text-dark mb-3">
                Here&apos;s an error message:
              </div>
              <div className="lead text-danger mb-4 font-weight-bold">
                {errorMsg}
              </div>
            </>
          )}
          <Button
            btnText={username ? "Home" : "Back to Sign in"}
            className="mt-3 mb-5"
            color="primary"
            fn={() => history.push(username ? "/home" : "/signIn")}
          />
        </div>
      )}
    </div>
  );
}
