import { ChangeEvent } from "react";
import { Accordion, Badge, Card, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { CookiesActionType } from "../../../types";
export default function CookiesAccordion() {
  const dispatch = useAppDispatch();
  const { draftCookiesConsent } = useAppSelector((state) => state.cookies);

  enum ConsentItemType {
    ADVERTISING = "advertising",
    FUNCTIONAL = "functional",
    PERFORMANCE = "performance"
  }
  function handleChange(
    e: ChangeEvent<HTMLInputElement>,
    type: ConsentItemType
  ) {
    if (type === ConsentItemType.ADVERTISING) {
      dispatch({
        type: CookiesActionType.SET_COOKIES_DRAFT_CONSENT,
        payload: {
          ...draftCookiesConsent,
          isAdvertisingEnabled: !draftCookiesConsent.isAdvertisingEnabled
        }
      });
    }
    if (type === ConsentItemType.FUNCTIONAL) {
      dispatch({
        type: CookiesActionType.SET_COOKIES_DRAFT_CONSENT,
        payload: {
          ...draftCookiesConsent,
          isFunctionalEnabled: !draftCookiesConsent.isFunctionalEnabled
        }
      });
    }
    if (type === ConsentItemType.PERFORMANCE) {
      dispatch({
        type: CookiesActionType.SET_COOKIES_DRAFT_CONSENT,
        payload: {
          ...draftCookiesConsent,
          isPerformanceEnabled: !draftCookiesConsent.isPerformanceEnabled
        }
      });
    }
  }
  return (
    <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>Essential Cookies</div>
            <Badge className="me-3" bg="success">
              Always Active
            </Badge>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          Essential cookies are required to enable basic features, such as
          remembering these cookie consent preferences and providing secure
          log-in. These cookies do not store any personally identifiable data.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>Functional</div>
            <Form.Check
              checked={draftCookiesConsent.isFunctionalEnabled}
              className="me-3 position-relative"
              onChange={(e) => handleChange(e, ConsentItemType.FUNCTIONAL)}
              onClick={(e) => e.stopPropagation()}
              type="switch"
              id="custom-functional-switch"
            />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          Functional cookies enable specific functionality, like supporting
          light and dark mode preferences.{" "}
          <span className="text-danger">
            Disabling these cookies may result in certain features not working
            as expected.
          </span>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header
          className="px-0"
          as={Card.Header}
          variant="link"
          style={{
            cursor: "pointer",
            border: "none",
            backgroundColor: "transparent"
          }}
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>Performance</div>
            <Form style={{ cursor: "pointer" }}>
              <Form.Check
                checked={draftCookiesConsent.isPerformanceEnabled}
                className="me-3"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleChange(e, ConsentItemType.PERFORMANCE)}
                style={{ cursor: "pointer" }}
                type="switch"
                id="custom-performance-switch"
              />
            </Form>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          Performance cookies provide anonymous statistics about how customers
          navigate our site so we can improve site experience and performance.
          Approved third parties may perform analytics on our behalf, but they
          cannot use the data for their own purposes.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header
          className="px-0"
          as={Card.Header}
          variant="link"
          style={{
            cursor: "pointer",
            border: "none",
            backgroundColor: "transparent"
          }}
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>Advertising</div>
            <Form.Check
              checked={draftCookiesConsent.isAdvertisingEnabled}
              className="me-3"
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handleChange(e, ConsentItemType.ADVERTISING)}
              style={{ cursor: "pointer" }}
              type="switch"
              id="custom-advertising-switch"
            />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          Advertising cookies may be set through our site by us or our
          advertising partners and help us deliver relevant marketing content.
          If you do not allow these cookies, you will experience less relevant
          advertising.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
