import uniqBy from "lodash/uniqBy";

export default (state = [], action) => {
  switch (action.type) {
    case "ADD_NEW_VIDEO_TO_SLUG_TUBE_SUCCESS":
      return uniqBy(state.concat(action.payload), "id");
    case "GET_SLUGTUBE_VIDEO_LINKS_FROM_DB_SUCCESS":
      return uniqBy(state.concat(action.payload), "id");
    // case "MARK_VIDS_WATCHED_SUCCESS":
    //   return state.filter(item => !action.payload.includes(item));
    default:
      return state;
  }
};
