import { useDispatch, useSelector } from "react-redux";
import { handleDelete, handleDeleteAll } from "../actions";

const SlugModal = () => {
  const dispatch = useDispatch();
  const slugBucket = useSelector((state) => state.slugBucket);
  const {
    modalAction: action,
    artist,
    collapseId,
    title,
    fileToDelete
  } = slugBucket;

  const getActionButton = (action) => {
    switch (action) {
      case "delete":
        return (
          <button
            type="button"
            className={`btn btn-danger`}
            onClick={() => dispatch(handleDelete(fileToDelete))}
            data-bs-toggle="collapse"
            data-bs-target={collapseId}
          >
            Delete
          </button>
        );
      case "delete-all":
        return (
          <button
            type="button"
            className={`btn btn-danger`}
            onClick={() => dispatch(handleDeleteAll())}
            // onClick={() => props.handleDeleteAll()}
          >
            Delete
          </button>
        );
      // case "download":
      //   return (
      //     <a href={url} download={fileName} className="btn btn-primary">
      //       Download
      //     </a>
      //   );

      default:
        break;
    }
  };
  const getActionDescription = (action) => {
    switch (action) {
      case "delete":
        return "Delete Song";
      case "delete-all":
        return "Delete All Songs";
      case "download":
        return "Download Song";

      default:
        break;
    }
  };
  return (
    <div className="modal fade" id="slug-modal">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title lead">{`${getActionDescription(
              action
            )} from Slug Bucket`}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="lead d-flex flex-column">
              {action === "delete-all" ? (
                <>
                  <div className="align-self-center">
                    This will delete all songs not uploaded by you.
                  </div>
                  <div className="align-self-center">
                    Are you sure you want to do this?
                  </div>
                </>
              ) : (
                <div className="align-self-center">{`${title} by ${artist}`}</div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between modal-footer">
            <button
              type="button"
              className="btn btn-link text-body-secondary text-decoration-none"
              data-bs-dismiss="modal"
            >
              cancel
            </button>
            {getActionButton(action)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlugModal;
