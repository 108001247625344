import { SlugifyTrackType, UnmatchedSpotifyTrack } from "./slugify";

export enum AppleMusicActionType {
  GET_MATCHING_APPLE_MUSIC_TRACKS_SUCCESS = "get_matching_apple_music_tracks_success",
  SET_APPLE_MUSIC_CONNECTED_STATUS="set_apple_music_connected_status"
}

export interface AppleMusicState {
  appleMusicTokenData: AppleMusicTokenData;
  isConnected: boolean;
  myPlaylists: AppleMusicPlaylist[];
  tracks: AppleMusicTrack[];
  trackSearch: AppleMusicTrack[];
  unmatchedTracks: UnmatchedSpotifyTrack[];
}

export interface AppleMusicPlaylist {
  id: string;
  type: string;
  href: string;
  attributes: {
    canEdit: boolean;
    name: string;
    description: {
      standard: string;
    };
    isPublic: boolean;
    hasCatalog: false;
    playParams: {
      id: string;
      globalId?: string;
      kind: string;
      isLibrary: boolean;
    };
    dateAdded: string;
  };
}

export interface AppleMusicTokenData {
  token?: string;
  exp?: number;
}

export interface AppleMusicTrack {
  id: string;
  type: string;
  href: string;
  attributes: {
    albumName: string;
    trackNumber: number;
    durationInMillis: number;
    releaseDate: string;
    isrc: string;
    artwork: AppleMusicArtwork;
    composerName: string;
    playParams: {
      id: string;
      kind: string;
    };
    url: string;
    discNumber: number;
    hasLyrics: boolean;
    isAppleDigitalMaster: boolean;
    name: string;
    previews: { url: string }[];
    artistName: string;
    addedBy: string;
    trackType: SlugifyTrackType;
    playlist: string;
    slurpedBy: string[];
    slurpStatus: string;
  };
}

export interface AppleMusicArtwork {
      width: number;
      height: number;
      url: string;
      bgColor: string;
      textColor1: string;
      textColor2: string;
      textColor3: string;
      textColor4: string;
}

// unmatched
export interface AppleMusicUnmatchedTrack {
  trackType: SlugifyTrackType;
  added_by: { id: string };
  sharedByFriend: string[];
  appleMusicInfo: {
    albumArt: string;
    appleMusicId: string;
    appleMusicIsrc: string;
    addedBy: string;
    album: string;
    artist: string;
    title: string;
  };
}

// matched
export interface AppleMusicMatchedTrack {
  track: AppleMusicTrack;
  trackType: SlugifyTrackType;
  added_by: { id: string };
  matchedBy: string;
  spotifyInfo: {
    albumArt: AppleMusicArtwork;
    appleMusicId: string;
    appleMusicIsrc: string;
    addedBy: string;
    album: string;
    artist: string;
    title: string;
  };
}
