import { doSignOutCognitoUser } from "./actions";
import { useAppDispatch } from "./hooks";
import { useLocation } from "react-router-dom";
import StatusMessage from "./components/shared/StatusMessage";

function Aloha() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { primaryMusicStream } = location.state;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-center w-100 vh-100">
      <StatusMessage
        title="Thanks for trying Slugify"
        emoticon="🌺"
        subtitle="Your user account and data have been deleted."
      />

      {primaryMusicStream ? (
        <p className="lead status-subtitle-md">
          {`You can manually remove the Slugify playlist on ${
            primaryMusicStream === "appleMusic" ? "Apple Music" : "Spotify"
          }`}{" "}
          <strong>
            <a
              href={
                primaryMusicStream === "appleMusic"
                  ? "https://music.apple.com/online"
                  : "https://open.spotify.com/collection/playlists"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </strong>
          .
        </p>
      ) : null}

      <button
        type="button"
        className="btn btn-outline-primary my-3"
        data-bs-dismiss="modal"
        onClick={() => dispatch(doSignOutCognitoUser())}
      >
        Aloha
      </button>
    </div>
  );
}

export default Aloha;
