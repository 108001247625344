import { useState } from "react";
import { createSlugifySlurpedTracks } from "../actions";
import { isMobile } from "react-device-detect";
import { Modal } from "react-bootstrap";
import { SlugifyTrackType } from "../types"; // enum
import { useAppDispatch, useAppSelector } from "../hooks";
import Button from "./shared/Button";
import dayjs from "dayjs";
import type { SlugifySlurpedTrack, SpotifyArtist } from "../types";

interface Props {
  videoData: {
    error?: string;
    item?: gapi.client.youtube.Video;
    source?: any;
  };
  setVideoData: (v: { error?: string; item?: any }) => void;
  isYtMusicModalOpen: boolean;
  setIsYtMusicModalOpen: (b: boolean) => void;
}
export const SlugTubeMusicModal = ({
  videoData,
  setVideoData,
  isYtMusicModalOpen,
  setIsYtMusicModalOpen
}: Props) => {
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const { username } = useAppSelector((state) => state.auth);

  function cleanUpModal() {
    setVideoData({});
    setIsLoaded(false);
  }

  function handleClose() {
    cleanUpModal();
    setIsYtMusicModalOpen(false);
  }

  async function handleMarkAsNotFound() {
    try {
      setIsSpinning(true);
      console.log("DATA", videoData);
      const { source } = videoData;
      if (!source) {
        throw new Error("No track source!");
      }
      if (!("trackType" in source)) {
        throw new Error("No trackType source!");
      }
      const payload: Partial<SlugifySlurpedTrack> = {
        toBeDeletedOn: dayjs().add(1, "month").toISOString(),
        owner: username,
        username: username
      };
      if (source.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE) {
        payload.trackType = SlugifyTrackType.YOUTUBE_UNMATCHED_FROM_APPLE_MUSIC;
        payload.appleMusicId = source.id;
        payload.originalTrackAddedBy = source.addedBy as string;
        payload.appleMusicTrackTitle = source.attributes.name;
        payload.appleMusicTrackAlbum = source.attributes.albumName;
        payload.appleMusicTrackArtist = source.attributes.artistName;
        payload.appleMusicTrackIsrc = source.attributes.isrc;
      } else if (source.trackType === SlugifyTrackType.SPOTIFY_NATIVE) {
        payload.trackType = SlugifyTrackType.YOUTUBE_UNMATCHED_FROM_SPOTIFY;
        payload.spotifyId = source.track.id;
        payload.originalTrackAddedBy = source.added_by.id as string;
        payload.spotifyTrackTitle = source.track.name;
        payload.spotifyTrackAlbum = source.track.album.name;
        payload.spotifyTrackArtists = source.track.artists.map(
          (item: SpotifyArtist) => item.name
        );
        payload.spotifyTrackIsrc = source.track.external_ids.isrc;
      }
      // console.log("PAYLOAD", payload);
      await dispatch(createSlugifySlurpedTracks([payload]));
      setIsSpinning(false);
      setIsYtMusicModalOpen(false);
    } catch (error) {
      setIsSpinning(false);
      console.warn("Could not mark as not found", error);
    }
  }

  return (
    <Modal
      className="d-flex justify-content-center"
      centered
      show={isYtMusicModalOpen}
      onHide={() => handleClose()}
    >
      <Modal.Body
        className="d-flex justify-content-center m-0 p-0"
        style={{
          maxWidth: isMobile ? 240 : 480,
          maxHeight: isMobile ? 180 : 360
        }}
      >
        {/* {!isLoaded && !videoData.error && (
          <MoonLoader
            size={64}
            style={{ backgroundColor: "transparent" }}
          />
        )} */}
        {!isLoaded && videoData.error && (
          <div className="d-flex flex-column align-items-center text-light m-4">
            <div style={{ fontSize: 56 }}>
              <span role="img" aria-label="scream emoji">
                😱
              </span>
            </div>
            <div className="text-center" style={{ fontSize: 28 }}>
              Can&apos;t find that track on YouTube!
            </div>
            <Button
              btnText="Mark as Not Found"
              className="mt-3"
              color="primary"
              fn={() => handleMarkAsNotFound()}
              isDisabled={isSpinning}
              isSpinning={isSpinning}
              outline
              spinnerColor="primary"
            />
          </div>
        )}
        {/* {!isLoaded &&
          (!videoData.error ? (
            <div className="bg-body-tertiary">
              <Spinner color="light" size={64} type="moon" />
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center text-light">
              <div style={{ fontSize: 48 }}>
                <span role="img" aria-label="scream emoji">
                  😱
                </span>
              </div>
              <div style={{ fontSize: 32 }}>
                Can&apos;t find that track on YouTube!
              </div>
            </div>
          ))} */}
        {videoData && !videoData.error && videoData.item && (
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            // frameBorder="0"
            height={isLoaded ? (isMobile ? 180 : 360) : 0}
            // onLoad={() => (videoData.id ? setIsLoaded(true) : null)}
            onLoad={() => setIsLoaded(videoData?.item?.id ? true : false)}
            src={`https://www.youtube.com/embed/${videoData?.item?.id}?autoplay=1`}
            title={videoData.item.id}
            width={isLoaded ? (isMobile ? 240 : 480) : 0}
            style={{ backgroundColor: "black" }}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
