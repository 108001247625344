import { configureStore } from "@reduxjs/toolkit";
import { AnyAction, combineReducers } from "redux";
import { ThunkAction } from "redux-thunk";
import logger from "redux-logger";
import appleMusic from "../reducers/appleMusicReducer";
import auth from "../reducers/authReducer";
import cookies from "../reducers/cookiesReducer";
import errors from "../reducers/errorReducer";
// import settings from "../reducers/settingsReducer";
import slugBucket from "../reducers/slugBucketReducer";
import slugify from "../reducers/slugifyReducer";
import slugTube from "../reducers/slugTubeReducer";
import slugTubeComments from "../reducers/slugTubeCommentsReducer";
import spotify from "../reducers/spotifyReducer";
import user from "../reducers/userReducer";
import newUser from "../reducers/newUserReducer";
import youtube from "../reducers/youTubeReducer";

const isDev = process.env.NODE_ENV === "development";

const appReducer = combineReducers({
  appleMusic,
  auth,
  cookies,
  errors,
  // settings,
  slugBucket,
  slugify,
  slugTube,
  slugTubeComments,
  spotify,
  user,
  newUser,
  youtube
});

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction
) => {
  if (action.type === "SIGN_OUT_SUCCESS") {
    return appReducer(undefined, { type: undefined });
  }
  return appReducer(state, action);
};
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    isDev
      ? getDefaultMiddleware({ serializableCheck: false }).concat(logger)
      : getDefaultMiddleware({ serializableCheck: false })
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
