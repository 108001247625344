import { useState } from "react";
import { createSlugifySlurpedTracks } from "../actions";
import { useAppDispatch, useAppSelector } from "../hooks";
import $ from "jquery";
import dayjs from "dayjs";
import { SlugifySlurpedTrack } from "../types";

interface Props {
  item: any;
}
export default function SlugifyAppleMusicUnmatchedItem({ item }: Props) {
  const dispatch = useAppDispatch();
  const {
    auth: { username },
    newUser: { friends }
  } = useAppSelector((state) => state);

  const [isForgetting, setIsForgetting] = useState(false);

  // useEffect(() => {
  //   $('[data-bs-toggle="tooltip"]').tooltip();
  //   return () => $('[data-bs-toggle="tooltip"]').tooltip("dispose");
  // }, []);

  const fixTrackTitle = (title: string) => {
    if (process.env.NODE_ENV === "development") {
      console.log("%cFixing Track Title", "color:cyan", title);
    }
    let fixedTitle = title;
    if (title.includes("-")) {
      fixedTitle = fixedTitle.split("-")[0].trim();
    }
    if (title.includes("(")) {
      fixedTitle = fixedTitle.split("(")[0].trim();
    }
    return fixedTitle;
  };

  const getSearchTerm = () => {
    const term =
      // encodeURIComponent(fixTrackTitle(item.title));
      encodeURIComponent(fixTrackTitle(item.title)) +
      "%20" +
      encodeURIComponent(item.artist);
    return term;
  };

  const getToolTipText = () => {
    if (item.sharedByFriend) {
      return `Shared by ${item.sharedByFriend[0]}`;
    }
  };
  return (
    <li
      className={`d-flex justify-content-center list-group-item mb-2 rounded bg-body-tertiary ${
        item.isFound || item.isDeleted ? "selected-list-item" : ""
      }`}
      key={item.spotifyId}
      data-bs-toggle={
        friends.length > 0 && item.sharedByFriend && item.sharedByFriend.length
          ? "tooltip"
          : null
      }
      data-placement="top"
      style={{ border: "none" }}
      title={
        friends.length > 0 && item.sharedByFriend && item.sharedByFriend.length
          ? getToolTipText()
          : ""
      }
    >
      <div className="d-flex align-items-center w-100">
        <img
          className="d-block d-sm-none"
          src={item.albumArt.url}
          alt={`${item.album} album art`}
          height="48px"
          width="48px"
          style={{ borderRadius: "8px" }}
        />
        <img
          className="d-none d-sm-block"
          src={item.albumArt.url}
          alt={`${item.album} album art`}
          height="64px"
          width="64px"
          style={{ borderRadius: "8px" }}
        />
        <div className="d-flex flex-column ms-3 flex-grow-1">
          <small className="d-block d-sm-none font-weight-bold me-auto">
            {item.title}
          </small>
          <div className="d-none d-sm-block font-weight-bold me-auto">
            {item.title}
          </div>
          <small className="font-weight-normal">{item.artist}</small>
        </div>
        <div className="d-none d-sm-flex">
          <i
            className={`icon-delete_forever slug-icon text-danger ms-auto me-2 ${
              isForgetting
                ? "animate__animated animate__headShake animate__infinite"
                : ""
            }`}
            role="img"
            aria-label="trash can"
            onClick={
              !isForgetting
                ? () => {
                    try {
                      $('[data-bs-toggle="tooltip"]').tooltip("dispose");
                      setIsForgetting(true);
                      const forgottenTrack: Partial<SlugifySlurpedTrack> = {};
                      forgottenTrack.originalTrackAddedBy = item.added_by;
                      forgottenTrack.owner = username;
                      forgottenTrack.spotifyId = item.spotifyId;
                      forgottenTrack.trackType = item.trackType;
                      forgottenTrack.toBeDeletedOn = dayjs()
                        .add(1, "month")
                        .toISOString();
                      forgottenTrack.username = username;
                      dispatch(createSlugifySlurpedTracks([forgottenTrack]));
                    } catch (error) {
                      setIsForgetting(false);
                      console.warn(error);
                    }
                  }
                : () => {
                    console.log("I do nothing");
                  }
            }
            style={{
              fontSize: "28px",
              cursor: isForgetting ? "default" : "pointer"
            }}
            title="Forget this track"
          />
          <a
            className="text-decoration-none"
            href={`https://music.apple.com/search?term=${getSearchTerm()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="icon-search slug-icon text-body-secondary ms-auto"
              style={{ fontSize: "28px", cursor: "pointer" }}
              title="Search for this track on Apple Music"
            />
          </a>
        </div>
      </div>
    </li>
  );
}
