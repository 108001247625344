import { useContext, useEffect, useState } from "react";
import { Spinner } from "./shared/Spinner";
import { createNewSpotifyPlaylist } from "../actions";
import StatusMessage from "./shared/StatusMessage";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { ThemeContext } from "../contexts/ColorThemeProvider";

interface Props {
  isCreateNewSpotifyPlaylistModalOpen: boolean;
  setIsCreateNewSpotifyPlaylistModalOpen: (b: boolean) => void;
}
export const CreateNewSpotifyPlaylistModal = ({
  isCreateNewSpotifyPlaylistModalOpen,
  setIsCreateNewSpotifyPlaylistModalOpen
}: Props) => {
  const dispatch = useDispatch();
  const { isDark } = useContext(ThemeContext);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [newPlaylistName, setNewPlaylistName] = useState("");

  useEffect(() => {
    const cleanUpModal = () => {
      setNewPlaylistName("");
      setIsSpinning(false);
      setIsDone(false);
    };

    () => {
      cleanUpModal();
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    try {
      setIsSpinning(true);
      await dispatch(createNewSpotifyPlaylist(newPlaylistName));
      setIsSpinning(false);
      setIsDone(true);
    } catch (error) {
      setIsSpinning(true);
    }
  };

  const renderBody = () => {
    if (isDone) {
      return (
        <StatusMessage
          title="Success"
          emoticon="🌟"
          subtitle={`${newPlaylistName} was created on Spotify!`}
          subtitle2=" "
        />
      );
    }
    return (
      <form onSubmit={(e) => handleSubmit(e)}>
        <StatusMessage
          emoticon="tipper"
          subtitle="This will create a new playlist on Spotify"
        />
        <div className="d-flex flex-column mb-3 w-100">
          <label
            className="form-label"
            htmlFor="new-playlist-name-input"
            id="new-playlist-name-label"
          >
            Enter new playlist name:
          </label>
          <input
            className="form-control"
            type="text"
            id="new-playlist-name-input"
            placeholder="Slugify Slurped Tracks"
            aria-describedby="new-playlist-name-label"
            onChange={(e) => setNewPlaylistName(e.target.value)}
            value={newPlaylistName}
          />
        </div>
      </form>
    );
  };

  return (
    <>
      <Modal
        centered={!isMobile}
        show={isCreateNewSpotifyPlaylistModalOpen}
        onHide={() => setIsCreateNewSpotifyPlaylistModalOpen(false)}
      >
        <Modal.Header closeButton>Create New Playlist</Modal.Header>
        <Modal.Body>{renderBody()}</Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <button
              type="button"
              className="btn btn-link text-body-secondary text-decoration-none"
              onClick={() => setIsCreateNewSpotifyPlaylistModalOpen(false)}
            >
              {isDone ? "close" : "cancel"}
            </button>
            <button
              className={`d-flex align-items-center justify-content-center btn btn-sm btn-${
                isDark ? "outline-primary" : "primary"
              }`}
              disabled={isSpinning || isDone}
              // onClick={(e) => handleSubmit(e)}
              type="submit"
              style={{ height: 40, width: 170 }}
            >
              {isSpinning ? (
                <div className="me-1">
                  <Spinner
                    size={18}
                    color={isDark ? "primary" : "light"}
                    type="puff"
                  />
                </div>
              ) : (
                <span role="img" aria-label="sparkles">
                  ✨
                </span>
              )}
              <span className="ms-2">Create Playlist</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* <div className="modal fade" id="create-new-spotify-playlist-modal">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lead">Create New Playlist</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="d-flex flex-column modal-body">{renderBody()}</div>
            <div className="d-flex justify-content-between modal-footer">
              <button
                type="button"
                className="btn btn-link text-body-secondary text-decoration-none"
                data-bs-dismiss="modal"
              >
                {isDone ? "close" : "cancel"}
              </button>
              <button
                className="d-flex align-items-center justify-content-center btn btn-primary"
                disabled={isSpinning || isDone}
                // onClick={(e) => handleSubmit(e)}
                type="submit"
                style={{ height: 40, width: 170 }}
              >
                {isSpinning ? (
                  <div className="me-1">
                    <Spinner size={18} color="light" type="puff" />
                  </div>
                ) : (
                  <span role="img" aria-label="sparkles">
                    ✨
                  </span>
                )}
                <>
                  <span className="ms-2">Create Playlist</span>
                </>
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
