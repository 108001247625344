import {
  getAlbumArt,
  getUserAvatar,
  handleDownload,
  isEmptyObject,
  trimText
} from "../functions";
import {
  appleMusicSongSearch,
  handleLike,
  spotifySongSearch
} from "../actions";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { Flipped } from "react-flip-toolkit";
import PropTypes from "prop-types";
import { useEffect } from "react";

const SongListItemSmall = ({ file, index }) => {
  const {
    album,
    artistClearText: artist,
    titleClearText: title,
    fileName,
    liked,
    s3FileName,
    uploadedBy
  } = file;

  const dispatch = useDispatch();
  const currentSpotifyTokenData = useSelector(
    (state) => state.user.spotifyTokenData
  );
  const currentAppleMusicUserData = useSelector(
    (state) => state.user.appleMusicUserData
  );
  const uploadProgress = useSelector(
    (state) => state.slugBucket.uploadProgress
  );

  useEffect(() => {
    $("[data-bs-toggle='popover']").popover({
      trigger: "focus"
    });
  }, []);

  return (
    <Flipped key={index} flipId={fileName + "_sm"}>
      <li className="list-group-item d-flex flex-column justify-contents-center w-100 px-1 mb-1 bg-light">
        <div className="d-flex w-100">
          {getAlbumArt(file)}
          <div className="d-flex flex-column text-left ms-2 w-100">
            <div className="d-flex font-weight-bold text-capitalize">
              {trimText(28, title.toLowerCase())}
            </div>
            <small className="d-flex flex-column">
              <span className="font-weight-normal text-capitalize">
                {artist.toLowerCase()}
              </span>
              <i
                aria-expanded="false"
                aria-controls={"#button-controls_" + index}
                className="icon-more_vert slug-icon text-danger text-right"
                data-bs-toggle="collapse"
                data-bs-target={"#button-controls_" + index}
              />
            </small>
          </div>
        </div>
        <div className="collapse" id={"button-controls_" + index}>
          <div className="d-flex justify-content-center align-items-center mt-1">
            {!isEmptyObject(currentAppleMusicUserData) ? (
              <i
                className="icon-applemusic slug-icon mx-2"
                onClick={
                  uploadProgress > 0
                    ? () => {}
                    : () => {
                        dispatch(appleMusicSongSearch(album, artist, title));
                      }
                }
                data-bs-toggle="modal"
                data-bs-target={
                  uploadProgress > 0 ? "" : "#slug-bucket-apple-music-modal"
                }
                style={{
                  cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`,
                  color: "black",
                  fontSize: "20px"
                }}
                title="Search on Apple Music"
              />
            ) : // <img
            //   src={appleMusicLogo}
            //   alt="Apple Music Logo"
            //   height="20px"
            //   width="20px"
            //   onClick={
            //     uploadProgress > 0
            //       ? () => {}
            //       : () => {
            //           dispatch(appleMusicSongSearch(album, artist, title));
            //         }
            //   }
            //   data-bs-toggle="modal"
            //   data-bs-target={
            //     uploadProgress > 0 ? "" : "#slug-bucket-apple-music-modal"
            //   }
            //   style={{
            //     cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`
            //   }}
            //   title="Search on Apple Music"
            // />
            // </i>
            null}
            {!isEmptyObject(currentSpotifyTokenData) ? (
              <i
                className="icon-spotify slug-icon mx-2"
                onClick={
                  uploadProgress > 0
                    ? () => {}
                    : () => {
                        dispatch(
                          spotifySongSearch(
                            currentSpotifyTokenData,
                            artist,
                            album,
                            title
                          )
                        );
                      }
                }
                data-bs-toggle="modal"
                data-bs-target={uploadProgress > 0 ? "" : "#spotify-modal"}
                style={{
                  cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`,
                  fontSize: "20px"
                }}
                title="Search on Spotify"
              >
                {/* <img
                  src={spotify}
                  alt="spotify"
                  height="20px"
                  width="20px"
                  onClick={
                    uploadProgress > 0
                      ? () => {}
                      : () => {
                          dispatch(
                            spotifySongSearch(
                              currentSpotifyTokenData,
                              artist,
                              album,
                              title
                            )
                          );
                        }
                  }
                  data-bs-toggle="modal"
                  data-bs-target={uploadProgress > 0 ? "" : "#spotify-modal"}
                  style={{
                    cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`
                  }}
                  title="Search on Spotify"
                /> */}
              </i>
            ) : null}
            <div className="mx-2 mx-sm-1 mb-1">{getUserAvatar(uploadedBy)}</div>
            <i
              onClick={() =>
                dispatch(handleLike(artist, title, s3FileName, "slugBucket"))
              }
              className={`mx-2 mx-sm-1 ${
                liked
                  ? "icon-favorite slug-icon text-danger"
                  : "icon-favorite_outline slug-icon text-body-secondary"
              }`}
              style={{ cursor: "pointer" }}
              title={liked ? "Unlike" : "Like"}
            />
            <i
              className="d-block d-sm-none icon-play_circle_outline slug-icon mx-2 mx-sm-1 text-secondary"
              onClick={
                uploadProgress > 0
                  ? () => {}
                  : () => {
                      handleDownload(s3FileName);
                    }
              }
              style={{
                cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`
              }}
              title="Download"
            />
            <i
              className="d-none d-sm-block icon-cloud_download slug-icon mx-2 mx-sm-1 text-info"
              onClick={
                uploadProgress > 0
                  ? () => {}
                  : () => {
                      handleDownload(s3FileName);
                    }
              }
              style={{
                cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`
              }}
              title="Download"
            />
            <i
              onClick={
                uploadProgress > 0
                  ? () => {}
                  : () => {
                      dispatch({
                        type: "SET_DATA_FOR_DELETE_MODAL",
                        payload: {
                          fileToDelete: s3FileName,
                          modalAction: "delete",
                          artist,
                          title,
                          collapseId: "#button-controls_" + index
                        }
                      });
                    }
              }
              className="icon-delete_forever slug-icon mx-2 mx-sm-1 text-danger"
              style={{
                cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`
              }}
              data-bs-toggle="modal"
              data-bs-target={uploadProgress > 0 ? "" : "#slug-modal"}
              title="Delete"
            />
          </div>
        </div>
      </li>
    </Flipped>
  );
};

SongListItemSmall.propTypes = {
  file: PropTypes.object.isRequired
};

export default SongListItemSmall;
