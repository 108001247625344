import { compact, omit } from "lodash";
import { getRandomDancers } from "../functions";
import { isMobile } from "react-device-detect";
import {
  // handlePushNotification,
  createSlugifySlurpedTracks,
  getVideosFromYouTubePlaylist,
  getYouTubeVideos,
  addTracksToYouTubePlaylist
} from "../actions";
import {
  SelectedPlaylist,
  SlugifySlurpedTrack,
  SlugifyTrackType
} from "../types";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useCallback, useContext, useMemo, useState } from "react";
import dayjs from "dayjs";
import youTubeIcon from "../images/yt_icon_rgb.png";
import { Modal } from "react-bootstrap";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { Spinner } from "./shared/Spinner";
import SlugifySlurpProgressBar from "./SlugifySlurpProgressBar";

interface Props {
  selectedMusicPlaylist: SelectedPlaylist | undefined;
  selectedVideosPlaylist: SelectedPlaylist | undefined;
  isSlugifyYouTubeModalOpen: boolean;
  setIsSlugifyYouTubeModalOpen: (b: boolean) => void;
  hasMatchedTracks: boolean | undefined;
  hasVideos: boolean | undefined;
}
export default function SlugifyYouTubeModal({
  isSlugifyYouTubeModalOpen,
  setIsSlugifyYouTubeModalOpen,
  selectedMusicPlaylist,
  selectedVideosPlaylist,
  hasMatchedTracks,
  hasVideos
}: Props) {
  const { isDark } = useContext(ThemeContext);
  const dispatch = useAppDispatch();

  const {
    auth: { username: currentUsername },
    slugify: { filteredTracks, slurpedTracks }
  } = useAppSelector((state) => state);

  enum YTSlurpStatus {
    NOT_STARTED = "notStarted",
    SLURPING_MUSIC = "slurpingMusic",
    SLURPING_VIDEOS = "slurpingVideos",
    SENDING_NOTIFICATIONS = "sendingNotifications",
    FINISHED = "finished",
    FINISHED_WITH_ERROR = "finishedWithError"
  }
  const [error, setError] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [status, setStatus] = useState<YTSlurpStatus>(
    YTSlurpStatus.NOT_STARTED
  );

  const dancers = useCallback(() => getRandomDancers(), []);

  async function addTracksToPlaylists() {
    try {
      dispatch({ type: "ADD_TRACKS_TO_YOUTUBE_PLAYLISTS_START" });
      setIsSpinning(true);
      // let slurpedTracksToCreate: Partial<SlugifySlurpedTrack>[] = [];
      if (hasMatchedTracks) {
        console.log(`%cAdding matched tracks to YouTube...`, "color:cyan");
        await handleMusicTracks();
        // if (slurpedMusic) {
        //   slurpedTracksToCreate = slurpedTracksToCreate.concat(slurpedMusic);
        // }
      }
      if (hasVideos) {
        console.log(`%cAdding shared videos to YouTube...`, "color:cyan");
        await handleVideos();
        // if (slurpedVideos) {
        //   slurpedTracksToCreate = slurpedTracksToCreate.concat(slurpedVideos);
        // }
      }
      // if (slurpedTracksToCreate.length) {
      //   await dispatch(createSlugifySlurpedTracks(slurpedTracksToCreate));
      // } else {
      //   console.log("%cNo slurped tracks to create!", "color:orange");
      // }
      setIsSpinning(false);
      dispatch({ type: "ADD_TRACKS_TO_YOUTUBE_PLAYLISTS_SUCCESS" });
      setStatus(YTSlurpStatus.FINISHED);
      console.log("%cNeed to handle notifications!", "color:orange");
      console.log(
        "%cNeed to handle end of process status message!",
        "color:orange"
      );
    } catch (error: any) {
      //
      console.warn(error);
      const message = error?.result?.error?.message || "That didn't work!";
      console.log("Message", message);
      setStatus(YTSlurpStatus.FINISHED_WITH_ERROR);
      setError(message);
      setIsSpinning(false);
      dispatch({
        type: "ADD_TRACKS_TO_YOUTUBE_PLAYLISTS_FAIL",
        payload: error
      });
    }
  }

  async function handleVideos(): Promise<void> {
    try {
      dispatch({ type: "ADD_TRACKS_TO_YOUTUBE_VIDEOS_PLAYLIST_START" });
      setStatus(YTSlurpStatus.SLURPING_VIDEOS);
      if (!selectedVideosPlaylist) {
        console.log("%cNo Selected Videos Playlist!");
        return Promise.resolve();
      }
      const myExistingSlurpedTracks: SlugifySlurpedTrack[] =
        slurpedTracks.filter(
          (item: SlugifySlurpedTrack) => item.owner === currentUsername
        );
      const existingSlurpedTracksForVideosPlaylist =
        myExistingSlurpedTracks.filter(
          (item: SlugifySlurpedTrack) =>
            item.slurpToPlaylistId === selectedVideosPlaylist.id
        );

      console.log(
        `%c🥤 ${currentUsername.toUpperCase()} HAS ${
          existingSlurpedTracksForVideosPlaylist.length
        } EXISTING SLURPED TRACKS ASSOCIATED WITH THEIR ${selectedVideosPlaylist.name.toUpperCase()} PLAYLIST: `,
        "color:cyan",
        existingSlurpedTracksForVideosPlaylist
      );
      const existingSlurpedYoutubeIds = compact(
        existingSlurpedTracksForVideosPlaylist.map(
          (item: SlugifySlurpedTrack) => {
            if (
              (item.trackType === SlugifyTrackType.YOUTUBE_NATIVE ||
                item.trackType ===
                  SlugifyTrackType.YOUTUBE_TRACK_MATCHED_FROM_APPLE_MUSIC ||
                item.trackType ===
                  SlugifyTrackType.YOUTUBE_TRACK_MATCHED_FROM_SPOTIFY) &&
              item.appleMusicId

              // item.trackType === SlugifyTrackType.YOUTUBE_NATIVE &&
              // item.appleMusicId
            ) {
              return item.appleMusicId;
            }
          }
        )
      );
      // console.log(
      //   "%cExistingSlurpedYoutubeIds",
      //   "color:orange",
      //   existingSlurpedYoutubeIds
      // );

      const ytNativeTracks: gapi.client.youtube.PlaylistItem[] =
        filteredTracks.filter(
          (item: gapi.client.youtube.PlaylistItem) =>
            item.kind === "youtube#playlistItem"
        );
      // console.log("%cYouTube native tracks", "color:orange", ytNativeTracks);

      const ytNativeTracksNotYetSlurped: gapi.client.youtube.PlaylistItem[] =
        ytNativeTracks.filter(
          (item: gapi.client.youtube.PlaylistItem) =>
            item.snippet?.resourceId?.videoId &&
            !existingSlurpedYoutubeIds.includes(item.snippet.resourceId.videoId)
        );
      // console.log(
      //   "%cYouTube native tracks not yet slurped",
      //   "color:orange",
      //   ytNativeTracksNotYetSlurped
      // );
      const tracksInSelectedPlaylist = await dispatch(
        getVideosFromYouTubePlaylist(selectedVideosPlaylist.id)
      );
      console.log(
        `%cExisting tracks in ${selectedVideosPlaylist.name} playlist`,
        "color:cyan",
        tracksInSelectedPlaylist
      );
      const trackIdsInSelectedPlaylist = tracksInSelectedPlaylist.map(
        (item) => item.snippet?.resourceId?.videoId
      );
      console.log(
        `%cExisting track IDs in ${selectedVideosPlaylist.name} playlist`,
        "color:cyan",
        trackIdsInSelectedPlaylist
      );

      const unslurpedTracksInPlaylist = ytNativeTracksNotYetSlurped.filter(
        (item) =>
          // item.snippet?.resourceId?.videoId &&
          trackIdsInSelectedPlaylist.includes(item.snippet?.resourceId?.videoId)
      );
      console.log(
        `%cUnslurped tracks in ${selectedVideosPlaylist.name} playlist`,
        "color:cyan",
        unslurpedTracksInPlaylist
      );

      const unslurpedTracksNotInPlaylist = ytNativeTracksNotYetSlurped.filter(
        (item) =>
          // item.snippet?.resourceId?.videoId &&
          !trackIdsInSelectedPlaylist.includes(
            item.snippet?.resourceId?.videoId
          )
      );
      console.log(
        `%cUnslurped tracks not in ${selectedVideosPlaylist.name} playlist`,
        "color:cyan",
        unslurpedTracksNotInPlaylist
      );

      const ids = compact(
        unslurpedTracksNotInPlaylist.map(
          (item) => item.snippet?.resourceId?.videoId
        )
      );
      const insertedVideos = await dispatch(
        addTracksToYouTubePlaylist(selectedVideosPlaylist.id, ids)
      );
      const compacted = compact(insertedVideos);
      console.log(
        `${compacted.length} videos were inserted in ${selectedVideosPlaylist.name}`,
        compacted
      );
      const insertedIds = compacted.map(
        (item) => item.snippet.resourceId.videoId
      );
      let tracksToMarkAsSlurped: any[] = [];
      const successfullyInsertedTracks = ytNativeTracksNotYetSlurped.filter(
        (item) => insertedIds.includes(item.snippet?.resourceId?.videoId)
      );
      console.log(
        "%cSuccessfully Inserted Tracks",
        "color:orange",
        successfullyInsertedTracks
      );
      if (successfullyInsertedTracks) {
        tracksToMarkAsSlurped = [...successfullyInsertedTracks];
      }

      // let slurpedTracksForItemsAlreadyInPlaylist = [];
      if (unslurpedTracksInPlaylist.length || tracksToMarkAsSlurped.length) {
        const combined = tracksToMarkAsSlurped.concat(
          unslurpedTracksInPlaylist
        );
        const payload: Partial<SlugifySlurpedTrack>[] = combined.map((item) => {
          return {
            appleMusicId: item.snippet?.resourceId?.videoId || null,
            appleMusicTrackTitle: item.snippet?.title || null,
            originalTrackAddedBy: item.snippet?.playlistId || "n/a",
            owner: currentUsername,
            slurpToPlaylistId: selectedVideosPlaylist.id,
            toBeDeletedOn: dayjs().add(1, "month").toISOString(),
            trackType: SlugifyTrackType.YOUTUBE_NATIVE,
            username: currentUsername
          };
        });

        console.log(`%cNew slurped tracks payload`, "color:cyan", payload);
        // slurpedTracksForItemsAlreadyInPlaylist = fixed;
        await dispatch(createSlugifySlurpedTracks(payload));
      }

      dispatch({ type: "ADD_TRACKS_TO_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS" });
      return Promise.resolve();
    } catch (error) {
      console.warn(error);
      dispatch({
        type: "ADD_TRACKS_TO_YOUTUBE_VIDEOS_PLAYLIST_FAIL",
        payload: error
      });
      return Promise.reject(error);
    }
  }

  async function handleMusicTracks() {
    try {
      dispatch({ type: "ADD_TRACKS_TO_YOUTUBE_MUSIC_PLAYLIST_START" });
      setStatus(YTSlurpStatus.SLURPING_MUSIC);
      if (!selectedMusicPlaylist) {
        throw new Error("No selected playlist!");
      }

      const tracksInSelectedPlaylist = await dispatch(
        getVideosFromYouTubePlaylist(selectedMusicPlaylist.id)
      );
      console.log(
        `%cExisting tracks in ${selectedMusicPlaylist.name} playlist`,
        "color:cyan",
        tracksInSelectedPlaylist
      );
      // MATCH ALL FILTERED TRACKS WITH MATCHED YOUTUBE TRACKS
      // This does not include youtube native videos
      type SearchArrayItem = {
        addedBy: string;
        album: string;
        artist: string;
        isrc: string;
        sourceTrackId: string;
        title: string;
        trackType: string;
      };

      const searchArray: SearchArrayItem[] = filteredTracks.map((item: any) => {
        if (item.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE) {
          return {
            addedBy: item.addedBy,
            album: item.attributes.albumName,
            artist: item.attributes.artistName,
            isrc: item.attributes.isrc,
            sourceTrackId: item.id,
            title: item.attributes.name,
            trackType: item.trackType
          };
        } else if (item.trackType === SlugifyTrackType.SPOTIFY_NATIVE) {
          return {
            addedBy: item.added_by.id,
            artist: item.track.artists[0],
            album: item.track.album.name,
            isrc: item.track.external_ids.isrc,
            sourceTrackId: item.track.id,
            title: item.track.name,
            trackType: item.trackType
          };
        }
      });

      const matchedTracks: any[] | undefined = await dispatch(
        getYouTubeVideos(searchArray)
      );

      // console.log("%cMatched Tracks", "color:cyan", matchedTracks);

      const matchedTracksWithOriginalTrackInfo = matchedTracks?.map(
        (item, index) => {
          const newInfo: SearchArrayItem = searchArray[index];
          return {
            ...item,
            ...newInfo
          };
        }
      );

      const myExistingSlurpedTracks: SlugifySlurpedTrack[] =
        slurpedTracks.filter(
          (item: SlugifySlurpedTrack) => item.owner === currentUsername
        );

      const existingSlurpedTracksForMusicPlaylist =
        myExistingSlurpedTracks.filter(
          (item: SlugifySlurpedTrack) =>
            item.slurpToPlaylistId === selectedMusicPlaylist.id
        );

      console.log(
        `%c🥤 ${currentUsername.toUpperCase()} HAS ${
          existingSlurpedTracksForMusicPlaylist.length
        } EXISTING SLURPED TRACKS ASSOCIATED WITH THEIR ${selectedMusicPlaylist.name.toUpperCase()} PLAYLIST: `,
        "color:cyan",
        existingSlurpedTracksForMusicPlaylist
      );

      const existingSlurpedTracksAppleMusicIds = compact(
        existingSlurpedTracksForMusicPlaylist.map(
          (item: SlugifySlurpedTrack) => {
            if (
              (item.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE ||
                item.trackType ===
                  SlugifyTrackType.APPLE_MUSIC_UNMATCHED_FROM_SPOTIFY ||
                item.trackType ===
                  SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY) &&
              item.appleMusicId
            ) {
              return item.appleMusicId;
            }
          }
        )
      );

      const existingSlurpedTracksSpotifyIds = compact(
        existingSlurpedTracksForMusicPlaylist.map(
          (item: SlugifySlurpedTrack) => {
            if (
              (item.trackType === SlugifyTrackType.SPOTIFY_NATIVE ||
                item.trackType ===
                  SlugifyTrackType.SPOTIFY_UNMATCHED_FROM_APPLE_MUSIC ||
                item.trackType ===
                  SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC) &&
              item.spotifyId
            ) {
              return item.spotifyId;
            }
          }
        )
      );

      const slurpedMatchedTracks = matchedTracksWithOriginalTrackInfo?.filter(
        (item) => {
          if (item.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE) {
            return existingSlurpedTracksAppleMusicIds.includes(
              item.sourceTrackId
            );
          }
          if (item.trackType === SlugifyTrackType.SPOTIFY_NATIVE) {
            return existingSlurpedTracksSpotifyIds.includes(item.sourceTrackId);
          }
        }
      );

      console.log(
        `%c📻 ${
          slurpedMatchedTracks ? slurpedMatchedTracks.length : 0
        } PREVIOUSLY SLURPED MATCHED TRACKS`,
        "color:cyan",
        slurpedMatchedTracks
      );

      // const tracksToAddToPlaylist = compact(
      const unslurpedMatchedTracks = compact(
        matchedTracksWithOriginalTrackInfo?.filter((item) => {
          return (
            slurpedMatchedTracks &&
            ((item.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE &&
              !slurpedMatchedTracks
                .map((slurpedTrack) => slurpedTrack.appleMusicId)
                .includes(item.sourceTrackId)) ||
              (item.trackType === SlugifyTrackType.SPOTIFY_NATIVE &&
                !slurpedMatchedTracks
                  .map((slurpedTrack) => slurpedTrack.spotifyId)
                  .includes(item.sourceTrackId)))
          );
        })
      );

      console.log(
        `%c📻 ${
          unslurpedMatchedTracks.length ? unslurpedMatchedTracks.length : "NO"
        } UNSLURPED MATCHED TRACKS TO ADD TO ${currentUsername.toUpperCase()}'S ${selectedMusicPlaylist.name.toUpperCase()} PLAYLIST.`,
        "color:cyan",
        unslurpedMatchedTracks
      );
      // console.log(
      //   "%cExisting Tracks in Playlist",
      //   "color:cyan",
      //   tracksInSelectedPlaylist
      // );
      const trackIdsInPlaylist = tracksInSelectedPlaylist.map(
        (item: gapi.client.youtube.PlaylistItem) =>
          item.snippet?.resourceId?.videoId
      );
      console.log("%cTrackIdsInPlaylist", "color:orange", trackIdsInPlaylist);

      // const tracksToSave = matchedTracksWithOriginalTrackInfo?.filter(
      const tracksToAddToPlaylist = unslurpedMatchedTracks?.filter((item) => {
        return !trackIdsInPlaylist.includes(item.id);
      });
      console.log(
        "%cTracks to add to playlist",
        "color:cyan",
        tracksToAddToPlaylist
      );
      const tracksToSlurpButNotAddToPlaylist = unslurpedMatchedTracks?.filter(
        (item) => {
          return trackIdsInPlaylist.includes(item.id);
        }
      );
      console.log(
        "%cTracks to slurp but not add to playlist",
        "color:cyan",
        tracksToSlurpButNotAddToPlaylist
      );

      const ids: string[] | undefined = tracksToAddToPlaylist?.map(
        (item) => item.id
      );
      const insertedVideos = await dispatch(
        addTracksToYouTubePlaylist(selectedMusicPlaylist.id, ids)
      );
      const compacted = compact(insertedVideos);
      // console.log(
      //   `%cVideos inserted into ${selectedMusicPlaylist.name}`,
      //   compacted
      // );
      const insertedIds = compacted.map(
        (item) => item.snippet.resourceId.videoId
      );
      let tracksToMarkAsSlurped: any[] = [];
      const successfullyInsertedVideos = tracksToAddToPlaylist?.filter((item) =>
        insertedIds.includes(item.id)
      );
      console.log(
        "%cSuccessfully Inserted Videos",
        "color:lime",
        successfullyInsertedVideos
      );
      if (successfullyInsertedVideos) {
        tracksToMarkAsSlurped = [...successfullyInsertedVideos];
      }

      let slurpedTracksForItemsAlreadyInPlaylist = [];
      if (tracksToSlurpButNotAddToPlaylist?.length) {
        const fixed: any[] = tracksToSlurpButNotAddToPlaylist.map((item) => {
          const updatedItem = { ...item };
          if (updatedItem.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE) {
            updatedItem.trackType =
              SlugifyTrackType.YOUTUBE_TRACK_MATCHED_FROM_APPLE_MUSIC;
          } else if (
            updatedItem.trackType === SlugifyTrackType.SPOTIFY_NATIVE
          ) {
            updatedItem.trackType =
              SlugifyTrackType.YOUTUBE_TRACK_MATCHED_FROM_SPOTIFY;
          }
          updatedItem.appleMusicId = item.sourceTrackId;
          updatedItem.appleMusicTrackAlbum = item.album;
          updatedItem.appleMusicTrackArtist = item.artist;
          updatedItem.appleMusicTrackIsrc = item.isrc;
          updatedItem.appleMusicTrackTitle = item.title;
          updatedItem.toBeDeletedOn = dayjs().add(1, "month").toISOString();
          updatedItem.originalTrackAddedBy = item.addedBy;
          updatedItem.slurpToPlaylistId = selectedMusicPlaylist.id;
          updatedItem.owner = currentUsername;
          updatedItem.username = currentUsername;
          return omit(updatedItem, [
            "addedBy",
            "album",
            "artist",
            "etag",
            "id",
            "isrc",
            "kind",
            "player",
            "snippet",
            "sourceTrackId",
            "title"
          ]);
        });
        console.log(
          "%cNew slurped tracks for items already in playlist",
          "color:cyan",
          fixed
        );
        slurpedTracksForItemsAlreadyInPlaylist = fixed;
      }

      if (
        !tracksToMarkAsSlurped.length &&
        !slurpedTracksForItemsAlreadyInPlaylist.length
      ) {
        console.log(
          "No slurped tracks to create for matched music.  Returning now.",
          "color:cyan"
        );
        return Promise.resolve();
      }

      const newSlurpedTracks: any[] | undefined = tracksToMarkAsSlurped?.map(
        (item) => {
          if (item.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE) {
            return {
              appleMusicId: item.sourceTrackId,
              appleMusicTrackAlbum: item.album,
              appleMusicTrackArtist: item.artist,
              appleMusicTrackIsrc: item.isrc,
              appleMusicTrackTitle: item.title,
              originalTrackAddedBy: item.addedBy,
              owner: currentUsername,
              slurpToPlaylistId: selectedMusicPlaylist.id,
              trackType:
                SlugifyTrackType.YOUTUBE_TRACK_MATCHED_FROM_APPLE_MUSIC,
              toBeDeletedOn: dayjs().add(1, "month").toISOString(),
              username: currentUsername
            };
          } else if (item.trackType === SlugifyTrackType.SPOTIFY_NATIVE) {
            return {
              spotifyId: item.sourceTrackId,
              spotifyTrackAlbum: item.album,
              spotifyTrackArtists: item.artist,
              spotifyTrackIsrc: item.isrc,
              spotifyTrackTitle: item.title,
              trackType: SlugifyTrackType.YOUTUBE_TRACK_MATCHED_FROM_SPOTIFY,
              toBeDeletedOn: dayjs().add(1, "month").toISOString(),
              originalTrackAddedBy: item.addedBy,
              slurpToPlaylistId: selectedMusicPlaylist.id,
              owner: currentUsername,
              username: currentUsername
            };
          }
        }
      );
      console.log(
        "%cNew slurped tracks that were added to playlist",
        "color:cyan",
        newSlurpedTracks
      );
      const combinedSlurpedTracks = newSlurpedTracks.concat(
        slurpedTracksForItemsAlreadyInPlaylist
      );
      console.log(
        "%cNew combined slurped tracks",
        "color:cyan",
        combinedSlurpedTracks
      );

      if (combinedSlurpedTracks && combinedSlurpedTracks.length) {
        const compacted = compact(combinedSlurpedTracks);
        if (compacted && compacted.length) {
          await dispatch(createSlugifySlurpedTracks(compacted));
          // setSlurpedTracksToCreate(compacted);
        }
      }
      // console.log("%cNeed to handle notifications!", "color:orange");
      // setIsSendingNotifications(true);
      // await dispatch(
      //   handlePushNotification(
      //     `${currentUsername} just slurped your tracks!`,
      //     false,
      //     matchedTracksWithOriginalTrackInfo
      //   )
      // );
      // setIsSendingNotifications(false);
      // setTracksAddSuccess(true);
      // setIsSpinning(false);
      dispatch({ type: "ADD_TRACKS_TO_YOUTUBE_MUSIC_PLAYLIST_SUCCESS" });
      return Promise.resolve();
    } catch (error: any) {
      // setIsSpinning(false);
      dispatch({
        type: "ADD_TRACKS_TO_YOUTUBE_MUSIC_PLAYLIST_FAIL",
        payload: error
      });
      setError(error.message);
      console.log(error);
      return Promise.reject(error);
    }
  }

  function renderBody() {
    if (status === YTSlurpStatus.SENDING_NOTIFICATIONS) {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="Pager">
              📟
            </span>
          </div>
          <div className="lead">Sending notifications...</div>
        </>
      );
    }
    if (status === YTSlurpStatus.FINISHED) {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="People Dancing">
              {dancers()}
            </span>
          </div>
          <div className="lead">Videos have been added to YouTube!</div>
        </>
      );
    }
    // else if (tracksAddSuccess && tracksToSaveIsEmpty) {
    //   return (
    //     <>
    //       <div className="my-2 slug-title">
    //         <span role="img" aria-label="See-No-Evil Monkey">
    //           🙈
    //         </span>
    //         <div className="slug-subtitle mb-2">Nothing was added...</div>
    //       </div>
    //       <div className="lead">
    //         These tracks already exist in{" "}
    //         <strong>{selectedMusicPlaylist?.name || "playlist"}</strong>
    //       </div>
    //     </>
    //   );
    else if (error) {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="health worker emoji">
              👩🏻‍⚕️
            </span>
          </div>
          <div className="lead">Something&apos;s not right...</div>
          <div className="lead">Here&apos;s an error message:</div>
          <div className="lead text-danger">{error}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="radio">
              📻
            </span>
          </div>
          {status === YTSlurpStatus.NOT_STARTED && (
            <div className="lead">
              This will add all shared items to the following playlists on
              YouTube:
              <ul className="list-group flush mt-2">
                {hasMatchedTracks && (
                  <li className="list-group-item">
                    {selectedMusicPlaylist?.name}
                  </li>
                )}
                {hasVideos && (
                  <li className="list-group-item">
                    {selectedVideosPlaylist?.name}
                  </li>
                )}
              </ul>
            </div>
          )}
          {status === YTSlurpStatus.SLURPING_MUSIC && (
            <div className="d-flex flex-column justify-content-center lead">
              <div className="lead">Adding matched music videos to: </div>
              <strong className="my-2">{selectedMusicPlaylist?.name}</strong>
              <SlugifySlurpProgressBar />
            </div>
          )}
          {status === YTSlurpStatus.SLURPING_VIDEOS && (
            <div className="d-flex flex-column justify-content-center lead">
              <div className="lead">Adding shared videos to: </div>
              <strong className="my-2">{selectedVideosPlaylist?.name}</strong>
              <SlugifySlurpProgressBar />
            </div>
          )}
        </>
      );
    }
  }

  const btnColorClass = useMemo(() => {
    if (error) {
      if (isDark) {
        return "btn-outline-danger";
      }
      return "danger";
    }
    if (status === YTSlurpStatus.FINISHED) {
      if (isDark) {
        return "btn-outline-success";
      }
      return "btn-success";
    }
    if (isDark) {
      return "btn-outline-primary";
    }
    return "btn-primary";
  }, [error, status, isDark]);

  function renderSlurpButton() {
    return (
      <button
        type="button"
        disabled={error !== "" || isSpinning}
        className={`d-flex align-items-center btn btn-sm ${btnColorClass}`}
        onClick={() => addTracksToPlaylists()}
      >
        {isSpinning ? (
          <>
            <span className="me-2">
              <Spinner
                color={isDark ? "primary" : "light"}
                size={24}
                type="puff"
              />
            </span>
            <span className="visually-hidden">Loading...</span>
            <span>Add to YouTube</span>
          </>
        ) : status === YTSlurpStatus.FINISHED ? (
          <>
            <i className="icon-playlist_add_check slug-icon me-2" />
            <span>Add to YouTube</span>
          </>
        ) : !error ? (
          <>
            <i className="icon-playlist_add slug-icon me-2" />
            <span>Add to YouTube</span>
          </>
        ) : (
          <>
            <i className="icon-error slug-icon me-2" />
            <span>Add to YouTube</span>
          </>
        )}
      </button>
    );
  }

  const cleanUpModal = () => {
    setError("");
    setIsSpinning(false);
    setStatus(YTSlurpStatus.NOT_STARTED);
  };

  return (
    <>
      <Modal
        centered={!isMobile}
        show={isSlugifyYouTubeModalOpen}
        onHide={() => {
          cleanUpModal();
          setIsSlugifyYouTubeModalOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <div className="d-flex align-items-center modal-title">
            <img
              className="me-3"
              src={youTubeIcon}
              alt="YouTube"
              height={28}
              width="auto"
            />
            <h5 className="mb-0">Add Videos to YouTube</h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center text-center">
            {renderBody()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <button
              type="button"
              className="btn btn-link text-body-secondary text-decoration-none"
              onClick={() => {
                cleanUpModal();
                setIsSlugifyYouTubeModalOpen(false);
              }}
            >
              close
            </button>
            {renderSlurpButton()}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
