import PropTypes from "prop-types";

const EmoticonSpinner = ({
  animation,
  emoticon,
  emoticonLabel,
  fontSize,
  spinning
}) => {
  return (
    <span
      className={`${spinning ? `animate__animated ${animation} infinite` : ""}`}
      role="img"
      aria-label={emoticonLabel}
      id="emoticon"
      style={{ fontSize }}
    >
      {emoticon}
    </span>
  );
};

EmoticonSpinner.propTypes = {
  emoticon: PropTypes.string.isRequired
};

export default EmoticonSpinner;
