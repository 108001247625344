export const TutorialPage5 = () => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center text-center tutorial-page"
      style={{ height: "480px" }}
    >
      <div className="tutorial-emoji">
        <span role="img" aria-label="pager emoji">
          📟
        </span>
      </div>
      <div className="tutorial-title">Notifications</div>
      <p className="tutorial-lead">
        Enable notifications to inform and be informed when things happen on
        Slugify, like when new tracks are shared.
      </p>
    </div>
  );
};
