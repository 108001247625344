import { useContext, useMemo, useState } from "react";
import { ThemeContext } from "../../contexts/ColorThemeProvider";
import { Spinner } from "./Spinner";

const isDev = process.env.NODE_ENV === "development";

interface Props {
  block?: boolean;
  btnText: string;
  className?: string;
  color?: string;
  cursor?: string;
  fn?: () => void;
  height?: number | undefined;
  icon?: string | undefined;
  iconSize?: number;
  isDisabled?: boolean;
  isSpinning?: boolean;
  link?: boolean;
  outline?: boolean;
  size?: string | undefined;
  spinnerColor?: string;
  spinnerReplacesText?: boolean;
  spinnerSize?: number | string;
  spinnerType?: string;
  title?: string;
  type?: "submit" | "reset" | "button";
  width?: number;
}

export default function Button({
  block = false,
  btnText,
  className = "",
  color,
  cursor = "pointer",
  fn,
  height,
  icon,
  iconSize = 24,
  isDisabled,
  isSpinning,
  link,
  outline,
  size = "sm",
  spinnerColor = "light",
  spinnerReplacesText,
  spinnerSize = 24,
  spinnerType = "puff",
  title,
  type = "button",
  width
}: Props) {
  const { isDark } = useContext(ThemeContext);
  const [isHovered, setIsHovered] = useState(false);
  const linkColor = color ? color : isDark ? "light" : "dark";

  const spinColor = useMemo(() => {
    let spinColor = spinnerColor;
    if (!outline) {
      spinColor = isDark ? "light" : "dark";
    } else {
      spinColor = isHovered ? "dark" : spinnerColor;
    }

    return spinColor;
  }, [spinnerColor, isHovered, isDark]);

  function getIcon() {
    if (icon) {
      return (
        <span
          className={`${icon} slug-icon me-3`}
          style={{ marginBottom: 2 }}
        />
      );
    }
  }

  function makeItClassy() {
    let btnClass = `d-flex align-items-center justify-content-center btn ${className}`;
    // don't forget to put a space in front when adding new classes!
    if (size) {
      btnClass += ` btn-${size}`;
    }
    if (block && !link) {
      btnClass += ` w-100`;
    }
    if (outline && !link) {
      btnClass += ` btn-outline-${color}`;
    }
    if (!outline && !link && isDark) {
      btnClass += ` btn-outline-${color}`;
    }
    if (link && !block && !outline) {
      btnClass += ` btn-link text-${linkColor} text-decoration-none`;
      // btnClass += `btn-link text-light text-decoration-none`;
    }
    if (!outline && !link && !isDark) {
      btnClass += ` btn-${color}`;
    }
    if (!outline && !link && isDark) {
      btnClass += ` btn-outline-${color}`;
    }
    return btnClass;
  }

  if (!spinnerReplacesText) {
    return (
      <button
        className={makeItClassy()}
        disabled={isDisabled}
        onClick={
          fn
            ? () => fn()
            : () => {
                if (isDev) {
                  console.log("Doing nothing");
                }
              }
        }
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          cursor: cursor,
          height: height ? `${height}px` : "",
          width: width ? `${width}px` : ""
        }}
        title={title}
        type={type}
      >
        {isSpinning ? (
          <div className="me-3">
            <Spinner size={spinnerSize} color={spinColor} type={spinnerType} />
          </div>
        ) : (
          // <div
          //   className={`spinner-${spinnerType} spinner-${spinnerType}-${spinnerSize} text-${spinnerColor} ${
          //     spinnerReplacesText ? "" : "me-3"
          //   }`}
          //   role="status"
          //   style={{
          //     marginBotom: "4px"
          //   }}
          // >
          //   <span className="visually-hidden">Loading...</span>
          // </div>
          icon && (
            <i
              className={`${icon} slug-icon me-3`}
              style={{ width: iconSize, height: iconSize }}
            />
          )
        )}
        <span>{btnText}</span>
      </button>
    );
  }
  return (
    <button
      className={makeItClassy()}
      disabled={isDisabled}
      onClick={
        fn
          ? () => fn()
          : () => {
              if (isDev) {
                console.log("Doing nothing");
              }
            }
      }
      style={{
        cursor: cursor,
        height: height ? height : "",
        width: width ? width : ""
      }}
      title={title}
      type={type}
    >
      {isSpinning ? (
        <div
          className="d-flex align-items-center"
          style={{ height: spinnerReplacesText ? 24 : "" }}
        >
          <Spinner size={spinnerSize} color={spinnerColor} type={spinnerType} />
        </div>
      ) : (
        <>
          <span>{getIcon()}</span>
          <span>{btnText}</span>
        </>
      )}
    </button>
  );
}
