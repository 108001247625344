import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import EmoticonSpinner from "../shared/EmoticonSpinner";
import $ from "jquery";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";

const SlugTubeNewVideoModal = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.email);
  const [error, setError] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [success, setSuccess] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [subject, setSubject] = useState("");

  useEffect(() => {
    const cleanUpModal = () => {
      setError(false);
      setSpinning(false);
      setSuccess(false);
      setVideoUrl("");
    };
    $("#slugTubeNewVideoModal").on("hidden.bs.modal", () => {
      cleanUpModal();
    });
  }, []);

  const handleSave = async () => {
    const payload = {
      id: uuid(videoUrl),
      url: videoUrl.replace(/www.youtube.com\/watch\?v=/, "/youtu.be/"),
      user: userEmail,
      createdAt: dayjs.format(),
      updatedAt: dayjs.format()
    };
    if (subject) {
      payload.subject = subject;
    }
    console.log("PAYLOAD: ", payload);
    setSpinning(true);
    try {
      dispatch({ type: "ADD_NEW_VIDEO_TO_SLUG_TUBE_START" });
      await API.put("SlugBucketAPI", "/slugTube", {
        body: {
          ...payload
        }
      });
      dispatch({ type: "ADD_NEW_VIDEO_TO_SLUG_TUBE_SUCCESS", payload });
      setSpinning(false);
      setSuccess(true);
    } catch (e) {
      dispatch({ type: "ADD_NEW_VIDEO_TO_SLUG_TUBE_FAIL", payload: e });
    }
  };

  const validateUrl = () => {
    const regex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;
    const result = videoUrl.match(regex);
    if (!result) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="slugTubeNewVideoModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="slugTubeNewVideoModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="slugTubeNewVideoModalLabel">
              Add New Video
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {!success ? (
              <>
                <div className="form-group">
                  <label htmlFor="UrlInput">YouTube Video Link</label>
                  <input
                    aria-describedby="urlHelp"
                    className="form-control"
                    id="UrlInput"
                    onBlur={() => validateUrl()}
                    onChange={(e) => setVideoUrl(e.target.value)}
                    placeholder="https://youtu.be/D4K1xu98tF8"
                    type="text"
                    value={videoUrl}
                  />
                  {error ? (
                    <small id="urlHelp" className="form-text text-danger">
                      Please use a proper YouTube sharing URL.
                    </small>
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="subjectInput">Comment (optional)</label>
                  <input
                    className="form-control"
                    id="subjectInput"
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Harpo is the Schiznit!"
                    type="text"
                    value={subject}
                  />
                </div>
              </>
            ) : null}
            <div className="d-flex flex-column align-items-center">
              {success ? (
                <>
                  <div className="display-4 text-body-secondary">Success!</div>
                  <span
                    role="img"
                    aria-label="Monkey Face"
                    style={{ fontSize: "36px" }}
                  >
                    🍿
                  </span>
                  <p className="ms-3 mt-2 pb-0">
                    Your video has been added to SlugTube.
                  </p>
                </>
              ) : (
                <>
                  <span
                    role="img"
                    aria-label="Exploding Head"
                    style={{ fontSize: "36px" }}
                  >
                    🤯
                  </span>
                  <div className="lead font-weight-bold">Pro Tip</div>
                  <p className="ms-3 mt-2 pb-0">
                    You can also send videos to{" "}
                    <a href="mailto:slug@slugtube.abts.io">
                      slug@slugtube.abts.io
                    </a>
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center modal-footer">
            <button
              type="button"
              className="btn btn-link text-body-secondary text-decoration-none"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary d-flex align-items-center"
              disabled={success || error || !videoUrl.length}
              onClick={() => handleSave()}
            >
              <EmoticonSpinner
                titleAnimation="heartBeat"
                isInfinite
                emoticon="💾"
                emoticonLabel="Floppy Disk"
                fontSize="18px"
                spinning={spinning}
              />
              <span className="ms-2">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlugTubeNewVideoModal;
