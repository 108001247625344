// import { UserActionType } from "../types";

const initialState = {
  appleMusicUserData: { slugBucketPlaylistId: null },
  expoPushTokenData: {},
  friends: [],
  spotifyTokenData: {},
  spotifyUserData: {},
  userSettings: {
    homeMenu: "slugify",
    screenAppearance: "Automatic"
    // slugifyDefaultSlurpDestination: {
    //   id: "not set",
    //   name: "not set",
    //   type: "not set"
    // }
  },
  watchedSlugTubeVids: []
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case "ADD_NEW_FRIEND_SUCCESS":
      return {
        ...state,
        friends: [
          ...state.friends.filter(
            (item) => item.username !== action.payload.username
          ),
          action.payload
        ]
      };
    // case UserActionType.GET_SPOTIFY_USER_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     spotifyUserData: {
    //       ...state.spotifyUserData,
    //       ...action.payload
    //     }
    //   };
    case "GET_USER_DATA_FROM_DB_SUCCESS":
      return {
        ...state,
        ...action.payload,
        userSettings: { ...state.userSettings, ...action.payload.userSettings }
      };
    // case "GET_SPOTIFY_TOKEN_DATA_TO_USER_SUCCESS":
    //   return {
    //     ...state,
    //     spotifyTokenData: { ...state.spotifyTokenData, ...action.payload }
    //   };
    case "MARK_VIDS_WATCHED_SUCCESS":
      return {
        ...state,
        watchedSlugTubeVids: [...action.payload]
      };
    case "MARK_VIDS_UNWATCHED_SUCCESS":
      return {
        ...state,
        watchedSlugTubeVids: [...action.payload]
      };
    // case "REFRESH_SPOTIFY_TOKEN_SUCCESS":
    //   return {
    //     ...state,
    //     spotifyTokenData: { ...state.spotifyTokenData, ...action.payload }
    //   };
    // case "REMOVE_SPOTIFY_TOKEN_DATA_FROM_USER":
    //   return {
    //     ...state,
    //     ...action.payload
    //   };
    // case "REMOVE_APPLE_MUSIC_USER_DATA_SUCCESS":
    //   return {
    //     ...state,
    //     appleMusicUserData: { ...initialState.appleMusicUserData }
    //   };
    case "REMOVE_FRIEND":
      return {
        ...state,
        friends: state.friends.filter(
          (friend) => friend.username !== action.payload
        )
      };
    // case "SAVE_APPLE_MUSIC_USER_DATA_SUCCESS":
    //   return {
    //     ...state,
    //     appleMusicUserData: { ...state.appleMusicUserData, ...action.payload }
    //   };
    case "UPDATE_FRIENDS_IN_DB_SUCCESS":
      return {
        ...state,
        friends: [...state.friends, ...action.payload]
      };
    case "UPDATE_USER_SETTINGS_SUCCESS":
      return {
        ...state,
        userSettings: { ...state.userSettings, ...action.payload }
      };
    default:
      return state;
  }
};
