/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSlugifyUser = /* GraphQL */ `
  mutation CreateSlugifyUser(
    $input: CreateSlugifyUserInput!
    $condition: ModelSlugifyUserConditionInput
  ) {
    createSlugifyUser(input: $input, condition: $condition) {
      id
      username
      appleMusicSlugifyPlaylist
      autoSlurpFriends
      expoPushTokenData
      homeMenu
      isSubscribedOneSignal
      owner
      primaryMusicStream
      publicPlaylists
      sharedPlaylists
      screenAppearance
      friends {
        items {
          id
          userId
          username
          owner
          primaryMusicStream
          musicStreamId
          createdAt
          updatedAt
        }
        nextToken
      }
      slugifyMobileSlurpDestination
      spotifyTokenData
      spotifyUserData
      youTubeUserData
      createdAt
      updatedAt
      slurpedTracks {
        items {
          id
          username
          appleMusicId
          appleMusicTrackAlbum
          appleMusicTrackArtist
          appleMusicTrackIsrc
          appleMusicTrackTitle
          matchedBy
          originalTrackAddedBy
          owner
          slurpToPlaylistId
          spotifyId
          spotifyTrackAlbum
          spotifyTrackArtists
          spotifyTrackIsrc
          spotifyTrackTitle
          toBeDeletedOn
          trackType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSlugifyUser = /* GraphQL */ `
  mutation UpdateSlugifyUser(
    $input: UpdateSlugifyUserInput!
    $condition: ModelSlugifyUserConditionInput
  ) {
    updateSlugifyUser(input: $input, condition: $condition) {
      id
      username
      appleMusicSlugifyPlaylist
      autoSlurpFriends
      expoPushTokenData
      homeMenu
      isSubscribedOneSignal
      owner
      primaryMusicStream
      publicPlaylists
      sharedPlaylists
      screenAppearance
      friends {
        items {
          id
          userId
          username
          owner
          primaryMusicStream
          musicStreamId
          createdAt
          updatedAt
        }
        nextToken
      }
      slugifyMobileSlurpDestination
      spotifyTokenData
      spotifyUserData
      youTubeUserData
      createdAt
      updatedAt
      slurpedTracks {
        items {
          id
          username
          appleMusicId
          appleMusicTrackAlbum
          appleMusicTrackArtist
          appleMusicTrackIsrc
          appleMusicTrackTitle
          matchedBy
          originalTrackAddedBy
          owner
          slurpToPlaylistId
          spotifyId
          spotifyTrackAlbum
          spotifyTrackArtists
          spotifyTrackIsrc
          spotifyTrackTitle
          toBeDeletedOn
          trackType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSlugifyUser = /* GraphQL */ `
  mutation DeleteSlugifyUser(
    $input: DeleteSlugifyUserInput!
    $condition: ModelSlugifyUserConditionInput
  ) {
    deleteSlugifyUser(input: $input, condition: $condition) {
      id
      username
      appleMusicSlugifyPlaylist
      autoSlurpFriends
      expoPushTokenData
      homeMenu
      isSubscribedOneSignal
      owner
      primaryMusicStream
      publicPlaylists
      sharedPlaylists
      screenAppearance
      friends {
        items {
          id
          userId
          username
          owner
          primaryMusicStream
          musicStreamId
          createdAt
          updatedAt
        }
        nextToken
      }
      slugifyMobileSlurpDestination
      spotifyTokenData
      spotifyUserData
      youTubeUserData
      createdAt
      updatedAt
      slurpedTracks {
        items {
          id
          username
          appleMusicId
          appleMusicTrackAlbum
          appleMusicTrackArtist
          appleMusicTrackIsrc
          appleMusicTrackTitle
          matchedBy
          originalTrackAddedBy
          owner
          slurpToPlaylistId
          spotifyId
          spotifyTrackAlbum
          spotifyTrackArtists
          spotifyTrackIsrc
          spotifyTrackTitle
          toBeDeletedOn
          trackType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSlugifySlurpedTrack = /* GraphQL */ `
  mutation UpdateSlugifySlurpedTrack(
    $input: UpdateSlugifySlurpedTrackInput!
    $condition: ModelSlugifySlurpedTrackConditionInput
  ) {
    updateSlugifySlurpedTrack(input: $input, condition: $condition) {
      id
      username
      appleMusicId
      appleMusicTrackAlbum
      appleMusicTrackArtist
      appleMusicTrackIsrc
      appleMusicTrackTitle
      matchedBy
      originalTrackAddedBy
      owner
      slurpToPlaylistId
      spotifyId
      spotifyTrackAlbum
      spotifyTrackArtists
      spotifyTrackIsrc
      spotifyTrackTitle
      toBeDeletedOn
      trackType
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const createSlugifyFriend = /* GraphQL */ `
  mutation CreateSlugifyFriend(
    $input: CreateSlugifyFriendInput!
    $condition: ModelSlugifyFriendConditionInput
  ) {
    createSlugifyFriend(input: $input, condition: $condition) {
      id
      userId
      username
      owner
      primaryMusicStream
      musicStreamId
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const updateSlugifyFriend = /* GraphQL */ `
  mutation UpdateSlugifyFriend(
    $input: UpdateSlugifyFriendInput!
    $condition: ModelSlugifyFriendConditionInput
  ) {
    updateSlugifyFriend(input: $input, condition: $condition) {
      id
      userId
      username
      owner
      primaryMusicStream
      musicStreamId
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const deleteSlugifyFriend = /* GraphQL */ `
  mutation DeleteSlugifyFriend(
    $input: DeleteSlugifyFriendInput!
    $condition: ModelSlugifyFriendConditionInput
  ) {
    deleteSlugifyFriend(input: $input, condition: $condition) {
      id
      userId
      username
      owner
      primaryMusicStream
      musicStreamId
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const createSlugifySlurpedTrack = /* GraphQL */ `
  mutation CreateSlugifySlurpedTrack(
    $input: CreateSlugifySlurpedTrackInput!
    $condition: ModelSlugifySlurpedTrackConditionInput
  ) {
    createSlugifySlurpedTrack(input: $input, condition: $condition) {
      id
      username
      appleMusicId
      appleMusicTrackAlbum
      appleMusicTrackArtist
      appleMusicTrackIsrc
      appleMusicTrackTitle
      matchedBy
      originalTrackAddedBy
      owner
      slurpToPlaylistId
      spotifyId
      spotifyTrackAlbum
      spotifyTrackArtists
      spotifyTrackIsrc
      spotifyTrackTitle
      toBeDeletedOn
      trackType
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const deleteSlugifySlurpedTrack = /* GraphQL */ `
  mutation DeleteSlugifySlurpedTrack(
    $input: DeleteSlugifySlurpedTrackInput!
    $condition: ModelSlugifySlurpedTrackConditionInput
  ) {
    deleteSlugifySlurpedTrack(input: $input, condition: $condition) {
      id
      username
      appleMusicId
      appleMusicTrackAlbum
      appleMusicTrackArtist
      appleMusicTrackIsrc
      appleMusicTrackTitle
      matchedBy
      originalTrackAddedBy
      owner
      slurpToPlaylistId
      spotifyId
      spotifyTrackAlbum
      spotifyTrackArtists
      spotifyTrackIsrc
      spotifyTrackTitle
      toBeDeletedOn
      trackType
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
