/* eslint-disable no-case-declarations */
import { AnyAction } from "redux";
import { CookiesActionType } from "../types"; // enum
import type { CookiesState } from "../types";

const initialState = {
  cookiesConsent: {
    isAdvertisingEnabled: false,
    isFunctionalEnabled: false,
    isPerformanceEnabled: false
  },
  draftCookiesConsent: {
    isAdvertisingEnabled: false,
    isFunctionalEnabled: false,
    isPerformanceEnabled: false
  },
  initialCookieState: {
    isAdvertisingEnabled: false,
    isFunctionalEnabled: false,
    isPerformanceEnabled: false
  },
  isCookiesModalOpen: false,
  isCookiesToastVisible: false
} as CookiesState;

export default (state = { ...initialState }, action: AnyAction) => {
  switch (action.type) {
    case CookiesActionType.SET_ALL_COOKIE_CONSENT_STATES:
      return {
        ...state,
        cookiesConsent: action.payload,
        draftCookiesConsent: action.payload,
        initialCookieState: action.payload
      };
    case CookiesActionType.SET_COOKIES_CONSENT:
      return {
        ...state,
        cookiesConsent: action.payload
      };
    case CookiesActionType.SET_COOKIES_DRAFT_CONSENT:
      return {
        ...state,
        draftCookiesConsent: action.payload
      };
    case CookiesActionType.SET_INITIAL_COOKIE_CONSENT_STATE:
      return {
        ...state,
        cookiesConsent: action.payload
      };

    case CookiesActionType.SET_COOKIES_MODAL_VISIBILITY:
      return {
        ...state,
        isCookiesModalOpen: action.payload
      };
    case CookiesActionType.SET_COOKIES_TOAST_VISIBILITY:
      return {
        ...state,
        isCookiesToastVisible: action.payload
      };
    default:
      return state;
  }
};
