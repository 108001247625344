import { Auth, Storage } from "aws-amplify";

export const getCurrentUser = async (setCurrentUser) => {
  const { username } = await Auth.currentAuthenticatedUser();
  setCurrentUser(username);
};

export const getUrl = async (fileName, setDownloadLink) => {
  console.log("geturl filename", fileName);
  try {
    const url = await Storage.get(fileName);
    setDownloadLink(url);
  } catch (e) {
    console.log(e);
  }
};

export const handleDownload = async (fileName) => {
  console.log(`🐕 Fetching Url for: ${fileName}`);
  try {
    const url = await Storage.get(fileName);
    window.open(url, "_blank");
  } catch (e) {
    console.log(e);
  }
};
export const handleDownloadAll = async (songs, currentUser) => {
  const getUrl = async (fileName) => {
    console.log(`🐕 Fetching url for: ${fileName}`);
    try {
      const url = await Storage.get(fileName);
      return url;
    } catch (e) {
      console.log(e);
    }
  };

  const pendingDownloadUrl = songs
    .filter((song) => song.uploadedBy !== currentUser)
    .map((song) => getUrl(song.s3FileName));
  console.log("⏲️ Waiting for urls...");
  Promise.all(pendingDownloadUrl).then(async (urls) => {
    console.log("Urls resolved...");
    console.log(urls);
    for (let url of urls) {
      window.open(url, "_blank");
    }
  });
};

export const removeEmptyStringElements = (obj) => {
  for (var prop in obj) {
    if (typeof obj[prop] === "object") {
      removeEmptyStringElements(obj[prop]);
    } else if (obj[prop] === "") {
      delete obj[prop];
    }
  }
  return obj;
};
