import { useState } from "react";
import { withRouter } from "react-router-dom";
import YouTube from "react-youtube";
import SlugTubeActionBar from "./SlugTubeActionBar";

const SlugTubeItem = ({ height, item, location, width }) => {
  const [readyToPlay, setReadyToPlay] = useState(false);
  const opts = {
    height,
    width,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0
    }
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
    setReadyToPlay(true);
  };

  const renderPlayer = () => {
    if (!urlPrefix === "https://youtu.be") return null;
    return (
      <div
        className={`pt-1 pb-0 px-1 pt-sm-2 pb-sm-0 px-sm-2 ${
          !readyToPlay ? "slug-tube__bg-placeholder" : ""
        }`}
        style={{ border: "0" }}
      >
        <YouTube videoId={videoId} opts={opts} onReady={_onReady} />
      </div>
    );
  };

  const urlPrefix = item.url.slice(0, 17);
  const videoId = item.url.slice(17);

  return (
    <div className="d-flex flex-column align-items-center">
      {renderPlayer(urlPrefix, videoId)}
      {readyToPlay && !location.pathname.includes("commentary") ? (
        <SlugTubeActionBar item={item} />
      ) : (
        <div
          className="slug-tube__bg-placeholder w-100 mb-1"
          style={{ height: "32px" }}
        />
      )}
    </div>
  );
};
export default withRouter(SlugTubeItem);
