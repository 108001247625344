import { useCallback } from "react";
import { getRandomBunny } from "../../functions";

export const TutorialPage4 = () => {
  const bunny = useCallback(getRandomBunny(), []);
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center text-center tutorial-page"
      style={{ height: "480px" }}
    >
      <div className="tutorial-emoji">{bunny}</div>
      <div className="tutorial-title">Add Friends</div>
      <p className="tutorial-lead">Slugify likes privacy, especially yours.</p>
      <p className="tutorial-lead">
        Only you can initiate friendships with others. There are no random
        friend requests.
      </p>
      <p className="tutorial-lead">
        Only your friends can view the tracks you share in Slugify and
        vice-a-versa.
      </p>
    </div>
  );
};
