import { compact } from "lodash";
import { getRandomDancers } from "../functions";
import {
  handlePushNotification,
  createSlugifySlurpedTracks,
  checkSpotifyToken
} from "../actions";
import { isMobile } from "react-device-detect";
import { Modal } from "react-bootstrap";
import {
  SelectedPlaylist,
  SlugifySlurpedTrack,
  SlugifyTrackType
} from "../types";
import { Spinner } from "./shared/Spinner";
import { SpotifyTrack } from "../types/spotify";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import chalk from "chalk";
import dayjs from "dayjs";
import spotifyLogo from "../images/Spotify_Logo_RGB_Green.png";

const isDev = process.env.NODE_ENV === "development";

interface Props {
  selectedPlaylist: SelectedPlaylist | undefined;
  isSlugifySpotifyModalOpen: boolean;
  setIsSlugifySpotifyModalOpen: (b: boolean) => void;
}
export default function SlugifySpotifyModal({
  isSlugifySpotifyModalOpen,
  setIsSlugifySpotifyModalOpen,
  selectedPlaylist
}: Props) {
  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);

  const {
    auth: { username: currentUsername },
    slugify: { filteredTracks, slurpedTracks }
  } = useAppSelector((state) => state);

  const [error, setError] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [isSendingNotifications, setIsSendingNotifications] = useState(false);
  const [tracksAddSuccess, setTracksAddSuccess] = useState(false);
  const [tracksToSaveIsEmpty, setTracksToSaveIsEmpty] = useState(false);

  const dancers = useCallback(() => getRandomDancers(), []);

  useEffect(() => {
    () => cleanUpModal();
  }, []);

  const addTracksToPlaylist = async () => {
    try {
      dispatch({ type: "ADD_TRACKS_TO_SPOTIFY_PLAYLIST_START" });
      if (!selectedPlaylist) {
        throw new Error("No Selected Playlist!");
      }
      setIsSpinning(true);
      const accessToken = await dispatch(checkSpotifyToken());
      const getOptions = {
        method: "GET",
        url: `https://api.spotify.com/v1/playlists/${selectedPlaylist.id}/tracks`,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json"
        }
      };
      const response = await axios(getOptions);

      const existingSlurpedTracks = slurpedTracks.filter(
        (item: SlugifySlurpedTrack) => item.username === currentUsername
      );

      const existingSlurpedTracksForPlaylist = existingSlurpedTracks.filter(
        (item: SlugifySlurpedTrack) =>
          item.slurpToPlaylistId === selectedPlaylist.id
      );

      if (isDev) {
        console.log(
          `%c🥤 ${currentUsername.toUpperCase()} HAS ${
            existingSlurpedTracksForPlaylist.length
          } EXISTING SLURPED TRACKS ASSOCIATED WITH THEIR ${selectedPlaylist.name.toUpperCase()} PLAYLIST: `,
          "color:cyan",
          existingSlurpedTracksForPlaylist
        );
      }
      const existingSlurpedTracksAppleMusicIds =
        existingSlurpedTracksForPlaylist.map((item: SlugifySlurpedTrack) => {
          if (item.appleMusicId) {
            return item.appleMusicId;
          }
        });

      if (isDev) {
        console.log(chalk.red("fix type for filtered tracks", filteredTracks));
      }
      const slurpedMatchedTracks = filteredTracks.filter(
        (item: SlugifySlurpedTrack) =>
          existingSlurpedTracksAppleMusicIds.includes(item.appleMusicId)
      );

      if (isDev) {
        console.log(
          `%c📻 ${slurpedMatchedTracks.length} PREVIOUSLY SLURPED MATCHED TRACKS`,
          "color:cyan",
          slurpedMatchedTracks
        );
      }

      if (isDev) {
        console.log(chalk.red("fix type for filtered tracks", filteredTracks));
      }
      const tracksToAddToPlaylist = compact(
        filteredTracks.filter((item: SlugifySlurpedTrack) => {
          if (
            !slurpedMatchedTracks
              .map(
                (slurpedTrack: SlugifySlurpedTrack) => slurpedTrack.appleMusicId
              )
              .includes(item.appleMusicId)
          ) {
            const updatedItem = { ...item };
            // const updatedItem = { ...item, addedBy: sourcePlaylist.id };
            return updatedItem;
          } else {
            return null;
          }
        })
      );

      if (isDev) {
        console.log(
          `%c📻 ${
            tracksToAddToPlaylist.length ? tracksToAddToPlaylist.length : "NO"
          } MATCHED TRACKS TO ADD TO ${currentUsername.toUpperCase()}'S ${selectedPlaylist.name.toUpperCase()} PLAYLIST.`,
          "color:cyan",
          tracksToAddToPlaylist
        );
      }
      const trackIdsInPlaylist = response.data.items.map(
        (item: SpotifyTrack) => item.track.id
      );
      if (isDev) {
        console.log(chalk.red("fix type for filtered tracks"));
      }
      const tracksToSave = filteredTracks.filter(
        (item: SpotifyTrack) => !trackIdsInPlaylist.includes(item.track.id)
      );

      if (!tracksToSave.length) {
        setTracksAddSuccess(true);
        setIsSpinning(false);
        setTracksToSaveIsEmpty(true);
        return;
      }

      const urisToSlurp = tracksToSave.map(
        (item: SpotifyTrack) => item.track.uri
      );
      const postData = { uris: urisToSlurp };
      if (isDev) {
        console.log(`Adding tracks to ${selectedPlaylist.name}`, urisToSlurp);
      }
      const postOptions = {
        method: "POST",
        url: `https://api.spotify.com/v1/playlists/${selectedPlaylist.id}/tracks`,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: postData
      };
      await axios(postOptions);
      const newSlurpedTracks: SlugifySlurpedTrack[] = tracksToSave.map(
        (item: SpotifyTrack) => {
          if (
            item.trackType ===
            SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC
          ) {
            return {
              appleMusicId: item.appleMusicInfo.appleMusicId,
              appleMusicTrackAlbum: item.appleMusicInfo.album,
              appleMusicTrackArtist: item.appleMusicInfo.artist,
              appleMusicTrackIsrc: item.appleMusicInfo.appleMusicIsrc,
              appleMusicTrackTitle: item.appleMusicInfo.title,
              matchedBy: item.matchedBy,
              originalTrackAddedBy: item.appleMusicInfo.addedBy,
              owner: currentUsername,
              slurpToPlaylistId: selectedPlaylist.id,
              spotifyId: item.track.id,
              spotifyTrackAlbum: item.track.album.name,
              spotifyTrackArtists: item.track.artists.map((item) => item.name),
              spotifyTrackIsrc: item.track.external_ids.isrc,
              spotifyTrackTitle: item.track.name,
              trackType: item.trackType,
              toBeDeletedOn: dayjs().add(1, "month").toISOString(),
              username: currentUsername
            };
          } else {
            return {
              spotifyId: item.track.id,
              spotifyTrackAlbum: item.track.album.name,
              spotifyTrackArtists: item.track.artists.map((item) => item.name),
              spotifyTrackIsrc: item.track.external_ids.isrc,
              spotifyTrackTitle: item.track.name,
              trackType: item.trackType,
              toBeDeletedOn: dayjs().add(1, "month").toISOString(),
              originalTrackAddedBy: item.appleMusicInfo.addedBy,
              slurpToPlaylistId: selectedPlaylist.id,
              owner: currentUsername,
              username: currentUsername
            };
          }
        }
      );
      await dispatch(createSlugifySlurpedTracks(newSlurpedTracks));
      setIsSendingNotifications(true);
      await dispatch(
        handlePushNotification(
          `${currentUsername} just slurped your tracks!`,
          false,
          filteredTracks
        )
      );
      setIsSendingNotifications(false);
      setTracksAddSuccess(true);
      setIsSpinning(false);
      dispatch({
        type: "ADD_TRACKS_TO_SPOTIFY_PLAYLIST_SUCCESS"
      });
    } catch (error: any) {
      setIsSpinning(false);
      dispatch({ type: "ADD_TRACKS_TO_SPOTIFY_PLAYLIST_FAIL", payload: error });
      setError(error.message);
      console.log(error);
    }
  };
  const renderBody = () => {
    if (isSendingNotifications) {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="Pager">
              📟
            </span>
          </div>
          <div className="lead">Sending notifications...</div>
        </>
      );
    }
    if (tracksAddSuccess && !tracksToSaveIsEmpty) {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="People Dancing">
              {dancers()}
            </span>
          </div>
          <div className="lead">
            Tracks have been added to{" "}
            <strong>{selectedPlaylist?.name || "playlist"}</strong> on Spotify!
          </div>
        </>
      );
    } else if (tracksAddSuccess && tracksToSaveIsEmpty) {
      return (
        <>
          <div className="my-2 slug-title">
            <span role="img" aria-label="See-No-Evil Monkey">
              🙈
            </span>
            <div className="slug-subtitle mb-2">Nothing was added...</div>
          </div>
          <div className="lead">
            These tracks already exist in{" "}
            <strong>{selectedPlaylist?.name || "that playlist"}</strong>
          </div>
        </>
      );
    } else if (error) {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="collision">
              💥
            </span>
          </div>
          <div className="lead">Something&apos;s not right...</div>
          <div className="lead">Here&apos;s an error message:</div>
          <div className="lead text-danger">{error}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="radio">
              📻
            </span>
          </div>
          <div className="lead">
            This will add all songs to{" "}
            <strong>{selectedPlaylist?.name || " the playlist"}</strong> on
            Spotify.
          </div>
        </>
      );
    }
  };

  const btnColorClass = useMemo(() => {
    if (error) {
      if (isDark) {
        return "btn-outline-danger";
      }
      return "danger";
    }
    if (tracksAddSuccess) {
      if (isDark) {
        return "btn-outline-success";
      }
      return "btn-success";
    }
    if (isDark) {
      return "btn-outline-primary";
    }
    return "btn-primary";
  }, [error, tracksAddSuccess, isDark]);

  const renderSlurpButton = () => (
    <button
      type="button"
      disabled={error !== "" || tracksAddSuccess || isSpinning}
      className={`d-flex align-items-center btn btn-sm ${btnColorClass}`}
      onClick={
        tracksAddSuccess
          ? () => {
              if (isDev) {
                console.log("Doing Nothing");
              }
            }
          : () => addTracksToPlaylist()
      }
    >
      {isSpinning ? (
        <>
          <span className="me-2">
            <Spinner
              color={isDark ? "primary" : "light"}
              size={24}
              type="puff"
            />
          </span>
          <span className="visually-hidden">Loading...</span>
          <span>Add to Playlist</span>
        </>
      ) : tracksAddSuccess ? (
        <>
          <i className="icon-playlist_add_check slug-icon me-2" />
          <span>Add to Playlist</span>
        </>
      ) : !error ? (
        <>
          <i className="icon-playlist_add slug-icon me-2" />
          <span>Add to Playlist</span>
        </>
      ) : (
        <>
          <i className="icon-error slug-icon me-2" />
          <span>Add to Playlist</span>
        </>
      )}
    </button>
  );
  const cleanUpModal = () => {
    setError("");
    setTracksAddSuccess(false);
    setTracksToSaveIsEmpty(false);
    setIsSpinning(false);
    setTracksAddSuccess(false);
  };

  return (
    <Modal
      centered={!isMobile}
      id="slugify-spotify-modal"
      onHide={() => setIsSlugifySpotifyModalOpen(false)}
      show={isSlugifySpotifyModalOpen}
    >
      <Modal.Header closeButton>
        <h5 className="d-flex align-items-center modal-title">
          <div className="d-flex flex-column flex-sm-row"></div>
          <img
            className="d-none d-sm-block ms-2"
            src={spotifyLogo}
            alt="Spotify"
            height="28.8px"
            width="96px"
          />
        </h5>
        {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center text-center">
          {renderBody()}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <button
            type="button"
            className="btn btn-link text-body-secondary text-decoration-none"
            onClick={() => setIsSlugifySpotifyModalOpen(false)}
          >
            close
          </button>
          {renderSlurpButton()}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
