import { useEffect, useRef, useState } from "react";
import { addFriend } from "../actions";
import { btoa } from "../functions";
import { getError } from "../selectors";
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "../hooks";
// import $ from "jquery";
import chalk from "chalk";
import Modal from "react-bootstrap/Modal";

interface Props {
  isAddFriendModalOpen: boolean;
  setIsAddFriendModalOpen: (b: boolean) => boolean;
}
export default function AddFriendModal({
  isAddFriendModalOpen,
  setIsAddFriendModalOpen
}: Props) {
  const dispatch = useAppDispatch();
  const usernameInput = useRef<HTMLInputElement>(null);
  const secretCodeInput = useRef<HTMLInputElement>(null);

  const {
    errors,
    auth: { username },
    slugify: { addDeleteFriendStatus },
    newUser: { friends: currentUsersFriends }
  } = useAppSelector((state) => state);
  const [friendUsername, setFriendUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [friendSecretCode, setFriendSecretCode] = useState("");
  const [secretCodeError, setSecretCodeError] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [isDone, setIsDone] = useState(false);

  function cleanUpModal() {
    setFriendSecretCode("");
    setFriendUsername("");
    setSecretCodeError("");
    setUsernameError("");
  }

  function handleClose() {
    cleanUpModal();
    setIsAddFriendModalOpen(false);
  }

  useEffect(() => {
    if (!isEmpty(errors)) {
      const message = getError(["GET_USER_DATA_FROM_DB"], errors);
      dispatch({
        type: "SET_SLUGIFY_ERROR",
        payload: {
          alert: {
            alertType: "danger",
            message,
            emoticon: "🙀",
            title: "Oh, nos!"
          }
        }
      });
    }
  }, [dispatch, errors]);

  // useEffect(() => {
  //   $("#add-friend-modal").on("shown.bs.modal", () => {
  //     if (usernameInput?.current) {
  //       usernameInput.current.focus();
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   const cleanUpModal = () => {
  //     setFriendSecretCode("");
  //     setFriendUsername("");
  //     setSecretCodeError("");
  //     setUsernameError("");
  //   };

  //   $("#add-friend-modal").on("hidden.bs.modal", () => {
  //     cleanUpModal();
  //   });
  // }, []);

  useEffect(() => {
    if (isDone) {
      setFriendSecretCode("");
      setFriendUsername("");
      setIsDone(false);
      setIsAddFriendModalOpen(false);
    }
  }, [isDone]);

  const handleAddFriend = async () => {
    try {
      setUsernameError("");
      setSecretCodeError("");
      setIsSpinning(true);
      if (friendSecretCode.replace(/\w/g, "").length) {
        setSecretCodeError("No special characters, please");
        setIsSpinning(false);
        return;
      }
      if (friendSecretCode.length > 8) {
        setSecretCodeError("Secret Code is too long");
        setIsSpinning(false);
        return;
      }
      if (friendSecretCode.length < 8) {
        setSecretCodeError("Secret Code is too short");
        setIsSpinning(false);
        return;
      }
      console.log(chalk.red("Fix Friend type"));
      if (
        currentUsersFriends
          .map((item: any) => item.username)
          .includes(friendUsername)
      ) {
        setUsernameError(`${friendUsername} is already a friend`);
        setIsSpinning(false);
        return;
      }
      if (username === friendUsername) {
        setUsernameError("That's your username");
        setIsSpinning(false);
        return;
      }
      const encoded = btoa(friendUsername);
      if (encoded !== friendSecretCode) {
        setSecretCodeError("Secret code is incorrect for username");
        setUsernameError("Check your spelling here; capitalization matters.");
        setIsSpinning(false);
        return;
      }
      await dispatch(addFriend(friendUsername));

      setFriendSecretCode("");
      setFriendUsername("");
      setIsDone(true);
      setIsSpinning(false);
    } catch (error: any) {
      console.warn(error);
      setIsSpinning(false);
      setUsernameError(error.message);
    }
  };
  return (
    <Modal show={isAddFriendModalOpen} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Add a Friend</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <div className="d-flex justify-content-center">
          <span
            role="img"
            aria-label="People with Bunny Ears"
            style={{ fontSize: "64px" }}
          >
            👯
          </span>
        </div>
        <form action="">
          <div className="">
            <label className="form-label" htmlFor="username">
              Enter friend&apos;s username
            </label>
            <input
              className="form-control"
              id="username"
              onChange={(e) =>
                setFriendUsername(e.target.value.replace(/ /g, ""))
              }
              placeholder="Slugbert"
              ref={usernameInput}
              type="text"
              value={friendUsername}
            />
            <small id="userNameHelp" className="form-text text-danger">
              {usernameError ? usernameError : ""}
            </small>
          </div>
          <div className="mt-3">
            <label className="form-label" htmlFor="secret-code">
              Enter friend&apos;s secret code
            </label>
            <input
              className="form-control"
              id="secret-code"
              onChange={(e) =>
                setFriendSecretCode(e.target.value.replace(/ /g, ""))
              }
              onKeyDown={(e) => {
                if (e.key === "Enter" && friendSecretCode && friendUsername) {
                  handleAddFriend();
                }
              }}
              placeholder="U3y281Km"
              ref={secretCodeInput}
              type="text"
              value={friendSecretCode}
            />
            <small id="userNameHelp" className="form-text text-danger">
              {secretCodeError ? secretCodeError : ""}
            </small>
          </div>
        </form>
        {addDeleteFriendStatus ? (
          <div className="d-flex justify-content-center">
            <p>{addDeleteFriendStatus}</p>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-link text-body-secondary text-decoration-none"
          onClick={() => handleClose()}
        >
          cancel
        </button>
        <button
          className="btn btn-sm btn-primary"
          disabled={!friendUsername || friendSecretCode.length !== 8}
          onClick={() => handleAddFriend()}
        >
          {isSpinning ? (
            <div
              className="spinner-grow spinner-grow-sm text-light me-2"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span role="img" aria-label="Women with Bunny Ears">
              ️👯‍♀️
            </span>
          )}
          <span className="ms-2">Add Friend</span>
        </button>
      </Modal.Footer>
    </Modal>
    // <div className="modal fade" id="add-friend-modal">
    //   <div className="modal-dialog" role="document">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title lead">Add Friend</h5>
    //         <button
    //           type="button"
    //           className="btn-close"
    //           data-bs-dismiss="modal"
    //           aria-label="Close"
    //         >
    //           <span aria-hidden="true">&times;</span>
    //         </button>
    //       </div>
    //       <div className="modal-body">

    //         <div className="d-flex justify-content-center">
    //           <span
    //             role="img"
    //             aria-label="People with Bunny Ears"
    //             style={{ fontSize: "64px" }}
    //           >
    //             👯
    //           </span>
    //         </div>
    //         <form action="">
    //           <div className="form-group">
    //             <label htmlFor="username">Enter friend&apos;s username</label>
    //             <input
    //               className="form-control"
    //               id="username"
    //               onChange={(e) =>
    //                 setFriendUsername(e.target.value.replace(/ /g, ""))
    //               }
    //               placeholder="Slugbert"
    //               ref={usernameInput}
    //               type="text"
    //               value={friendUsername}
    //             />
    //             <small id="userNameHelp" className="form-text text-danger">
    //               {usernameError ? usernameError : ""}
    //             </small>
    //           </div>
    //           <div className="form-group">
    //             <label htmlFor="secret-code">
    //               Enter friend&apos;s secret code
    //             </label>
    //             <input
    //               className="form-control"
    //               id="secret-code"
    //               onChange={(e) =>
    //                 setFriendSecretCode(e.target.value.replace(/ /g, ""))
    //               }
    //               onKeyDown={(e) => {
    //                 if (
    //                   e.key === "Enter" &&
    //                   friendSecretCode &&
    //                   friendUsername
    //                 ) {
    //                   handleAddFriend();
    //                 }
    //               }}
    //               placeholder="U3y281Km"
    //               ref={secretCodeInput}
    //               type="text"
    //               value={friendSecretCode}
    //             />
    //             <small id="userNameHelp" className="form-text text-danger">
    //               {secretCodeError ? secretCodeError : ""}
    //             </small>
    //           </div>
    //         </form>
    //         {addDeleteFriendStatus ? (
    //           <div className="d-flex justify-content-center">
    //             <p>{addDeleteFriendStatus}</p>
    //           </div>
    //         ) : null}
    //       </div>
    //       <div className="d-flex justify-content-between modal-footer">
    //         <button
    //           type="button"
    //           className="btn btn-link text-body-secondary text-decoration-none"
    //           data-bs-dismiss="modal"
    //         >
    //           cancel
    //         </button>
    //         <button
    //           className="btn btn-primary"
    //           disabled={!friendUsername || friendSecretCode.length !== 8}
    //           onClick={() => handleAddFriend()}
    //         >
    //           {isSpinning ? (
    //             <div
    //               className="spinner-grow spinner-grow-sm text-light me-2"
    //               role="status"
    //             >
    //               <span className="visually-hidden">Loading...</span>
    //             </div>
    //           ) : (
    //             <span role="img" aria-label="Women with Bunny Ears">
    //               ️👯‍♀️
    //             </span>
    //           )}
    //           <span className="ms-2">Add Friend</span>
    //         </button>
    //       </div>
    // </div>
    // </div>
    // </div>
    // </div>
  );
}
