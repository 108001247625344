import { useAppSelector } from "../hooks";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LottieLoader from "./shared/LottieLoader";
import StatusMessage from "./shared/StatusMessage";

const LoadingPage = () => {
  const history = useHistory();
  const {
    slugify: { isDoneLoading, loadingStatus, showTutorial }
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (isDoneLoading) {
      if (showTutorial) {
        history.push("/tutorial");
      } else {
        history.push("/home");
      }
    }
  }, [history, isDoneLoading, showTutorial]);

  // const {lottieHeight, lottieWidth} = useMemo(()=>{
  //   const lottieName = randomLottie.name
  //   lottieHeight = getLottieHeight()
  // }, [ randomLottie ])
  return (
    <div
      className="d-flex align-items-center justify-content-center animate__animated animate__fadeIn"
      style={{ height: "100vh" }}
    >
      <div className="d-flex flex-column align-items-center">
        {loadingStatus.includes("Houston") ? (
          <>
            <StatusMessage
              titleAnimation="animate__flash"
              emoticon="👩🏽‍🚀"
              title={loadingStatus}
            />
            <button
              className="btn btn-danger mt-0"
              onClick={() => window.location.reload()}
            >
              Eject!
            </button>
            <p className="lead mt-2">
              If this error persists, click{" "}
              <strong
                className="text-primary"
                onClick={() => history.push("app-status")}
                style={{ cursor: "pointer" }}
              >
                {/* <Link to="/">here</Link> */}
                here
              </strong>
            </p>
          </>
        ) : (
          <>
            <div className="d-flex d-sm-none justify-content-center">
              <LottieLoader smallScreen />
            </div>
            <div className="d-none d-sm-flex justify-content-center">
              <LottieLoader />
            </div>
          </>
        )}
        <p
          className="d-flex d-sm-none display my-5 text-center"
          style={{ fontSize: "18px" }}
        >
          {loadingStatus && !loadingStatus.includes("Houston")
            ? loadingStatus
            : "Loading..."}
        </p>
        <p
          className="d-none d-sm-flex display my-5 text-center"
          style={{ fontSize: "36px" }}
        >
          {loadingStatus
            ? loadingStatus.includes("Houston")
              ? null
              : loadingStatus
            : "Warming up..."}
        </p>
        {loadingStatus === "Authorizing Apple Music..." ? (
          <button className="btn btn-danger" onClick={() => history.push("/")}>
            Cancel Apple Authorization
          </button>
        ) : null}
        {process.env.NODE_ENV === "development" && (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className={gapi.client ? "text-success" : "text-danger"}>
              {gapi.client ? "gapi loaded" : "gapi not loaded"}
            </div>
            <div
              className={gapi.client?.youtube ? "text-success" : "text-danger"}
            >
              {gapi.client?.youtube ? "gapi youtube loaded" : "gapi youtube not loaded"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoadingPage;
