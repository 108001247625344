import PropTypes from "prop-types";
import SongListItem from "./SongListItem";
import SongListItemSmall from "./SongListItemSmall";

const SongListItems = (props) => {
  const { filteredSongs } = props;

  return filteredSongs.map((file) => {
    const index = filteredSongs.indexOf(file);
    return (
      <div className="w-100" key={index}>
        <div className="d-block d-sm-none">
          <SongListItemSmall file={file} index={index} />
        </div>
        <div className="d-none d-sm-block">
          <SongListItem file={file} index={index} />
        </div>
      </div>
    );
  });
};

SongListItems.propTypes = {
  uploadProgress: PropTypes.number.isRequired
};

export default SongListItems;
