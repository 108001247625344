import { TutorialPage1 } from "./TutorialPage1";
import { TutorialPage2 } from "./TutorialPage2";
import { TutorialPage3 } from "./TutorialPage3";
import { TutorialPage4 } from "./TutorialPage4";
import { TutorialPage5 } from "./TutorialPage5";
import { TutorialPage6 } from "./TutorialPage6";
import { updateTutorialStatus } from "../../actions";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import trippinBallz from "../../images/you_be_trippin_ballz.jpg";
import { useWindowSize } from "../../hooks";
import { useMemo } from "react";

export default function Tutorial() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [viewPortHeight, viewPortWidth] = useWindowSize();

  const isLandscape = useMemo(() => {
    return viewPortWidth > viewPortHeight;
  }, [viewPortHeight, viewPortWidth]);

  const location = useLocation();

  if (isLandscape && isMobile) {
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeIn w-100 vh-100"
        style={{
          backgroundImage: `url(${trippinBallz})`,
          backgroundPosition: "center",
          backgroundRepeat: "never",
          backgroundSize: "cover",
          padding: "env(safe-area-inset)",
          position: "fixed"
        }}
      >
        <div className="rotate-counter-clockwise-slow">
          <span role="img" aria-label="monkey face" style={{ fontSize: 72 }}>
            🐵
          </span>
        </div>
        <div
          className="display-4 mt-3 text-center text-light w-100"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
          Rotate Your Screen
        </div>
      </div>
    );
  }
  return (
    <div
      className={`d-flex flex-column justify-content-center ${
        viewPortWidth > 480 ? "vh-100" : ""
      }`}
    >
      <div
        id="tutorial"
        className="carousel slide"
        // data-ride="carousel"
      >
        <div
          className={`carousel-inner ${viewPortWidth > 480 ? "pb-5" : "pb-3"}`}
        >
          <div className="carousel-item active">
            <div className="d-flex justify-content-center w-100">
              <TutorialPage1 />
            </div>
          </div>
          <div className="carousel-item">
            <div className="d-flex justify-content-center w-100">
              <TutorialPage2 />
            </div>
          </div>
          <div className="carousel-item">
            <div className="d-flex justify-content-center">
              <TutorialPage3 />
            </div>
          </div>
          <div className="carousel-item">
            <div className="d-flex justify-content-center">
              <TutorialPage4 />
            </div>
          </div>
          <div className="carousel-item">
            <div className="d-flex justify-content-center">
              <TutorialPage5 />
            </div>
          </div>
          <div className="carousel-item">
            <div className="d-flex justify-content-center">
              <TutorialPage6 />
            </div>
          </div>
        </div>
        {viewPortWidth >= 768 && (
          <>
            <a
              className="carousel-control-prev text-decoration-none"
              href="#tutorial"
              role="button"
              data-bs-slide="prev"
            >
              <i className="icon-navigate_before carousel-control-icon text-primary" />
              <span className="visually-hidden">Previous</span>
            </a>
            <a
              className="carousel-control-next text-decoration-none"
              href="#tutorial"
              role="button"
              data-bs-slide="next"
            >
              <i className="icon-navigate_next carousel-control-icon text-primary" />
              <span className="visually-hidden">Next</span>
            </a>
          </>
        )}
        {/* <div className="carousel-indicators">
          <button
            className="active"
            data-bs-target="#tutorial"
            data-bs-slide-to="0"
            type="button"
          ></button>
          <button
            type="button"
            data-bs-target="#tutorial"
            data-bs-slide-to="1"
          />
          <button
            type="button"
            data-bs-target="#tutorial"
            data-bs-slide-to="2"
          />
          <button
            type="button"
            data-bs-target="#tutorial"
            data-bs-slide-to="3"
          />
          <button
            type="button"
            data-bs-target="#tutorial"
            data-bs-slide-to="4"
          />
          <button
            type="button"
            data-bs-target="#tutorial"
            data-bs-slide-to="5"
          />
        </div> */}
      </div>
      {viewPortWidth >= 768 ? (
        <div className="d-flex justify-content-center w-100">
          {location.pathname !== "/" && (
            <button
              className="btn btn-link btn-sm text-body-secondary text-decoration-none"
              onClick={() => {
                dispatch(updateTutorialStatus(false));
                history.goBack();
              }}
            >
              end tutorial
            </button>
          )}
        </div>
      ) : (
        <div className="d-flex align-items-end justify-content-center">
          <a
            className="text-decoration-none"
            href="#tutorial"
            role="button"
            data-bs-slide="prev"
          >
            <i
              className="icon-navigate_before carousel-control-icon text-primary me-3"
              style={{ fontSize: 24 }}
            />
            <span className="visually-hidden">Previous</span>
          </a>
          {location.pathname !== "/" && (
            <button
              className="btn btn-link btn-sm text-body-secondary text-decoration-none"
              onClick={() => {
                dispatch(updateTutorialStatus(false));
                history.goBack();
              }}
            >
              end tutorial
            </button>
          )}
          <a
            className="text-decoration-none"
            href="#tutorial"
            role="button"
            data-bs-slide="next"
          >
            <i
              className="icon-navigate_next carousel-control-icon text-primary ms-3"
              style={{ fontSize: 24 }}
            />
            <span className="visually-hidden">Next</span>
          </a>
        </div>
      )}
    </div>
  );
}
