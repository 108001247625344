import { Auth } from "aws-amplify";
import { AppThunk } from "../store/store";

export const doSignOutCognitoUser = (): AppThunk => async (dispatch) => {
  dispatch({ type: "SIGN_OUT_START" });
  try {
    await Auth.signOut();
    dispatch({ type: "SIGN_OUT_SUCCESS" });
  } catch (e) {
    dispatch({ type: "SIGN_OUT_FAIL", payload: e });
    console.log(e);
  }
};

export const signIn =
  (data: any): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch({
      type: "SIGN_IN",
      payload: data
    });
  };

// export const signOut = () => ({
//   type: "SIGN_OUT"
// });
