import uniqBy from "lodash/uniqBy";
export default (state = [], action) => {
  switch (action.type) {
    case "ADD_NEW_SLUGTUBE_COMMENT_SUCCESS":
      return [...state, action.payload];
    case "GET_INITIAL_ITEM_COMMENTS_SUCCESS":
      console.log(action.payload);
      return uniqBy(state.concat(action.payload), "commentId");
    default:
      return state;
  }
};
