import { useState, useContext, useMemo } from "react";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function SlugifyAppleMusicItemWithWidgets({
  item,
  showMyTracks,
  showSlurpedTracks
}) {
  const { isDark } = useContext(ThemeContext);

  const [isLoaded, setIsLoaded] = useState(false);

  const { toolTipText } = useMemo(() => {
    let slurpedBy = null;
    let toolTipText = null;
    if (item.slurpedBy && item.slurpedBy.length) {
      if (item.slurpedBy.length === 1) {
        slurpedBy = item.slurpedBy[0];
      } else {
        const entries = item.slurpedBy.slice(0, -1);
        const lastEntry = item.slurpedBy.slice(-1);
        slurpedBy = entries.join(", ") + ` & ${lastEntry}`;
      }
    }
    if (
      (!showMyTracks && showSlurpedTracks) ||
      (!showMyTracks && !showSlurpedTracks)
    ) {
      if (item.sharedByFriend && item.sharedByFriend.length) {
        toolTipText = `Shared by ${item.sharedByFriend[0]}`;
      }
    } else if (
      (showMyTracks && showSlurpedTracks) ||
      (showMyTracks && !showSlurpedTracks)
    ) {
      toolTipText = slurpedBy ? `Slurped by ${slurpedBy}` : null;
    }
    return { toolTipText };
  }, [item, showMyTracks, showSlurpedTracks]);

  const handleLoad = async () => {
    setIsLoaded(true);
  };

  return (
    <OverlayTrigger
      key={item.id}
      placement="top"
      overlay={<Tooltip id={`tooltip-${item.id}`}>{toolTipText}</Tooltip>}
    >
      <li>
        <div
          className={
            isLoaded ? `animate__animated animate__fadeIn mb-2` : "mx-0"
          }
          style={{ height: isLoaded ? "148px" : "0px" }}
        >
          <iframe
            allow="autoplay *; encrypted-media *;"
            onLoad={() => handleLoad()}
            src={
              item.attributes.url.replace(
                /music.apple.com/,
                "embed.music.apple.com"
              ) + `&theme=${isDark ? "dark" : "light"}`
            }
            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
            style={{
              allow: "autoplay *; encrypted-media *; clipboard-write",
              borderRadius: 10,
              width: "100%",
              overflow: "hidden",
              backgroundColor: "transparent",
              transform: "translateZ('0px')",
              animation: "2s ease 0s 6 normal none running loading-indicator"
            }}
            id={`apple-music-embed-item-${item.id}`}
            title={toolTipText}
            width="100%"
          />
        </div>
      </li>
    </OverlayTrigger>
  );
}
