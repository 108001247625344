import { doSignOutCognitoUser } from "../../actions";
import { Link } from "react-router-dom";
import { Spinner } from "./Spinner";
import { useAppDispatch } from "../../hooks";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/ColorThemeProvider";

interface Props {
  animation: string;
  currentPath: string;
  isSpinning: boolean;
  myFriendsSharedPlaylists: any[];
  setIsSpinning: (b: boolean) => void;
}

export default function NavBarCollapse({
  animation,
  currentPath,
  isSpinning,
  myFriendsSharedPlaylists,
  setIsSpinning
}: Props) {
  const dispatch = useAppDispatch();
  const { isDark, toggleTheme } = useContext(ThemeContext);
  return (
    <div className="d-flex flex-column d-md-none">
      {myFriendsSharedPlaylists && myFriendsSharedPlaylists.length && (
        <li
          className={`nav-item ${
            currentPath === "/friends-playlists" ||
            currentPath === "/shared-playlist-tracks"
              ? "active"
              : ""
          } mx-sm-3 ${animation}`}
        >
          <Link className="nav-link" to="/friends-playlists">
            <span data-bs-toggle="collapse" data-bs-target="#navbar">
              Friend&apos;s Shared Playlists
            </span>
          </Link>
        </li>
      )}
      <li
        className={`nav-item ${
          currentPath === "/shared-playlists" ? "active" : ""
        } mx-sm-3 ${animation}`}
      >
        <Link className="nav-link" to="/shared-playlists">
          <span data-bs-toggle="collapse" data-bs-target="#navbar">
            My Shared Playlists
          </span>
        </Link>
      </li>
      <li
        className={`nav-item ${
          currentPath === "/settings" ? "active" : ""
        } mx-sm-3 ${animation}`}
      >
        <Link className="nav-link" to="/settings">
          <span data-bs-toggle="collapse" data-bs-target="#navbar">
            Settings
          </span>
        </Link>
      </li>
      <li
        className={`nav-item ${
          currentPath === "/tutorial" ? "active" : ""
        } mx-sm-3`}
      >
        <Link className="nav-link" to="/tutorial">
          <span data-bs-toggle="collapse" data-bs-target="#navbar">
            Show Tutorial
          </span>
        </Link>
      </li>
      <li
        className={`nav-item ${
          currentPath === "/app-status" ? "active" : ""
        } mx-sm-3 ${animation}`}
      >
        <Link className="nav-link" to="/app-status">
          <span data-bs-toggle="collapse" data-bs-target="#navbar">
            Slugify Status
          </span>
        </Link>
      </li>
      <li className="nav-item" onClick={() => toggleTheme()}>
        <span className="nav-link">
          {isDark ? "🌞" : "🌝"} Change to {isDark ? "Light" : "Dark"} Mode
        </span>
      </li>
      <li
        className={`nav-item mx-sm-3 ${animation}`}
        onClick={() => {
          try {
            setIsSpinning(true);
            dispatch(doSignOutCognitoUser());
          } catch (error) {
            setIsSpinning(false);
            console.warn(error);
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <div className="d-flex align-items-center nav-link">
          {isSpinning ? (
            <div className="py-1 ms-5" style={{ height: 24 }}>
              <Spinner size={16} color="secondary" type="propagate" />
            </div>
          ) : (
            <span className="text-danger">Sign Out</span>
          )}
        </div>
      </li>
    </div>
  );
}
