import { omit } from "lodash";
import { timezones } from "../data/timezones";
import { updateSlugifyUser } from "../actions";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

export const TimeZoneSelectorMobile = ({
  autoSlurpFriend,
  autoSlurpFriends,
  friend,
  isUpdatingAutoSlurpNotificationTimezone,
  setIsUpdatingAutoSlurpNotificationTimezone
}) => {
  const dispatch = useDispatch();
  const [isGuessingTimeZone, setIsGuessingTimeZone] = useState(false);

  const getLabel = (offsetMins) => {
    if (offsetMins === 0) {
      return "(GMT)";
    }
    const absMins = offsetMins > 0 ? offsetMins : offsetMins * -1;
    const offsetHours = absMins / 60;
    if (offsetHours % 1 === 0) {
      return `(GMT${offsetMins > 0 ? "+" : "-"}${offsetHours}:00)`;
    } else {
      const hour = Math.floor(offsetHours);
      const partialHour = offsetHours - hour;
      const mins = 60 * partialHour;
      return `(GMT${offsetMins > 0 ? "+" : "-"}${hour}:${mins})`;
    }
  };

  const handleUpdateUserSettings = async (label, value) => {
    try {
      const updatedAutoSlurpFriend = omit(autoSlurpFriend, "scheduleTimezone");
      updatedAutoSlurpFriend.scheduleTimezone = {
        label: label + " " + value,
        value
      };
      const payload = [
        ...autoSlurpFriends.filter((item) => item.username !== friend.username),
        updatedAutoSlurpFriend
      ];
      await dispatch(updateSlugifyUser({ autoSlurpFriends: payload }));
      return Promise.resolve();
    } catch (error) {
      console.warn(error);
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (
      autoSlurpFriend &&
      autoSlurpFriend.scheduleTimezone &&
      autoSlurpFriend.scheduleTimezone.label &&
      autoSlurpFriend.scheduleTimezone.value
    ) {
      // do nothing
    } else {
      setIsGuessingTimeZone(true);
      const value = dayjs.tz.guess();
      const timeintimezone = dayjs().tz(value);
      const offsetMins = timeintimezone.utcOffset();
      const label = getLabel(offsetMins);
      const updatedAutoSlurpFriend = omit(autoSlurpFriend, "scheduleTimezone");
      updatedAutoSlurpFriend.scheduleTimezone = {
        label: label + " " + value,
        value
      };
      const payload = [
        ...autoSlurpFriends.filter((item) => item.username !== friend.username),
        updatedAutoSlurpFriend
      ];
      dispatch(updateSlugifyUser({ autoSlurpFriends: payload }))
        .then(() => {
          setIsGuessingTimeZone(false);
        })
        .catch((error) => {
          setIsGuessingTimeZone(false);
          console.warn(error);
        });
    }
  }, [autoSlurpFriend, autoSlurpFriends, dispatch, friend.username]);

  const handleTimeZoneSelect = async (value) => {
    try {
      const timeintimezone = dayjs().tz(value);
      const offsetMins = timeintimezone.utcOffset();
      const label = getLabel(offsetMins);
      setIsUpdatingAutoSlurpNotificationTimezone(true);
      await handleUpdateUserSettings(label, value);
      setIsUpdatingAutoSlurpNotificationTimezone(false);
    } catch (error) {
      console.warn(error);
    }
  };

  const renderOptions = () => {
    if (isGuessingTimeZone) {
      return <option value={""}>Guessing Time Zone...</option>;
    }
    return timezones.map((item) => {
      const timeintimezone = dayjs().tz(item.tz);
      const offsetMins = timeintimezone.utcOffset();
      const label = getLabel(offsetMins);
      return (
        <option key={item.tz} value={item.tz}>
          {label + " " + item.locale}
        </option>
      );
    });
  };

  return (
    <div className="form-group mb-0">
      <label htmlFor="timezone">Your time zone</label>
      <select
        className="form-select form-select-sm"
        disabled={isUpdatingAutoSlurpNotificationTimezone || isGuessingTimeZone}
        id="timezone"
        onChange={(e) => handleTimeZoneSelect(e.target.value)}
        value={
          autoSlurpFriend &&
          autoSlurpFriend.scheduleTimezone &&
          autoSlurpFriend.scheduleTimezone.value
            ? autoSlurpFriend.scheduleTimezone.value
            : "Guessing Timezone..."
        }
      >
        {/* <option value="">Select your time zone</option> */}
        {renderOptions()}
      </select>
    </div>
  );
};
