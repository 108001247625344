import { useEffect, useState } from "react";
import {
  getSlugBucketSearchResults,
  handleLike,
  getS3MusicFiles
} from "./actions";
import $ from "jquery";
import { useAppDispatch, useAppSelector } from "./hooks";
import { SlugbucketActionType } from "./types";

export default function SlugSearch() {
  const dispatch = useAppDispatch();

  const { slugbucketSongs, searchResults } = useAppSelector(
    (state) => state.slugBucket
  );

  const [searchHasRun, setSearchHasRun] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [spinnerOn, setSpinnerOn] = useState(false);

  useEffect(() => {
    if (!slugbucketSongs) dispatch(getS3MusicFiles());
  }, [dispatch, slugbucketSongs]);

  const handleChange = async (e) => {
    await setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    setSpinnerOn(true);
    dispatch(
      getSlugBucketSearchResults(searchValue, setSpinnerOn, setSearchHasRun)
    );
  };

  const handleReset = () => {
    setSearchValue("");
    dispatch({ type: SlugbucketActionType.RESET_SLUG_BUCKET_SEARCH_RESULTS });
    setSearchHasRun(false);
    $("#searchInput").focus();
  };

  const renderResults = () => {
    if (spinnerOn) {
      return (
        <div className="d-flex align-items-center justify-content-center w-100">
          <div
            className="spinner-border text-primary my-5 text-center"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    } else if (searchResults.length === 0 && searchHasRun) {
      return (
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="lead my-5 text-center">No Matching Results...</div>
        </div>
      );
    } else {
      return searchResults.map((item) => {
        // TODO: remote if not needed
        const {
          album,
          // artist,
          artistClearText,
          fileName,
          liked,
          // title,
          s3FileName,
          titleClearText
        } = item;
        return (
          <li className="list-group-item" key={searchResults.indexOf(item)}>
            <div className="lead">
              <div className="d-flex justify-content-between">
                <strong>{titleClearText}</strong>
                <i
                  className={`${
                    liked
                      ? "icon-favorite slug-icon text-danger"
                      : " icon-favorite_outline slug-icon text-body-secondary"
                  }`}
                  onClick={() => {
                    dispatch(
                      handleLike(
                        artistClearText,
                        titleClearText,
                        s3FileName,
                        "search"
                      )
                    );
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <small>
              <div>
                <strong>Artist:</strong>&nbsp;
                {artistClearText || "n/a"} &nbsp;
                <strong>Album:</strong>&nbsp;
                {album || "n/a"} &nbsp;
                <strong>File Name:</strong>&nbsp;
                {fileName || "n/a"} &nbsp;
              </div>
            </small>
          </li>
        );
      });
    }
  };

  return (
    <div>
      <div className="d-flex flex-column container my-3 my-sm-5 align-items-center w-auto">
        <div
          className="d-block d-sm-none display-4 mb-4 text-center"
          style={{ fontSize: "32px" }}
        >
          Slug Search
        </div>
        <div className="d-none d-sm-block display-3 mb-4 text-center">
          Slug Search
        </div>
        <div className="lead text-center mb-3">
          Find any song that&apos;s been uploaded to Slug Bucket
        </div>
        <div className="d-flex justify-content-center form-group w-100">
          <div className="d-flex flex-column align-items-center flex-md-row slug-width">
            <input
              autoFocus
              id="searchInput"
              type="search"
              className="form-control"
              aria-label="Search"
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              placeholder="Search by Album, Artist, or Title..."
              value={searchValue}
            />
            <button
              className="d-block d-md-none btn btn-primary btn-block my-2 my-sm-3"
              disabled={!searchValue}
              onClick={() => handleSearch()}
            >
              Search
            </button>
            <button
              className="d-none d-md-block btn btn-primary ms-3 my-2 my-sm-3"
              disabled={!searchValue}
              onClick={() => handleSearch()}
            >
              Search
            </button>
            <button
              className="d-block d-md-none btn btn-secondary btn-block"
              disabled={!searchValue}
              onClick={() => handleReset()}
            >
              Reset
            </button>
            <button
              className="d-none d-md-block btn btn-secondary ms-3"
              disabled={!searchValue}
              onClick={() => handleReset()}
            >
              Reset
            </button>
          </div>
        </div>
        {searchResults.length !== 0 ? (
          <div className="lead mb-2">
            {searchResults.length} matching result(s)
          </div>
        ) : null}
        <ul className="list-group slug-width">{renderResults()}</ul>
      </div>
    </div>
  );
}
