import { AppThunk } from "../../store/store";
import { CookiesActionType } from "../../types"; // enum
import { Link } from "react-router-dom";
import { ThemeContext } from "../../contexts/ColorThemeProvider";
import { useAppDispatch } from "../../hooks";
import { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";

interface Props {
  doSignOutCognitoUser: () => AppThunk<void>;
  renderName: () => string;
}
export default function NavBarDropDown({
  doSignOutCognitoUser,
  renderName
}: Props) {
  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);
  return (
    <Dropdown style={{ marginTop: 2 }}>
      <Dropdown.Toggle
        variant={isDark ? "dark" : "light"}
        size="sm"
        className="d-flex align-items-center"
      >
        <i className="icon-account_circle slug-icon me-1" />
        <span className="mx-2">{renderName()}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/shared-playlists">
          <span className="dropdown-item">My Shared Playlists</span>
        </Dropdown.Item>
        <Dropdown.Item as={Link} className="dropdown-item" to="/settings">
          <span className="dropdown-item">Settings</span>
        </Dropdown.Item>
        <Dropdown.Item as={Link} className="dropdown-item" to="/tutorial">
          <span className="dropdown-item">Show Tutorial</span>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item>
          <div
            className="dropdown-item"
            onClick={() =>
              dispatch({
                type: CookiesActionType.SET_COOKIES_MODAL_VISIBILITY,
                payload: true
              })
            }
          >
            Cookies Preferences
          </div>
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/app-status">
          <span className="dropdown-item">Slugify Status</span>
        </Dropdown.Item>
        <Dropdown.Item
          href="https://www.youtube.com/watch?v=abjF91McrtQ&list=PLpf0oypHBL-kAktRMRiYdWFEanfyw6Q23"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="dropdown-item">Slugify on YouTube</span>
        </Dropdown.Item>
        <Dropdown.Item>
          <span
            className="dropdown-item"
            onClick={() => dispatch(doSignOutCognitoUser())}
          >
            Sign Out
          </span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
