import { useCallback } from "react";
import {
  getRandomTipper,
  getRandomBunny,
  getRandomDancers
} from "../../functions";

interface Props {
  title?: string
  emoticon: string
  size?: string
  titleAnimation?: string
  subtitle?: string
  subtitle2?: string
  subtitleAnimation?: string
  className?:string,
  isInfinite?: boolean
}
const StatusMessage = ({
  title,
  emoticon,
  size = "sm",
  titleAnimation,
  subtitle,
  subtitle2,
  subtitleAnimation,
  className = "",
  isInfinite
}: Props) => {
  const tipper = useCallback(()=>getRandomTipper(), []);
  const bunny = useCallback(()=>getRandomBunny(), []);
  const dancers = useCallback(()=>getRandomDancers(), []);

  const getAnimation = () => {
    if (titleAnimation) {
      return `animate__animated animate__${titleAnimation} ${
        isInfinite ? "animate__infinite" : null
      }`;
    }
  };
  const getSubtitleAnimation = () => {
    if (subtitleAnimation) {
      return `animate__animated animate__${subtitleAnimation} ${
        isInfinite ? "animate__infinite" : null
      }`;
    }
  };

  const getEmoticon = (emoticon: string) => {
    switch (emoticon) {
      case "bunny":
        return bunny();
      case "dancers":
        return dancers();
      case "tipper":
        return tipper();
      default:
        return emoticon;
    }
  };
  return (
    <div
      className={`d-flex flex-column align-items-center text-center animate__animated animate__fadeIn my-2 my-sm-3 ${className}`}
    >
      <div className="display">
        <span
          role="img"
          aria-label={emoticon}
          className={`status-emoji-${size}`}
        >
          {getEmoticon(emoticon)}
        </span>
      </div>
      {title ? (
        <p className={`lead mb-2 ${getAnimation()} status-title-${size}`}>
          {title}
        </p>
      ) : null}
      {subtitle ? (
        <p
          className={`lead mb-0 ${getSubtitleAnimation()} status-subtitle-${size} px-1`}
        >
          {subtitle}
        </p>
      ) : null}
      {subtitle2 ? (
        <p className={`lead mb-0 status-subtitle-${size} px-1`}>{subtitle2}</p>
      ) : null}
    </div>
  );
};

export default StatusMessage;
