import { useState } from "react";
import { SignInPageMobile } from "./SignInPageMobile";
import { SignInPageNormal } from "./SignInPageNormal";
import { isMobile } from "react-device-detect";

export const SignInPage = () => {
  const [formAction, setFormAction] = useState("signIn");
  // const [isScreenShort, setIsScreenShort] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeResent, setIsCodeResent] = useState(false);

  // useEffect(() => {
  //   if (window.innerHeight <= 568) {
  //     setIsScreenShort(true);
  //   }
  // }, []);

  if (isMobile) {
    return (
      <SignInPageMobile
        formAction={formAction}
        setFormAction={setFormAction}
        // isScreenShort={isScreenShort}
        isCodeSent={isCodeSent}
        setIsCodeSent={setIsCodeSent}
        isCodeResent={isCodeResent}
        isMobile={isMobile}
        setIsCodeResent={setIsCodeResent}
      />
    );
  }
  return (
    <SignInPageNormal
      formAction={formAction}
      setFormAction={setFormAction}
      // isScreenShort={isScreenShort}
      isCodeSent={isCodeSent}
      isMobile={isMobile}
      setIsCodeSent={setIsCodeSent}
      isCodeResent={isCodeResent}
      setIsCodeResent={setIsCodeResent}
    />
  );
};
