import { acceptAllCookies } from "../../actions/cookiesActions";
import { CookiesActionType } from "../../types";
import { ReactElement, useContext } from "react";
import { ThemeContext } from "../../contexts/ColorThemeProvider";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useHistory } from "react-router-dom";
import bgImage from "../../images/dmitry-bayer-FhUcX30FAr4-unsplash-BW.jpg";
import Button from "../../components/shared/Button";
import CookiesModal from "./components/CookiesModal";
import DarkModeToggleButton from "../../components/shared/DarkModeToggleButton";
import Feature from "./components/Feature";
import Footer from "../../components/shared/Footer";
import Toast from "react-bootstrap/Toast";
import { Helmet } from "react-helmet";

type Feature = {
  color: string;
  emoji: string;
  title: string;
  text: ReactElement<any, any>;
};
const features = [
  {
    color: "#ffb3ba",
    emoji: "👩‍🎤",
    title: "It Just Works",
    text: (
      <div>
        Easily share music with friends across Apple Music, Spotify, and
        Youtube. <br />
        It just works!
      </div>
    )
  },
  {
    color: "#ffdfba",
    emoji: "📻",
    title: "Tune In",
    text: (
      <div>
        Slugify is great for getting turned on to new music when your friends
        share their stuff with you.
      </div>
    )
  },
  {
    color: "#ffffba",
    emoji: "🎧",
    title: "Turn On",
    text: (
      <div>
        When you share your stuff with friends, they&apos;ll think you&apos;re
        cool! Or maybe not, it really depends...
      </div>
    )
  },
  {
    color: "#9ee09e",
    emoji: "📺",
    title: "SlugTube",
    text: (
      <div>
        YouTube users can now get in on the action. Videos are matched to music
        tracks and vice-a-versa.
      </div>
    )
  },
  {
    color: "#9ec1cf",
    emoji: "💃🏽",
    title: "The Price is Right!",
    text: (
      <div>
        Slugify is currently free for a limited time, after which existing users
        will receive a discounted subscription.
      </div>
    )
  },
  {
    color: "#cc99c9",
    emoji: "👩🏽‍🏫",
    title: "Learn More",
    text: (
      <div>
        Check out the Slugify Tutorial to get a better understanding how Slugify
        can expand your groove!
      </div>
    )
  }
];

function renderFeatures() {
  return features.map((item: Feature, index) => (
    <Feature
      key={index}
      color={item.color}
      emoji={item.emoji}
      title={item.title}
      text={item.text}
    />
  ));
}

export default function Home() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);
  const {
    cookies: { isCookiesToastVisible },
    slugify: { loadingStatus }
  } = useAppSelector((state) => state);
  // const [isCookiesToastVisible, setIsCookiesToastVisible] = useState(false);
  // const [isCookiesModalOpen, setIsCookiesModalOpen] = useState(false);
  // const [state, setState] = useState<CookiesConsentState>(initialState);
  // const [initialCookieState, setInitialCookieState] =
  //   useState<CookiesConsentState>();

  // useEffect(() => {
  //   const cookie = Cookies.get(CONSENT_COOKIE_NAME);
  //   if (cookie) {
  //     const parsed: CookiesConsentState = JSON.parse(cookie);
  //     setInitialCookieState(parsed);
  //     setState({ ...state, ...parsed });
  //   }
  // }, []);

  // async function handleAcceptAll() {
  //   const updatedState = {
  //     isAdvertisingEnabled: true,
  //     isFunctionalEnabled: true,
  //     isPerformanceEnabled: true
  //   };
  //   setState({
  //     ...state,
  //     ...updatedState
  //   });
  //   setInitialCookieState(updatedState);
  //   setCookie(updatedState);
  //   dispatch({
  //     type: CookiesActionType.SET_COOKIES_MODAL_VISIBILITY,
  //     payload: false
  //   });
  //   // setIsCookiesModalOpen(false);
  // }

  // async function handleSave() {
  //   const updatedState = { ...state };
  //   setInitialCookieState(updatedState);
  //   setCookie(updatedState);
  //   dispatch({
  //     type: CookiesActionType.SET_COOKIES_MODAL_VISIBILITY,
  //     payload: false
  //   });
  //   // setIsCookiesModalOpen(false);
  // }

  // function setCookie(updatedState: CookiesConsentState) {
  //   const consent = JSON.stringify(updatedState);
  //   Cookies.set(CONSENT_COOKIE_NAME, consent, {
  //     expires: 365,
  //     path: "/"
  //   });
  // }

  return (
    // <div className="d-flex flex-column  justify-content-center w-100 vh-100 position-relative">
    <div
      className="d-flex flex-column align-items-center justify-content-center position-relative vh-100"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.60)), url(${bgImage})`,
        backgroundPosition: "left",
        backgroundRepeat: "never",
        backgroundSize: "cover"
        // height: "100vh"
      }}
    >
      <Helmet>
        <title>Slugify</title>
        <meta
          name="description"
          content="Share music freely across Apple Music, Spotify, and YouTube with Slugify.  Discover new music and share with ease, no matter what service you use!"
        />
        <meta property="og:title" content="Slugify" />
        <meta
          property="og:description"
          content="Share music freely across Apple Music, Spotify, and YouTube with Slugify.  Discover new music and share with ease, no matter what service you use!"
        />
        <meta
          property="og:image"
          content="https://slugify.abts.io/slugify.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://slugify.abts.io" />
        <link rel="canonical" href="https://slugify.abts.io" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <div
        className="d-flex flex-column align-items-center justify-content-xl-center w-100"
        // className="d-flex flex-column align-items-center justify-content-center w-100 position-relative mt-3 mt-sm-5"
        style={{ marginBottom: 56, overflow: "scroll", maxWidth: 1024 }}
      >
        <div
          className="d-flex justify-content-end fixed-top w-100 pt-3 pe-4"
          // style={{
          //   marginBottom: 56
          // }}
        >
          <DarkModeToggleButton lightMoon />
          {/* <div
              onClick={() => setIsCookiesToastVisible(true)}
              style={{
                cursor: "pointer",
                fontSize: 42
              }}
            >
              🍪
            </div> */}
        </div>
        <div className="d-flex d-sm-none mt-3">
          <h1
            className="display-4 mb-3 text-light"
            style={{ fontSize: 42, opacity: 0.7 }}
          >
            Slugify
          </h1>
        </div>
        <div className="d-none d-sm-flex mt-5">
          <h1 className="display-3 text-light" style={{ opacity: 0.7 }}>
            Slugify
          </h1>
        </div>
        <div className="container d-flex justify-content-center flex-wrap w-100 my-2 my-sm-4">
          {renderFeatures()}
        </div>
        <Button
          btnText={loadingStatus ? "Signing in" : "Sign In"}
          isDisabled={loadingStatus}
          color="primary"
          className="mb-5 mb-sm-5"
          outline
          fn={() => history.push("/signIn")}
        />
        {isCookiesToastVisible ? (
          <div
            className="d-flex justify-content-end fixed-bottom w-100 pe-3"
            style={{ marginBottom: 72, marginRight: 24, right: 26 }}
          >
            <Toast
              onClose={() =>
                dispatch({
                  type: CookiesActionType.SET_COOKIES_TOAST_VISIBILITY,
                  payload: false
                })
              }
            >
              <Toast.Header>
                <strong className="me-auto">🍪 Cookies Consent</strong>
              </Toast.Header>
              <Toast.Body>
                <div className="d-flex flex-column">
                  We use cookies to improve your user experience. You can read
                  more about how we use cookies in our Cookies Policy
                  <div className="d-flex justify-content-between mt-3">
                    <Button
                      btnText="Preferences"
                      color="secondary"
                      size="sm"
                      fn={() => {
                        dispatch({
                          type: CookiesActionType.SET_COOKIES_TOAST_VISIBILITY,
                          payload: false
                        });
                        dispatch({
                          type: CookiesActionType.SET_COOKIES_MODAL_VISIBILITY,
                          payload: true
                        });
                      }}
                    />
                    <button
                      className={`btn btn-sm btn-${
                        isDark ? "outline-primary" : "primary"
                      }`}
                      id="accept-all-cookies-toast-button"
                      onClick={() => {
                        dispatch(acceptAllCookies());
                        dispatch({
                          type: CookiesActionType.SET_COOKIES_TOAST_VISIBILITY,
                          payload: false
                        });
                        // dispatch({
                        //   type: CookiesActionType.SET_COOKIES_MODAL_VISIBILITY,
                        //   payload: false
                        // });
                      }}
                    >
                      Accept All
                    </button>
                  </div>
                </div>
              </Toast.Body>
            </Toast>
          </div>
        ) : (
          <div
            className="d-flex justify-content-end fixed-bottom w-100 pe-3"
            style={{
              marginBottom: 56
            }}
          >
            <div
              onClick={() =>
                dispatch({
                  type: CookiesActionType.SET_COOKIES_TOAST_VISIBILITY,
                  payload: true
                })
              }
              style={{
                cursor: "pointer",
                fontSize: 42
              }}
            >
              🍪
            </div>
          </div>
        )}
        <Footer />
      </div>
      <CookiesModal />
    </div>
    // </>
  );
}
