import { getRandomDancers } from "../functions";
import {
  handlePushNotification,
  createSlugifySlurpedTracks,
  getAppleMusicInstance
} from "../actions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect, useState, useCallback, useMemo, useContext } from "react";
import appleMusicIcon from "../images/icon_apple_music_new_red.png";
import axios from "axios";
import chalk from "chalk";
import dayjs from "dayjs";
import { SlugifyTrackType } from "../types";
import { Modal } from "react-bootstrap";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { Spinner } from "./shared/Spinner";
import { isMobile } from "react-device-detect";

const isDev = process.env.NODE_ENV === "development";

interface Props {
  selectedPlaylist?: { id: string; name: string };
  isSlugifyAppleMusicModalOpen: boolean;
  setIsSlugifyAppleMusicModalOpen: (b: boolean) => void;
}
export default function SlugifyAppleMusicModal({
  selectedPlaylist,
  isSlugifyAppleMusicModalOpen,
  setIsSlugifyAppleMusicModalOpen
}: Props) {
  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);
  const dancers = useCallback(() => getRandomDancers(), []);

  const {
    auth: { username: currentUsername },
    appleMusic: appleMusicData,
    appleMusic: {
      appleMusicTokenData: { token: appleDeveloperToken }
    },
    slugify: { filteredTracks }
  } = useAppSelector((state) => state);

  const [error, setError] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [isSendingNotifications, setIsSendingNotifications] = useState(false);
  const [tracksAddSuccess, setTracksAddSuccess] = useState(false);
  const [tracksToSaveIsEmpty, setTracksToSaveIsEmpty] = useState(false);

  useEffect(() => {
    () => {
      cleanUpModal();
    };
  }, []);

  const addTracksToPlaylist = async () => {
    dispatch({ type: "ADD_TRACKS_TO_APPLE_MUSIC_PLAYLIST_START" });
    setIsSpinning(true);
    try {
      if (!selectedPlaylist?.id) {
        throw new Error("No Selected Playlist!");
      }
      const { music } = await dispatch(getAppleMusicInstance());
      const appleMusicUserToken = await music.authorize();
      if (isDev) {
        console.log("🔑 Is Apple Music Authorized? ", !!appleMusicUserToken);
      }
      dispatch({
        type: "SET_APPLE_MUSIC_CONNECTED_STATUS",
        payload: !!appleMusicUserToken
      });
      type DataItem = {
        id: string;
        type: string;
      };
      const data: { data?: DataItem[] } = {};

      // TODO: Prevent duplicates when adding to playlist (check first)
      if (!filteredTracks.length) {
        setTracksAddSuccess(true);
        setIsSpinning(false);
        setTracksToSaveIsEmpty(true);
        return;
      }

      data.data = [
        ...filteredTracks.map((item: DataItem) => ({
          id: item.id,
          type: item.type
        }))
      ];

      const postOptions = {
        method: "POST",
        url: `https://api.music.apple.com/v1/me/library/playlists/${selectedPlaylist.id}/tracks`,
        headers: {
          Authorization: "Bearer " + appleDeveloperToken,
          "Music-User-Token": appleMusicUserToken,
          "Content-Type": "application/json"
        },
        data: data
      };

      await axios(postOptions);

      if (isDev) {
        console.log(chalk("Fix this type!"));
      }
      const newSlurpedTracks = filteredTracks.map((item: any) => {
        if (
          item.trackType ===
          SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY
        ) {
          return {
            appleMusicId: item.id,
            appleMusicTrackAlbum: item.attributes.albumName,
            appleMusicTrackArtist: item.attributes.artistName,
            appleMusicTrackIsrc: item.attributes.isrc,
            appleMusicTrackTitle: item.attributes.name,
            matchedBy: item.matchedby,
            originalTrackAddedBy: item.spotifyInfo.added_by,
            owner: currentUsername,
            slurpToPlaylistId: selectedPlaylist,
            spotifyId: item.spotifyInfo.spotifyId,
            spotifyTrackAlbum: item.spotifyInfo.album,
            spotifyTrackArtists: [item.spotifyInfo.artist],
            spotifyTrackIsrc: item.spotifyInfo.spotifyIsrc,
            spotifyTrackTitle: item.spotifyInfo.title,
            trackType: item.trackType,
            toBeDeletedOn: dayjs().add(1, "month").toISOString(),
            username: currentUsername
          };
        } else {
          return {
            appleMusicId: item.id,
            appleMusicTrackAlbum: item.attributes.albumName,
            appleMusicTrackArtist: item.attributes.artistName,
            appleMusicTrackIsrc: item.attributes.isrc,
            appleMusicTrackTitle: item.attributes.name,
            matchedBy: item.matchedby,
            originalTrackAddedBy: item.added_by,
            owner: currentUsername,
            slurpToPlaylistId: selectedPlaylist,
            trackType: item.trackType,
            toBeDeletedOn: dayjs().add(1, "month").toISOString(),
            username: currentUsername
          };
        }
      });

      await dispatch(createSlugifySlurpedTracks(newSlurpedTracks));

      dispatch({
        type: "ADD_TRACKS_TO_APPLE_MUSIC_PLAYLIST_SUCCESS"
      });
      setIsSendingNotifications(true);
      dispatch(
        handlePushNotification(
          `${currentUsername} just slurped your tracks!`,
          false,
          filteredTracks
        )
      );
      setIsSendingNotifications(false);
      setTracksAddSuccess(true);
      setIsSpinning(false);
    } catch (error: any) {
      setIsSpinning(false);
      dispatch({
        type: "ADD_TRACKS_TO_APPLE_MUSIC_PLAYLIST_FAIL",
        payload: error.response || error
      });
      console.warn(error.response || error);
      setError(error.message);
    }
  };

  const getPlaylistName = (playlistId: string | undefined): string => {
    if (!playlistId) return "playlist";
    return appleMusicData.myPlaylists
      .filter((item) => playlistId === item.id)
      .map((item) => item.attributes.name)[0];
  };

  const renderBody = () => {
    if (isSendingNotifications) {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="Pager">
              📟
            </span>
          </div>
          <div className="lead">Sending notifications...</div>
        </>
      );
    }
    if (tracksAddSuccess && !tracksToSaveIsEmpty) {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="People Dancing">
              {dancers()}
            </span>
          </div>
          <div className="lead">
            Tracks have been added to{" "}
            <strong>{selectedPlaylist?.name || "playlist"}</strong> on Apple
            Music!
          </div>
        </>
      );
    } else if (tracksAddSuccess && tracksToSaveIsEmpty) {
      return (
        <>
          <div className="my-2 slug-title">
            <span role="img" aria-label="See-No-Evil Monkey">
              🙈
            </span>
            <div className="slug-subtitle mb-2">Nothing was added...</div>
          </div>
          <div className="lead">
            These tracks already exist in{" "}
            <strong>{getPlaylistName(selectedPlaylist?.id)}</strong>
          </div>
        </>
      );
    } else if (error) {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="collision">
              💥
            </span>
          </div>
          <div className="lead">Something&apos;s not right...</div>
          <div className="lead">Here&apos;s an error message:</div>
          <div className="lead text-danger">{error}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="mb-3 slug-title">
            <span role="img" aria-label="radio">
              📻
            </span>
          </div>
          <div className="lead">
            This will add all songs to{" "}
            <strong>{selectedPlaylist?.name || "playlist"}</strong> on Apple
            Music.
          </div>
        </>
      );
    }
  };

  const btnColorClass = useMemo(() => {
    if (error) {
      if (isDark) {
        return "btn-outline-danger";
      }
      return "btn-danger";
    }
    if (tracksAddSuccess) {
      if (isDark) {
        return "btn-outline-success";
      }
      return "btn-success";
    }
    if (isDark) {
      return "btn-outline-success";
    }
    return "btn-success";
  }, [error, tracksAddSuccess]);
  const renderSlurpButton = () => (
    <button
      type="button"
      disabled={error !== "" || tracksAddSuccess || isSpinning}
      className={`d-flex align-items-center btn btn-sm ${btnColorClass}`}
      onClick={
        tracksAddSuccess
          ? () => {
              if (isDev) {
                console.log("Doing Nothing");
              }
            }
          : () => addTracksToPlaylist()
      }
    >
      {isSpinning ? (
        <>
          <span className="me-2">
            <Spinner
              color={isDark ? "primary" : "light"}
              size={24}
              type="puff"
            />
          </span>
          <span className="visually-hidden">Loading...</span>
          <span>Add to Playlist</span>
        </>
      ) : tracksAddSuccess ? (
        <>
          <i className="icon-playlist_add_check slug-icon me-2" />
          <span>Add to Playlist</span>
        </>
      ) : !error ? (
        <>
          <i className="icon-playlist_add slug-icon me-2" />
          <span>Add to Playlist</span>
        </>
      ) : (
        <>
          <i className="icon-error slug-icon me-2" />
          <span>Add to Playlist</span>
        </>
      )}
    </button>
  );
  const cleanUpModal = () => {
    setError("");
    setTracksAddSuccess(false);
    setTracksToSaveIsEmpty(false);
    setIsSpinning(false);
    setTracksAddSuccess(false);
  };

  return (
    <>
      <Modal
        centered={!isMobile}
        show={isSlugifyAppleMusicModalOpen}
        onHide={() => setIsSlugifyAppleMusicModalOpen(false)}
      >
        <Modal.Header closeButton>
          <h5 className="d-flex align-items-center modal-title">
            <img
              className="d-none d-sm-block me-2"
              src={appleMusicIcon}
              alt="Apple Music"
              height="32px"
              width="32px"
            />
            Apple Music
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center text-center">
            {renderBody()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <button
              type="button"
              className="btn btn-link text-body-secondary text-decoration-none"
              onClick={() => setIsSlugifyAppleMusicModalOpen(false)}
            >
              close
            </button>
            {renderSlurpButton()}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
