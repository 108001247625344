import { useDispatch, useSelector } from "react-redux";
import { feedTheSlug } from "../actions";
import $ from "jquery";

const BeenThereDoneThat = () => {
  const dispatch = useDispatch();
  const btdt = useSelector((state) => state.slugBucket.beenThereDoneThat);
  const {
    acceptedFilesLength = undefined,
    artist = "",
    file = undefined,
    title = "",
    updatedMetadata = undefined,
    uploadedBy = ""
  } = btdt;

  const uploadAgain = () => {
    dispatch(feedTheSlug(updatedMetadata, file));
    $("#been-there-done-that-modal").modal("hide");
  };

  const closeModal = () => {
    dispatch({ type: "REMOVE_BEEN_THERE_DONE_THAT" });
    $("#been-there-done-that-modal").modal("hide");
  };

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      id="been-there-done-that-modal"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <span role="img" aria-label="bored-face">
                😴
              </span>{" "}
              Been there; done that...
            </h5>
            <button
              onClick={() => closeModal()}
              type="button"
              className="btn-close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>The Slug has previously slurped:</p>
            <p>
              <strong>{title}</strong> by <strong>{artist}</strong>;
            </p>
            <p>It was uploaded by {uploadedBy}</p>
            {acceptedFilesLength > 1 ? (
              <>
                <p>You can try again, excluding this upload.</p>
                <p>If you really want to re-upload it, do it individually.</p>
              </>
            ) : null}
          </div>
          <div className="modal-footer">
            {!acceptedFilesLength > 1 ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => uploadAgain()}
              >
                Upload Again
              </button>
            ) : null}
            <button
              onClick={() => closeModal()}
              type="button"
              className="btn btn-secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeenThereDoneThat;
