import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { AppThunk } from "../store/store";
import { omitBy, isNil } from "lodash";
import { parseSlugifyUserData } from "../functions";
import {
  Friend,
  SlugifyActionType,
  SlugifySlurpedTrack,
  SlugifyUser,
  UserActionType
} from "../types";
import { v4 as uuid } from "uuid";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import chalk from "chalk";

const isDev = process.env.NODE_ENV === "development";

export const getAllSlurpedTracks =
  (): AppThunk<Promise<void>> =>
  // (): AppThunk<Promise<SlugifySlurpedTrack[]>> =>
  async (dispatch, getState) => {
    // const getUserSlurpedTracks = async (name) => {
    //   try {
    //     const data = await API.graphql(
    //       graphqlOperation(queries.listSlugifySlurpedTracks, {
    //         filter: { username: { eq: name } },
    //         limit: 500
    //       })
    //     );
    //     console.warn("DATA", data);
    //     return data.data.listSlugifySlurpedTracks.items;
    //   } catch (error) {
    //     console.warn(error);
    //   }
    // };

    try {
      dispatch({ type: SlugifyActionType.GET_SLURPED_TRACKS_START });
      const {
        auth: { username },
        newUser: { friends }
      } = getState();
      // const friendUsernames = friends.map((item) => item.username);
      // const usernames = [username, ...friendUsernames];
      // console.warn("usernames", usernames);
      const usernamesToGet = [
        { username: { eq: username } },
        ...friends.map((item: Friend) => ({ username: { eq: item.username } }))
      ];
      const filter = {
        or: usernamesToGet
      };
      // const pendingSlurpedTracks = usernames.map((username) =>
      //   getUserSlurpedTracks(username)
      // );
      // const resolvedSlurpedTracks = await Promise.all(pendingSlurpedTracks);
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(queries.listSlugifySlurpedTracks, {
          filter,
          limit: 999
        })
      );
      const slurpedTracks = data.data.listSlugifySlurpedTracks.items;
      if (slurpedTracks.length > 900) {
        alert(`Warning: Slurped Tracks is nearing 1000`);
      }
      dispatch({
        type: SlugifyActionType.GET_SLURPED_TRACKS_SUCCESS,
        payload: slurpedTracks
      });
      return Promise.resolve();
    } catch (error: any) {
      const message = error.errors ? error.errors[0].message : error.message;
      console.warn(error);
      dispatch({
        type: SlugifyActionType.GET_SLURPED_TRACKS_FAIL,
        payload: message
      });
      return Promise.reject(message);
    }
  };

export const getAllSlugifyUsers =
  (): AppThunk<Promise<SlugifyUser[]>> => async () => {
    try {
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(queries.listSlugifyUsers)
      );
      if (isDev) {
        console.log("%cUSERS", "color:orange", data.data.listSlugifyUsers);
      }
      return Promise.resolve(data.data.listSlugifyUsers.items);
    } catch (error: any) {
      return Promise.reject(
        new Error(`Failed to get slurped tracks: ${error.errors[0].message}`)
      );
    }
  };

export const getMyFriendsExpoKeys =
  (slurpedFriends = []): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_MY_FRIENDS_EXPO_KEYS_START" });
      const {
        newUser: { friends }
      } = getState();

      if (isDev) {
        console.log(
          chalk.red("fix type for slurpedFriends/Friends", slurpedFriends)
        );
      }
      const friendIdsToGet = slurpedFriends.length
        ? [
            ...slurpedFriends.map((item: Friend) => ({
              id: { eq: item.userId }
            }))
          ]
        : [...friends.map((item: Friend) => ({ id: { eq: item.userId } }))];
      const filter = {
        or: friendIdsToGet
      };
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(queries.listSlugifyUsers, { filter })
      );

      const myFriendsTokens = data.data.listSlugifyUsers.items.map(
        (item: SlugifyUser) => JSON.parse(item.expoPushTokenData).token
      );

      dispatch({ type: "GET_MY_FRIENDS_EXPO_KEYS_SUCCESS" });
      return Promise.resolve(myFriendsTokens);
    } catch (error: any) {
      const message = error.response
        ? error.response
        : error.errors
        ? error.errors[0].message
        : error.message;
      dispatch({
        type: "GET_MY_FRIENDS_EXPO_KEYS_FAIL",
        payload: new Error(message)
      });
      return Promise.reject(message);
    }
  };

export const createSlugifyFriend =
  (friend: any): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    const {
      auth: { userId }
    } = getState();
    const input = {
      id: uuid(),
      slugifyFriendUserId: userId,
      ...friend
    };

    try {
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(mutations.createSlugifyFriend, { input })
      );
      console.log("👯 New friend created!");
      return Promise.resolve(data.data.createSlugifyFriend);
    } catch (error: any) {
      return Promise.reject(
        new Error(`Failed to create friend: ${error.errors[0].message}`)
      );
    }
  };

export const deleteSlugifyFriend =
  (id: string): AppThunk<Promise<any>> =>
  async () => {
    try {
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(mutations.deleteSlugifyFriend, { input: { id } })
      );
      console.log("😿 Friend deleted!");
      return Promise.resolve(data.data.deleteSlugifyFriend);
    } catch (error: any) {
      return Promise.reject(
        new Error(`Failed to delete friend: ${error.errors[0].message}`)
      );
    }
  };

export const createSlugifyUser =
  (user: any): AppThunk<Promise<any>> =>
  async () => {
    try {
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(mutations.createSlugifyUser, { input: user })
      );
      const userData = data.data.createSlugifyUser;
      userData.friends = userData.friends.items;
      return Promise.resolve(omitBy(userData, isNil));
    } catch (error: any) {
      return Promise.reject(
        new Error(`Failed to create user: ${error.errors[0].message}`)
      );
    }
  };

export const findFriend =
  (username: string): AppThunk<Promise<any>> =>
  async () => {
    try {
      const filter = {
        username: { eq: username }
      };
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(queries.listSlugifyUsers, { filter })
      );
      const friends = [...data.data.listSlugifyUsers.items];
      if (friends.length) {
        const parsedFriends = await parseSlugifyUserData(friends);
        return Promise.resolve(parsedFriends);
      } else {
        return Promise.resolve([]);
      }
    } catch (error: any) {
      return Promise.reject(
        new Error(`Failed to get user: ${error.errors[0].message}`)
      );
    }
  };

export const deleteSlugifyUser =
  (id: string): AppThunk<Promise<any>> =>
  async () => {
    try {
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(mutations.deleteSlugifyUser, { input: { id } })
      );
      console.log(`😿 User, ${data.data.deleteSlugifyUser.username}, deleted!`);
      return Promise.resolve(data.data.deleteSlugifyUser);
    } catch (error: any) {
      if (error.error) {
        return Promise.reject(
          new Error(`Failed to delete friend: ${error.errors[0].message}`)
        );
      }
      return Promise.reject(error);
    }
  };

export const getCurrentUserFriends =
  (): AppThunk<Promise<Friend[]>> => async (dispatch, getState) => {
    try {
      dispatch({ type: "get_current_user_friends_start" });
      const { username } = getState().auth;
      const variables = {
        filter: {
          owner: {
            eq: username
          }
        }
      };
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(queries.listSlugifyFriends, variables)
      );
      if (!data.data.listSlugifyFriends) {
        dispatch({ type: "get_current_user_friends_success" });
        console.log(`%c😭 ${username} has no friends`, "color:cyan");
        return [];
      }
      dispatch({ type: "get_current_user_friends_success" });
      if (isDev) {
        console.log(
          `%c🫶 ${username} has ${data.data.listSlugifyFriends.items.length} friends`,
          "color:cyan"
        );
      }
      return data.data.listSlugifyFriends.items;
    } catch (error) {
      console.warn(error);
      dispatch({ type: "get_current_user_friends_fail", error });
    }
  };

export const getSlugifyUser =
  (userId: string): AppThunk<Promise<any>> =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_SLUGIFY_USER_START" });
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(queries.getSlugifyUser, { id: userId })
      );
      if (!data.data.getSlugifyUser) {
        dispatch({ type: "GET_SLUGIFY_USER_END_NO_ACTION" });
        return {};
      }
      const userData = data.data.getSlugifyUser;

      userData.autoSlurpFriends = userData.autoSlurpFriends
        ? JSON.parse(userData.autoSlurpFriends)
        : userData.autoSlurpFriends;

      userData.expoPushTokenData = userData.expoPushTokenData
        ? JSON.parse(userData.expoPushTokenData)
        : userData.expoPushTokenData;

      if (isDev) {
        console.log(
          "%cThese friends are wrong!  There are more friends that the current user is the owner of.",
          "color:orange",
          userData.friends.items
        );
        console.log(
          "%cAnd it's including the current owner when someone else has them as a friend",
          "color:orange",
          userData.friends.items
        );
        console.log(
          "%cEither need to fix the schema or do a separate call on the Friends collection",
          "color:orange",
          userData.friends.items
        );
      }
      userData.friends = await dispatch(getCurrentUserFriends());

      userData.publicPlaylists = userData.publicPlaylists
        ? JSON.parse(userData.publicPlaylists)
        : userData.publicPlaylists;

      userData.sharedPlaylists = userData.sharedPlaylists
        ? JSON.parse(userData.sharedPlaylists)
        : userData.sharedPlaylists;

      userData.slugifyMobileSlurpDestination =
        userData.slugifyMobileSlurpDestination
          ? JSON.parse(userData.slugifyMobileSlurpDestination)
          : userData.slugifyMobileSlurpDestination;

      userData.spotifyTokenData = userData.spotifyTokenData
        ? JSON.parse(userData.spotifyTokenData)
        : userData.spotifyTokenData;

      userData.spotifyUserData = userData.spotifyUserData
        ? JSON.parse(userData.spotifyUserData)
        : userData.spotifyUserData;

      userData.youTubeUserData = userData.youTubeUserData
        ? JSON.parse(userData.youTubeUserData)
        : userData.youTubeUserData;

      dispatch({ type: "GET_SLUGIFY_USER_SUCCESS" });
      return Promise.resolve(omitBy(userData, isNil));
    } catch (error: any) {
      dispatch({ type: "GET_SLUGIFY_USER_ERROR", payload: error });
      if (error.errors) {
        return Promise.reject(
          new Error(`Failed to get user: ${error.errors[0].message}`)
        );
      } else {
        return Promise.reject(error);
      }
    }
  };

export const getMySlugifyFriends =
  (): AppThunk<Promise<any>> => async (dispatch, getState) => {
    try {
      const {
        newUser: { friends },
        auth: { userId }
      } = getState();

      // adding current user
      const myFriendsAndMe = [...friends, { userId }];
      const pendingFriend = myFriendsAndMe.map((item) =>
        dispatch(getSlugifyUser(item.userId))
      );
      if (isDev) {
        console.log(
          "%c⏳ Awaiting my friends and me promises...",
          "color:cyan"
        );
      }
      const meAndMyFriends = await Promise.all(pendingFriend);
      if (meAndMyFriends.length) {
        if (meAndMyFriends.length === 1) {
          console.log("%c😭 You have no friends!", "color:cyan");
        }
        if (isDev) {
          console.log(
            "%c🧘🏽 My friend's and my promises fulfilled!",
            "color:lime",
            meAndMyFriends
          );
        }
      } else {
        if (isDev) {
          console.log("%cCurrent user not found in db!", "color:lime");
        }
        throw new Error("Current user not found in db!");
      }
      return Promise.resolve(meAndMyFriends);
    } catch (error: any) {
      if (error.error) {
        return Promise.reject(
          new Error(
            `Failed to create slurped track: ${error.errors[0].message}`
          )
        );
      } else {
        return Promise.reject(error);
      }
    }
  };

const createSlugifySlurpedTrack = async (
  track: Partial<SlugifySlurpedTrack>
) => {
  try {
    const response = await API.graphql(
      graphqlOperation(mutations.createSlugifySlurpedTrack, {
        input: { ...track }
      })
    );
    return Promise.resolve(response);
  } catch (error: any) {
    if (error.errors) {
      return Promise.reject(
        new Error(`Failed to create slurped track: ${error.errors[0].message}`)
      );
    } else {
      return Promise.reject(error);
    }
  }
};

export const createSlugifySlurpedTracks =
  (slurpedTracks: Partial<SlugifySlurpedTrack>[]): AppThunk<Promise<any>> =>
  async () => {
    try {
      const pendingCreate = slurpedTracks.map((track) =>
        createSlugifySlurpedTrack(track)
      );
      if (isDev) {
        console.log(
          "%c⏳ Awaiting slurped track creation promises...",
          "color:cyan"
        );
      }
      const createdSlurpedTracks = await Promise.all(pendingCreate);
      if (isDev) {
        console.log(
          "%c🧘🏽 Create Slurped Track Promises fulfilled!",
          "color:lime",
          createdSlurpedTracks
        );
      }
      return Promise.resolve(createdSlurpedTracks);
    } catch (error) {
      return Promise.reject(error);
    }
  };

const deleteSlugifySlurpedTrack = async (trackId: string) => {
  try {
    const response = await API.graphql(
      graphqlOperation(mutations.deleteSlugifySlurpedTrack, {
        input: { id: trackId }
      })
    );
    return Promise.resolve(response);
  } catch (error: any) {
    if (error.errors) {
      return Promise.reject(
        new Error(`Failed to delete slurped track: ${error.errors[0].message}`)
      );
    } else {
      return Promise.reject(error);
    }
  }
};

export const deleteUserAccountRemoveSlurpedTracks =
  (username: string): AppThunk<Promise<any>> =>
  async () => {
    try {
      const filter = {
        owner: { eq: username }
      };
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(queries.listSlugifySlurpedTracks, {
          filter,
          limit: 999
        })
      );
      const slurpedTracksToDelete =
        data.data.listSlugifySlurpedTracks.items.map(
          (item: SlugifySlurpedTrack) => item.id
        );
      if (!slurpedTracksToDelete.length) {
        if (isDev) {
          console.log("🤷🏽 No slurped tracks to delete");
        }
        return Promise.resolve();
      }
      if (isDev) {
        console.log(
          `🔥 Deleting all of ${username}'s slurped tracks: `,
          slurpedTracksToDelete
        );
      }

      const pendingDelete = slurpedTracksToDelete.map(
        (track: SlugifySlurpedTrack) => deleteSlugifySlurpedTrack(track.id)
      );
      if (isDev) {
        console.log(
          "%c⏳ Awaiting slurped track deletion promises...",
          "color:cyan"
        );
      }
      const deletedSlurpedTracks = await Promise.all(pendingDelete);
      if (isDev) {
        console.log(
          "%c🧘🏽 Promises fulfilled!",
          "color:lime",
          deletedSlurpedTracks
        );
      }
      return Promise.resolve(deletedSlurpedTracks);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteUserAccountRemoveFriends =
  (username: string): AppThunk<Promise<any>> =>
  async () => {
    const deleteFriend = async (id: string) => {
      try {
        const data: GraphQLResult<any> = await API.graphql(
          graphqlOperation(mutations.deleteSlugifyFriend, { input: { id } })
        );
        if (isDev) {
          console.log("😿 Friend deleted!", data.data.deleteSlugifyFriend);
        }
        return Promise.resolve(data.data.deleteSlugifyFriend);
      } catch (error: any) {
        return Promise.resolve(
          new Error(
            `Failed to delete friend: ${
              error.errors ? error.errors[0].message : error
            }`
          )
        );
      }
    };
    try {
      const filter = {
        or: [
          { owner: { eq: username } },
          {
            username: { eq: username }
          }
        ]
      };
      const data: GraphQLResult<any> = await API.graphql(
        graphqlOperation(queries.listSlugifyFriends, { filter })
      );
      if (isDev) {
        console.log(chalk.red("Fix Slugify friends type"));
      }
      const friendsIdsToDelete = data.data.listSlugifyFriends.items.map(
        (item: any) => item.id
      );
      if (!friendsIdsToDelete.length) {
        if (isDev) {
          console.log("🤷🏽 No friends to delete");
        }
        return Promise.resolve();
      }
      if (isDev) {
        console.log(
          `🔥 Removing ${username} as a friend: `,
          friendsIdsToDelete
        );
      }

      const pendingDelete = friendsIdsToDelete.map((id: string) =>
        deleteFriend(id)
      );
      if (isDev) {
        console.log("%c⏳ Awaiting friend deletion promises...", "color:cyan");
      }
      const deletedFriends = await Promise.all(pendingDelete);
      if (isDev) {
        console.log("%c🧘🏽 Promises fulfilled!", "color:lime", deletedFriends);
      }
      return Promise.resolve(deletedFriends);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteUserAccount =
  (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    try {
      const {
        newUser: { id, username }
      } = getState();
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Deleting slurped tracks..."
      });
      await dispatch(deleteUserAccountRemoveSlurpedTracks(username));
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Removing friends..."
      });
      await dispatch(deleteUserAccountRemoveFriends(username));
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Deleting user data..."
      });
      await dispatch(deleteSlugifyUser(id));
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "User data deleted"
      });
      console.log("%c🧘🏽 All User Data Removed!", "color:cyan");
      return Promise.resolve();
    } catch (error) {
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Something's not right..."
      });
      return Promise.reject(error);
    }
  };

export const deleteStaleSlugifySlurpedTracks =
  (slurpedTrackIds: string[]): AppThunk<Promise<any>> =>
  async (dispatch) => {
    try {
      dispatch({ type: "DELETE_STALE_SLUGIFY_SLURPED_TRACKS_START" });
      const pendingDelete = slurpedTrackIds.map((trackId) =>
        deleteSlugifySlurpedTrack(trackId)
      );
      if (isDev) {
        console.log(
          "%c⏳ Awaiting slurped track deletion promises...",
          "color:cyan"
        );
      }
      const deletedSlurpedTracks = await Promise.all(pendingDelete);
      if (isDev) {
        console.log(
          "%c🧘🏽 Promises fulfilled!",
          "color:lime",
          deletedSlurpedTracks
        );
      }
      dispatch({
        type: "DELETE_STALE_SLUGIFY_SLURPED_TRACKS_SUCCESS",
        payload: slurpedTrackIds
      });
      return Promise.resolve(deletedSlurpedTracks);
    } catch (error) {
      dispatch({
        type: "DELETE_STALE_SLUGIFY_SLURPED_TRACKS_FAIL",
        payload: error
      });
      return Promise.reject(error);
    }
  };

const updateSlugifySlurpedTrack = async (track: SlugifySlurpedTrack) => {
  try {
    const response = await API.graphql(
      graphqlOperation(mutations.updateSlugifySlurpedTrack, {
        input: { ...track }
      })
    );
    if (isDev) {
      console.log("Update slurped track response", response);
    }
    return Promise.resolve(response);
  } catch (error: any) {
    if (error.errors) {
      return Promise.reject(
        new Error(`Failed to updated slurped track: ${error.errors[0].message}`)
      );
    } else {
      return Promise.reject(error);
    }
  }
};

export const updateSlugifySlurpedTracks =
  (slurpedTracks: SlugifySlurpedTrack[]): AppThunk<Promise<any>> =>
  async () => {
    try {
      const pendingUpdate = slurpedTracks.map((track) =>
        updateSlugifySlurpedTrack(track)
      );
      if (isDev) {
        console.log(
          "%c⏳ Awaiting slurped track update promises...",
          "color:cyan"
        );
      }
      const updatedSlurpedTracks = await Promise.all(pendingUpdate);
      if (isDev) {
        console.log(
          "%c🧘🏽 Promises fulfilled!",
          "color:lime",
          updatedSlurpedTracks
        );
      }
      return Promise.resolve(updatedSlurpedTracks);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateSlugifyUser =
  (newSettings: any): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: UserActionType.UPDATE_USER_START });
      const {
        auth: { userId, username },
        newUser: currentUserData
      } = getState();
      const payload = {
        autoSlurpFriends: JSON.stringify(
          newSettings.autoSlurpFriends
            ? newSettings.autoSlurpFriends
            : currentUserData.autoSlurpFriends
        ),
        appleMusicSlugifyPlaylist: newSettings.appleMusicSlugifyPlaylist
          ? newSettings.appleMusicSlugifyPlaylist
          : currentUserData.appleMusicSlugifyPlaylist,
        expoPushTokenData: JSON.stringify(
          newSettings.expoPushTokenData
            ? newSettings.expoPushTokenData
            : currentUserData.expoPushTokenData
        ),
        homeMenu: newSettings.homeMenu
          ? newSettings.homeMenu
          : currentUserData.homeMenu,
        id: userId,
        isSubscribedOneSignal: newSettings.isSubscribedOneSignal
          ? newSettings.isSubscribedOneSignal
          : currentUserData.isSubscribedOneSignal,
        owner: currentUserData.owner,
        primaryMusicStream: newSettings.primaryMusicStream
          ? newSettings.primaryMusicStream
          : currentUserData.primaryMusicStream,
        publicPlaylists: JSON.stringify(
          newSettings.publicPlaylists
            ? newSettings.publicPlaylists
            : currentUserData.publicPlaylists
        ),
        sharedPlaylists: JSON.stringify(
          newSettings.sharedPlaylists
            ? newSettings.sharedPlaylists
            : currentUserData.sharedPlaylists
        ),
        screenAppearance: newSettings.screenAppearance
          ? newSettings.screenAppearance
          : currentUserData.screenAppearance,
        slugifyMobileSlurpDestination: JSON.stringify(
          newSettings.slugifyMobileSlurpDestination
            ? newSettings.slugifyMobileSlurpDestination
            : currentUserData.slugifyMobileSlurpDestination
        ),
        spotifyTokenData: JSON.stringify(
          newSettings.spotifyTokenData
            ? newSettings.spotifyTokenData
            : currentUserData.spotifyTokenData
        ),
        spotifyUserData: JSON.stringify(
          newSettings.spotifyUserData
            ? newSettings.spotifyUserData
            : currentUserData.spotifyUserData
        ),
        username,
        youTubeUserData: JSON.stringify(
          newSettings.youTubeUserData
            ? newSettings.youTubeUserData
            : currentUserData.youTubeUserData
        )
      };
      await API.graphql(
        graphqlOperation(mutations.updateSlugifyUser, {
          input: { ...payload }
        })
      );
      dispatch({
        type: UserActionType.UPDATE_USER_SUCCESS,
        payload: newSettings
      });
      if (isDev) {
        console.log("🤸 User update success!");
      }
      return Promise.resolve();
    } catch (error: any) {
      if (error.errors) {
        dispatch({
          type: UserActionType.UPDATE_USER_FAIL,
          payload: error.errors[0].message
        });
        return Promise.reject(
          new Error(`Failed to update user: ${error.errors[0].message}`)
        );
      } else {
        dispatch({
          type: UserActionType.UPDATE_USER_FAIL,
          payload: error.message
        });
        return Promise.reject(error);
      }
    }
  };

// export const saveSpotifyUserData = newSpotifyUserData => async (
//   dispatch,
//   getState
// ) => {
//   const {
//     auth: { userId },
//     newUser: { spotifyUserData }
//   } = getState();

//   if (!spotifyUserData || isEmptyObject(spotifyUserData)) {
//     try {
//       // await API.graphql(
//       //   graphqlOperation(mutations.createSlugifySpotifyUserData, {
//       //     input: {
//       //       data: JSON.stringify(newSpotifyUserData),
//       //       id: uuid(),
//       //       userId: userId
//       //     }
//       //   })
//       // );
//       console.log("💾 Spotify user data saved!");
//       return Promise.resolve();
//     } catch (error) {
//       return Promise.reject(
//         `Failed to create spotify user data: ${error.errors[0].message}`
//       );
//     }
//   } else {
//     try {
//       // await API.graphql(
//       //   graphqlOperation(mutations.updateSlugifySpotifyUserData, {
//       //     input: {
//       //       data: JSON.stringify({ ...spotifyUserData, ...newSpotifyUserData })
//       //     },
//       //     id: spotifyUserData.id,
//       //     userId: userId
//       //   })
//       // );
//       console.log("💾 Spotify user data updated!");
//       return Promise.resolve();
//     } catch (error) {
//       return Promise.reject(
//         `Failed to update spotify user data: ${error.errors[0].message}`
//       );
//     }
//   }
// };
