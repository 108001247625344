import { API, Storage } from "aws-amplify";
import $ from "jquery";
import * as mm from "music-metadata-browser";
import { v3 as uuid } from "uuid";

import { isEmptyObject, removeEmptyStringElements } from "../functions";

const MY_NAMESPACE = uuid("slugbucket.abts.io", uuid.DNS);
const MUSIC_SUFFIXES = ["mp3", "m4a", "wma"];

export const getDataUrl = (picture) => {
  const arrayBuff = picture[0].data;
  let base64String = "";
  for (let i = 0; i < arrayBuff.length; i++) {
    base64String += String.fromCharCode(arrayBuff[i]);
  }
  try {
    let dataUrl = `data:${picture[0].format};base64,${window.btoa(
      base64String
    )}`;
    return dataUrl;
  } catch (error) {
    console.log(error);
  }
};

export const getS3MusicFiles = () => async (dispatch) => {
  dispatch({ type: "GET_S3_FILE_LIST_START" });
  dispatch({ type: "SET_UPLOAD_STATUS", payload: "Processing uploads..." });
  try {
    const response = await Storage.list("");
    const filteredResponse = response.filter((item) => {
      return MUSIC_SUFFIXES.includes(item.key.slice(-3));
    });
    dispatch({
      type: "GET_S3_FILE_LIST_SUCCESS",
      payload: {
        s3FileList: filteredResponse
      }
    });
    console.log("🐕 Fetched a list of all files in bucket", response);
    console.log("🎼 Of which, these are music files", filteredResponse);
    dispatch(getSongMetaData(filteredResponse));
  } catch (e) {
    dispatch({ type: "GET_S3_FILE_LIST_FAIL", payload: e });
    console.log(e);
  }
};

export const getSlugBucketSearchResults =
  (searchValue, setSpinnerOn, setSearchHasRun) => async (dispatch) => {
    dispatch({
      type: "GET_SLUG_BUCKET_SEARCH_RESULTS_START"
    });
    const params = encodeURIComponent(
      JSON.stringify({ searchString: searchValue })
    );
    console.log(params);
    try {
      const response = await API.get("SlugBucketAPI", `/songs?${params}`);
      setSpinnerOn(false);
      setSearchHasRun(true);
      dispatch({
        type: "GET_SLUG_BUCKET_SEARCH_RESULTS_SUCCESS",
        payload: response
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: "GET_SLUG_BUCKET_SEARCH_RESULTS_FAIL",
        payload: e
      });
    }
  };

export const getSongMetaData = (s3FileList) => (dispatch) => {
  try {
    console.log("🐕 Fetching metadata for each song in bucket...");
    const getSong = async (song) => {
      const artist = song.key.split("_")[0];
      const title = song.key.split("_")[1].slice(0, -4);
      let metadata = await API.get(
        "SlugBucketAPI",
        `/songs/object/${artist}/${title}`
      );
      metadata.s3FileName = song.key;
      if (metadata.albumArtS3Key) {
        const s3Url = await Storage.get(`${song.key.slice(0, -4)}.txt`, {});
        const res = await fetch(s3Url);
        const dataUrl = await res.text();
        metadata.albumArtDataUrl = dataUrl;
      }
      console.log("🗃️ Here's your metadata", metadata);
      return metadata;
    };
    if (!s3FileList.length) {
      console.log("🤷🏽‍♀️ No tracks in the Slugbucket.  Nothing to do!");
    } else {
      dispatch({ type: "GET_TRACKS_METADATA_FROM_DB_START" });
      const pending = s3FileList.map((song) => getSong(song));
      Promise.all(pending).then((songs) => {
        dispatch({
          type: "GET_TRACKS_METADATA_FROM_DB_SUCCESS",
          payload: songs
        });
      });
    }
  } catch (e) {
    dispatch({ type: "GET_TRACKS_METADATA_FROM_DB_FAIL", payload: e });
  }
};

export const handleDelete = (fileToDelete) => async (dispatch) => {
  console.log("file name to delete", fileToDelete);
  dispatch({
    type: "REMOVE_SONG_FROM_SLUGBUCKET_START"
  });
  dispatch({
    type: "REMOVE_SONG_FROM_SLUGBUCKET_SUCCESS",
    payload: fileToDelete
  });
  $("#slug-modal").modal("hide");
  try {
    const deleteSongResult = await Storage.remove(fileToDelete);
    console.log(deleteSongResult);
    const deleteAlbumArtResult = await Storage.remove(
      `${fileToDelete.slice(0, -4)}.txt`
    );
    console.log(deleteAlbumArtResult);
  } catch (e) {
    dispatch({ type: "REMOVE_SONG_FROM_SLUGBUCKET_FAIL", payload: e });
    console.log(e);
  }
};

export const handleLike =
  (artist, title, s3FileName, caller) => (dispatch, getState) => {
    const songsTemp =
      caller === "slugbucket"
        ? getState().slugBucket.songs
        : getState().slugBucket.searchResults;
    songsTemp.map((song) => {
      try {
        if (song.s3FileName === s3FileName) {
          if (song.liked) {
            song.liked = false;
          } else {
            song.liked = true;
          }
          console.log(`${song.liked ? "Liking" : "Unliking"} song on in db`);
          API.put("SlugBucketAPI", "/songs", {
            body: { ...song }
          });
          console.log(
            `${title} by ${artist} has been ${song.liked ? "liked" : "unliked"}`
          );
          dispatch({ type: "LIKE_SONG_TOGGLE_SUCCESS", payload: song.liked });
          dispatch({
            type: "TOGGLE_SLUG_BUCKET_SEARCH_RESULTS_LIKE",
            payload: {
              liked: song.liked,
              artist: song.artist,
              title: song.title
            }
          });
        }
      } catch (e) {
        dispatch({
          type: "SET_UPLOAD_ERROR",
          payload: {
            error: {
              emoticon: "💔",
              title: "The slug is a picky eater.",
              message: "Problem with setting like!"
            }
          }
        });
        console.log(e);
      }
      return song;
    });
  };

export const feedTheSlug =
  (updatedMetadata, file) => async (dispatch, getState) => {
    const currentUser = getState().auth.username;
    const {
      album,
      artist,
      artistClearText,
      fileName,
      picture,
      title,
      titleClearText
    } = updatedMetadata;
    updatedMetadata.uploadedBy = currentUser;

    dispatch({
      type: "PREP_THE_SLUG_FOR_FEEDING",
      payload: {
        uploadStatus: "1%",
        uploadProgress: 1,
        dropzoneDisabled: true,
        error: {
          emoticon: "",
          message: "",
          title: ""
        }
      }
    });
    try {
      console.log(`Feeding the slug... ${artistClearText} / ${titleClearText}`);
      const s3FileName = artist + "_" + title;
      if (picture) {
        const s3AlbumArtFileName = artist + "_" + uuid(album, MY_NAMESPACE);
        const dataUrl = getDataUrl(picture);
        updatedMetadata.albumArtS3Key = s3AlbumArtFileName;
        dispatch({
          type: "SET_UPLOAD_STATUS",
          payload: "Digesting album art..."
        });
        await Storage.put(`${s3FileName}.txt`, dataUrl, {});
      }

      await dispatch(updateSlugBucketMetaData(updatedMetadata));

      await Storage.put(`${s3FileName}.${suffix}`, file, {
        contentType: "audio/mpeg",
        contentDisposition: `attachment; filename="${fileName}"`,
        progressCallback(progress) {
          console.log(progress);
          dispatch({
            type: "SET_UPLOAD_PROGRESS_AND_STATUS",
            payload: progress
            // payload: {
            //   uploadProgress: `${parseInt(
            //     100 * (progress.loaded / progress.total)
            //   )}`,
            //   uploadStatus: `${parseInt(
            //     100 * (progress.loaded / progress.total)
            //   )}%`
            // }
          });
        }
      });
    } catch (e) {
      dispatch({ type: "FEED_THE_SLUG_FAIL", payload: e });
      dispatch({
        type: "SET_UPLOAD_ERROR",
        payload: {
          uploadProgress: 0,
          uploadStatus: "0%",
          dropzoneDisabled: false,
          error: {
            emoticon: "😱",
            title: "Oh, nos!  The slug has indigestion.",
            message: e.message
          }
        }
      });
      console.log(e);
    }
  };

export const handleDeleteAll = () => async (dispatch, getState) => {
  const songs = getState().slugBucket.songs;
  const currentUser = getState().auth.username;

  const deleteSong = async (key) => {
    console.log(`Deleting ${key}...`);
    try {
      await Storage.remove(key);
      await Storage.remove(`${key.slice(0, -4)}.txt`);
    } catch (e) {
      dispatch({ type: "REMOVE_ALL_SONGS_FROM_SLUGBUCKET_FAIL", payload: e });
      console.log(e);
    }
  };

  dispatch({ type: "REMOVE_ALL_SONGS_FROM_SLUGBUCKET_START" });
  const notMySongs = songs.filter((song) => song.uploadedBy !== currentUser);
  console.log("Songs to be deleted: ", notMySongs);
  const pendingDelete = notMySongs.map((song) => deleteSong(song.s3FileName));
  console.log("⏲️ Waiting for deletion confirmations...");
  await Promise.all(pendingDelete);
  dispatch({ type: "REMOVE_ALL_SONGS_FROM_SLUGBUCKET_SUCCESS" });
  $("#slug-modal").modal("hide");
  console.log("All songs that aren't mine deleted!");
};

let suffix;
export const prepareToFeedTheSlug =
  (acceptedFiles, acceptedFilesLength) => async (dispatch) => {
    const file = acceptedFiles[0];
    const fileName = file.name;
    let metadata;
    try {
      metadata = await mm.parseBlob(file);
      console.log("Metadata extracted!");
      console.log(metadata);
      let { album, title, artist, year } = metadata.common;
      const updatedMetadata = { ...metadata.common };
      delete updatedMetadata.year;
      updatedMetadata.fileName = fileName;
      if (title) {
        updatedMetadata.title = uuid(title, MY_NAMESPACE);
        updatedMetadata.titleClearText = title;
      } else {
        updatedMetadata.title = uuid(fileName.slice(0, -4), MY_NAMESPACE);
        updatedMetadata.titleClearText = fileName.slice(0, -4);
      }
      if (artist) {
        updatedMetadata.artist = uuid(artist, MY_NAMESPACE);
        updatedMetadata.artistClearText = artist;
      } else {
        updatedMetadata.artist = "n/a";
      }
      if (album) {
        updatedMetadata.album = album;
      } else {
        updatedMetadata.album = "n/a";
      }
      if (year) {
        updatedMetadata.releaseYear = year;
      } else {
        updatedMetadata.releaseYear = "n/a";
      }
      suffix = fileName.slice(-3).toLowerCase();
      if (MUSIC_SUFFIXES.includes(suffix)) {
        console.log(`Ooh, a lovely ${suffix} to slurp!`);
      } else {
        dispatch({
          type: "SET_UPLOAD_ERROR",
          payload: {
            uploadProgress: 0,
            uploadStatus: "0%",
            dropzoneDisabled: false,
            error: {
              emoticon: "🤢",
              title: "The slug is a picky eater.",
              message: "The slug only eats mp3s, m4as, and wmas!"
            }
          }
        });
        return;
      }

      console.log("Calling the Slug...");

      // check if song exists in db, if not feed the Slug
      try {
        const response = await API.get(
          "SlugBucketAPI",
          `/songs/object/${updatedMetadata.artist}/${updatedMetadata.title}`
        );
        if (isEmptyObject(response) || acceptedFilesLength > 1) {
          dispatch(feedTheSlug(updatedMetadata, file));
        } else {
          dispatch({
            type: "SET_BEEN_THERE_DONE_THAT",
            payload: {
              artist: response.artistClearText,
              title: response.titleClearText,
              uploadedBy: response.uploadedBy,
              updatedMetadata,
              file
            }
          });
          $("#been-there-done-that-modal").modal("show");
          const message = `The Slug has previously slurped '${response.titleClearText}' by ${response.artistClearText}.`;
          console.log(message);
          return;
        }
      } catch (e) {
        dispatch({
          type: "SET_UPLOAD_ERROR",
          payload: {
            dropzoneDisabled: false,
            error: {
              emoticon: "🤮",
              title: "Oh, nos!  The slug has indigestion.",
              message: `Probably missing or dirty metadata.`
            }
          }
        });
        console.log(
          "If it fails here, it's probably a character in the artist or title.  \nIf it says, network error, that's the API lying tu us, because it can't properly pass errors through yet.\n",
          updatedMetadata.artistClearText +
            " | " +
            updatedMetadata.titleClearText,
          e
        );
      }
    } catch (e) {
      if (e.message === "Guessed MIME-type not supported: image/jpeg") {
        dispatch({
          type: "SET_UPLOAD_ERROR",
          payload: {
            error: {
              emoticon: "🤠",
              title: "Watch it, cowboy.  This ain't no picture show.",
              message: e.message
            }
          }
        });
      } else if (e.message === "Failed to guess MIME-type") {
        dispatch({
          type: "SET_UPLOAD_ERROR",
          payload: {
            error: {
              emoticon: "🙄",
              title: "Umm, what kind of crap is that?",
              message: e.message
            }
          }
        });
      } else {
        dispatch({
          type: "SET_UPLOAD_ERROR",
          payload: {
            error: {
              emoticon: "🙀",
              title: "Oh, nos!  The slug has indigestion.",
              message: e.message
            }
          }
        });
        console.log(
          "If it fails here, the ID tag analyzer had a problem it couldn't handle.",
          e
        );
      }
    }
  };

export const updateSlugBucketMetaData = (updatedMetaData) => () => {
  const {
    album,
    artistClearText,
    fileName,
    releaseYear,
    titleClearText,
    uploadedBy
  } = updatedMetaData;
  console.log(`
    Uploading metadata ...
    artist: ${artistClearText},
    title: ${titleClearText},
    album: ${album},
    release year: ${releaseYear},
    file name: ${fileName},
    uploaded by: ${uploadedBy}
  `);
  // DynamoDB doesn't like blanks!
  const metadataNoBlanks = removeEmptyStringElements(updatedMetaData);
  delete metadataNoBlanks.picture;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.post("SlugBucketAPI", `/songs/`, {
        body: {
          ...metadataNoBlanks
        }
      });
      console.log("Slug Bucket Metadata Updated!");
      console.log(response);
      resolve(response);
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};
