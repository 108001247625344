import { getYouTubeVideos, getYouTubeVideosById } from "../actions";
import { trimText } from "../functions";
import { useAppDispatch } from "../hooks";
import youtubeLogo from "../images/yt_icon_rgb.png";
import { SlugifyTrackType } from "../types";

const isDev = process.env.NODE_ENV === "development";

interface Props {
  item: any;
  showButton: boolean;
  showMyTracks?: boolean;
  showSlurpedTracks?: boolean;
  setVideoData: (data: any) => void;
  setIsYtMusicModalOpen: (b: boolean) => void;
}
export default function SlugifyYouTubeMusicItem({
  item,
  showButton,
  setVideoData,
  setIsYtMusicModalOpen
}: Props) {
  const dispatch = useAppDispatch();
  const setStatusBg = (count: string) => {
    switch (count) {
      case "fullySlurped":
        return "bg-pale-green"; // success
      case "partiallySlurped":
        return "selected-list-item"; // danger;
      default:
        return "bg-body-tertiary";
    }
  };

  const getAlbumArt = (px: string) => {
    try {
      switch (item.trackType) {
        case SlugifyTrackType.APPLE_MUSIC_NATIVE:
          return item.attributes.artwork.url
            .replace(/{w}/, px)
            .replace(/{h}/, px);
        case SlugifyTrackType.SPOTIFY_NATIVE:
          return item.track.album.images[2].url;
        default:
          return item.snippet.thumbnails.default.url;
      }
    } catch (error) {
      console.warn("Could not get album art!", error);
    }
  };

  const getAlbumName = () => {
    try {
      switch (item.trackType) {
        case SlugifyTrackType.APPLE_MUSIC_NATIVE:
          return item.attributes.albumName;
        case SlugifyTrackType.SPOTIFY_NATIVE:
          return item.track.album.name;
        default:
          return null;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const getIsrc = () => {
    try {
      switch (item.trackType) {
        case SlugifyTrackType.APPLE_MUSIC_NATIVE:
          return item.attributes.isrc;
        case SlugifyTrackType.SPOTIFY_NATIVE:
          return item.track.external_ids.isrc;
        default:
          return null;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const getTrackArtist = () => {
    try {
      switch (item.trackType) {
        case SlugifyTrackType.APPLE_MUSIC_NATIVE:
          return item.attributes.artistName;
        case SlugifyTrackType.SPOTIFY_NATIVE:
          return item.track.artists[0].name;
        default:
          return null;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const getTrackName = () => {
    try {
      switch (item.trackType) {
        case SlugifyTrackType.APPLE_MUSIC_NATIVE:
          return item.attributes.name;
        case SlugifyTrackType.SPOTIFY_NATIVE:
          return item.track.name;
        default:
          return item.snippet.title;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  async function getYouTubeVideo() {
    try {
      const isrc = getIsrc();
      const artist = getTrackArtist();
      const title = getTrackName();
      const kind = item.kind;
      if (kind) {
        if (isDev) {
          console.log("%cItem is a YouTube video", "color:cyan", item);
        }
        const resp = await getYouTubeVideosById([
          item.snippet.resourceId.videoId
        ]);
        if (resp.items && resp.items.length) {
          setVideoData({ item: resp.items[0] });
        } else {
          setVideoData({ error: "track not found", source: item });
        }
        setIsYtMusicModalOpen(true);
      } else {
        const video = await dispatch(
          getYouTubeVideos([{ artist, isrc, title }])
        );
        if (video && video[0]) {
          console.log(
            `%c📺 Now playing: ${video[0].snippet?.title || "video"}`,
            "color:cyan"
          );
          setVideoData({ item: video[0] });
        } else {
          setVideoData({ error: "track not found", source: item });
        }
        setIsYtMusicModalOpen(true);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <li
      className={`list-group-item ${setStatusBg(
        item.slurpStatus
      )} animate__animated animate__fadeIn mt-2`}
      style={{ borderRadius: 8 }}
    >
      <div className="d-flex align-items-center px-3 py-2">
        {showButton ? (
          <>
            <img
              className="d-block d-sm-none"
              src={getAlbumArt("48")}
              alt={`${getAlbumName} album art`}
              height={42}
              width="auto"
              style={{ borderRadius: 8 }}
            />
            <img
              className="d-none d-sm-block"
              src={getAlbumArt("64")}
              alt={`${getAlbumName()} album art`}
              height={56}
              width="auto"
              style={{ borderRadius: 8 }}
            />
          </>
        ) : (
          <>
            <div className="d-block d-sm-none">
              <img
                src={getAlbumArt("48")}
                alt={`${getAlbumName()} album art`}
                height={48}
                width="auto"
                style={{ borderRadius: 8 }}
              />
            </div>
            <div className="d-none d-sm-block">
              <img
                src={getAlbumArt("64")}
                alt={`${getAlbumName()} album art`}
                height={64}
                width="auto"
                style={{ borderRadius: 8 }}
              />
            </div>
          </>
        )}
        <div className="d-flex flex-column ms-3 flex-grow-1">
          <small className="d-block d-sm-none font-weight-bold me-auto">
            {trimText(20, getTrackName())}
          </small>
          <div className="d-none d-sm-block font-weight-bold me-auto">
            {getTrackName()}
          </div>
          <small className="d-flex d-sm-none font-weight-normal">
            {trimText(20, getTrackArtist())}
          </small>
          <small className="d-none d-sm-flex font-weight-normal">
            {getTrackArtist()}
          </small>
        </div>
        {showButton ? (
          <img
            src={youtubeLogo}
            alt="Listen on YouTube"
            style={{ cursor: "pointer", height: 28, width: "auto" }}
            onClick={() => getYouTubeVideo()}
          />
        ) : null}
      </div>
    </li>
  );
}
