import { useContext, useState } from "react";
import {
  getMyYouTubeChannel,
  getMyYoutubePlaylists,
  handleYouTubePlaylists
} from "../actions";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { useAppDispatch } from "../hooks";
import { Spinner } from "./shared/Spinner";
import StatusMessage from "./shared/StatusMessage";

export default function UserSettingsYouTubeChannelInfo() {
  const { isDark } = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  async function reCheckChannel() {
    try {
      setLoading(true);
      const channel = await dispatch(getMyYouTubeChannel());
      if (channel) {
        const playlists = await dispatch(getMyYoutubePlaylists());
        await dispatch(handleYouTubePlaylists(playlists));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.warn("Error re-checking channel", error);
    }
  }
  return (
    <div className="d-flex flex-column align-items-center w-100">
      <StatusMessage
        title="Setup YouTube Channel"
        emoticon="tipper"
        subtitle="YouTube users need to create a channel to use Slugify"
      />
      <a
        href={
          "https://www.youtube.com/create_channel?action_create_new_channel_redirect=true"
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <button
          className={`btn btn-${isDark ? "outline-primary" : "primary"} mb-3`}
          style={{ width: 240 }}
        >
          Create YouTube Channel
        </button>
      </a>
      <div className="mb-1">
        You can name your channel &apos;Slugify&apos; or whatever you want
      </div>
      <div className="mb-3">
        Once you&apos;ve created a channel, come back here and re-check channels
      </div>
      <button
        className={`d-flex align-items-center justify-content-center btn btn-${
          isDark ? "outline-secondary" : "primary"
        }`}
        disabled={loading}
        onClick={() => reCheckChannel()}
        style={{ width: 240 }}
      >
        {loading && (
          <div className="mx-0">
            <Spinner
              size={18}
              color={isDark ? "secondary" : "light"}
              type="puff"
            />
          </div>
        )}
        <span className="ms-2">Re-Check Channels</span>
      </button>
    </div>
  );
}
