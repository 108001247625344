import { useDispatch, useSelector } from "react-redux";
import { Flipper } from "react-flip-toolkit";
import { getUrl, handleDownloadAll } from "../functions";
import {
  getS3MusicFiles,
  handlePushNotification,
  prepareToFeedTheSlug
} from "../actions";
import { Storage } from "aws-amplify";
import BeenThereDoneThat from "../components/BeenThereDoneThat";
import Dropzone from "react-dropzone";
import InfoModal from "../components/InfoModal";
import ProgressBar from "../components/ProgressBar";
import { useEffect, useState } from "react";
import slug from "../images/slug.png";
import slugfeeding from "../images/slug_feeding.gif";
import SlugModal from "../components/SlugModal";
import SongListItems from "../components/SongListItems";
import SlugBucketSpotifyModal from "../components/SlugBucketSpotifyModal";
import SlugBucketAppleMusicModal from "../components/SlugBucketAppleMusicModal";

Storage.configure({ level: "public" });

const SlugBucket = () => {
  const dispatch = useDispatch();

  const {
    auth: { username: currentUser },
    slugBucket: {
      beenThereDoneThat,
      dropzoneDisabled,
      error,
      isLoading: slugbucketIsLoading,
      s3FileList,
      songs,
      uploadCount,
      uploadProgress,
      uploadStatus
    }
  } = useSelector((state) => state);

  const [acceptedFilesLength, setAcceptedFilesLength] = useState(undefined);
  const [downloadLink, setDownloadLink] = useState("");
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [hideMyUploads, setHideMyUploads] = useState(false);

  const feedingFrenzy = async (acceptedFiles) => {
    setAcceptedFilesLength(acceptedFiles.length);
    acceptedFiles.forEach((file) => {
      dispatch(prepareToFeedTheSlug([file], acceptedFiles.length));
    });
  };

  useEffect(() => {
    dispatch(getS3MusicFiles());
  }, [dispatch]);

  useEffect(() => {
    const sendPushNotification = async () => {
      dispatch(handlePushNotification(`${currentUser} just fed the Slug!`));
    };
    if (uploadProgress === 100 && uploadCount >= acceptedFilesLength) {
      setAcceptedFilesLength(0);
      console.log("The Slug has been fed");
      dispatch({
        type: "FEED_THE_SLUG_SUCCESS",
        payload: {
          uploadStatus: "Upload complete!"
        }
      });
      // TODO:  See if there's a better way to do this.
      // Without this, sometimes not all files are returned in s3List!
      setTimeout(() => {
        dispatch(getS3MusicFiles());
        sendPushNotification();
      }, 2000);
    }
  }, [acceptedFilesLength, currentUser, dispatch, uploadCount, uploadProgress]);

  useEffect(() => {
    if (songs) {
      if (hideMyUploads) {
        setFilteredSongs(
          songs.filter((song) => song.uploadedBy !== currentUser)
        );
      } else {
        setFilteredSongs(songs);
      }
    }
  }, [currentUser, hideMyUploads, songs]);

  const renderFileList = () => {
    if (slugbucketIsLoading) {
      return (
        <span className="lead animate__animated animate__pulse animate__infinite">
          Slurping Bucket...
        </span>
      );
    } else if (!s3FileList || !s3FileList.length) {
      return (
        <span className="lead animate__animated animate__rubberBand">
          The Slug Bucket is Empty.
        </span>
      );
    } else if (s3FileList.length && !songs) {
      return (
        <span className="lead animate__animated animate__pulse animate__infinite">
          Gurgling Metadata...
        </span>
      );
    } else {
      console.log("Slurping...");
      return (
        <div
          className={`d-flex flex-column align-items-center w-100 animate__animated animate__fadeIn`}
        >
          <div
            className="align-items-center d-flex d-sm-none display-4 mb-3"
            style={{ fontSize: "24px" }}
          >
            <div>Songs in the Key of Slug</div>
          </div>
          <div
            className="align-items-center d-none d-sm-flex display-4 mb-3"
            style={{ fontSize: "32px" }}
          >
            <div>Songs in the Key of Slug</div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="hideMyUploads"
                checked={hideMyUploads}
                onChange={() => {
                  setHideMyUploads(!hideMyUploads);
                }}
              />
              <label className="custom-control-label" htmlFor="hideMyUploads">
                Hide my Slugloads
              </label>
            </div>
          </div>

          {hideMyUploads &&
          s3FileList.length &&
          !songs.filter((song) => song.uploadedBy !== currentUser).length ? (
            <div className="d-flex flex-column align-items-center mt-5 animate__animated animate__fadeIn haiku">
              <p className="">Within Slug&apos;s Belly</p>
              <p className="">You seek songs that are not yours</p>
              <p className="">They do not exist</p>
            </div>
          ) : null}
          <ul
            className={`d-flex flex-column align-items-center list-group container w-100 mb-3 px-1 text-center animate__animated song-list ${
              !filteredSongs.length ? "animate__fadeOut" : "animate__fadeIn"
            }`}
          >
            <Flipper
              flipKey={filteredSongs}
              className={`d-flex flex-column align-items-center w-100`}
            >
              <SongListItems
                filteredSongs={filteredSongs}
                getUrl={getUrl}
                setDownloadLink={setDownloadLink}
                uploadProgress={parseInt(uploadProgress)}
              />
            </Flipper>
          </ul>
          {filteredSongs.length ? (
            <div className="animate__animated animate__fadeIn width w-100 container px-1">
              <button
                className="d-flex justify-content-center align-items-center btn btn-block btn-danger mb-1"
                disabled={
                  !filteredSongs.filter(
                    (song) => song.uploadedBy !== currentUser
                  ).length || uploadProgress > 0
                }
                style={{
                  cursor: `${
                    !filteredSongs.filter(
                      (song) => song.uploadedBy !== currentUser
                    ).length || uploadProgress > 0
                      ? "not-allowed"
                      : "pointer"
                  }`
                }}
                onClick={() => {
                  dispatch({ type: "SET_MODAL_ACTION", payload: "delete-all" });
                }}
                data-bs-toggle="modal"
                data-bs-target="#slug-modal"
                title={`${
                  !filteredSongs.filter(
                    (song) => song.uploadedBy !== currentUser
                  ).length
                    ? "You can only flush other people's songs."
                    : "Flush Me!"
                }`}
              >
                <span role="img" aria-label="crapper">
                  🚽
                </span>
                <span className="ms-2">Flush the Slug Bucket</span>
              </button>
              <button
                className="d-flex justify-content-center align-items-center btn btn-block btn-primary mb-5"
                style={{
                  cursor: `${
                    !filteredSongs.filter(
                      (song) => song.uploadedBy !== currentUser
                    ).length || uploadProgress > 0
                      ? "not-allowed"
                      : "pointer"
                  }`
                }}
                onClick={() => {
                  handleDownloadAll(songs, currentUser);
                }}
                disabled={
                  !filteredSongs.filter(
                    (song) => song.uploadedBy !== currentUser
                  ).length || uploadProgress > 0
                }
                title={`${
                  !filteredSongs.filter(
                    (song) => song.uploadedBy !== currentUser
                  ).length
                    ? "You can only slurp other people's songs."
                    : "Slurp Me!"
                }`}
              >
                <span role="img" aria-label="pig">
                  💩
                </span>
                <span className="ms-2">Slurp the Whole Enchilada</span>
              </button>
            </div>
          ) : null}
        </div>
      );
    }
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center sm-container mx-1 mt-3 sm-mt-5">
        {error.message ? (
          <div
            className="alert alert-dismissible alert-danger"
            style={{
              position: "absolute",
              bottom: "0px",
              zIndex: "999"
            }}
          >
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                dispatch({
                  type: "RESET_SLUG_BUCKET_PROGRESS",
                  payload: {
                    uploadProgress: 0,
                    uploadStatus: "",
                    dropzoneDisabled: false,
                    error: {
                      emoticon: "",
                      message: "",
                      title: ""
                    }
                  }
                });
              }}
              style={{ "&:hover": { opacity: "1" } }}
            >
              &times;
            </button>
            <strong>
              <span className="me-2" role="img" aria-label="emoticon">
                {error.emoticon}
              </span>
              {error.title}
            </strong>
            <div>{`Message:  "${error.message}"`}</div>
          </div>
        ) : null}
        <div className="d-none d-sm-block display-3 text-center">
          Slug Bucket
        </div>
        <div className="d-none d-sm-flex justify-content-center input-group mb-3 text-center">
          <Dropzone
            onDrop={(acceptedFiles) => {
              feedingFrenzy(acceptedFiles);
            }}
            disabled={dropzoneDisabled}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div style={{ outline: "none" }} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <img
                    className="m-3"
                    src={dropzoneDisabled ? slugfeeding : slug}
                    alt="slug bucket logo"
                    height="201"
                    width="200px"
                    title="Feed me!"
                    style={{ cursor: "pointer" }}
                  />
                  <div className="lead">
                    {uploadProgress > 0 && uploadProgress <= 100 ? (
                      <div className="animate__animated animate__pulse animate__infinite">
                        {acceptedFilesLength > 1
                          ? "Feeding Frenzy!"
                          : "The Slug is Feeding!"}
                      </div>
                    ) : (
                      <div>Feed the Slug!</div>
                    )}
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        {uploadProgress > 0 ? (
          <ProgressBar
            acceptedFilesLength={acceptedFilesLength}
            uploadProgress={parseInt(uploadProgress)}
            uploadStatus={uploadStatus}
            uploadCount={uploadCount}
          />
        ) : null}
        <div className="d-flex justify-content-center text-center slug-width w-100">
          {renderFileList()}
        </div>
      </div>
      <InfoModal />
      <BeenThereDoneThat data={beenThereDoneThat} />
      <SlugModal url={downloadLink} />
      <SlugBucketSpotifyModal />
      <SlugBucketAppleMusicModal />
    </>
  );
};

export default SlugBucket;
