export const TutorialPage3 = () => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center text-center tutorial-page"
      style={{ height: "480px" }}
    >
      <div className="tutorial-emoji">
        <span role="img" aria-label="electrical plug emoji">
          🔌
        </span>
      </div>
      <div className="tutorial-title">Connect</div>
      <p className="tutorial-lead">
        You can connect Slugify to either Apple Music, Spotify, or YouTube.
      </p>
      <p className="tutorial-lead">
        Once connected, you can save music shared by your friends to playlists
        on your content streaming service.
      </p>
    </div>
  );
};
