/* eslint-disable react/no-unknown-property */
import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SpotifyTrack } from "../types/spotify";

interface Props {
  item: SpotifyTrack;
  showMyTracks: boolean;
  showSlurpedTracks: boolean;
}
export default function SlugifySpotifyMusicItemWithWidget({
  item,
  showMyTracks,
  showSlurpedTracks
}: Props) {
  const toolTipText = useMemo(() => {
    let slurpedBy = null;
    let toolTipText = null;
    if (item.slurpedBy && item.slurpedBy.length) {
      if (item.slurpedBy.length === 1) {
        slurpedBy = item.slurpedBy[0];
      } else {
        const entries = item.slurpedBy.slice(0, -1);
        const lastEntry = item.slurpedBy.slice(-1);
        slurpedBy = entries.join(", ") + ` & ${lastEntry}`;
      }
    }
    if (
      (!showMyTracks && showSlurpedTracks) ||
      (!showMyTracks && !showSlurpedTracks)
    ) {
      if (item.sharedByFriend && item.sharedByFriend.length) {
        toolTipText = `Shared by ${item.sharedByFriend[0]}`;
      }
    } else if (
      (showMyTracks && showSlurpedTracks) ||
      (showMyTracks && !showSlurpedTracks)
    ) {
      toolTipText = slurpedBy ? `Slurped by ${slurpedBy}` : "unslurped";
    }
    return toolTipText;
  }, [item, showMyTracks, showSlurpedTracks]);

  return (
    <OverlayTrigger
      key={item.track.id}
      placement="top"
      overlay={<Tooltip id={`tooltip-${item.track.id}`}>{toolTipText}</Tooltip>}
    >
      <li key={item.track.id}>
        <div>
          <iframe
            src={`https://open.spotify.com/embed/track/${item.track.id}?utm_source=generator`}
            width="100%"
            height="80"
            // frameBorder="0"
            allowFullScreen={false}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          />
        </div>
      </li>
    </OverlayTrigger>
  );
}
