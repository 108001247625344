import { useDispatch, useSelector } from "react-redux";

export const ErrorAlert = () => {
  const dispatch = useDispatch();
  const {
    alertType,
    emoticon,
    message,
    messageIsHtml = false,
    title
  } = useSelector((state) => state.slugify.alert);

  return (
    <div
      className={`alert alert-dismissible alert-${alertType} slug-width`}
      style={{ zIndex: "1039" }}
    >
      <button
        type="button"
        className="btn-close"
        onClick={() => {
          dispatch({
            type: "RESET_SLUGIFY_ALERT"
          });
        }}
        style={{ "&:hover": { opacity: "1" } }}
      >
        &times;
      </button>
      <strong>
        <span className="me-2" role="img" aria-label="emoticon">
          {emoticon}
        </span>
        {title}
      </strong>
      {messageIsHtml ? (
        <div className="mt-3" dangerouslySetInnerHTML={{ __html: message }} />
      ) : (
        <div>{message}</div>
      )}
    </div>
  );
};
