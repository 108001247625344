import { animateScroll as scroll } from "react-scroll";
import {
  cleanMySpotifyPlaylists,
  getSlugifyPublicPlaylist,
  handlePushNotification,
  slugifyPostInit,
  updateFilteredTracks,
  updateTrackSlurpStatuses
} from "../actions";
import { ErrorAlert } from "./shared/ErrorAlert";
import { isEmptyObject, getTitleCase } from "../functions";
import { isMobile } from "react-device-detect";
import { PrivatePlaylistMessage } from "./shared/PrivatePlaylistMessage";
import {
  SelectedPlaylist,
  SlugifySlurpedTrack,
  SlugifyTrackType
} from "../types";
import { SetNickname } from "./SetNickname";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import * as hooks from "../hooks";
import appleLogo from "../images/apple_music_icon_color_small.svg";
import chalk from "chalk";
import Form from "react-bootstrap/Form";
import SlugifyAppleMusic from "./SlugifyAppleMusic";
import SlugifyAppleMusicModal from "./SlugifyAppleMusicModal";
import SlugifySpotify from "./SlugifySpotify";
import SlugifySpotifyModal from "./SlugifySpotifyModal";
import SlugifyYouTube from "./SlugifyYouTube";
import SlugifyYouTubeModal from "./SlugifyYouTubeModal";
import StatusMessage from "./shared/StatusMessage";
import { YouTubePlaylist } from "../types/youtube";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import UserSettingsYouTubeChannelInfo from "./UserSettingsYouTubeChannelInfo";

const isDev = process.env.NODE_ENV === "development";

export default function Slugify() {
  hooks.useSlurpedTrackSubscription();

  const history = useHistory();
  const { isDark } = useContext(ThemeContext);
  const dispatch = useAppDispatch();

  const {
    appleMusic: {
      isConnected: isAppleMusicConnected,
      myPlaylists: myAppleMusicPlaylists,
      tracks: appleMusicTracks,
      unmatchedTracks: unmatchedTracksByAppleMusic
    },
    auth: {
      username: currentUsername,
      attributes: { nickname }
    },
    slugify: {
      alert,
      filteredTracks,
      isLoading,
      showMyTracks,
      showSlurpedTracks,
      slurpedTracks,
      spotifyTracks,
      unmatchedTracks: unmatchedTracksBySpotify
    },
    spotify: {
      isConnected: isSpotifyConnected,
      myPlaylists: mySpotifyPlaylists
    },
    newUser: {
      friends,
      spotifyUserData: currentSpotifyUserData,
      primaryMusicStream,
      appleMusicSlugifyPlaylist
    },
    youtube: {
      channel: youTubeChannel,
      myPlaylists: myYoutubePlaylists,
      user: { id: youTubeId }
    }
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (primaryMusicStream === "youtube") {
      const musicPlaylist = myYoutubePlaylists.find(
        (item: YouTubePlaylist) => item.name === "SlugTube Music"
      );
      if (musicPlaylist) {
        setSelectedPlaylist(musicPlaylist);
      }
      const videosPlaylist = myYoutubePlaylists.find(
        (item: YouTubePlaylist) => item.name === "SlugTube Videos"
      );
      if (videosPlaylist) {
        setSelectedVideosPlaylist(videosPlaylist);
      }
    }
  }, [myYoutubePlaylists, primaryMusicStream]);

  const { hasVideos, hasMatchedTracks } = useMemo(() => {
    const hasVideos = filteredTracks.find(
      (item: gapi.client.youtube.PlaylistItem) =>
        item.kind === "youtube#playlistItem"
    );
    const hasMatchedTracks = filteredTracks.find(
      (item: gapi.client.youtube.PlaylistItem) => !item.kind
    );
    return { hasVideos, hasMatchedTracks };
  }, [filteredTracks]);

  const myForgottenUnmatchedTrackIds: string[] = slurpedTracks
    .filter((slurpedTrack: SlugifySlurpedTrack) => {
      if (primaryMusicStream === "appleMusic") {
        return (
          slurpedTrack.owner === currentUsername &&
          slurpedTrack.trackType ===
            SlugifyTrackType.APPLE_MUSIC_UNMATCHED_FROM_SPOTIFY
        );
      } else if (primaryMusicStream === "spotify") {
        return (
          slurpedTrack.owner === currentUsername &&
          slurpedTrack.trackType ===
            SlugifyTrackType.SPOTIFY_UNMATCHED_FROM_APPLE_MUSIC
        );
      }
      if (isDev) {
        console.log(chalk.red("Get this working for YouTube"));
      }
      return null;
    })
    .map((unmatchedSlurpedTrack: SlugifySlurpedTrack) => {
      // this can be confusing
      // unmatched do not have their own native track id
      // so we pull the opposite
      return primaryMusicStream === "appleMusic"
        ? unmatchedSlurpedTrack.spotifyId
        : unmatchedSlurpedTrack.appleMusicId;
    });

  if (isDev) {
    console.log(
      "%c My Forgotten Unmatched Tracks",
      "color:pink",
      myForgottenUnmatchedTrackIds
    );
  }

  const myNonForgottenUnmatchedTracks =
    primaryMusicStream === "appleMusic"
      ? unmatchedTracksByAppleMusic.filter((item) => {
          if (isDev) {
            console.log(chalk.red("Unmatched Apple Music Track"), item);
          }
          return (
            !myForgottenUnmatchedTrackIds.includes(item.spotifyId) &&
            ((item.playlist_name.includes("Slugify Direct") &&
              item.playlist_name.includes(getTitleCase(currentUsername))) ||
              !item.playlist_name.includes("Slugify Direct"))
          );
        })
      : unmatchedTracksBySpotify.filter((item) => {
          if (isDev) {
            console.log("%c Unmatched Spotify Track", "color:pink", item);
          }
          return (
            !myForgottenUnmatchedTrackIds.includes(
              item.appleMusicInfo.appleMusicId
            ) &&
            ((item.added_by.id.includes("Slugify Direct") &&
              item.added_by.id.includes(getTitleCase(currentUsername))) ||
              !item.added_by.id.includes("Slugify Direct"))
          );
        });

  if (isDev) {
    console.log(
      "%c My non-forgotten unmatched tracks",
      "color:pink",
      myNonForgottenUnmatchedTracks
    );
  }

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  // const [modalAction, setModalAction] = useState("");
  const [, setModalAction] = useState("");
  const [selectedPlaylist, setSelectedPlaylist] = useState<SelectedPlaylist>();
  const [selectedVideosPlaylist, setSelectedVideosPlaylist] =
    useState<SelectedPlaylist>();
  const [slugifyPlaylistIsPublic, setSlugifyPlaylistIsPublic] = useState(false);
  const [isSlugifyAppleMusicModalOpen, setIsSlugifyAppleMusicModalOpen] =
    useState(false);
  const [isSlugifySpotifyModalOpen, setIsSlugifySpotifyModalOpen] =
    useState(false);
  const [isSlugifyYouTubeModalOpen, setIsSlugifyYouTubeModalOpen] =
    useState(false);

  const handleAddAllToAppleMusic = () => {
    setModalAction("add");
    setIsSlugifyAppleMusicModalOpen(true);
    if (isMobile) {
      scroll.scrollToTop();
    }
  };

  const handleAddAllToSpotify = () => {
    setModalAction("add");
    setIsSlugifySpotifyModalOpen(true);
    if (isMobile) {
      scroll.scrollToTop();
    }
  };

  const handleAddAllToYouTube = () => {
    setModalAction("add");
    setIsSlugifyYouTubeModalOpen(true);
    if (isMobile) {
      scroll.scrollToTop();
    }
  };

  function renderAddButtonTitle(): string {
    if (primaryMusicStream === "appleMusic") {
      if (isDev) {
        console.log(chalk.red("Fix this type!"));
      }
      if (
        !filteredTracks.filter((item: any) => {
          if (
            item.trackType ===
            SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY
          ) {
            return item.spotifyInfo.added_by !== currentSpotifyUserData.id;
          } else if (item.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE) {
            return item.addedBy !== currentUsername;
          } else {
            return null;
          }
        }).length
      ) {
        return "You can only slurp other people's tracks.";
      } else if (!selectedPlaylist) {
        return "Please select a playlist first.";
      }
      return `Slurp to Apple Music!`;
    } else if (primaryMusicStream === "spotify") {
      if (isDev) {
        console.log(chalk.red("Fix this type!"));
      }
      if (
        !filteredTracks.filter(
          (track: any) => track.added_by.id !== currentSpotifyUserData.id
        ).length
      ) {
        return "You can only slurp other people's tracks.";
      } else if (!selectedPlaylist) {
        return "Please select a playlist first.";
      }
      return `Slurp to Spotify!`;
    } else if (primaryMusicStream === "youtube") {
      if (
        hasVideos &&
        hasMatchedTracks &&
        (!selectedPlaylist || !selectedVideosPlaylist)
      ) {
        return "Please select playlists first.";
      } else if (hasVideos && !selectedVideosPlaylist) {
        return "Please select a Videos Destination playlist first.";
      } else if (hasMatchedTracks && !selectedPlaylist) {
        return "Please select a Music Destination playlist first.";
      } else {
        return `Slurp to YouTube!`;
      }
    }
    return "Slurp";
  }

  // const renderAutoSlurpButton = () => {
  //   if (!isRefreshing) {
  //     return (
  //       <button
  //         className={`d-flex justify-content-center align-items-center btn btn-${
  //           isDark ? "outline-success" : "success"
  //         } animate__animated animate__fadeIn mt-2 w-100`}
  //         onClick={async () => {
  //           try {
  //             setIsSpinning(true);
  //             await dispatch(invokeAutoSlurp());
  //             setIsSpinning(false);
  //           } catch (error) {
  //             setIsSpinning(false);
  //             console.warn(error);
  //           }
  //         }}
  //         style={{ maxWidth: 800 }}
  //         title="Invoke AutoSlurp"
  //       >
  //         <span
  //           className={` mb-1 ${
  //             isSpinning
  //               ? "animate__animated animate__wobble animate__infinite"
  //               : ""
  //           }`}
  //           role="img"
  //           aria-label="magic wand"
  //         >
  //           🪄
  //         </span>
  //         <span className="d-flex ms-2">Invoke Auto Slurp</span>
  //       </button>
  //     );
  //   }
  //   return null;
  // };
  const renderNotifyButton = () => {
    if (!isRefreshing) {
      return (
        <button
          className={`d-flex justify-content-center align-items-center btn btn-${
            isDark ? "outline-secondary" : "secondary"
          } animate__animated animate__fadeIn mt-2 w-100`}
          onClick={async () => {
            try {
              setIsSpinning(true);
              await dispatch(
                handlePushNotification(
                  `New tracks from ${currentUsername} on Slugify!`
                )
              );
              setIsSpinning(false);
            } catch (error) {
              setIsSpinning(false);
              console.warn(error);
            }
          }}
          style={{ maxWidth: 800 }}
          title="Tell a friend!"
        >
          <span
            className={` mb-0 ${
              isSpinning
                ? "animate__animated animate__wobble animate__infinite"
                : ""
            }`}
            role="img"
            aria-label="Bellhop Bell"
          >
            🛎️
          </span>
          <span className="d-flex d-sm-none ms-2">Notify About New Tracks</span>
          <span className="d-none d-sm-flex ms-2">
            Notify Others About New Tracks
          </span>
        </button>
      );
    }
    return null;
  };

  const renderOpenAppleMusicPlaylistButton = () => {
    if (
      !isRefreshing &&
      filteredTracks.length &&
      primaryMusicStream === "appleMusic"
    ) {
      return (
        <a
          href={`https://music.apple.com/us/playlist/slugify/${appleMusicSlugifyPlaylist}`}
          target="_blank"
          rel="noopener noreferrer"
          className={`d-flex justify-content-center align-items-center btn btn-${
            isDark ? "outline-light" : "light"
          } animate__animated animate__fadeIn w-100`}
          onClick={async () => {
            try {
              // open window
            } catch (error) {
              console.warn(error);
            }
          }}
          style={{ maxWidth: 800 }}
          title="Open Apple Music playlist"
        >
          <div className="d-flex align-items-center">
            <img
              src={appleLogo}
              alt="apple music logo"
              style={{ height: 22, width: 22 }}
            />
            <span className="ms-2">Open Slugify on Apple Music</span>
          </div>
        </a>
      );
    }
    return null;
  };
  const renderUpdateButton = () => {
    if (!isRefreshing) {
      return (
        <button
          className={`d-flex justify-content-center align-items-center btn btn-${
            isDark ? "outline-info" : "info"
          } my-2 animate__animated animate__fadeIn w-100`}
          onClick={async () => {
            setIsRefreshing(true);
            dispatch({ type: "RESET_APPLE_MUSIC_TRACKS" });
            dispatch({ type: "RESET_SPOTIFY_TRACKS" });
            dispatch(slugifyPostInit(setIsRefreshing));
          }}
          style={{ maxWidth: 800 }}
        >
          {isRefreshing ? (
            <div
              className="spinner-grow spinner-grow-sm text-light me-2 animate__animated animate__fadeIn"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span className="icon-loop slug-icon" />
          )}
          <span className="ms-2">Check for Updates</span>
        </button>
      );
    }
  };

  const renderSlugifyContent = () => {
    if (primaryMusicStream === "youtube" && !isRefreshing) {
      return (
        <SlugifyYouTube
          // currentSpotifyUserData={currentSpotifyUserData}
          filteredTracks={filteredTracks}
          handleAddAllToYouTube={handleAddAllToYouTube}
          myNonForgottenUnmatchedTracks={myNonForgottenUnmatchedTracks}
          primaryMusicStream={primaryMusicStream}
          renderAddButtonTitle={renderAddButtonTitle}
          renderPlaylistOptions={renderPlaylistOptions}
          selectedMusicPlaylist={selectedPlaylist}
          setSelectedMusicPlaylist={setSelectedPlaylist}
          selectedVideosPlaylist={selectedVideosPlaylist}
          setSelectedVideosPlaylist={setSelectedVideosPlaylist}
          showMyTracks={showMyTracks}
          showSlurpedTracks={showSlurpedTracks}
          hasMatchedTracks={hasMatchedTracks}
          hasVideos={hasVideos}
          // appleMusicTracks={appleMusicTracks}
        />
      );
    }
    if (primaryMusicStream === "appleMusic" && !isRefreshing) {
      return (
        <SlugifyAppleMusic
          // currentSpotifyUserData={currentSpotifyUserData}
          filteredTracks={filteredTracks}
          handleAddAllToAppleMusic={handleAddAllToAppleMusic}
          myNonForgottenUnmatchedTracks={myNonForgottenUnmatchedTracks}
          renderAddButtonTitle={renderAddButtonTitle}
          renderPlaylistOptions={renderPlaylistOptions}
          selectedPlaylist={selectedPlaylist}
          setSelectedPlaylist={setSelectedPlaylist}
          showMyTracks={showMyTracks}
          showSlurpedTracks={showSlurpedTracks}
          appleMusicTracks={appleMusicTracks}
        />
      );
    }
    if (primaryMusicStream === "spotify" && !isRefreshing) {
      return (
        <SlugifySpotify
          filteredTracks={filteredTracks}
          handleAddAllToSpotify={handleAddAllToSpotify}
          myNonForgottenUnmatchedTracks={myNonForgottenUnmatchedTracks}
          mySpotifyPlaylists={mySpotifyPlaylists}
          renderAddButtonTitle={renderAddButtonTitle}
          renderPlaylistOptions={renderPlaylistOptions}
          selectedPlaylist={selectedPlaylist}
          setSelectedPlaylist={setSelectedPlaylist}
          showMyTracks={showMyTracks}
          showSlurpedTracks={showSlurpedTracks}
          // slurpedTracks={slurpedTracks}
        />
      );
    }
    return (
      <StatusMessage
        titleAnimation="pulse"
        isInfinite
        emoticon="🕴️"
        title="Updating ..."
        size="sm"
      />
    );
  };

  const renderPlaylistOptions = () => {
    if (primaryMusicStream === "appleMusic" && myAppleMusicPlaylists.length) {
      return myAppleMusicPlaylists
        .filter(
          (playlist) =>
            (playlist.attributes.name !== "Slugify" &&
              !playlist.attributes.name.includes("Slugify Direct") &&
              !playlist.attributes.description) ||
            (playlist.attributes.name !== "Slugify" &&
              !playlist.attributes.name.includes("Slugify Direct") &&
              playlist.attributes.description &&
              !playlist.attributes.description.standard) ||
            (playlist.attributes.name !== "Slugify" &&
              !playlist.attributes.name.includes("Slugify Direct") &&
              playlist.attributes.description &&
              playlist.attributes.description.standard &&
              !playlist.attributes.description.standard.includes("via Slugify"))
        )
        .map((playlist) => (
          <option key={playlist.id} value={playlist.id}>
            {playlist.attributes.name}
          </option>
        ));
    } else if (primaryMusicStream === "spotify" && mySpotifyPlaylists.length) {
      return mySpotifyPlaylists
        .filter(
          (playlist) =>
            playlist.name !== "Slugify" &&
            !playlist.name.includes("(Slugify Direct)") &&
            !playlist.description.includes("via Slugify")
        )
        .map((playlist) => (
          <option key={playlist.id} value={playlist.id}>
            {playlist.name}
          </option>
        ));
    } else if (primaryMusicStream === "youtube" && myYoutubePlaylists.length) {
      return myYoutubePlaylists
        .filter(
          (playlist: YouTubePlaylist) =>
            playlist.name !== "Slugify" &&
            !playlist.name.includes("Previously Shared") &&
            !playlist.name.includes("(Slugify Direct)") &&
            !playlist.name.includes("via Slugify")
        )
        .map((playlist: YouTubePlaylist) => (
          <option key={playlist.id} value={playlist.id}>
            {playlist.name}
          </option>
        ));
    }
  };

  const renderGoToSettingsButton = () => {
    return (
      <>
        <button
          className={`d-flex d-sm-none justify-content-center align-items-center btn btn-sm btn-${
            isDark ? "outline-primary" : "primary"
          } mt-2 animate__animated animate__fadeIn`}
          onClick={() => history.push("/settings")}
        >
          <i
            className="icon-settings slug-icon me-2"
            style={{ fontSize: "18px" }}
          />
          Go To Settings
        </button>
        <button
          className={`d-none d-sm-flex justify-content-center align-items-center btn btn-${
            isDark ? "outline-primary" : "primary"
          } mt-2 animate__animated animate__fadeIn`}
          onClick={() => history.push("/settings")}
        >
          <i className="icon-settings slug-icon me-2" />
          Go To Settings
        </button>
      </>
    );
  };

  const renderStatus = () => {
    if (!nickname) {
      return <SetNickname />;
    } else if (!primaryMusicStream) {
      return (
        <>
          <StatusMessage
            className="mb-3"
            emoticon="tipper"
            subtitle="To get things rollin', go to Settings and connect your music streaming service."
            size="sm"
          />
          {renderGoToSettingsButton()}
        </>
      );
    } else if (primaryMusicStream === "appleMusic" && !isAppleMusicConnected) {
      return (
        <>
          <StatusMessage
            titleAnimation="wobble"
            emoticon="tipper"
            title="You need to connect Apple Music"
            size="sm"
          />
          {renderGoToSettingsButton()}
        </>
      );
    } else if (primaryMusicStream === "youtube" && !youTubeId) {
      return (
        <>
          <StatusMessage
            titleAnimation="wobble"
            emoticon="tipper"
            title="You need to connect YouTube"
            size="sm"
          />
          {renderGoToSettingsButton()}
        </>
      );
    } else if (
      primaryMusicStream &&
      primaryMusicStream === "youtube" &&
      !youTubeChannel?.id
    ) {
      return <UserSettingsYouTubeChannelInfo />;
    } else if (
      primaryMusicStream &&
      (isAppleMusicConnected || isSpotifyConnected || !!youTubeId) &&
      !slugifyPlaylistIsPublic
    ) {
      return <PrivatePlaylistMessage />;
    } else if (primaryMusicStream === "spotify" && !isSpotifyConnected) {
      return (
        <>
          <StatusMessage
            titleAnimation="wobble"
            emoticon="tipper"
            title="You need to connect Spotify"
            size="sm"
          />
          {renderGoToSettingsButton()}
        </>
      );
    } else if (
      primaryMusicStream &&
      (isAppleMusicConnected || isSpotifyConnected || !!youTubeId) &&
      !friends.length
    ) {
      return (
        <>
          <StatusMessage
            titleAnimation="heartBeat"
            emoticon="dancers"
            title="It takes two to Tango!"
            subtitle="Add a friend in Settings"
            size="sm"
          />
          {renderGoToSettingsButton()}
        </>
      );
    }
  };

  useEffect(() => {
    dispatch(getSlugifyPublicPlaylist()).then((isPublic) => {
      setSlugifyPlaylistIsPublic(
        primaryMusicStream === "appleMusic" ? !!isPublic : isPublic
      );
    });
  }, [
    dispatch,
    myAppleMusicPlaylists,
    mySpotifyPlaylists,
    myYoutubePlaylists,
    primaryMusicStream
  ]);

  useEffect(() => {
    dispatch(updateFilteredTracks())
      .then(() => {
        dispatch(updateTrackSlurpStatuses())
          .then((updatedTracks) => {
            if (!updatedTracks.length) {
              if (isDev) {
                console.log("🤷🏻‍♀️ No tracks to update");
              }
            } else {
              if (isDev) {
                console.log(
                  `✨ ${updatedTracks.length} tracks updated with slurped status`
                );
              }
            }
            dispatch(cleanMySpotifyPlaylists())
              .then((result) => {
                if (isDev) {
                  console.log(`${result?.length || 0} playlists cleaned`);
                }
                if (result && result.length > 0) {
                  dispatch(updateFilteredTracks());
                }
              })
              .catch((error) => {
                console.warn("Error cleaning playlist", error);
              });
          })
          .catch((error) => console.warn("Error updating tracks ", error));
      })
      .catch((error) => console.warn("Error filtering tracks: ", error));
  }, [
    appleMusicTracks.length,
    dispatch,
    showMyTracks,
    showSlurpedTracks,
    slurpedTracks.length,
    spotifyTracks.length
  ]);

  return (
    <>
      <SlugifyAppleMusicModal
        selectedPlaylist={selectedPlaylist}
        isSlugifyAppleMusicModalOpen={isSlugifyAppleMusicModalOpen}
        setIsSlugifyAppleMusicModalOpen={setIsSlugifyAppleMusicModalOpen}
        // modalAction={modalAction}
        // allowDuplicates={showSlurpedTracks}
      />
      <SlugifySpotifyModal
        selectedPlaylist={selectedPlaylist}
        isSlugifySpotifyModalOpen={isSlugifySpotifyModalOpen}
        setIsSlugifySpotifyModalOpen={setIsSlugifySpotifyModalOpen}
        // modalAction={modalAction}
        // allowDuplicates={showSlurpedTracks}
      />
      <SlugifyYouTubeModal
        hasMatchedTracks={hasMatchedTracks}
        hasVideos={hasVideos}
        selectedMusicPlaylist={selectedPlaylist}
        selectedVideosPlaylist={selectedVideosPlaylist}
        isSlugifyYouTubeModalOpen={isSlugifyYouTubeModalOpen}
        setIsSlugifyYouTubeModalOpen={setIsSlugifyYouTubeModalOpen}
        // modalAction={modalAction}
        // allowDuplicates={showSlurpedTracks}
      />
      <div className="d-flex flex-column align-items-center container my-3">
        {!isEmptyObject(alert) ? <ErrorAlert /> : null}
        <div
          className={`text-center mb-3 slug-title ${
            primaryMusicStream === "youtube" ? "slugTube-font" : ""
          }`}
        >
          Slugify
        </div>
        {!slugifyPlaylistIsPublic ||
        !nickname ||
        !primaryMusicStream ||
        (primaryMusicStream === "appleMusic" && !isAppleMusicConnected) ||
        (primaryMusicStream === "spotify" && !isSpotifyConnected) ||
        (primaryMusicStream === "youtube" && !youTubeId) ||
        !friends.length ? (
          renderStatus()
        ) : (
          <>
            <div className="d-flex flex-column">
              <Form.Check
                id="showMyTracks"
                checked={showMyTracks}
                label="Show My Slugify Playlist Tracks"
                onChange={() => {
                  dispatch({
                    type: "UPDATE_SHOW_MY_TRACKS",
                    payload: !showMyTracks
                  });
                }}
                type="switch"
              />
              <Form.Check
                id="showSlurpedTracks"
                checked={showSlurpedTracks}
                label="Show Previously Slurped Tracks"
                onChange={() => {
                  dispatch({
                    type: "UPDATE_SHOW_SLURPED_TRACKS",
                    payload: !showSlurpedTracks
                  });
                }}
                type="switch"
              />
            </div>
            {filteredTracks.length ||
            unmatchedTracksByAppleMusic.length ||
            myNonForgottenUnmatchedTracks.length ? (
              <>
                {renderSlugifyContent()}
                {renderNotifyButton()}
                {renderUpdateButton()}
                {renderOpenAppleMusicPlaylistButton()}
                {/* {currentUsername === "Kimfucious" && renderAutoSlurpButton()} */}
              </>
            ) : isLoading && !isRefreshing ? (
              <StatusMessage
                emoticon="🐌"
                isInfinite
                title="Slurping Streams..."
                titleAnimation="animate__pulse"
              />
            ) : (
              <>
                {!isRefreshing ? (
                  <>
                    <StatusMessage
                      titleAnimation="tada"
                      emoticon="🙉"
                      title="No can hear!"
                      subtitle="(got beer in my ear)"
                      size="sm"
                    />
                    <button
                      className={`d-flex justify-content-center align-items-center btn btn-sm btn-${
                        isDark ? "outline-info" : "info"
                      } mt-3 px-3`}
                      onClick={async () => {
                        setIsRefreshing(true);
                        await dispatch(slugifyPostInit());
                        setIsRefreshing(false);
                      }}
                    >
                      {isRefreshing ? (
                        <div
                          className="spinner-grow spinner-grow-sm text-light me-2 animate__animated animate__fadeIn"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <span
                          className="icon-loop slug-icon"
                          style={{ fontSize: "18px", marginBottom: "3px" }}
                        />
                      )}
                      <span className="ms-2">Check for Updates</span>
                    </button>
                  </>
                ) : (
                  <StatusMessage
                    titleAnimation="pulse"
                    emoticon="🕴️"
                    title="Updating..."
                    size="sm"
                    isInfinite
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
