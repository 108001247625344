import { useState } from "react";
import { Auth } from "aws-amplify";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

interface Props {
  authUser: any;
  resetType: string;
  show: boolean;
  setShow: (b: boolean) => void;
}

export default function NewPasswordModal({
  authUser: user,
  resetType,
  show,
  setShow
}: Props) {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [spinning, setSpinning] = useState(false);
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("");
  const history = useHistory();

  function handleClose() {
    setShow(false);
  }
  function getMessage() {
    if (resetType === "New Password Required") {
      return (
        <div className="d-flex flex-column align-items-center">
          <span
            role="img"
            aria-label="At your service!"
            style={{ fontSize: "36px" }}
          >
            💁‍♀️
          </span>
          <p>Your password needs to be reset</p>
        </div>
      );
    } else if (resetType === "Password Reset") {
      return (
        <div className="d-flex flex-column align-items-center">
          <span
            role="img"
            aria-label="At your service!"
            style={{ fontSize: "36px" }}
          >
            💁‍♀️
          </span>
          <p>Your password needs to be reset</p>
          <p>Check your inbox to retrieve the verification code.</p>
        </div>
      );
    }
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (resetType === "Password Reset") {
      resetForgottenPassword();
    } else if (resetType === "New Password Required") {
      saveNewPassword();
    }
  }

  async function resetForgottenPassword() {
    setSpinning(true);
    setError("");
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      setSpinning(false);
      setStatus("done");
    } catch (e: any) {
      setSpinning(false);
      setError(e.message);
      console.log(e);
    }
  }

  async function saveNewPassword() {
    setSpinning(true);
    try {
      const loggedUser = await Auth.completeNewPassword(user, password);
      setSpinning(false);
      console.log(loggedUser);
      $("#new-password-modal").modal("hide");
      history.push("/loading");
    } catch (e: any) {
      setSpinning(false);
      setError(e.message);
      console.log(e);
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{resetType}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {status === "done" ? (
            <div className="d-flex flex-column align-items-center animate__animated animate__fadeIn">
              <span
                role="img"
                aria-label="At your service!"
                style={{ fontSize: "36px" }}
              >
                💁‍♀️
              </span>
              <p>Your password has been reset.</p>
              <p>Close this window and sign in with your new password.</p>
            </div>
          ) : (
            <div>
              {getMessage()}
              {resetType === "Password Reset" ? (
                <div>
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                      autoComplete="username"
                      type="text"
                      className="form-control"
                      id="username"
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder=""
                      value={username}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="code">Verification Code</label>
                    <input
                      autoComplete="code"
                      type="text"
                      className="form-control"
                      id="code"
                      onChange={(e) => setCode(e.target.value)}
                      placeholder=""
                      value={code}
                    />
                    <small
                      id="codeHelp"
                      className="form-text text-body-secondary"
                    >
                      Find this in your inbox
                    </small>
                  </div>
                </div>
              ) : null}
              <div className="form-group">
                <label htmlFor="newPasswordInput">Enter New Password</label>
                <input
                  autoComplete="password"
                  type="password"
                  className="form-control"
                  id="newPasswordInput"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder=""
                  value={password}
                />
              </div>
            </div>
          )}
          {error ? <small className="text-danger">{error}</small> : null}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between modal-footer">
            <button
              type="button"
              className="btn btn-link text-body-secondary text-decoration-none"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              disabled={status === "done"}
              type="submit"
              className="d-flex justify-content-center align-items-center btn btn-primary"
            >
              {spinning ? (
                <div
                  className="spinner-grow spinner-grow-sm text-light me-3"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <i className="icon-save slug-icon me-2" />
              )}
              <span>Save</span>
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

// export default withRouter(NewPasswordModal);
