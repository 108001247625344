export const TutorialPage2 = () => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center text-center tutorial-page"
      style={{ height: "480px" }}
    >
      <div className="tutorial-emoji">
        <span role="img" aria-label="radio emoji">
          📻
        </span>
      </div>
      <div className="tutorial-title">Slugify Playlist</div>
      <p className="tutorial-lead">
        Slugify works by sharing the tracks that you place in a special playlist
        named <span className="text-primary font-weight-bold">Slugify</span>.
      </p>
      <p className="tutorial-lead">
        This playlist is created for you when you connect Slugify to Apple
        Music, Spotify, or YouTube.
      </p>
      <br />
    </div>
  );
};
