import { Storage } from "aws-amplify";
import genericAlbumArt from "../images/generic_album_cover_150.jpg";
import { Buffer } from "buffer";

export const cleanThing = (thing) => {
  return thing
    .replace(/\(/g, "<")
    .replace(/\[/g, "<")
    .replace(/\)/g, ">")
    .replace(/\]/g, ">")
    .replace(/’/g, "<rightSingleQuote>")
    .replace(/ʻ/g, "<okina>")
    .replace(/´/g, "'")
    .replace(/!/g, "<bang>")
    .replace(/\?/g, "<what>")
    .replace(/¿/g, "<SpanishFrontWhat>")
    .replace(/#/g, "<hash>")
    .replace(/\//g, "<slash>")
    .replace(/í/g, "<iWithRightAccent>")
    .replace(/ú/g, "<uWithRightAccent>")
    .replace(/ô/g, "<oWithCarat>")
    .replace(/ö/g, "<oWithUmlaut>")
    .replace(/é/g, "<eWithRightAccent>")
    .replace(/á/g, "<aWithRightAccent>");
};

export const uncleanThing = (thing) => {
  return thing
    .replace(/<rightSingleQuote>/g, "’")
    .replace(/<okina>/g, "ʻ")
    .replace(/<bang>/g, "!")
    .replace(/<what>/g, "?")
    .replace(/<SpanishFrontWhat>/g, "¿")
    .replace(/<hash>/g, "#")
    .replace(/<slash>/g, "/")
    .replace(/<iWithRightAccent>/g, "í")
    .replace(/<uWithRightAccent>/g, "ú")
    .replace(/<oWithCarat>/g, "ô")
    .replace(/<oWithUmlaut>/g, "ö")
    .replace(/<eWithRightAccent>/g, "é")
    .replace(/<aWithRightAccent>/g, "á")
    .replace(/</g, "(")
    .replace(/\(-/g, "<-")
    .replace(/>/g, ")");
};

export const isEmptyObject = (obj) => {
  if (Object.entries(obj).length === 0 && obj.constructor === Object) {
    return true;
  }
  return false;
};

export const getPrimaryMusicStreamName = (primaryMusicStream) => {
  try {
    switch (primaryMusicStream) {
      case "appleMusic":
        return "Apple Music";
      case "spotify":
        return "Spotify";
      case "youtube":
        return "YouTube";
      default:
        return null;
    }
  } catch (error) {
    console.warn(error);
  }
};

export const openStreamingWindow = async (fileName) => {
  console.log(fileName);
  const url = await Storage.get(fileName, {
    // these lines have zero effect.
    // contentDisposition: "inline",
    // contentType: "audio/mpeg"
  });
  window.open(url, "_blank");
};

export const getAlbumArt = (file) => {
  if (file.albumArtDataUrl) {
    const url = file.albumArtDataUrl;
    return (
      //eslint-disable-next-line
      <a
        className="info_btn_custom mb-0"
        data-content={getSongDetailHtml(file)}
        data-html="true"
        data-placement="right"
        data-bs-toggle="popover"
        data-trigger="focus"
        role="button"
        tabIndex="0"
        title="Song Details"
      >
        <img
          className="me-sm-3"
          src={url}
          alt={file.album}
          height="64px"
          width="64px"
          title="Song Details"
        />
      </a>
    );
  } else {
    return (
      //eslint-disable-next-line
      <a
        className="info_btn_custom mb-0"
        data-content={getSongDetailHtml(file)}
        data-html="true"
        data-placement="right"
        data-bs-toggle="popover"
        data-trigger="click"
        role="button"
        tabIndex="0"
        title="Song Details"
      >
        <img
          className="me-sm-3"
          src={genericAlbumArt}
          alt={file.album}
          height="75px"
          width="75px"
          title="Song Details"
        />
      </a>
    );
  }
};

export const getSongDetailHtml = (song) => {
  let songDetailHtml = "<div>";
  songDetailHtml += `<div>
          <strong>Title: </strong>${song.titleClearText}
        </div>
        <div>
          <strong>Artist: </strong>${song.artistClearText}
        </div>
        <div>
          <strong>Album: </strong>${song.album}
        </div>
        <div>
          <strong>File Name: </strong>${song.fileName}
        </div>
        <div>
          <strong>Uploaded by: </strong>${song.uploadedBy}
        </div>
    `;
  if (song.releaseYear && song.releaseYear !== "n/a") {
    songDetailHtml += `<div>
          <strong>Year: </strong>${song.releaseYear}
          </div>
      `;
  }
  if (song.artists) {
    songDetailHtml += `<div>
          <strong>Artists: </strong>${song.artists.join(",")}
        </div>
      `;
  }
  if (song.composer) {
    songDetailHtml += `<div>
          <strong>Composer(s): </strong>${song.composer.join(",")}
        </div>
      `;
  }
  if (song.genre) {
    songDetailHtml += `<div>
          <strong>Genre: </strong>${song.genre.join(",")}
        </div>
      `;
  }
  if (song.disk && song.disk.no && song.disk.of) {
    songDetailHtml += `<div>
          <strong>Disk: </strong>${song.disk.no} of ${song.disk.of}
        </div>
      `;
  }
  if (song.track && song.track.no && song.track.of) {
    songDetailHtml += `<div>
          <strong>Track: </strong>${song.track.no} of ${song.track.of}
        </div>
      `;
  }
  if (song.encodedBy) {
    songDetailHtml += `<div>
          <strong>Encoded by: </strong>${song.encodedBy}
        </div>
      `;
  }
  // this needs to be last
  songDetailHtml += "</div>";
  return songDetailHtml;
};

export const getUserAvatar = (user) => {
  user = user.toLowerCase();
  switch (user) {
    case "slug":
      return (
        <span
          role="img"
          aria-label="snake"
          title="Uploaded by Slug"
          style={{ fontSize: "20px", cursor: "default" }}
        >
          🐍
        </span>
      );
    case "slugbert":
      return (
        <span
          role="img"
          aria-label="snail"
          title="Uploaded by Slugbert"
          style={{ fontSize: "20px", cursor: "default" }}
        >
          🐌
        </span>
      );
    case "kimfucious":
      return (
        <span
          role="img"
          aria-label="mage"
          title="Uploaded by Kimfucious"
          style={{ fontSize: "20px", cursor: "default" }}
        >
          🧙‍♂️
        </span>
      );
    case "mjb":
      return (
        <span
          role="img"
          aria-label="guitar"
          title="Uploaded by mjb"
          style={{ fontSize: "20px", cursor: "default" }}
        >
          🎸
        </span>
      );
    default:
      return (
        <span
          role="img"
          aria-label="tito"
          title="Uploaded by Tito"
          style={{ fontSize: "20px", cursor: "default" }}
        >
          🕺🏿
        </span>
      );
  }
};

export const getUserYouTubeAvatar = (user) => {
  if (user.toLowerCase().includes("alohajeffs")) {
    return (
      <span
        role="img"
        aria-label="snake"
        title="Uploaded by Slug"
        style={{ fontSize: "20px", cursor: "default" }}
      >
        🐍
      </span>
    );
  }
  if (user.toLowerCase().includes("slugbert")) {
    return (
      <span
        role="img"
        aria-label="snail"
        title="Uploaded by Slugbert"
        style={{ fontSize: "20px", cursor: "default" }}
      >
        🐌
      </span>
    );
  }
  if (user.toLowerCase().includes("kimford")) {
    return (
      <span
        role="img"
        aria-label="mage"
        title="Uploaded by Kimfucious"
        style={{ fontSize: "20px", cursor: "default" }}
      >
        🧙‍♂️
      </span>
    );
  }
  return (
    <span
      role="img"
      aria-label="ghost"
      title={` Uploaded by ${user.split(" <")[0]}`}
      style={{ fontSize: "20px", cursor: "default" }}
    >
      👻
    </span>
  );
};

export const getTitleCase = (str) =>
  str
    .split(" ")
    .map((str) => {
      const word = str.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");

export const trimText = (len, text) => {
  if (!text) return "";
  if (text && text.length <= len) return text;
  return text.slice(0, len - 3) + "...";
};

export const parseSlugifyUserData = (users, update) => {
  const parseItem = (obj) => {
    try {
      if (!obj || isEmptyObject(obj)) {
        return {};
      }
      if (typeof obj === "string") {
        if (obj === "{}") {
          return {};
        }
        if (update) {
          return JSON.parse(JSON.parse(obj));
        } else {
          return JSON.parse(obj);
        }
      }
      return obj;
    } catch (error) {
      console.warn(error);
    }
  };
  const parseUser = (user) => {
    try {
      return {
        appleMusicSlugifyPlaylist: user.appleMusicSlugifyPlaylist,
        expoPushTokenData: parseItem(user.expoPushTokenData),
        friends: user.friends.items || [],
        homeMenu: user.homeMenu,
        id: user.id,
        isSubscribedOneSignal: user.isSubscribedOneSignal,
        owner: user.owner,
        primaryMusicStream: user.primaryMusicStream,
        screenAppearance: user.screenAppearance,
        slugifyMobileSlurpDestination: parseItem(
          user.slugifyMobileSlurpDestination
        ),
        slurpedTracks: user.slurpedTracks.items || [],
        spotifyTokenData: parseItem(user.spotifyTokenData),
        spotifyUserData: parseItem(user.spotifyUserData),
        username: user.username
      };
    } catch (error) {
      console.warn(error);
    }
  };
  try {
    if (Array.isArray(users)) {
      if (users.length) {
        return Promise.resolve(users.map((user) => parseUser(user)));
      } else {
        return Promise.resolve([]);
      }
    } else {
      return Promise.resolve(parseUser(users));
    }
  } catch (error) {
    console.warn(error);
    Promise.reject(error);
  }
};

export function btoa(string) {
  let encoded = Buffer.from(string, "utf8").toString("base64");
  encoded = encoded.replace("=", "").slice(0, 8);
  // console.log("encoded", encoded);
  return encoded;
}

export function atob(string) {
  let decoded = Buffer.from(string, "base64").toString("utf8");
  // console.log("decoded");
  return decoded;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
