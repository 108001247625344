import { AnyAction } from "redux";
import { SpotifyActionType, SpotifyState } from "../types";

const initialState = {
  clientCredentialsTokenData: {},
  isConnected: false,
  myPlaylists: [],
  trackSearch: []
} as SpotifyState;

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SpotifyActionType.CREATE_APPLE_MUSIC_PLAYLIST_ON_SPOTIFY_SUCCESS:
    case "CREATE_NEW_SPOTIFY_PLAYLIST_SUCCESS":
    case "CREATE_SPOTIFY_SLUGIFY_DIRECT_PLAYLIST_SUCCESS":
    case "CREATE_SPOTIFY_SLUGIFY_PLAYLIST_SUCCESS":
      return {
        ...state,
        myPlaylists: [
          ...state.myPlaylists.filter((item) => item.id !== action.payload.id),
          action.payload
        ]
      };
    case "CHECK_SPOTIFY_CONNECTED_STATUS_SUCCESS":
    case SpotifyActionType.SET_SPOTIFY_CONNECTED_STATUS:
      return {
        ...state,
        isConnected: action.payload
      };
    case "DELETE_CURRENT_SPOTIFY_SEARCH_RESULTS":
      return {
        ...state,
        myPlaylists: [...state.myPlaylists],
        trackSearch: {}
      };
    case "GET_SPOTIFY_CLIENT_CREDENTIALS_TOKEN_DATA_TO_USER_SUCCESS":
      return {
        ...state,
        clientCredentialsTokenData: {
          ...state.clientCredentialsTokenData,
          ...action.payload
        }
      };
    case "GET_SPOTIFY_CURRENT_USER_PLAYLISTS_SUCCESS":
      return {
        ...state,
        myPlaylists: [...action.payload]
      };
    case "GET_SPOTIFY_TRACK_SEARCH_RESULTS_SUCCESS":
      return {
        ...state,
        trackSearch: { ...action.payload }
      };
    case "REMOVE_SPOTIFY_DATA":
      return {
        ...initialState,
        clientCredentialsTokenData: state.clientCredentialsTokenData
      };
    default:
      return state;
  }
};
