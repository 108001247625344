import { useContext, useMemo, useState } from "react";
import { deleteFriend } from "../actions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useHistory } from "react-router-dom";
import appleMusicIcon from "../images/icon_apple_music_new_red.png";
import spotifyIcon from "../images/Spotify_Icon_RGB_Green.png";
import youTubeIcon from "../images/yt_icon_rgb.png";
import { Friend } from "../types";
import { ThemeContext } from "../contexts/ColorThemeProvider";

interface Props {
  friend: Friend;
}
export const FriendListItem = ({ friend }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const addDeleteFriendStatus = useAppSelector(
    (state) => state.slugify.addDeleteFriendStatus
  );

  const { isDark } = useContext(ThemeContext);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isDeletePending, setIsDeletePending] = useState(false);

  const { icon, altText } = useMemo(() => {
    let icon, altText;
    if (friend.primaryMusicStream === "appleMusic") {
      icon = appleMusicIcon;
      altText = "Apple Music";
    } else if (friend.primaryMusicStream === "spotify") {
      icon = spotifyIcon;
      altText = "Spotify";
    } else if (friend.primaryMusicStream === "youtube") {
      icon = youTubeIcon;
      altText = "YouTube";
    }
    return { icon, altText };
  }, [friend]);

  return (
    <li className="list-group-item d-flex justify-content-between align-items-center animate__animated animate__fadeIn">
      {isDeletePending ? null : (
        <div
          className="d-flex align-items-center animate__animated animate__fadeIn"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/friend-details", { friend });
          }}
        >
          <div>
            <img alt={altText} src={icon} height="auto" width="36px" />
          </div>
          <span className="lead ps-3" style={{ fontSize: "18px" }}>
            {friend.username}
          </span>
        </div>
      )}
      {isSpinning ? (
        <div className="d-flex align-items-center justify-content-center w-100 animate__animated animate__fadeIn">
          <span className="me-auto">{addDeleteFriendStatus}</span>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : !isDeletePending ? (
        <span
          role="img"
          aria-label="Trash can"
          onClick={() => setIsDeletePending(true)}
          style={{ fontSize: "24px", cursor: "pointer" }}
        >
          🗑️
        </span>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center w-100 animate__animated animate__slideInRight"
          style={{ height: 36 }}
        >
          <span className="text-danger">Are you sure?</span>
          <button
            className={`btn btn-sm btn-${
              isDark ? "outline-danger" : "danger"
            } ms-3`}
            onClick={async () => {
              try {
                setIsSpinning(true);
                await dispatch(deleteFriend(friend.id, friend.username));
              } catch (error) {
                console.warn(error);
                setIsSpinning(false);
                setIsDeletePending(false);
              }
            }}
          >
            Yes
          </button>
          <button
            className={`btn btn-sm btn-${
              isDark ? "outline-primary" : "primary"
            } ms-3`}
            onClick={() => setIsDeletePending(false)}
          >
            No
          </button>
        </div>
      )}
    </li>
  );
};
