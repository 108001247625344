import { useState } from "react";
import { useDispatch } from "react-redux";
import disconnectImage from "../images/remove_slugbucket_from_spotify_640.jpg";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { updateSlugifyUser } from "../actions";
import { SpotifyActionType } from "../types";

const DisconnectSpotifyModal = () => {
  const dispatch = useDispatch();
  const [spinning, setSpinning] = useState(false);
  const handleDisconnectSpotify = async () => {
    try {
      setSpinning(true);
      await dispatch(
        updateSlugifyUser({ spotifyTokenData: {}, spotifyUserData: {} })
      );
      await dispatch({ type: "REMOVE_SPOTIFY_DATA" });
      dispatch({
        type: SpotifyActionType.SET_SPOTIFY_CONNECTED_STATUS,
        payload: false
      });
      $("#disconnect-spotify-modal").modal("hide");
      window.open("https://www.spotify.com/account/apps");
      // history.push("/home");
      setSpinning(false);
    } catch (error) {
      console.warn(error);
      setSpinning(false);
    }
  };
  return (
    <div className="modal fade" id="disconnect-spotify-modal">
      <div
        className="modal-dialog modal-dialog-scrollable modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Disconnect Spotify</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="d-flex flex-column align-items-center modal-body">
            <div>
              <h5>
                <span role="img" aria-label="unplug Spotify">
                  🔌
                </span>{" "}
                To disconnect Spotify:
              </h5>
              <ol>
                <li>
                  Click the <strong>Go to Spotify</strong> button below. This
                  will remove all Spotify data from Slugify open your Spotify
                  account in a new browser tab.
                </li>
                <li>
                  This will also open your Spotify account in a new browser tab.
                </li>
                <li className="d-none d-md-block">
                  You should be in the <strong>Apps</strong> section and see{" "}
                  <strong>Apps with access to your Spotify information</strong>{" "}
                  like in the picture below.
                </li>
                <li className="d-block d-md-none">
                  You should be in the <strong>Apps</strong> section and see{" "}
                  <strong>Apps with access to your Spotify information</strong>.
                </li>
                <li>
                  Locate <strong>Slug Bucket</strong> in the area on the right,
                  and click <strong>Remove Access</strong>.
                </li>
                <li>
                  You should see the message:{" "}
                  <strong>Successfully revoked access for application</strong>.
                </li>
                <li>
                  You can now close this tab, if you want, and/or return to
                  Slugify unplugged.
                </li>
              </ol>
            </div>
            <img
              alt="How to disconnect from Spotify"
              className="d-none d-md-block text-center"
              src={disconnectImage}
              height="440px"
              width="640px"
            />
          </div>
          <div className="d-flex justify-content-between modal-footer">
            <button
              type="button"
              className="btn btn-link text-body-secondary text-decoration-none"
              data-bs-dismiss="modal"
            >
              close
            </button>
            <a
              href="http://spotify.com/account/apps/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="d-flex align-items-center btn btn-success"
                onClick={() => handleDisconnectSpotify()}
              >
                {spinning ? (
                  <div
                    className="spinner-grow spinner-grow-sm text-light me-3"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <i className="icon-launch slug-icon me-2" />
                )}
                Go to Spotify
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(DisconnectSpotifyModal);
