// eslint-disable jsx-a11y/anchor-is-valid
import { useContext, useEffect, useMemo, useState } from "react";
import { CreateMatchedPlaylistModal } from "./CreateMatchedPlaylistModal";
import { find, omit } from "lodash";
import { getPrimaryMusicStreamName, getTitleCase } from "../functions";
import {
  getTracksFromAppleMusicPlaylist,
  getTracksFromSpotifyPlaylist,
  getAppleMusicDevToken,
  createAppleMusicPlaylistOnSpotify,
  createSpotifyPlaylistOnAppleMusic,
  updateSlugifyUser,
  getSlugifyUser
} from "../actions";
import { AutoSlurpFriend, AutoSlurpFriendPlaylist } from "../types";
import { Spinner } from "./shared/Spinner";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { useAppDispatch, useAppSelector, useWindowSize } from "../hooks";
import { useLocation, useHistory } from "react-router-dom";
import $ from "jquery";
import SlugifyAppleMusicItem from "./SlugifyAppleMusicItem";
import SlugifySpotifyMusicItem from "./SlugifySpotifyMusicItem";
import StatusMessage from "./shared/StatusMessage";

const isDev = process.env.NODE_ENV === "development";

export const MyFriendsSharedPlaylistTracks = () => {
  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);
  const location = useLocation();
  const history = useHistory();
  const [, width] = useWindowSize();
  const {
    auth: { userId },
    newUser: { autoSlurpFriends, myFriendsSharedPlaylists, primaryMusicStream },
    spotify: { myPlaylists: mySpotifyPlaylists }
  } = useAppSelector((state) => state);

  if (isDev) {
    console.log("%cFix this type on location.state", "color:orange");
  }
  const { friendUsername, playlist } = location.state as any;

  if (isDev) {
    console.log("%cFix this type on myFriendsSharedPlaylists", "color:orange");
  }
  const playlists = myFriendsSharedPlaylists.filter((item: any) => {
    return item.owner === friendUsername;
  });
  if (isDev) {
    console.log("%cPut modal data type in the right place", "color:orange");
  }
  type ModalData =
    | {
        result: string;
        deletedTracks: number;
        addedTracks: number;
      }
    | {
        playlist: any;
        unmatchedTrackCount: number;
      }
    | { error: string };
  if (isDev) {
    console.log("%cFix this type for autoslurp friend", "color:orange");
  }
  const [autoSlurpFriend, setAutoSlurpFriend] = useState<AutoSlurpFriend>();
  const [error, setError] = useState("");
  if (isDev) {
    console.log("%cFix this type for tracks", "color:orange");
  }
  const [tracks, setTracks] = useState<any[]>([]);
  const [modalData, setModalData] = useState<ModalData>();
  const [isFetchingTracks, setIsFetchingTracks] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isSynching, setIsSynching] = useState(false);

  useEffect(() => {
    async function init() {
      try {
        setError("");
        setIsFetchingTracks(true);
        if (primaryMusicStream === "appleMusic") {
          const tracks = await dispatch(
            getTracksFromSpotifyPlaylist(playlist.id)
          );
          if (isDev) {
            console.log("%cTracks", "color:cyan", tracks);
          }
          setTracks(tracks);
          setIsFetchingTracks(false);
        } else {
          dispatch(getAppleMusicDevToken()).then((token) => {
            dispatch(getTracksFromAppleMusicPlaylist(playlist.id, token)).then(
              (tracks) => {
                setTracks(tracks);
                setIsFetchingTracks(false);
              }
            );
          });
        }
      } catch (error: any) {
        setIsFetchingTracks(false);
        if (isDev) {
          console.warn(
            "Could not fetch tracks from playlist!",
            error?.response || error
          );
        } else {
          console.log(
            "%c😿 Could not fetch tracks from playlist!",
            "color:yellow"
          );
        }
        if (error?.response?.status === 404) {
          setError("Your friend is longer sharing this playlist");
        } else {
          setError(
            error?.response?.data?.error?.message || "Something bad happened"
          );
        }
      }
    }
    init();
  }, [dispatch, playlist.id, primaryMusicStream]);

  useEffect(() => {
    async function doAutoSlurp() {
      try {
        const data = await dispatch(getSlugifyUser(userId));
        const friendAutoSlurpData = find(
          data.autoSlurpFriends,
          (item) => item.username === friendUsername
        );
        if (friendAutoSlurpData && friendAutoSlurpData.username) {
          setAutoSlurpFriend(friendAutoSlurpData);
        } else {
          setAutoSlurpFriend(undefined);
        }
      } catch (error) {
        console.warn("Error in init", error);
      }
    }
    doAutoSlurp();
  }, [autoSlurpFriends, dispatch, friendUsername, userId]);

  const isAutoSlurpEnabledForFriend = useMemo(() => {
    if (autoSlurpFriend) {
      return true;
    } else {
      return false;
    }
  }, [autoSlurpFriend]);

  const { existingSpotifyPlaylist, isPlaylistOnSpotify } = useMemo(() => {
    let existingSpotifyPlaylist = undefined;
    let isPlaylistOnSpotify = false;
    const matches = mySpotifyPlaylists.filter((item) => {
      return item.name === playlist.name;
    });
    if (matches && matches.length && matches.length === 1) {
      const { name, id } = matches[0];
      isPlaylistOnSpotify = true;
      existingSpotifyPlaylist = { name, id };
    } else if (matches && matches.length > 1) {
      isPlaylistOnSpotify = false;
      existingSpotifyPlaylist = undefined;
      if (isDev) {
        console.warn("User has more than one copy of playlist on Spotify!");
      }
    }
    return { isPlaylistOnSpotify, existingSpotifyPlaylist };
  }, [mySpotifyPlaylists, playlist]);

  // useEffect(() => {
  //   const existingSpotifyPlaylist = mySpotifyPlaylists.filter((item) => {
  //     return (
  //       item.name === playlist.name
  //       // item.description ===
  //       //   `Created by ${getTitleCase(friendUsername)} via Slugify!`
  //     );
  //   });
  //   if (
  //     existingSpotifyPlaylist &&
  //     existingSpotifyPlaylist.length &&
  //     existingSpotifyPlaylist.length === 1
  //   ) {
  //     // do something
  //     const { name, id } = existingSpotifyPlaylist[0];
  //     setIsPlaylistOnSpotify(true);
  //     setExistingSpotifyPlaylist({ name, id });
  //   } else if (existingSpotifyPlaylist && existingSpotifyPlaylist.length > 1) {
  //     if (isDev) {
  //       console.warn("User has more than one copy of playlist on Spotify!");
  //     }
  //   }
  // }, [mySpotifyPlaylists, playlist]);

  const isAutoSlurpEnabledForPlaylist = useMemo(() => {
    if (isPlaylistOnSpotify) {
      if (
        autoSlurpFriend &&
        autoSlurpFriend.playlists &&
        autoSlurpFriend.playlists.length
      ) {
        const friendPlaylist = autoSlurpFriend.playlists.filter(
          (item: {
            slurpFromPlaylist: AutoSlurpFriendPlaylist;
            slurpToPlaylist: AutoSlurpFriendPlaylist;
          }) => {
            return item.slurpToPlaylist.id === existingSpotifyPlaylist?.id;
          }
        );
        if (friendPlaylist.length) {
          return true;
        } else {
          return false;
        }
      }
    }
  }, [
    autoSlurpFriend,
    existingSpotifyPlaylist?.id,
    friendUsername,
    isPlaylistOnSpotify
  ]);

  // useEffect(() => {
  //   if (isPlaylistOnSpotify) {
  //     if (
  //       autoSlurpFriend &&
  //       autoSlurpFriend.playlists &&
  //       autoSlurpFriend.playlists.length
  //     ) {
  //       const friendPlaylist = autoSlurpFriend.playlists.filter(
  //         (item: {
  //           slurpFromPlaylist: AutoSlurpFriendPlaylist;
  //           slurpToPlaylist: AutoSlurpFriendPlaylist;
  //         }) => {
  //           return item.slurpToPlaylist.id === existingSpotifyPlaylist?.id;
  //         }
  //       );
  //       if (friendPlaylist.length) {
  //         setIsAutoSlurpEnabledForPlaylist(true);
  //       } else {
  //         setIsAutoSlurpEnabledForPlaylist(false);
  //       }
  //     }
  //   }
  // }, [
  //   autoSlurpFriend,
  //   existingSpotifyPlaylist?.id,
  //   friendUsername,
  //   isPlaylistOnSpotify
  // ]);

  const handleAutoSlurpToggle = async () => {
    try {
      setIsSpinning(true);
      let updatedPlaylists = [];
      // let updatedPlaylists: {
      //   slurpFromPlaylist: AutoSlurpFriendPlaylist;
      //   slurpToPlaylist: AutoSlurpFriendPlaylist;
      // } | null = null;
      if (
        autoSlurpFriend &&
        autoSlurpFriend.playlists &&
        autoSlurpFriend.playlists.length
      ) {
        if (isAutoSlurpEnabledForPlaylist) {
          if (isDev) {
            console.log("%cRemoving playlist from AutoSlurp", "color:cyan");
          }
          // remove playlist from autoslurp friends playlists
          updatedPlaylists = [
            ...autoSlurpFriend.playlists.filter(
              (item: {
                slurpFromPlaylist: AutoSlurpFriendPlaylist;
                slurpToPlaylist: AutoSlurpFriendPlaylist;
              }) => {
                return item.slurpFromPlaylist.id !== playlist.id;
              }
            )
          ];
        } else {
          // add playlist too autoslurp friends playlists
          if (isDev) {
            console.log("%cAdding playlist to AutoSlurp", "color:cyan");
          }
          updatedPlaylists = [
            ...autoSlurpFriend.playlists.filter(
              (item: {
                slurpFromPlaylist: AutoSlurpFriendPlaylist;
                slurpToPlaylist: AutoSlurpFriendPlaylist;
              }) => item.slurpFromPlaylist.id !== playlist.id
            ),
            {
              slurpToPlaylist: {
                name: existingSpotifyPlaylist?.name,
                id: existingSpotifyPlaylist?.id
              },
              slurpFromPlaylist: {
                name: playlist.name,
                id: playlist.id
              }
            }
          ];
        }
        const updatedAutoSlurpFriend = omit(
          autoSlurpFriend,
          "playlists"
        ) as AutoSlurpFriend;
        if (isDev) {
          console.log(
            "%cFix this any type for updatedPlaylists playlists",
            "color:orange"
          );
        }
        updatedAutoSlurpFriend.playlists = updatedPlaylists as any;
        await dispatch(
          updateSlugifyUser({
            autoSlurpFriends: [
              ...autoSlurpFriends.filter(
                (item: AutoSlurpFriend) => item.username !== friendUsername
              ),
              updatedAutoSlurpFriend
            ]
          })
        );
        setIsSpinning(false);
      } else {
        // might need something here!
      }
    } catch (error) {
      setIsSpinning(false);
      console.warn(error);
    }
  };

  const handleCreatePlaylist = async () => {
    try {
      setIsSynching(true);
      if (primaryMusicStream === "appleMusic") {
        const response = await dispatch(
          createSpotifyPlaylistOnAppleMusic(
            friendUsername,
            playlist.name,
            tracks
          )
        );
        setModalData(response);
      } else {
        if (isDev) {
          console.log(
            "%cFix this type for create playlist response",
            "color:orange"
          );
        }
        const response: any = await dispatch(
          createAppleMusicPlaylistOnSpotify(
            friendUsername,
            playlist.name,
            tracks
          )
        );
        setModalData(response);
      }
      setIsSynching(false);
      $("#create-matched-playlist-modal").modal("show");
    } catch (error: any) {
      setIsSynching(false);
      setModalData({ error: error.message });
      console.warn("Could not handleCreatePlaylist", error);
    }
  };

  const renderTracks = () => {
    return tracks.map((item) => {
      if (primaryMusicStream === "appleMusic") {
        return <SlugifySpotifyMusicItem key={item.track.id} item={item} />;
      } else {
        return (
          <SlugifyAppleMusicItem key={item.id} item={item} showButton={false} />
        );
      }
    });
  };

  return playlists.length ? (
    <>
      <CreateMatchedPlaylistModal response={modalData} playlist={playlist} />
      <div
        className="container d-flex flex-column align-items-center w-100 mt-3 mt-sm-5 animate__animated animate__fadeIn"
        style={{ maxWidth: 800 }}
      >
        <div className="slug-title text-body-secondary text-center">
          {playlist.name}
        </div>
        <div className="slug-subtitle text-body-secondary text-center">
          by {getTitleCase(friendUsername)}
        </div>
        {isFetchingTracks && (
          <div className="my-4 animate__animated animate__fadeIn">
            <Spinner size={64} color="muted" type="moon" />
          </div>
        )}
        {error && !isFetchingTracks && (
          <StatusMessage
            title="The Sadness..."
            emoticon="🥀"
            subtitle={error}
            size="sm"
          />
        )}
        {tracks.length > 0 && !error && !isFetchingTracks && (
          <ul className="list-group w-100 mt-3">{renderTracks()}</ul>
        )}
        {tracks.length === 0 && !error && !isFetchingTracks && (
          <StatusMessage
            title="The Emptiness..."
            emoticon="🥀"
            subtitle="This playlist has no tracks"
            size="sm"
          />
        )}
        {tracks.length ? (
          <>
            {primaryMusicStream === "spotify" &&
            isPlaylistOnSpotify &&
            isAutoSlurpEnabledForFriend ? (
              <button
                className={`d-flex justify-content-center align-items-center btn btn-block btn-${
                  isAutoSlurpEnabledForPlaylist ? "danger" : "light"
                } animate__animated animate__fadeIn mt-2`}
                onClick={() => {
                  handleAutoSlurpToggle();
                }}
                style={{ maxWidth: 800 }}
                title={`${
                  isAutoSlurpEnabledForPlaylist
                    ? "Disable AutoSlurp"
                    : "Enable AutoSlurp"
                }`}
              >
                <span
                  className={`animate__animated animate__${
                    isSpinning ? "slideOutLeft" : "slideInRight"
                  }`}
                  role="img"
                  aria-label="auto rickshaw"
                >
                  🛺
                </span>
                <span className="ms-2">
                  {`${
                    isAutoSlurpEnabledForPlaylist ? "Disable" : "Enable"
                  } AutoSlurp ${width < 576 ? "" : "for " + playlist.name}`}
                </span>
              </button>
            ) : null}
            <button
              className={`d-flex justify-content-center align-items-center btn btn-sm btn-${
                isDark ? "outline-primary" : "primary"
              } animate__animated animate__fadeIn mt-2 w-100 `}
              onClick={() => {
                handleCreatePlaylist();
              }}
              style={{ maxWidth: 800 }}
              title="Create playlist"
            >
              {isSynching ? (
                <div>
                  <Spinner size={24} color="light" type="puff" />
                </div>
              ) : (
                <span role="img" aria-label="sparkles">
                  ✨
                </span>
              )}
              <span className="ms-2">
                {`Sync ${
                  width < 576 ? "" : playlist.name
                } to ${getPrimaryMusicStreamName(primaryMusicStream)}`}
              </span>
            </button>
          </>
        ) : null}
        <button
          className="btn btn-link text-body-secondary mt-3 mt-sm-4 mb-3 text-decoration-none"
          onClick={() => history.goBack()}
        >
          back
        </button>
      </div>
    </>
  ) : null;
};
