import { useAppSelector } from "../hooks";

export default function SlugifySlurpProgressBar() {
  const { slurpCounterCurrent: current, slurpCounterTarget: target } =
    useAppSelector((state) => state.slugify);
  if (target !== 0) {
    const now = (current / target) * 100;
    // console.log("%cNOW:", "color:hotpink", now);
    return (
      <div
        className="progress w-100"
        role="progressbar"
        aria-label="Slurp Progress"
        aria-valuenow={now}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          style={{ width: `${now}%` }}
        ></div>
      </div>
    );
  }
  return null;
}
