import { find, isEmpty } from "lodash";
import { AppThunk } from "../store/store";
import {
  Friend,
  SlugifyActionType,
  YouTubeActionType,
  YouTubePlaylist,
  YouTubeSearchObject
} from "../types";
import { sleep } from "../functions";

const isDev = process.env.NODE_ENV === "development";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const YOUTUBE_API_KEY = process.env.REACT_APP_SLUGIFY_YOUTUBE_API_KEY;
const DISCOVERY_DOCS =
  "https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest";
const SCOPE = "https://www.googleapis.com/auth/youtube";

export const createYouTubeUserDataPayload =
  (): AppThunk<Promise<any>> => async (dispatch, getState) => {
    try {
      dispatch({ type: YouTubeActionType.CREATE_YOUTUBE_USER_DATA_START });
      const {
        newUser: { youTubeUserData },
        youtube: { channel, myPlaylists, user }
      } = getState();

      if (isDev) {
        console.log("%cMy YouTube playlists", "color:orange", myPlaylists);
      }
      const mySlugifyPlaylist: YouTubePlaylist | undefined = myPlaylists.find(
        (item: YouTubePlaylist) =>
          item.name === "Slugify" && item.privacyStatus === "public"
      );
      const myVideoDestinationPlaylist: YouTubePlaylist | undefined =
        myPlaylists.find(
          (item: YouTubePlaylist) => item.name === "SlugTube Videos"
        );
      const payload = {
        ytChannelId: channel.id || youTubeUserData.ytChannelId,
        ytPlayerPreference: youTubeUserData.ytPlayerPreference || "youtube",
        familyName: user.familyName || youTubeUserData.familyName,
        givenName: user.givenName || youTubeUserData.givenName,
        slugifyPlaylistId:
          mySlugifyPlaylist?.id || youTubeUserData.slugifyPlaylistId || null,
        youtubeDestinationPlaylist:
          myVideoDestinationPlaylist?.id ||
          youTubeUserData.youtubeDestinationPlaylist ||
          null,
        fullName: user.fullName || youTubeUserData.fullName,
        id: user.id || youTubeUserData.id,
        email: user.email
      };
      dispatch({ type: YouTubeActionType.CREATE_YOUTUBE_USER_DATA_SUCCESS });
      return Promise.resolve(payload);
    } catch (error) {
      console.warn("Could not createYouTubeUserDataPayload", error);
      dispatch({
        type: YouTubeActionType.CREATE_YOUTUBE_USER_DATA_FAIL,
        payload: error
      });
      return Promise.reject(error);
    }
  };

export function gapiInit() {
  try {
    if (!CLIENT_ID) {
      throw new Error("No Google Client ID!");
    }
    if (!gapi) {
      throw new Error("No gapi!");
    }

    if (isDev) {
      console.log("%cInitializing gapi client...", "color:cyan");
      console.log("%cNeed to migrate to Google identity!", "color:orange");
    }
    // google.accounts.id.initialize({
    //   client_id: CLIENT_ID,
    //   callback: (data) => {
    //     console.log("DATA", data);
    //   },
    //   auto_select: true
    // });
    // https://developers.google.com/identity/gsi/web/guides/migration
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ client_id: CLIENT_ID });
    });
    if (isDev) {
      console.log("%cgapi client initialized!", "color:lime");
    }
  } catch (error) {
    console.warn("Error in gapiInit", error);
  }
}

export const gapiAuthenticate =
  (): AppThunk<Promise<any>> => async (dispatch) => {
    try {
      dispatch({ type: YouTubeActionType.GAPI_AUTHENTICATE_START });
      if (!SCOPE) {
        throw new Error("No scope configured!");
      }
      const auth2 = gapi.auth2.getAuthInstance();
      await auth2.signIn({ scope: SCOPE });
      dispatch({ type: YouTubeActionType.GAPI_AUTHENTICATE_SUCCESS });
      return Promise.resolve();
    } catch (error) {
      console.warn("Error in gapiAuthenticate", error);
      dispatch({
        type: YouTubeActionType.GAPI_AUTHENTICATE_FAIL,
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const gapiLoadClient =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    try {
      dispatch({ type: YouTubeActionType.GAPI_LOAD_CLIENT_START });
      if (!YOUTUBE_API_KEY) {
        throw new Error("No YouTube API Key configured!");
      }
      if (!DISCOVERY_DOCS) {
        throw new Error("No discovery docs configured!");
      }
      if (!gapi) {
        throw new Error("No gapi!");
      }
      if (!gapi.client) {
        throw new Error("No gapi client!");
      }
      gapi.client.setApiKey(YOUTUBE_API_KEY);
      await gapi.client.load(DISCOVERY_DOCS);
      if (isDev) {
        console.log("%cGAPI client loaded", "color:lime");
      }
      dispatch({ type: YouTubeActionType.GAPI_LOAD_CLIENT_SUCCESS });
      return Promise.resolve();
    } catch (error) {
      console.warn("Error in gapiLoadClient", error);
      dispatch({
        type: YouTubeActionType.GAPI_LOAD_CLIENT_FAIL,
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const getMyYouTubeChannel =
  (): AppThunk<Promise<any>> => async (dispatch, getState) => {
    try {
      dispatch({ type: YouTubeActionType.GET_MY_YOUTUBE_CHANNEL_START });
      const { user } = getState().youtube;
      if (!user?.id) {
        if (isDev) {
          console.log(
            "%cUser is not signed in with YouTube scope.  Skipping channel fetch!",
            "color:yellow"
          );
        }
        return Promise.resolve(null);
      }
      if (!YOUTUBE_API_KEY) {
        throw new Error("No YouTube API Key configured!");
      }
      if (!DISCOVERY_DOCS) {
        throw new Error("No discovery docs configured!");
      }
      if (!gapi) {
        throw new Error("No gapi!");
      }
      if (!gapi.client) {
        throw new Error("No gapi client!");
      }
      if (!gapi.client.youtube) {
        if (isDev) {
          console.log("%cNo YouTube!", "color:yellow");
        }
        return Promise.resolve(null);
      }
      const resp = await gapi.client.youtube.channels.list({
        part: ["snippet,contentDetails,statistics"],
        mine: true
      });
      const parsed = JSON.parse(resp.body);
      const payload =
        parsed.items && parsed.items.length ? parsed.items[0] : null;
      if (payload) {
        if (isDev) {
          console.log("%cUser has a YouTube channel", "color:lime", payload);
        }
      } else {
        if (isDev) {
          console.log("%cUser has no YouTube channel!", "color:yellow");
        }
      }
      dispatch({
        type: YouTubeActionType.GET_MY_YOUTUBE_CHANNEL_SUCCESS,
        payload
      });
      return Promise.resolve(payload);
    } catch (error) {
      console.warn("Error fetching YouTube channel", error);
      dispatch({
        type: YouTubeActionType.GET_MY_YOUTUBE_CHANNEL_FAIL,
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const initYouTubeApi =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    try {
      dispatch({ type: YouTubeActionType.INIT_GAPI_START });
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Yodeling at Youtube..."
      });
      gapiInit();
      dispatch({ type: YouTubeActionType.INIT_GAPI_SUCCESS });
      // if (!CLIENT_ID) throw new Error("No Google Client ID");
      // if (!SCOPE) throw new Error("No scope configured");
      // dispatch({ type: "INIT_YOUTUBE_API_START" });
      // dispatch({
      //   type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
      //   payload: "Yodeling at Youtube..."
      // });
      // client = google.accounts.oauth2.initTokenClient({
      //   client_id: CLIENT_ID,
      //   scope: SCOPE,
      //   callback: (tokenResponse: any) => {
      //     console.log("TOKEN RESPONSE", tokenResponse);
      //     access_token = tokenResponse.access_token;
      //     console.log(
      //       "hasaccess",
      //       google.accounts.oauth2.hasGrantedAllScopes(
      //         tokenResponse,
      //         "https://www.googleapis.com/auth/youtube"
      //       )
      //     );
      //   }
      // });
      // google.accounts.id.initialize({
      //   callback: handleCallbackResponse,
      //   client_id: CLIENT_ID
      //   // scope: SCOPE
      // });
    } catch (error) {
      console.warn(error);
      dispatch({ type: YouTubeActionType.INIT_GAPI_FAIL, payload: error });
    }
  };

// I can't get this to work.
// export const addTracksToYouTubePlaylistBad =
//   (playlistId: string, videoIds?: string[]): AppThunk<Promise<any[]>> =>
//   async (dispatch) => {
//     try {
//       dispatch({
//         type: YouTubeActionType.ADD_TRACKS_TO_YOUTUBE_PLAYLIST_START
//       });
//       if (!videoIds) {
//         return Promise.resolve([]);
//       }

//       dispatch({
//         type: SlugifyActionType.SET_SLURP_COUNTER,
//         payload: { current: 0, target: videoIds.length }
//       });

//       const batchRequests: any[] = [];

//       for (let i = 0; i < videoIds.length; i++) {
//         const details = {
//           part: "snippet",
//           resource: {
//             snippet: {
//               playlistId: playlistId,
//               position: i,
//               resourceId: {
//                 kind: "youtube#video",
//                 videoId: videoIds[i]
//               }
//             }
//           }
//         };

//         const request = gapi.client.youtube.playlistItems.insert(details);
//         batchRequests.push(request);
//       }

//       const batch = gapi.client.newBatch();

//       for (let i = 0; i < batchRequests.length; i++) {
//         const request = batchRequests[i];
//         batch.add(request, {
//           id: i.toString(),
//           callback: (
//             individualResponse: gapi.client.Response<any>,
//             rawBatchResponse: string
//           ) => {
//             console.log("raw", rawBatchResponse);
//             console.log(
//               `Request ${i} completed with status ${individualResponse.status}.`
//             );
//           }
//         });
//       }

//       const response = batch.execute(
//         (responseMap: { [id: string]: gapi.client.Response<any> }) => {
//           console.log("fucking response map", responseMap);
//           console.log(
//             `Batch request completed with ${
//               Object.keys(responseMap).length
//             } responses.`
//           );
//         }
//       );
//       console.log(`Added ${videoIds.length} videos to playlist ${playlistId}.`);

//       dispatch({
//         type: YouTubeActionType.ADD_TRACKS_TO_YOUTUBE_PLAYLIST_SUCCESS
//       });
//       return Promise.resolve([]);
//     } catch (error) {
//       dispatch({
//         type: YouTubeActionType.ADD_TRACKS_TO_YOUTUBE_PLAYLIST_FAIL,
//         payload: error
//       });
//       return Promise.reject(error);
//     }
//   };

export const addTracksToYouTubePlaylist =
  (playlistId: string, videoIds?: string[]): AppThunk<Promise<any[]>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: YouTubeActionType.ADD_TRACKS_TO_YOUTUBE_PLAYLIST_START
      });
      if (!videoIds) {
        return Promise.resolve([]);
      }
      const responses: any[] = [];
      dispatch({
        type: SlugifyActionType.SET_SLURP_COUNTER,
        payload: { current: 0, target: videoIds.length }
      });
      for (let i = 0; i < videoIds.length; i++) {
        try {
          const resp = await gapi.client.youtube.playlistItems.insert({
            part: "snippet",
            resource: {
              snippet: {
                playlistId: playlistId,
                position: i,
                resourceId: {
                  kind: "youtube#video",
                  videoId: videoIds[i]
                }
              }
            }
          });
          responses.push(resp.result);
          dispatch({
            type: SlugifyActionType.SET_SLURP_COUNTER,
            payload: { current: i + 1, target: videoIds.length }
          });
          sleep(500);
        } catch (error) {
          console.error("Error inserting video:", error);
          throw error;
        }
      }
      dispatch({
        type: YouTubeActionType.ADD_TRACKS_TO_YOUTUBE_PLAYLIST_SUCCESS
      });
      if (isDev) {
        console.log(
          `%c${responses.length} videos added to ${playlistId}`,
          "color:lime"
        );
      }
      return Promise.resolve(responses);
    } catch (error) {
      dispatch({
        type: YouTubeActionType.ADD_TRACKS_TO_YOUTUBE_PLAYLIST_FAIL,
        payload: error
      });
      return Promise.reject(error);
    }
  };
export const createNewYouTubePlaylist =
  (playlistName: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch({ type: YouTubeActionType.CREATE_NEW_YOUTUBE_PLAYLIST_START });
    try {
      const client = window.gapi.client;
      const response = await client.youtube.playlists.insert({
        part: "snippet,status",
        snippet: {
          title: playlistName,
          description: "Created by Slugify!"
        },
        status: {
          privacyStatus: "private"
        }
      });
      const payload = {
        id: response.result.id,
        name: response.result.snippet.title,
        privacyStatus: response.result.status.privacyStatus
      };
      dispatch({
        type: YouTubeActionType.CREATE_NEW_YOUTUBE_PLAYLIST_SUCCESS,
        payload
      });
      console.log("🌟 New playlist created on YouTube", payload);
      return Promise.resolve();
    } catch (error) {
      dispatch({
        type: YouTubeActionType.CREATE_NEW_YOUTUBE_PLAYLIST_FAIL,
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const getVideosFromYouTubePlaylist =
  (playlistId: string): AppThunk<Promise<gapi.client.youtube.PlaylistItem[]>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: YouTubeActionType.GET_TRACKS_FROM_YOUTUBE_PLAYLIST_START
      });
      const items: gapi.client.youtube.PlaylistItem[] = [];
      let nextPageToken: string | undefined = undefined;
      do {
        if (isDev) {
          console.log(
            "%cFix this type for playlist items response",
            "color:orange"
          );
        }
        const response: gapi.client.Response<gapi.client.youtube.PlaylistItemListResponse> =
          await gapi.client.youtube.playlistItems.list({
            part: "snippet",
            playlistId,
            maxResults: 50,
            pageToken: nextPageToken
          });

        const pageItems = response.result.items || [];
        items.push(...pageItems);

        nextPageToken = response.result.nextPageToken;
      } while (nextPageToken);

      dispatch({
        type: YouTubeActionType.GET_TRACKS_FROM_YOUTUBE_PLAYLIST_SUCCESS
      });
      if (isDev) {
        console.log(
          `%c${items.length} track(s) fetched from YouTube playlist: ${playlistId}`,
          "color:lime"
        );
      }
      return Promise.resolve(items);
    } catch (error) {
      console.warn(error);
      dispatch({
        type: YouTubeActionType.GET_TRACKS_FROM_YOUTUBE_PLAYLIST_FAIL,
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const getYouTubeVideosById = async (ids: string[]) => {
  try {
    const videoIds = ids.join(",");
    const response = await gapi.client.youtube.videos.list({
      part: "snippet,player",
      id: videoIds
    });
    return Promise.resolve(response.result);
  } catch (error) {
    console.warn(error);
    return Promise.reject(error);
  }
};

export const getYouTubeVideos =
  (
    searchArray: any
  ): AppThunk<Promise<gapi.client.youtube.Video[] | undefined>> =>
  async (dispatch) => {
    const searchForYouTubeVideos = async (searchArray: any) => {
      try {
        const pending = searchArray.map((item: any) =>
          searchForYouTubeVideo(item)
        );
        const found = await Promise.all(pending);
        return Promise.resolve(found);
      } catch (error) {
        console.warn(error);
        return Promise.reject(error);
      }
    };

    async function getYtChannelForArtist(searchObject: YouTubeSearchObject) {
      try {
        if (!searchObject) {
          return Promise.resolve(null);
        }
        const { artist: artistName } = searchObject;
        const response = await gapi.client.youtube.search.list({
          part: "snippet",
          q: artistName,
          type: "channel",
          // this throws an "invalid argument error"
          // videoCategoryId: "10",
          maxResults: 50,
          topicId: "/m/04rlf"
        });

        const channels = response.result.items;
        if (!channels) {
          return Promise.resolve(null);
        }
        if (isDev) {
          console.log(
            "%cOnly returning first match of Channel that includes '- Topic'",
            "color:yellow"
          );
        }
        const musicChannels = channels.filter((channel) =>
          // this results in zero matches
          // channel.snippet?.liveBroadcastContent === "none" &&
          channel.snippet?.title?.includes("- Topic")
        );
        if (musicChannels.length) {
          return musicChannels[0].id?.channelId;
        }
        return Promise.resolve(null);
      } catch (error) {
        console.warn("Error in getYtChannelForArtist", error);
      }
    }

    async function searchYtChannelForVideo(
      channelId: string,
      searchObject: YouTubeSearchObject
    ) {
      try {
        if (!searchObject) {
          return Promise.resolve(null);
        }
        const response = await gapi.client.youtube.search.list({
          part: "snippet",
          channelId: channelId,
          q: searchObject.title,
          type: "video"
        });
        if (response.result.items && response.result.items.length > 0) {
          if (isDev) {
            console.log(
              "%cFound matching video:",
              "color:lime",
              response.result.items[0]
            );
          }
          return Promise.resolve(response.result.items[0].id?.videoId);
        } else {
          // If video not found, return null
          return Promise.resolve(null);
        }
      } catch (error) {
        console.warn(error);
        return Promise.reject(error);
      }
    }

    const searchForYouTubeVideo = async (
      searchObject: YouTubeSearchObject
    ): Promise<string | null | undefined> => {
      try {
        if (!searchObject) {
          return Promise.resolve(null);
        }
        const { artist, isrc, title } = searchObject;
        const client = window.gapi.client;
        // find best youtube channel
        // expand if not found
        const channelId = await getYtChannelForArtist(searchObject);
        if (channelId) {
          const videoId = await searchYtChannelForVideo(
            channelId,
            searchObject
          );
          if (videoId) {
            if (isDev) {
              console.log("%cVideo found in Channel:", "color:lime", videoId);
            }
          } else {
            if (isDev) {
              console.log("%cVideo not found in Channel:", "color:yellow");
            }
          }
          return videoId;
        } else {
          if (isDev) {
            console.log(
              "%cNo Channel found for:",
              "color:yellow",
              searchObject.artist
            );
          }
        }
        const response = await gapi.client.youtube.search.list({
          part: "snippet",
          q: isrc
        });
        if (
          response.result.items &&
          response.result.items.length > 0 &&
          response.result.items[0].id?.videoId
        ) {
          return Promise.resolve(response.result.items[0].id.videoId);
        } else {
          if (isDev) {
            console.log(
              `🤷🏻‍♀️ Nothing found searching by ISRC: ${isrc}.  Trying with Artist/Title: ${artist}/${title}`
            );
          }
          const response = await client.youtube.search.list({
            part: "snippet",
            q: `${title} ${artist}`
          });
          if (isDev) {
            console.log("Artist/Title Response", response);
          }
          if (response.result.items.length) {
            const match = find(
              response.result.items,
              (item) => item.snippet.title.toLowerCase() === title.toLowerCase()
            );
            if (match) {
              if (isDev) {
                console.log("🎉 Found exact title match", match);
              }
              // response.result.items = [match];
              return Promise.resolve(match.id.videoId);
            }
            if (isDev) {
              console.log(
                "😒 Returning first item in search results",
                response.result.items[0].id.videoId
              );
            }
            return Promise.resolve(response.result.items[0].id.videoId);
          }
          return Promise.resolve(null);
        }
      } catch (error: any) {
        console.warn(error);
        if (error.result.error.message.includes("exceeded")) {
          return Promise.reject(new Error("🐛 YouTube API quota exceeded!"));
        }
        return Promise.reject(new Error("🐛 " + error.result.error.message));
      }
    };

    try {
      dispatch({ type: "GET_YOUTUBE_VIDEOS_START" });
      const videoIds = await searchForYouTubeVideos(searchArray);
      // const items = flatten(response.map((item) => item.items));
      // const videoIds = items.map((item) => item.id.videoId);
      const videos = await getYouTubeVideosById(videoIds);
      dispatch({ type: "GET_YOUTUBE_VIDEOS_SUCCESS" });
      if (videos) {
        return Promise.resolve(videos.items);
      } else {
        return Promise.resolve([]);
      }
    } catch (error: any) {
      const message = error.response
        ? error.response.data.error.message
        : error.message;
      dispatch({ type: "GET_YOUTUBE_VIDEOS_FAIL", payload: error });
      console.warn(message);
      return Promise.reject(new Error(message));
    }
  };

export const getMyYoutubePlaylists =
  (): AppThunk<Promise<any>> => async (dispatch) => {
    if (isDev) {
      console.log("%c📻 Fetching user's YouTube playlists...", "color:cyan");
    }
    dispatch({ type: YouTubeActionType.GET_MY_YOUTUBE_PLAYLISTS_START });
    dispatch({
      type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
      payload: "Yanking Youtube playlists..."
    });
    try {
      const client = gapi.client;
      const playlistsResponse = await client.youtube.playlists.list({
        part: "snippet,status",
        maxResults: 50,
        mine: true
      });
      const myPlaylists = playlistsResponse.result.items?.map(
        (playlist: any) => {
          return {
            id: playlist.id,
            name: playlist.snippet.title,
            privacyStatus: playlist.status.privacyStatus
          };
        }
      );
      dispatch({
        type: YouTubeActionType.GET_MY_YOUTUBE_PLAYLISTS_SUCCESS,
        payload: myPlaylists || []
      });
      return Promise.resolve(myPlaylists);
    } catch (error) {
      dispatch({
        type: YouTubeActionType.GET_MY_YOUTUBE_PLAYLISTS_FAIL,
        payload: error
      });
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Ah, Houston, we've had a problem...."
      });
      return Promise.reject(error);
    }
  };

export const getFriendsYouTubePlaylists =
  // (): AppThunk<Promise<gapi.client.youtube.SearchResult[] | undefined>> =>


    (): AppThunk<Promise<gapi.client.youtube.Playlist[] | undefined>> =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: YouTubeActionType.GET_ALL_YOUTUBE_PLAYLISTS_START
        });

        const {
          auth: { username },
          newUser: { friends }
        } = getState();
        if (isDev) {
          console.log("%cCome back and fix this", "color:orange");
          console.log("%cFriends", "color:orange", friends);
        }
        const channels = friends
          .filter((friend: Friend) => !isEmpty(friend.user.youTubeUserData))
          .map((friend: Friend) => {
            const ytData = friend.user.youTubeUserData;
            if (typeof ytData === "string") {
              return JSON.parse(ytData).ytChannelId;
            } else {
              return ytData.ytChannelId;
            }
          });

        if (isDev) {
          console.log("%cFriends YouTube Channels", "color:orange", channels);
        }

        // if (myYoutubeChannel) {
        //   channels.push(myYoutubeChannel.id);
        // }
        // if (
        //   youtubeUserData?.ytChannelId &&
        //   !channels.includes(youtubeUserData.ytChannelId)
        // ) {
        //   channels.push(youtubeUserData.ytChannelId);
        // }
        // console.log(
        //   "%cYouTube Channels to get playlists from",
        //   "color:orange",
        //   channels
        // );

        let ytPlaylists: gapi.client.youtube.Playlist[] | undefined = [];
        // let ytPlaylists: gapi.client.youtube.SearchResult[] | undefined = [];
        for (const channel of channels) {
          const playlists = await dispatch(
            // getSlugifyPlaylistsFromYoutubeChannel(channel)
            getYoutubePlaylistsFromChannel(channel)
          );
          if (isDev) {
            console.log(
              "%cSearch does not work here, so filtering results after fetch",
              "color:orange",
              playlists
            );
          }
          if (playlists) {
            const filtered = playlists.filter(
              (item) =>
                item.snippet?.title === "Slugify" ||
                item.snippet?.title?.toLowerCase() ===
                  `${username.toLowerCase()} (slugify direct)`
            );
            ytPlaylists = ytPlaylists.concat(filtered);
          }
        }

        if (isDev) {
          console.log("%cAll You Tube Playlists", "color:orange", ytPlaylists);
        }

        dispatch({
          type: YouTubeActionType.GET_ALL_YOUTUBE_PLAYLISTS_SUCCESS,
          payload: ytPlaylists
        });
        return Promise.resolve(ytPlaylists);
      } catch (error) {
        console.warn("Could not getFriendsYouTubePlaylists", error);
        dispatch({
          type: YouTubeActionType.GET_ALL_YOUTUBE_PLAYLISTS_FAIL,
          payload: error
        });
        return Promise.reject(error);
      }
    };

export const getSlugifyPlaylistsFromYoutubeChannel =
  (
    channelId: string
  ): AppThunk<Promise<gapi.client.youtube.SearchResult[] | undefined>> =>
  async (dispatch) => {
    try {
      //
      dispatch({
        type: YouTubeActionType.GET_SLUGIFY_PLAYLISTS_FROM_YOUTUBE_CHANNEL_START
      });
      const client = gapi.client;
      if (!client) {
        throw new Error("No gapi.client!");
      }
      if (!client.youtube) {
        throw new Error("No gapi.client.youtube!");
      }
      const resp = await gapi.client.youtube.search.list({
        part: "id,snippet",
        channelId: channelId,
        q: "Slugify|slugify",
        type: "playlist",
        maxResults: 50
      });
      if (isDev) {
        console.log(
          `%cSlugify playlists in channel: ${channelId}`,
          "color:orange",
          resp.result.items
        );
      }
      dispatch({
        type: YouTubeActionType.GET_SLUGIFY_PLAYLISTS_FROM_YOUTUBE_CHANNEL_SUCCESS,
        payload: resp.result.items
      });
      return Promise.resolve(resp.result.items);
    } catch (error) {
      console.warn("Could not getYoutubePlaylistsFromChannel", error);
      dispatch({
        type: YouTubeActionType.GET_SLUGIFY_PLAYLISTS_FROM_YOUTUBE_CHANNEL_FAIL,
        payload: error
      });
    }
  };

export const getYoutubePlaylistsFromChannel =
  (
    channelId: string
  ): AppThunk<Promise<gapi.client.youtube.Playlist[] | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: YouTubeActionType.GET_YOUTUBE_PLAYLISTS_FROM_CHANNEL_START
      });
      const client = gapi.client;
      if (!client) {
        throw new Error("No gapi.client!");
      }
      if (!client.youtube) {
        throw new Error("No gapi.client.youtube!");
      }
      const resp = await gapi.client.youtube.playlists.list({
        part: ["snippet"],
        channelId: channelId
      });
      dispatch({
        type: YouTubeActionType.GET_YOUTUBE_PLAYLISTS_FROM_CHANNEL_SUCCESS
      });
      return Promise.resolve(resp.result.items);
    } catch (error) {
      console.warn("Could not getYoutubePlaylistsFromChannel", error);
      dispatch({
        type: YouTubeActionType.GET_YOUTUBE_PLAYLISTS_FROM_CHANNEL_FAIL,
        payload: error
      });
    }
  };

export const getYouTubeUserData =
  (currentGoogleUser: any): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch({ type: YouTubeActionType.GET_YOUTUBE_USER_DATA_START });
    try {
      const id = await currentGoogleUser.getBasicProfile().getId();
      const name = await currentGoogleUser.getBasicProfile().getName();
      const profileImg = await currentGoogleUser
        .getBasicProfile()
        .getImageUrl();
      if (isDev) {
        console.log(currentGoogleUser);
      }
      dispatch({
        type: YouTubeActionType.GET_YOUTUBE_USER_DATA_SUCCESS,
        payload: { id, name, profileImg }
      });
    } catch (e) {
      dispatch({
        type: YouTubeActionType.GET_YOUTUBE_USER_DATA_FAIL,
        payload: e
      });
    }
  };

export const createYouTubePlaylist =
  (
    title: string,
    description: string,
    privacyStatus: string
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch({ type: YouTubeActionType.CREATE_YOUTUBE_PLAYLIST_START });
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: `Creating ${title} playlist on YouTube`
      });
      const resp = await gapi.client.youtube.playlists.insert({
        part: ["snippet,status"],
        resource: {
          snippet: {
            title: title,
            description: description,
            defaultLanguage: "en"
          },
          status: {
            privacyStatus: privacyStatus
          }
        }
      });
      if (isDev) {
        console.log("Create Playlist Response", resp);
      }

      const payload = {
        id: resp.result.id,
        name: resp.result.snippet?.title,
        privacyStatus: resp.result.status?.privacyStatus
      };
      console.log("🌟 New playlist created on YouTube", payload);
      dispatch({
        type: YouTubeActionType.CREATE_YOUTUBE_PLAYLIST_SUCCESS,
        payload
      });
    } catch (e) {
      dispatch({
        type: YouTubeActionType.CREATE_YOUTUBE_PLAYLIST_FAIL,
        payload: e
      });
    }
  };

export const getTracksFromYouTubeSlugifyPlaylist =
  (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    try {
      dispatch({
        type: YouTubeActionType.GET_TRACKS_FROM_YOUTUBE_SLUGIFY_PLAYLIST_START
      });
      const { myPlaylists } = getState().youtube;
      const slugifyPlaylist = myPlaylists.find(
        (item: YouTubePlaylist) => item.name === "Slugify"
      );
      if (slugifyPlaylist) {
        const tracks = await dispatch(
          getVideosFromYouTubePlaylist(slugifyPlaylist.id)
        );
        dispatch({
          type: YouTubeActionType.GET_TRACKS_FROM_YOUTUBE_SLUGIFY_PLAYLIST_SUCCESS,
          payload: tracks
        });
      } else {
        throw new Error("No Slugify Playlist!");
      }
      return Promise.resolve();
    } catch (error) {
      dispatch({
        type: YouTubeActionType.GET_TRACKS_FROM_YOUTUBE_SLUGIFY_PLAYLIST_FAIL,
        payload: error
      });
      return Promise.reject(error);
    }
  };
export const handleYouTubePlaylists =
  (myYtPlaylists: any): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch({ type: YouTubeActionType.HANDLE_YOUTUBE_PLAYLISTS_START });
    try {
      const slugifyPlaylist = myYtPlaylists.find(
        (item: YouTubePlaylist) => item.name.toLowerCase() === "slugify"
      );
      const slugTubeVideosPlaylist = myYtPlaylists.find(
        (item: YouTubePlaylist) => item.name.toLowerCase() === "slugtube videos"
      );
      const slugTubeMusicPlaylist = myYtPlaylists.find(
        (item: YouTubePlaylist) => item.name.toLowerCase() === "slugtube music"
      );
      if (!slugifyPlaylist) {
        await dispatch(
          createYouTubePlaylist("Slugify", "Created by Slugify", "public")
        );
      }
      if (!slugTubeMusicPlaylist) {
        await dispatch(
          createYouTubePlaylist(
            "SlugTube Music",
            "SlugTube destination playlist",
            "private"
          )
        );
      }
      if (!slugTubeVideosPlaylist) {
        await dispatch(
          createYouTubePlaylist(
            "SlugTube Videos",
            "SlugTube destination playlist",
            "private"
          )
        );
      }
      dispatch({
        type: YouTubeActionType.HANDLE_YOUTUBE_PLAYLISTS_SUCCESS
      });
    } catch (e) {
      dispatch({
        type: YouTubeActionType.HANDLE_YOUTUBE_PLAYLISTS_FAIL,
        payload: e
      });
    }
  };

export const saveVidToYouTubePlaylist =
  (playlistId: string, videoId: string): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    dispatch({ type: "SAVE_VIDS_TO_YOUTUBE_PLAYLIST_START" });
    try {
      const client = await getState().youtube.client;
      const response = await client.youtube.playlistItems.insert({
        part: "snippet",
        resource: {
          snippet: {
            playlistId,
            resourceId: {
              videoId,
              kind: "youtube#video"
            }
          }
        }
      });
      dispatch({ type: "SAVE_VIDS_TO_YOUTUBE_PLAYLIST_SUCCESS" });
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch({ type: "SAVE_VIDS_TO_YOUTUBE_PLAYLIST_FAIL", payload: error });
      return Promise.reject(error.message);
    }
  };

export const setYouTubeSignInStatus =
  (): AppThunk<Promise<any>> => async (dispatch, getState) => {
    try {
      dispatch({ type: YouTubeActionType.SET_YOUTUBE_SIGN_IN_STATUS_START });
      const {
        newUser: { primaryMusicStream, youtubeUserData }
      } = getState();
      if (primaryMusicStream !== "youtube") {
        if (isDev) {
          console.log("%cYoutube User Data", "color:orange", youtubeUserData);
        }
        if (!youtubeUserData) {
          if (isDev) {
            console.log(
              "%cNon YouTube user has no YouTube data.",
              "color:yellow"
            );
          }
          dispatch({
            type: YouTubeActionType.SET_YOUTUBE_SIGN_IN_STATUS_SUCCESS,
            payload: {}
          });
          return Promise.resolve();
        }
        if (isDev) {
          console.log(
            "%cNot a YouTube user.  Skipping sign in...",
            "color:yellow"
          );
        }
        dispatch({
          type: YouTubeActionType.SET_YOUTUBE_SIGN_IN_STATUS_SUCCESS,
          payload: {}
        });
        return Promise.resolve();
      }
      if (!gapi) {
        throw new Error("No gapi!");
      }
      if (!gapi.auth2) {
        throw new Error("No gapi.auth2!");
      }
      const auth2 = await gapi.auth2.getAuthInstance();
      if (!auth2) throw new Error("No Google Auth!");
      const user = auth2.currentUser.get();
      if (!user) {
        dispatch({ type: "SET_SIGN_IN_STATUS_SUCCESS", payload: "reset" });
        if (isDev) {
          console.log("%c👮🏻‍♀️ YOUTUBE USER IS NOT AUTHORIZED", "color:red");
        }
        return Promise.resolve();
      }
      const isAuthorized = user.hasGrantedScopes(SCOPE);
      if (isAuthorized) {
        if (isDev) {
          console.log("%c👮🏻‍♀️ YOUTUBE USER IS AUTHORIZED", "color:#66ff00");
          console.log("%c🐕‍🦺 Fetching YouTube user profile...", "color:cyan");
        }
        const profile = auth2.currentUser.get().getBasicProfile();
        const payload = {
          id: profile.getId(),
          fullName: profile.getName(),
          givenName: profile.getGivenName(),
          familyName: profile.getFamilyName(),
          imageUrl: profile.getImageUrl(),
          email: profile.getEmail()
        };
        dispatch({
          type: YouTubeActionType.SET_YOUTUBE_SIGN_IN_STATUS_SUCCESS,
          payload
        });
        await dispatch(gapiLoadClient());
        // await dispatch(getMyYoutubePlaylists());
        // await dispatch(getMyYouTubeChannel());
        return Promise.resolve(payload);
      } else {
        if (isDev) {
          console.log("%c👮🏻‍♀️ YOUTUBE USER IS NOT AUTHORIZED", "color:red");
        }
        dispatch({ type: YouTubeActionType.RESET_YOUTUBE_USER });
        // $("#sign-in-or-out-button").html("Sign In/Authorize");
        // $("#revoke-access-button").css("display", "none");
        // $("#auth-status").html(
        //   "You have not authorized this app or you are " + "signed out."
        // );
        return Promise.resolve();
      }
    } catch (error: any) {
      console.warn(error);
      let message = error.result?.error?.message
        ? error.result.error.message
        : error.message;
      if (message.includes("exceeded")) {
        message = "🐛 YouTube API quota exceeded!";
      }
      if (message.includes("Channel not found")) {
        return Promise.resolve("Channel not found");
      }
      dispatch({
        type: "SET_YOUTUBE_SIGN_IN_STATUS_FAIL",
        payload: new Error(message)
      });
      return Promise.reject(new Error(message));
    }
  };

export const signOutYouTubeUser =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch({ type: "SIGN_OUT_YOU_TUBE_USER_START" });
    try {
      const GoogleAuth = await gapi.auth2.getAuthInstance();
      await GoogleAuth.signOut();
      dispatch({
        type: "SIGN_OUT_YOU_TUBE_USER_SUCCESS"
      });
      // dispatch(attachSignIn(googleAuthButton, auth2));
      return Promise.resolve();
    } catch (error) {
      dispatch({ type: "SIGN_OUT_YOU_TUBE_USER_FAIL", payload: error });
      return Promise.reject(error);
    }
  };
