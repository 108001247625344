import { useState, useRef } from "react";
import { btoa } from "../functions";
import { useAppSelector } from "../hooks";
import { Modal } from "react-bootstrap";

interface Props {
  isInviteFriendModalOpen: boolean;
  setIsInviteFriendModalOpen: (b: boolean) => void;
}
export default function InviteFriendModal({
  isInviteFriendModalOpen,
  setIsInviteFriendModalOpen
}: Props) {
  const textAreaInput = useRef<HTMLTextAreaElement>(null);
  const { username } = useAppSelector((state) => state.auth);
  const [buttonText, setButtonText] = useState("Copy Message to Clipboard");
  const mySecretCode = btoa(username);
  const message =
    "Aloha, \n\nHere's the secret info to become my friend on Slugify:\n\nUsername:  " +
    username +
    "\nSecret code:  " +
    mySecretCode +
    "\n\n🤙🏽 Mahalo!";

  function copyMessage() {
    if (textAreaInput.current) {
      navigator.clipboard.writeText(message);
      textAreaInput.current.blur();
      setButtonText("Message Copied");
    }
  }

  function cleanUpModal() {
    setButtonText("Copy Message to Clipboard");
  }

  function handleClose() {
    cleanUpModal();
    setIsInviteFriendModalOpen(false);
  }
  // useEffect(() => {
  //   const cleanUpModal = () => {
  //     setButtonText("Copy Message to Clipboard");
  //   };
  //   $("#invite-friend-modal").on("hidden.bs.modal", () => {
  //     cleanUpModal();
  //   });
  // }, []);
  return (
    <>
      <Modal show={isInviteFriendModalOpen} onHide={() => handleClose()}>
        <Modal.Header closeButton>Invite a Friend</Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center">
          <span
            role="img"
            aria-label="Woman Detective: Medium Skin Tone"
            style={{ fontSize: "64px" }}
          >
            🕵🏽‍♀️
          </span>
          <span className="display" style={{ fontSize: "32px" }}>
            Psst!
          </span>
          <p className="lead" style={{ fontSize: "18px" }}>
            Copy the message below and send it to a friend!
          </p>
          <div className="w-100">
            <label className="form-label" htmlFor="message">
              Your secret message
            </label>
            <textarea
              ref={textAreaInput}
              className="form-control"
              id="message"
              readOnly
              // rows={6}
              value={message}
              style={{ height: 220 }}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-link text-body-secondary text-decoration-none"
            onClick={() => handleClose()}
          >
            close
          </button>
          <button
            className={`btn btn-sm ${
              buttonText === "Message Copied"
                ? "btn-primary"
                : "btn-outline-primary"
            }`}
            disabled={buttonText === "Message Copied"}
            onClick={() => copyMessage()}
          >
            {buttonText}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="modal fade" id="invite-friend-modal">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title lead">Invite a Friend</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column align-items-center">
                <span
                  role="img"
                  aria-label="Woman Detective: Medium Skin Tone"
                  style={{ fontSize: "64px" }}
                >
                  🕵🏽‍♀️
                </span>
                <span className="display" style={{ fontSize: "32px" }}>
                  ️Psst!
                </span>
                <p className="lead" style={{ fontSize: "18px" }}>
                  Copy the message below and send it to a friend!
                </p>
              </div>
              {/* <form action=""> */}
              <div className="">
                <label className="form-label" htmlFor="message">
                  Your secret message
                </label>
                <textarea
                  ref={textAreaInput}
                  className="form-control"
                  id="message"
                  readOnly
                  rows={6}
                  value={message}
                ></textarea>
              </div>
              {/* </form> */}
            </div>
            <div className="d-flex justify-content-between modal-footer">
              <button
                type="button"
                className="btn btn-link text-body-secondary text-decoration-none"
                data-bs-dismiss="modal"
              >
                close
              </button>
              <button
                className={`btn btn-sm ${
                  buttonText === "Message Copied"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                disabled={buttonText === "Message Copied"}
                onClick={() => copyMessage()}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
