import { API } from "aws-amplify";
import { customHistory as history } from "../routers/AppRouter";
import { flatMap, differenceBy, flatten, compact } from "lodash";
import { getSlugifyPublicPlaylist } from "./slugifyActions";
import { handleUserAlert } from ".";
import { isEmptyObject, getTitleCase } from "../functions";
import { remove } from "diacritics";
import {
  removeAppleMusicUserData,
  saveAppleMusicUserData
} from "./userActions";
import { updateSlugifyUser } from "./slugifyGraphqlActions";
import axios from "axios";
import chalk from "chalk";
import dayjs from "dayjs";
import {
  AppleMusicActionType,
  AppleMusicPlaylist,
  AppleMusicTrack,
  MatchedByType,
  SlugifyActionType,
  SlugifyTrackType,
  SpotifyNativeTrack
} from "../types";
import type { AppThunk } from "../store/store";
import type { SpotifyTrack } from "../types/spotify";

const isDev = process.env.NODE_ENV === "development";

export const getAppleMusicInstance =
  (): AppThunk<Promise<{ music: any; token: string }>> => async (dispatch) => {
    try {
      const MusicKit = window.MusicKit;
      dispatch({ type: "GET_APPLE_MUSIC_INSTANCE_START" });
      if (!MusicKit) {
        if (isDev) {
          console.log("%cMusic kit is not loaded!", "color:hotpink");
        }
        dispatch({
          type: "GET_APPLE_MUSIC_INSTANCE_FAIL",
          payload: new Error("Apple MusicKit is not loaded.")
        });
      }
      if (isDev) {
        console.log("%cMusicKit is loaded!", "color:lime");
      }
      const token = await dispatch(getAppleMusicDevToken());
      await window.MusicKit.configure({
        developerToken: token,
        app: {
          name: "Slugify",
          build: "1978.4.1"
        }
      });
      if (isDev) {
        console.log("%cMusicKit has been configured!", "color:lime");
      }
      const music = await window.MusicKit.getInstance();
      dispatch({ type: "GET_APPLE_MUSIC_INSTANCE_SUCCESS" });
      return Promise.resolve({ music, token });
    } catch (error) {
      dispatch({ type: "GET_APPLE_MUSIC_INSTANCE_FAIL", payload: error });
      return Promise.reject(error);
    }
  };

// export async function getAppleMusicInstance2 () {
//   try {
//     if (!MusicKit) {
//       throw new Error ("No Music Kit!")
//     }
//     const token = await dispatch(getAppleMusicDevToken());
//     MusicKit({
//       developerToken: token,
//       app: {
//         name: "Slugify",
//         build: "1978.4.1"
//       }
//     });
//     const music = await window.MusicKit.getInstance();
//     dispatch({ type: "GET_APPLE_MUSIC_INSTANCE_SUCCESS" });
//     return Promise.resolve({ music, token });
//   } catch (error) {
//     dispatch({ type: "GET_APPLE_MUSIC_INSTANCE_FAIL", payload: error });
//     return Promise.reject(error);
//   }
// };

export const createAppleMusicSlugifyPlaylist =
  (): AppThunk<Promise<any>> => async (dispatch, getState) => {
    const {
      newUser: { primaryMusicStream }
    } = getState();

    if (!primaryMusicStream) {
      if (isDev) {
        console.log(
          "⛔ User has not selected primary music stream yet. Slugify playlist creation skipped."
        );
      }
      return Promise.resolve();
    } else {
      if (primaryMusicStream !== "appleMusic") {
        if (isDev) {
          console.log(
            "%c⛔ Not an Apple Music user.  Slugify playlist creation on Apple Music skipped.",
            "color:cyan"
          );
        }
        return Promise.resolve();
      }
    }
    try {
      dispatch({ type: "CREATE_APPLE_MUSIC_SLUGIFY_PLAYLIST_START" });
      const { music, token } = await dispatch(getAppleMusicInstance());
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Authorizing Apple Music..."
      });
      const appleMusicUserToken = await music.authorize();
      dispatch({
        type: "SET_APPLE_MUSIC_CONNECTED_STATUS",
        payload: !!appleMusicUserToken
      });
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Checking for Slugify playlist on Apple Music..."
      });
      const mySlugifyPlaylistsOnAppleMusic = await dispatch(
        getCurrentUserAppleMusicPlaylists()
      );
      const mySlugifyPlaylistOnAppleMusic =
        mySlugifyPlaylistsOnAppleMusic.filter(
          (item: AppleMusicPlaylist) =>
            item.attributes.name.toLowerCase() === "slugify"
        );
      if (!mySlugifyPlaylistOnAppleMusic.length) {
        dispatch({
          type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
          payload: "Creating Slugify Playlist on Apple Music..."
        });
        const postOptions = {
          method: "POST",
          url: `https://api.music.apple.com/v1/me/library/playlists`,
          headers: {
            Authorization: "Bearer " + token,
            "Music-User-Token": appleMusicUserToken,
            "Content-Type": "application/json"
          },
          data: {
            attributes: {
              description: "Created by Slugify!",
              name: "Slugify"
            }
          }
        };
        const { data } = await axios(postOptions);
        console.warn(data.data[0]);
        dispatch({
          type: "CREATE_APPLE_MUSIC_SLUGIFY_PLAYLIST_SUCCESS",
          payload: data.data[0]
        });
        console.log(
          "%c🌟 Slugify playlist created on Apple Music",
          "color:lime"
        );
        return Promise.resolve(data.data[0]);
      } else {
        if (isDev) {
          console.log(
            "%c✅ Slugify playlist already exists on Apple Music",
            "color:lime"
          );
        }
        return Promise.resolve(null);
      }
    } catch (error: any) {
      if (error === 1) {
        console.log("%c🙅 User cancelled Apple Authorization", "color:yellow");
        history.push("/");
        return Promise.reject(
          new Error("Apple Music Authorization was cancelled")
        );
      } else {
        console.warn(error.response || error);
        dispatch({
          type: "CREATE_SPOTIFY_SLUGIFY_PLAYLIST_FAIL",
          payload: error.response || error
        });
        return Promise.reject(error.response || error);
      }
    }
  };

export const createAppleMusicSlugifyDirectPlaylist =
  (friendUsername: string): AppThunk<Promise<any>> =>
  async (dispatch) => {
    try {
      console.log(
        "%cSTOP CREATION OF PLAYLIST IF IT ALREADY EXISTS!",
        "color:hotpink"
      );
      dispatch({ type: "CREATE_APPLE_MUSIC_SLUGIFY_DIRECT_PLAYLIST_START" });
      const { music, token } = await dispatch(getAppleMusicInstance());
      const appleMusicUserToken = await music.authorize();
      const postOptions = {
        method: "POST",
        url: `https://api.music.apple.com/v1/me/library/playlists`,
        headers: {
          Authorization: "Bearer " + token,
          "Music-User-Token": appleMusicUserToken,
          "Content-Type": "application/json"
        },
        data: {
          attributes: {
            description: "Created by Slugify!",
            name: `${getTitleCase(friendUsername)} (Slugify Direct)`
          }
        }
      };
      const { data } = await axios(postOptions);
      dispatch({
        type: "CREATE_APPLE_MUSIC_SLUGIFY_DIRECT_PLAYLIST_SUCCESS",
        payload: data.data[0]
      });
      console.log(
        "%c🌟 Slugify Direct playlist created on Apple Music",
        "color:lime"
      );
      return Promise.resolve(data.data[0]);
    } catch (error) {
      dispatch({
        type: "CREATE_APPLE_MUSIC_SLUGIFY_DIRECT_PLAYLIST_FAIL",
        payload: error
      });
      return Promise.resolve(error);
    }
  };

export const createSpotifyPlaylistOnAppleMusic =
  (
    friendUsername: string,
    playlistName: string,
    tracks: SpotifyTrack[]
  ): AppThunk<Promise<any>> =>
  async (dispatch) => {
    try {
      dispatch({ type: "CREATE_SPOTIFY_PLAYLIST_ON_APPLE_MUSIC_START" });
      const { music, token: appleDeveloperToken } = await dispatch(
        getAppleMusicInstance()
      );
      const appleMusicUserToken = await music.authorize();

      const cloudPlaylists = await music.api.library.playlists({ limit: 100 });
      await dispatch(updateAppleMusicPublicPlaylists(cloudPlaylists));

      const existingPlaylists = cloudPlaylists.filter(
        (item: AppleMusicPlaylist) => item.attributes.name === playlistName
      );

      if (existingPlaylists.length) {
        return Promise.resolve({
          error: "playlistAlreadyExists",
          playlist: existingPlaylists[0]
        });
      }

      const getMatchingTrack = async (item: SpotifyTrack) => {
        try {
          const isrcTracks = await dispatch(
            getMatchingAppleMusicTrackByIsrc(
              appleDeveloperToken,
              item.track.external_ids.isrc
            )
          );

          if (isDev) {
            console.log("%cISRC Tracks", "color:cyan", isrcTracks);
          }

          if (isrcTracks.length) {
            return isrcTracks[0];
          }
          if (isDev) {
            console.log(
              "%c🤷🏻‍♀️ No matching ISRC on Spotify.  Searching with Title/Album...",
              "color:cyan"
            );
          }
          const titleAlbumTracks = await dispatch(
            getMatchingAppleMusicTrackByTerm(appleDeveloperToken, item)
          );

          if (isDev) {
            console.log("%cTitle/Album Tracks", "color:cyan", titleAlbumTracks);
          }
          if (titleAlbumTracks.length) {
            return titleAlbumTracks[0];
          }
          return null;
        } catch (error) {
          console.warn(error);
        }
      };
      try {
        const pendingTracks = tracks.map((item) => getMatchingTrack(item));
        if (isDev) {
          console.log(
            "%c⏲️ Awaiting Apple Music Matched Track promises...",
            "color:cyan"
          );
        }
        const resolvedTracks = await Promise.all(pendingTracks);
        if (isDev) {
          console.log(
            "%c🧘🏽 Apple Music Matched Track Promises fulfilled",
            "color:lime",
            resolvedTracks
          );
        }
        const matchedTracks = compact(flatten(resolvedTracks));

        const newPlaylist = await dispatch(
          createAppleMusicPlaylistWithTracks(
            appleDeveloperToken,
            appleMusicUserToken,
            friendUsername,
            playlistName,
            matchedTracks
          )
        );
        console.log("%c🐣 New Apple Music playlist created!", "color:lime");
        const unmatchedTrackCount = tracks.length - matchedTracks.length;
        dispatch({
          type: "CREATE_SPOTIFY_PLAYLIST_ON_APPLE_MUSIC_SUCCESS"
        });
        return Promise.resolve({
          playlist: newPlaylist,
          unmatchedTrackCount
        });
      } catch (error) {
        console.warn(error);
        return Promise.reject(error);
      }
    } catch (error) {
      dispatch({
        type: "CREATE_SPOTIFY_PLAYLIST_ON_APPLE_MUSIC_FAIL",
        payload: error
      });
      return Promise.resolve(error);
    }
  };

export const createAppleMusicPlaylistWithTracks =
  (
    appleDeveloperToken: string,
    appleMusicUserToken: string,
    friendUsername: string,
    playlistName: string,
    tracksToAdd: AppleMusicTrack[]
  ): AppThunk<Promise<any>> =>
  async () => {
    try {
      if (isDev) {
        console.log(
          "%cTracks to add",
          "color:cyan",
          tracksToAdd.map((item) => ({ id: item.id, type: item.type }))
        );
      }
      const createPlaylistOptions = {
        method: "POST",
        url: `https://api.music.apple.com/v1/me/library/playlists`,
        headers: {
          Authorization: "Bearer " + appleDeveloperToken,
          "Music-User-Token": appleMusicUserToken,
          "Content-Type": "application/json"
        },
        data: {
          attributes: {
            description: `Created by ${getTitleCase(
              friendUsername
            )} via Slugify!`,
            name: playlistName
          },
          relationships: {
            tracks: {
              data: tracksToAdd.map((item) => ({
                id: item.id,
                type: item.type
              }))
            }
          }
        }
      };
      const { data: newPlaylist } = await axios(createPlaylistOptions);
      return Promise.resolve(newPlaylist.data[0]);
    } catch (error: any) {
      console.warn(error.response);
      return Promise.reject(error);
    }
  };

export const getMatchingAppleMusicTrackByIsrc =
  (appleDeveloperToken: string, isrc: string): AppThunk<Promise<any>> =>
  async () => {
    try {
      const getOptions = {
        method: "GET",
        url: `https://api.music.apple.com/v1/catalog/us/songs?filter[isrc]=${isrc}`,
        headers: {
          Authorization: "Bearer " + appleDeveloperToken,
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios(getOptions);
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.resolve(error);
    }
  };

export const getMatchingAppleMusicTrackByTerm =
  (appleDeveloperToken: string, track: SpotifyTrack): AppThunk<Promise<any>> =>
  async () => {
    try {
      if (isDev) {
        console.warn("make this better!");
      }
      const fixSpotifyTrackTitle = (str: string) => {
        const regex = /- \d{4} Remaster/g;
        const found = str.match(regex);

        if (found && found.length) {
          return str.replace(regex, "Remastered");
        } else {
          return str;
        }
      };

      const fixedTrackName = fixSpotifyTrackTitle(track.track.name);

      const term =
        encodeURIComponent(fixedTrackName).replace(/%20/g, "+") +
        "+" +
        encodeURIComponent(track.track.album.name).replace(/%20/g, "+");

      if (isDev) {
        console.log("🔍 Search Term: ", term);
      }

      const searchOptions = {
        method: "GET",
        url: `https://api.music.apple.com/v1/catalog/us/search?term=${term}&types=songs`,
        headers: {
          Authorization: "Bearer " + appleDeveloperToken,
          "Content-Type": "application/json"
        }
      };

      const { data } = await axios(searchOptions);
      if (isEmptyObject(data.results)) {
        return Promise.resolve([]);
      }
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.resolve(error);
    }
  };

export const getAppleMusicDevToken =
  (): AppThunk<Promise<string>> => async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_APPLE_MUSIC_DEV_TOKEN_START" });
      const {
        appleMusicTokenData,
        appleMusicTokenData: { token, exp }
      } = getState().appleMusic;
      let getNewToken = false;

      const nowUnix = dayjs().unix();
      if (isEmptyObject(appleMusicTokenData)) {
        if (isDev) {
          console.log(
            "%c🍏 Apple Music token doesn't exist (yet).",
            "color:cyan"
          );
        }
        getNewToken = true;
      } else if (exp && nowUnix > exp) {
        if (isDev) {
          console.log("%c🍏 Apple Music token has expired!", "color:yellow");
        }
        getNewToken = true;
      } else {
        if (isDev) {
          console.log("%c🍎 Apple Music token is good!", "color:lime");
        }
      }

      if (getNewToken) {
        if (isDev) {
          console.log("%c🍎 Getting new Apple Music token...", "color:cyan");
        }
        const response = await API.get(
          "SlugBucketAPI",
          "/apple-music-dev-token",
          {}
        );
        dispatch({
          type: "GET_APPLE_MUSIC_DEV_TOKEN_SUCCESS",
          payload: response
        });
        return Promise.resolve(response.token);
      } else {
        dispatch({
          type: "GET_APPLE_MUSIC_DEV_TOKEN_SUCCESS",
          payload: { ...appleMusicTokenData }
        });
        return Promise.resolve(token);
      }
    } catch (error: any) {
      dispatch({
        type: "GET_APPLE_MUSIC_DEV_TOKEN_FAIL",
        payload: error.response || error
      });
      dispatch(handleUserAlert(["GET_APPLE_MUSIC_DEV_TOKEN"]));
      return Promise.resolve(null);
    }
  };

// export async function getAppleMusicDevToken2() {
//   try {
//     dispatch({ type: "GET_APPLE_MUSIC_DEV_TOKEN_START" });
//     const {
//       appleMusicTokenData,
//       appleMusicTokenData: { token, exp }
//     } = getState().appleMusic;
//     let getNewToken = false;

//     const nowUnix = dayjs().unix();
//     if (isEmptyObject(appleMusicTokenData)) {
//       console.log("🍏 Apple Music token doesn't exist (yet).");
//       getNewToken = true;
//     } else if (exp && nowUnix > exp) {
//       console.log("🍏 Apple Music token has expired!");
//       getNewToken = true;
//     } else {
//       console.log("🍎 Apple Music token is good!");
//     }

//     if (getNewToken) {
//       console.log("🍎 Getting new Apple Music token...");
//       const response = await API.get(
//         "SlugBucketAPI",
//         "/apple-music-dev-token",
//         {}
//       );
//       dispatch({
//         type: "GET_APPLE_MUSIC_DEV_TOKEN_SUCCESS",
//         payload: response
//       });
//       return Promise.resolve(response.token);
//     } else {
//       dispatch({
//         type: "GET_APPLE_MUSIC_DEV_TOKEN_SUCCESS",
//         payload: { ...appleMusicTokenData }
//       });
//       return Promise.resolve(token);
//     }
//   } catch (error: any) {
//     dispatch({
//       type: "GET_APPLE_MUSIC_DEV_TOKEN_FAIL",
//       payload: error.response || error
//     });
//     dispatch(handleUserAlert(["GET_APPLE_MUSIC_DEV_TOKEN"]));
//     return Promise.resolve(null);
//   }
// };

export const getTracksFromAppleMusicPlaylist =
  (playlistId: string, appleDeveloperToken: string): AppThunk<Promise<any>> =>
  async () => {
    const playlistRoot =
      playlistId.includes("__") && playlistId.includes("Slugify Direct")
        ? playlistId.split("__")[0]
        : playlistId;
    try {
      const getOptions = {
        method: "GET",
        url: `https://api.music.apple.com/v1/catalog/us/playlists/${playlistRoot}`,
        headers: {
          Authorization: "Bearer " + appleDeveloperToken,
          "Content-Type": "application/json"
        },
        include: ["tracks", "curator"]
      };
      const { data } = await axios(getOptions);

      const playlistName =
        playlistId.includes("__") && playlistId.includes("Slugify Direct")
          ? playlistId.split("__")[1]
          : "Slugify";

      const playlistTracks = flatMap(
        data.data,
        (item) => item.relationships.tracks.data
      ).map((track) => ({
        ...track,
        addedBy: playlistRoot,
        trackType: SlugifyTrackType.APPLE_MUSIC_NATIVE,
        playlist: playlistName
      }));

      return Promise.resolve(playlistTracks);
      // return playlistTracks;
    } catch (error: any) {
      throw new Error(
        `Can't get tracks from playlist, ${playlistId}: ${
          error.response || error
        }`
      );
    }
  };
export const getAllTracksFromAppleMusicPlaylists =
  (): AppThunk<Promise<any>> => async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_ALL_TRACKS_FROM_APPLE_MUSIC_PLAYLISTS_START" });
      const {
        slugify: { appleMusicPlaylists }
      } = getState();
      if (!appleMusicPlaylists || !appleMusicPlaylists.length) {
        if (isDev) {
          console.log(
            "%c🤷 No Apple Music playlists.  Skipping fetching of tracks.",
            "color:cyan"
          );
        }
        return Promise.resolve(null);
      }
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Analyzing Apple Music tracks..."
      });
      const appleDeveloperToken = await dispatch(getAppleMusicDevToken());
      const pendingTracks = appleMusicPlaylists.map((playlist) =>
        dispatch(getTracksFromAppleMusicPlaylist(playlist, appleDeveloperToken))
      );
      const tracks = await Promise.all(pendingTracks);
      dispatch({
        type: "GET_ALL_TRACKS_FROM_APPLE_MUSIC_PLAYLISTS_SUCCESS",
        payload: flatten(tracks)
      });
      return Promise.resolve(flatten(tracks));
    } catch (error) {
      console.warn(error);
      dispatch({
        type: "GET_ALL_TRACKS_FROM_APPLE_MUSIC_PLAYLISTS_FAIL",
        payload: error
      });
      return Promise.resolve(error);
    }
  };

export const getCurrentUserAppleMusicPlaylists =
  (): AppThunk<Promise<any>> => async (dispatch, getState) => {
    dispatch({ type: "GET_CURRENT_USER_APPLE_MUSIC_PLAYLISTS_START" });
    try {
      const {
        newUser: { appleMusicSlugifyPlaylist, primaryMusicStream }
      } = getState();
      if (primaryMusicStream !== "appleMusic") {
        return Promise.resolve([]);
      }
      const { music } = await dispatch(getAppleMusicInstance());
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Authorizing Apple Music..."
      });
      try {
        const appleMusicUserToken = await music.authorize();
        dispatch({
          type: "SET_APPLE_MUSIC_CONNECTED_STATUS",
          payload: !!appleMusicUserToken
        });
      } catch (error) {
        if (error === 1) {
          console.log(
            "%c🙅 User cancelled Apple Authorization",
            "color:yellow"
          );
          history.push("/");
          throw new Error("Apple Authorization was cancelled");
        } else {
          throw error;
        }
      }
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Aggregating Apple Music playlists..."
      });
      const { data } = await music.api.music("/v1/me/library/playlists", {
        limit: 100
      });
      const cloudPlaylists = data.data;
      await dispatch(updateAppleMusicPublicPlaylists(cloudPlaylists));
      const mySlugifyPlaylist = cloudPlaylists.filter(
        (item: AppleMusicPlaylist) =>
          item && item.attributes && item.attributes.name === "Slugify"
      );
      dispatch({
        type: "GET_CURRENT_USER_APPLE_MUSIC_PLAYLISTS_SUCCESS",
        payload: cloudPlaylists
      });
      const publicPlaylist = await dispatch(getSlugifyPublicPlaylist());
      if (!!publicPlaylist && appleMusicSlugifyPlaylist !== publicPlaylist) {
        await dispatch(saveAppleMusicUserData(publicPlaylist));
      }
      return Promise.resolve(mySlugifyPlaylist);
    } catch (error: any) {
      if (error.message && error.message.includes("403")) {
        if (isDev) {
          console.log("%cFigure out a better way to do this!", "color:hotpink");
          console.log(
            "%c🚨 Apple Music auth is expired!",
            "color:yellow",
            error
          );
        }
        let appleMusicUserToken =
          await window.MusicKit.getInstance().unauthorize();
        await dispatch(removeAppleMusicUserData());
        dispatch({
          type: "SET_APPLE_MUSIC_CONNECTED_STATUS",
          payload: false
        });
        const { music } = await dispatch(getAppleMusicInstance());
        appleMusicUserToken = await music.authorize();
        if (isDev) {
          console.log(
            "%c🔑 Is Apple Music Authorized? ",
            "color:cyan",
            !!appleMusicUserToken
          );
        }
        dispatch({
          type: "SET_APPLE_MUSIC_CONNECTED_STATUS",
          payload: !!appleMusicUserToken
        });
        const cloudPlaylists = await music.api.library.playlists({
          limit: 100
        });
        // const mySlugifyPlaylist = cloudPlaylists.filter(
        //   (item) => item && item.attributes && item.attributes.name === "Slugify"
        // );
        dispatch({
          type: "GET_CURRENT_USER_APPLE_MUSIC_PLAYLISTS_SUCCESS",
          payload: cloudPlaylists
        });
        return Promise.resolve(cloudPlaylists);
      } else {
        console.warn(error);
        dispatch({
          type: "GET_CURRENT_USER_APPLE_MUSIC_PLAYLISTS_FAIL",
          payload: error
        });
        return Promise.reject(error);
      }
    }
  };

export const getMatchingAppleMusicTracks =
  (): AppThunk<Promise<any>> => async (dispatch, getState) => {
    // a matched Apple Music track is the resulting Apple Music track that has been matched from a source Spotify track.
    dispatch({ type: "GET_MATCHING_APPLE_MUSIC_TRACKS_START" });

    const {
      newUser: { primaryMusicStream }
    } = getState();

    if (primaryMusicStream !== "appleMusic") {
      if (
        primaryMusicStream === "spotify" ||
        primaryMusicStream === "youtube"
      ) {
        if (isDev) {
          console.log(
            "%c⛔ Not an Apple Music user.  Matching of Apple Music Tracks skipped.",
            "color:cyan"
          );
        }
      } else {
        if (isDev) {
          console.log(
            "%c⛔ User has not selected primary music stream yet.",
            "color:cyan"
          );
        }
      }
      dispatch({
        type: AppleMusicActionType.GET_MATCHING_APPLE_MUSIC_TRACKS_SUCCESS,
        payload: []
      });
      return Promise.resolve([]);
    }

    const {
      slugify: { spotifyTracks }
    } = getState();

    if (!spotifyTracks.length) {
      dispatch({
        type: AppleMusicActionType.GET_MATCHING_APPLE_MUSIC_TRACKS_SUCCESS,
        payload: []
      });
      if (isDev) {
        console.log(
          "%c🤷 No spotify tracks.  Skipping Apple Music Matching.",
          "color:cyan"
        );
      }
      return Promise.resolve([]);
    }

    const getMatchingTrack = async (
      track: SpotifyNativeTrack,
      appleDeveloperToken: string
    ) => {
      try {
        const getOptions = {
          method: "GET",
          url: `https://api.music.apple.com/v1/catalog/us/songs?filter[isrc]=${track.spotifyIsrc}`,
          headers: {
            Authorization: "Bearer " + appleDeveloperToken,
            "Content-Type": "application/json"
          }
        };
        const { data } = await axios(getOptions);
        if (isDev) {
          console.log(chalk("Fix this type!"));
        }
        const filteredTracks = data.data.filter((item: any) => {
          return (
            remove(track.album.toLowerCase()).includes(
              remove(item.attributes.albumName.toLowerCase())
            ) ||
            remove(item.attributes.albumName.toLowerCase()).includes(
              remove(track.album.toLowerCase())
            ) ||
            remove(track.title.toLowerCase()).includes(
              remove(item.attributes.name.toLowerCase())
            ) ||
            remove(item.attributes.name.toLowerCase()).includes(
              remove(track.title.toLowerCase())
            )
          );
        });
        if (isDev) {
          console.log(
            "%c🎼 FILTERED TRACKS ISRC: ",
            "color:cyan",
            filteredTracks
          );
        }
        if (filteredTracks[0]) {
          filteredTracks[0].spotifyInfo = { ...track };
          filteredTracks[0].trackType =
            SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY;
          filteredTracks[0].matchedBy = MatchedByType.ISRC;
          if (isDev) {
            console.log(
              `%c📻 Found track with ISRC: ${track.spotifyIsrc}: ${track.title}`,
              "color:lime"
            );
          }
          return filteredTracks[0];
        } else {
          if (isDev) {
            console.log(
              `%c🤷 Could not find track with ISRC: ${track.spotifyIsrc}.  Trying search with Artist/Title: ${track.artist}/${track.title}`,
              "color:cyan"
            );
          }
          // Apple Music doesn't like Spotify's naming of remastered tracks!
          // track.title = track.title.replace(/\'/g, "");

          // as this might become bigger, I've moved into a function.
          const fixSpotifyTrackTitle = (title: string) => {
            const regex = /- \d{4} Remaster/g;
            const found = title.match(regex);

            if (found && found.length) {
              return title.replace(regex, "Remastered");
            } else {
              return title;
            }
          };

          track.title = fixSpotifyTrackTitle(track.title);

          const term =
            encodeURIComponent(track.title).replace(/%20/g, "+") +
            "+" +
            encodeURIComponent(track.artist).replace(/%20/g, "+");

          if (isDev) {
            console.log("%c🔍 Search Term: ", "color:cyan", term);
          }
          const searchOptions = {
            method: "GET",
            url: `https://api.music.apple.com/v1/catalog/us/search?term=${term}&types=songs`,
            headers: {
              Authorization: "Bearer " + appleDeveloperToken,
              "Content-Type": "application/json"
            }
          };
          const { data } = await axios(searchOptions);
          // I'm only grabbing the first result
          // TODO:  Think about a better way to do this...
          const resultsData = data.results.songs
            ? data.results.songs.data[0]
            : null;
          if (resultsData) {
            resultsData.spotifyInfo = { ...track };
            resultsData.trackType =
              SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY;
            resultsData.matchedBy = MatchedByType.ARTIST_TITLE;
            return resultsData;
          } else {
            if (isDev) {
              console.log(
                `%c🤷 Could not find track with Artist/Title: ${track.artist}/${track.title}`,
                "color:cyan"
              );
            }
            // an Apple Music unmatched from Spotify track is a Spotify track that could not be matched by any Apple Music track
            filteredTracks.push({
              ...track,
              trackType: SlugifyTrackType.APPLE_MUSIC_UNMATCHED_FROM_SPOTIFY
            });
            return filteredTracks[0];
          }
        }
      } catch (error: any) {
        throw new Error(error.response || error);
      }
    };

    try {
      const appleDeveloperToken = await dispatch(getAppleMusicDevToken());
      const spotifyNativeTracks = spotifyTracks
        .filter(
          (track: SpotifyTrack) =>
            track.trackType === SlugifyTrackType.SPOTIFY_NATIVE
        )
        .map((item: SpotifyTrack) => ({
          spotifyId: item.track.id,
          spotifyIsrc: item.track.external_ids.isrc,
          added_by: item.added_by.id,
          album: item.track.album.name,
          playlist_name: item.playlist_name,
          title: item.track.name,
          artist: item.track.artists[0].name,
          albumArt: item.track.album.images[2]
        }));

      const pendingTracks = spotifyNativeTracks.map(
        (track: SpotifyNativeTrack) =>
          getMatchingTrack(track, appleDeveloperToken)
      );

      if (isDev) {
        console.log(
          "%c⏲️ Awaiting Apple Music Matching Track promises...",
          "color:cyan"
        );
      }

      const resolvedTracks = await Promise.all(pendingTracks);

      if (isDev) {
        console.log(
          "%c🧘🏽 Apple Music Matching Track Promises fulfilled",
          "color:lime",
          resolvedTracks
        );
      }

      dispatch({
        type: AppleMusicActionType.GET_MATCHING_APPLE_MUSIC_TRACKS_SUCCESS,
        payload: resolvedTracks
      });
      return Promise.resolve(resolvedTracks);
    } catch (error: any) {
      console.warn(error.response || error);
      dispatch({
        type: "GET_MATCHING_APPLE_MUSIC_TRACKS_FAIL",
        payload: error.response || error
      });
      dispatch(handleUserAlert("GET_MATCHING_APPLE_MUSIC_TRACKS"));
      return Promise.resolve(error.response || error);
    }
  };

// TODO:  delete if unused
export const appleMusicSongSearch =
  (
    album: string,
    artist: string,
    title: string,
    spotifyId: string,
    unmatchedSearch = false
  ): AppThunk<Promise<any>> =>
  async (dispatch) => {
    const appleDeveloperToken = await dispatch(getAppleMusicDevToken());
    let term;
    if (unmatchedSearch) {
      // this pattern doesn't seem to help with improving search results
      // title = title.replace(/[^a-z0-9+]+/gi, "+").replace(/\(.+?\)/g, "");
      // this pattern fixes some matching problems with Spotify tracks.
      // TODO write a looping try/fail function that searches until it finds something.
      // TODO: handle & with endodeURIComponent
      title = title.split(" -")[0];
      term = `${title.replace(/ /g, "+")}+${artist.replace(/ /g, "+")}`;
    } else {
      term = `${title.replace(/ /g, "+")}+${artist.replace(/ /g, "+")}`;
    }
    const searchOptions = {
      method: "GET",
      url: `https://api.music.apple.com/v1/catalog/us/search?term=${term}&limit=3&types=songs`,
      headers: {
        Authorization: "Bearer " + appleDeveloperToken,
        "Content-Type": "application/json"
      }
    };
    try {
      dispatch({
        type: "GET_APPLE_MUSIC_TRACK_SEARCH_RESULTS_START"
      });
      const { data } = await axios(searchOptions);
      let searchResults: AppleMusicTrack[] = [];
      if (data.results.songs) {
        if (isDev) {
          console.log(
            "%cSEARCH RESULTS: ",
            "color:lime",
            data.results.songs.data
          );
        }
        searchResults = [...data.results.songs.data];
      } else {
        if (isDev) {
          console.log(
            `%cNOTHING WAS FOUND SEARCHING FOR ${term}!`,
            "color:yellow"
          );
        }
      }
      dispatch({
        type: "GET_APPLE_MUSIC_TRACK_SEARCH_RESULTS_SUCCESS",
        payload: { artist, album, spotifyId, title, searchResults }
      });
    } catch (e) {
      dispatch({
        type: "GET_APPLE_MUSIC_TRACK_SEARCH_RESULTS_FAIL",
        payload: e
      });
      console.warn(e);
    }
  };
export const updateAppleMusicPublicPlaylists =
  (playlists: AppleMusicPlaylist[]): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPDATE_APPLE_MUSIC_PUBLIC_PLAYLISTS_START"
      });
      const {
        auth: { username },
        newUser: { publicPlaylists }
      } = getState();

      const publicCloudPlaylists = playlists
        .filter((item) => item.attributes.playParams.globalId)
        .map((item) => ({
          id: item.attributes.playParams.globalId,
          name: item.attributes.name,
          owner: username
        }));

      const currentPublicPlaylists = publicPlaylists ? publicPlaylists : [];

      const diff1 = differenceBy(
        currentPublicPlaylists,
        publicCloudPlaylists,
        "id"
      );
      const diff2 = differenceBy(
        publicCloudPlaylists,
        currentPublicPlaylists,
        "id"
      );
      const diff3 = [...diff2, ...diff1];
      // console.warn("diff1", diff1);
      // console.warn("diff2", diff2);
      // console.warn("diff3", diff3);

      if (diff3.length) {
        if (isDev) {
          console.log("%c🥯 Updating public playlists!", "color:cyan");
        }
        await dispatch(
          updateSlugifyUser({ publicPlaylists: publicCloudPlaylists })
        );
      }
      dispatch({
        type: "UPDATE_APPLE_MUSIC_PUBLIC_PLAYLISTS_SUCCESS"
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_APPLE_MUSIC_PUBLIC_PLAYLISTS_FAIL",
        payload: error
      });
    }
  };
