// eslint-disable jsx-a11y/anchor-is-valid
import { useContext, useState } from "react";
import { getMusicStreamIds } from "../actions";
import { getTitleCase } from "../functions";
import { MyFriendsSharedPlaylists } from "./MyFriendsSharedPlaylists";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Friend } from "../types";
import { ThemeContext } from "../contexts/ColorThemeProvider";

export const FriendsPlaylists = () => {
  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);
  const {
    newUser: { friends, myFriendsSharedPlaylists }
  } = useAppSelector((state) => state);

  const [isSpinning, setIsSpinning] = useState(false);
  const renderFriends = () => {
    const friendsWithSharedPlaylists: string = myFriendsSharedPlaylists.map(
      (item: any) => item.owner
    );
    return friends
      .filter((item: Friend) =>
        friendsWithSharedPlaylists.includes(item.username)
      )
      .map((item: Friend) => (
        <div key={item.username} className="d-flex flex-column mt-3 w-100">
          <div className="slug-subtitle text-left">
            {getTitleCase(item.username)}
          </div>
          <MyFriendsSharedPlaylists friendUsername={item.username} />
        </div>
      ));
  };

  return (
    <div
      className="container d-flex flex-column align-items-center w-100 mt-3 mt-sm-5 animate__animated animate__fadeIn"
      style={{ maxWidth: 800 }}
    >
      <div className="slug-title text-body-secondary text-center">
        Friend&apos;s Playlists
      </div>
      {renderFriends()}
      <button
        className={`d-flex justify-content-center align-items-center btn ${
          isDark ? "btn-outline-info" : "btn-info"
        } my-4 animate__animated animate__fadeIn mt-3 w-100 `}
        onClick={async () => {
          setIsSpinning(true);
          await dispatch(getMusicStreamIds());
          setIsSpinning(false);
        }}
        // style={{ maxWidth: 300 }}
      >
        <span
          className={`icon-loop slug-icon ${
            isSpinning ? "rotate-counter-clockwise" : ""
          }`}
        />
        <span className="ms-2">Check for Updates</span>
      </button>
    </div>
  );
};
