import { FriendListItem } from "./FriendListItem";
import { useAppSelector } from "../hooks";
import type { Friend } from "../types";

export default function FriendList() {
  const {
    newUser: { friends }
  } = useAppSelector((state) => state);
  console.log("%cFRIENDS", "color:orange", friends);
  const renderFriends = () =>
    friends.map((friend: Friend) => (
      <FriendListItem key={friend.username} friend={friend} />
    ));

  return <ul className="list-group w-100 mt-4 mb-2">{renderFriends()}</ul>;
}
