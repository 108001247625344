import axios from "axios";
import { compact, flatMap, flatten } from "lodash";
import { v4 as uuid } from "uuid";
import { uniq } from "lodash";
import {
  getMatchingAppleMusicTracks,
  getAllTracksFromAppleMusicPlaylists,
  createAppleMusicSlugifyPlaylist,
  getAppleMusicDevToken
} from "./appleMusicActions";
import {
  checkSpotifyClientCredentialsToken,
  getSpotifyClientCredentialsToken,
  checkSpotifyIsConnected,
  getMatchingSpotifyTracks,
  createSpotifySlugifyPlaylist,
  removeTracksFromSpotifyPlaylist
} from "./spotifyActions";
import { updateFilteredTracks, updateTutorialStatus } from "./userActions";
import { getError } from "../selectors";
import {
  getAllSlurpedTracks,
  getSlugifyUser,
  createSlugifyUser,
  findFriend,
  createSlugifyFriend,
  getMySlugifyFriends,
  deleteSlugifyFriend,
  deleteStaleSlugifySlurpedTracks
} from "./slugifyGraphqlActions";
import {
  getPrimaryMusicStreamName,
  getRandomErrorEmoji,
  getTitleCase
} from "../functions";
import dayjs from "dayjs";
import {
  getFriendsYouTubePlaylists,
  getMyYouTubeChannel,
  getMyYoutubePlaylists,
  getVideosFromYouTubePlaylist,
  getTracksFromYouTubeSlugifyPlaylist,
  handleYouTubePlaylists,
  setYouTubeSignInStatus
} from "./youTubeActions";
import {
  SlugifyActionType,
  SlugifyTrackType,
  UserActionType,
  YouTubeActionType
} from "../types";
import { SpotifyActionType } from "../types/spotify";

const isDev = process.env.NODE_ENV === "development";

export const slugifyInit = (signInData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "SLUGIFY_INIT_START" });
    dispatch({
      type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
      payload: "Initializing..."
    });
    const {
      auth: { userId }
    } = getState();

    if (!userId) {
      dispatch({ type: "SET_AUTH_USER_DATA", signInData });
    }
    if (!userId && !signInData.userId) {
      throw new Error("No userId!");
    }
    dispatch({
      type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
      payload: "Checking Slugify account..."
    });
    const user = await dispatch(
      getSlugifyUser(userId ? userId : signInData.userId)
    );
    if (user.username) {
      if (isDev) {
        console.log(`%c🙆 User, ${user.username}, found in db!`, "color:lime");
      }
      dispatch({
        type: UserActionType.SET_USER_DATA,
        payload: user
      });
    } else {
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Yay, we have a new user!"
      });
      dispatch(updateTutorialStatus(true));
      const newUser = await dispatch(
        createSlugifyUser({
          id: userId ? userId : signInData.userId,
          username: signInData.username
        })
      );
      dispatch({
        type: UserActionType.SET_USER_DATA,
        payload: newUser
      });
      console.log(`👶 New user, ${newUser.username}, created in db!`);
    }
    dispatch({
      type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
      payload: "Accessing Apple Music..."
    });
    await dispatch(getAppleMusicDevToken());
    dispatch({
      type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
      payload: "Synchronizing with Spotify..."
    });
    await dispatch(getSpotifyClientCredentialsToken());
    dispatch({ type: "SLUGIFY_INIT_SUCCESS" });
    dispatch({
      type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
      payload: "Initialization complete!"
    });
    return Promise.resolve();
  } catch (error) {
    console.warn(error);
    dispatch({
      type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
      payload: "Ah, Houston, we've had a problem...."
    });
    dispatch({ type: "SLUGIFY_INIT_FAIL", payload: error });
    dispatch(handleUserAlert(["SLUGIFY_INIT"]));
    return Promise.resolve(error);
  }
};

export const slugifyPostInit =
  (setIsRefreshing) => async (dispatch, getState) => {
    try {
      dispatch({ type: "SLUGIFY_LOADING_START" });
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Getting groove on..."
      });
      await dispatch(resetSlugifyTracks());
      await dispatch(getAllSlurpedTracks());
      await dispatch(getMusicStreamIds());
      const {
        slugify: { spotifyUserIds }
      } = getState();
      if (spotifyUserIds.length) {
        const spotifyIsConnected = await dispatch(checkSpotifyIsConnected());
        if (spotifyIsConnected) {
          await dispatch(createSpotifySlugifyPlaylist());
        } else {
          if (isDev) {
            console.log(
              "🤷 Spotify is not connected.  Skipping create Slugify playlist on Spotify process."
            );
          }
        }
        const spotifyPlaylists = await dispatch(getAllSpotifyPlaylists());
        if (!spotifyPlaylists) {
          if (isDev) {
            console.log("%c🤷 No spotify playlists", "color:yellow");
          }
          dispatch({
            type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
            payload: "🤷 No spotify playlists.  Skipping fetching of tracks."
          });
        } else {
          dispatch({
            type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
            payload: "Sorting Spotify tracks..."
          });
          const spotifyTrackItems = await dispatch(
            getAllTracksFromSpotifyPlaylists(spotifyPlaylists)
          );
          if (!spotifyTrackItems) {
            dispatch({
              type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
              payload: "🤷 No Spotify track items."
            });
            if (isDev) {
              console.log("🤷 No Spotify tracks!");
            }
          } else {
            if (isDev) {
              console.log(`📀 Spotify tracks: ${spotifyTrackItems.length}`);
            }
          }
        }
      } else {
        if (isDev) {
          console.log("🤷 No Spotify users.  Skipped spotify processes");
        }
      }
      await dispatch(createAppleMusicSlugifyPlaylist());
      // current Apple Music user playlists are fetched in createAppleMusicSlugifyPlaylist
      // no need to do this twice
      // await dispatch(getCurrentUserAppleMusicPlaylists());
      const appleMusicTracks = await dispatch(
        getAllTracksFromAppleMusicPlaylists()
      );
      const matchedAppleMusicTracks = await dispatch(
        getMatchingAppleMusicTracks()
      );
      if (!matchedAppleMusicTracks) {
        console.warn("🤷 No matched Apple Music tracks");
      }
      if (!appleMusicTracks || !appleMusicTracks.length) {
        if (isDev) {
          console.log(
            "🤷 No Apple Music tracks.  Skipping Spotify track matching."
          );
        }
      } else {
        await dispatch(getMatchingSpotifyTracks());
        dispatch({
          type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
          payload: "Matching Apple Music tracks..."
        });
      }
      await dispatch(setYouTubeSignInStatus());
      const channel = await dispatch(getMyYouTubeChannel());
      if (channel) {
        const myYtPlaylists = await dispatch(getMyYoutubePlaylists());
        await dispatch(handleYouTubePlaylists(myYtPlaylists));
        await dispatch(getTracksFromYouTubeSlugifyPlaylist());
        const ytPlaylists = await dispatch(getFriendsYouTubePlaylists());
        if (ytPlaylists) {
          for (const playlist of ytPlaylists) {
            const playlistId = playlist.id;
            const videos = await dispatch(
              getVideosFromYouTubePlaylist(playlistId)
            );
            if (videos) {
              dispatch({
                type: YouTubeActionType.SET_YOUTUBE_VIDEOS,
                payload: videos
              });
            }
          }
        }
      }
      if (isDev) {
        console.log("%cDeleting stale tracks is disabled!", "color:red");
      }
      // await dispatch(checkForStaleSlurpedTracks());
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Ready to rock!!"
      });
      dispatch({ type: "SLUGIFY_LOADING_SUCCESS" });
      if (setIsRefreshing) {
        setIsRefreshing(false);
      }
      return Promise.resolve();
    } catch (error) {
      console.warn(error);
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Ah, Houston, we've had a problem...."
      });
      dispatch({
        type: "SLUGIFY_LOADING_FAIL",
        payload: error
      });
      dispatch(handleUserAlert(["SLUGIFY_LOADING"]));
      if (setIsRefreshing) {
        setIsRefreshing(false);
      }
      return Promise.resolve(error.response || error);
    }
  };

export const addFriend = (friendUsername) => async (dispatch, getState) => {
  const {
    newUser: { primaryMusicStream: myPrimaryMusicStream }
  } = getState();
  try {
    dispatch({ type: "ADD_FRIEND_START" });
    const response = await dispatch(findFriend(friendUsername));
    if (!response.length) {
      dispatch({
        type: "ADD_FRIEND_FAIL",
        payload: "Cannot find friend with that username"
      });
      return Promise.reject(new Error("Cannot find friend with that username"));
    }
    const friend = response[0];
    console.warn(
      "This doesn't work because appleMusicSlugifyPlaylist is missing when user is new or just connected!"
    );
    if (
      !friend.primaryMusicStream ||
      (friend.primaryMusicStream === "appleMusic" &&
        !friend.appleMusicSlugifyPlaylist) ||
      (friend.primaryMusicStream === "spotify" &&
        (!friend.spotifyUserData || !friend.spotifyUserData.id)) ||
      (friend.primaryMusicStream === "youtube" &&
        (!friend.youTubeUserData || !friend.youTubeUserData.id))
    ) {
      dispatch({
        type: "ADD_FRIEND_FAIL",
        payload: "Friend has not yet connected a music stream"
      });
      return Promise.reject(
        new Error("Friend has not yet connected a music stream")
      );
    }
    if (friend.primaryMusicStream === myPrimaryMusicStream) {
      let errMsg = `You can't add friends on ${getPrimaryMusicStreamName(
        myPrimaryMusicStream
      )}`;
      if (myPrimaryMusicStream === "appleMusic") {
        errMsg = "You can only add Friends on Spotify or YouTube.";
      } else if (myPrimaryMusicStream === "spotify") {
        errMsg = "You can only add Friends on Apple Music or YouTube.";
      } else if (myPrimaryMusicStream === "youtube") {
        errMsg = "You can only add Friends on Apple Music or Spotify.";
      }
      dispatch({
        type: "ADD_FRIEND_FAIL",
        payload: "Attempted to add friend with same music stream."
      });
      return Promise.reject(new Error(errMsg));
    }

    dispatch({
      type: "UPDATE_ADD_DELETE_FRIEND_STATUS",
      payload: "🧟  Adding friend..."
    });

    const {
      primaryMusicStream: friendMusicStream,
      appleMusicSlugifyPlaylist: friendSlugifyPlaylist,
      spotifyUserData: friendSpotifyUserData,
      youtubeUserData: friendYouTubeUserData,
      id: friendUserId
    } = friend;

    let musicStreamId = null;
    if (friendMusicStream === "appleMusic") {
      musicStreamId = friendSlugifyPlaylist;
    } else if (friendMusicStream === "spotify") {
      musicStreamId = friendSpotifyUserData.id;
    } else if (friendMusicStream === "youtube") {
      musicStreamId = friendYouTubeUserData.id;
    }

    const newFriend = {
      userId: friendUserId,
      username: friendUsername,
      musicStreamId,
      primaryMusicStream: friendMusicStream
    };

    dispatch({
      type: "UPDATE_ADD_DELETE_FRIEND_STATUS",
      payload: "🥤 Slurping friend's music..."
    });

    const createdFriend = await dispatch(createSlugifyFriend(newFriend));
    dispatch({ type: "ADD_FRIEND_SUCCESS", payload: createdFriend });
    dispatch({
      type: "UPDATE_ADD_DELETE_FRIEND_STATUS",
      payload: "⚗️  Distilling tracks..."
    });
    await dispatch(slugifyPostInit());
    await dispatch(updateFilteredTracks());

    dispatch({ type: "UPDATE_ADD_DELETE_FRIEND_STATUS", payload: "" });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: "ADD_FRIEND_FAIL",
      payload: error.response || error
    });
    dispatch(handleUserAlert(["ADD_FRIEND"]));
    console.warn(error.response || error);
    return Promise.resolve(error.response || error);
  }
};

export const deleteFriend = (friendId, friendUsername) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_FRIEND_START" });
    dispatch({
      type: "UPDATE_ADD_DELETE_FRIEND_STATUS",
      payload: "🗑️ Deleting friend..."
    });

    await dispatch(deleteSlugifyFriend(friendId));
    dispatch({
      type: "UPDATE_ADD_DELETE_FRIEND_STATUS",
      payload: "📻 Removing music..."
    });
    await dispatch(removeFriendMusicData(friendUsername));
    dispatch({
      type: "UPDATE_ADD_DELETE_FRIEND_STATUS",
      payload: "🧹 Cleaning up..."
    });
    await dispatch(getMusicStreamIds());
    console.warn("parse this down for only changing one user");
    await dispatch(slugifyPostInit());
    await dispatch(updateFilteredTracks());
    dispatch({
      type: "UPDATE_ADD_DELETE_FRIEND_STATUS",
      payload: ""
    });
    dispatch({ type: "DELETE_FRIEND_SUCCESS", payload: { id: friendId } });
    return Promise.resolve("success");
  } catch (error) {
    dispatch({ type: "DELETE_FRIEND_FAIL", payload: error });
    dispatch(handleUserAlert(["DELETE_FRIEND"]));
    return Promise.reject(error);
  }
};

export const checkForStaleSlurpedTracks = () => async (dispatch, getState) => {
  // a stale slurped track is a SlugifySlurped track that no longer exists in
  // the Slugify playlist of the person who added the original track
  const {
    auth: { username },
    appleMusic: {
      tracks: appleMusicTracks,
      unmatchedTracks: unmatchedAppleMusicTracks
    },
    slugify: {
      spotifyTracks,
      slurpedTracks,
      unmatchedTracks: unmatchedSpotifyTracks
    },
    newUser: { primaryMusicStream }
  } = getState();

  const checkShouldDelete = (slurpedTrack) => {
    if (primaryMusicStream === "appleMusic") {
      if (
        slurpedTrack.trackType ===
        SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY
      ) {
        const existingAppleMusicTracks = appleMusicTracks
          .filter(
            (item) =>
              item.trackType ===
                SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY &&
              item.spotifyInfo.added_by === slurpedTrack.originalTrackAddedBy
          )
          .map((item) => item.spotifyInfo.spotifyId);
        if (!existingAppleMusicTracks.includes(slurpedTrack.spotifyId)) {
          return slurpedTrack.id;
        }
      } else if (
        slurpedTrack.trackType ===
        SlugifyTrackType.APPLE_MUSIC_UNMATCHED_FROM_SPOTIFY
      ) {
        const existingAppleMusicUnmatchedTracks = unmatchedAppleMusicTracks
          .filter(
            (item) =>
              item.trackType ===
                SlugifyTrackType.APPLE_MUSIC_UNMATCHED_FROM_SPOTIFY &&
              item.added_by.id === slurpedTrack.originalTrackAddedBy
          )
          .map((item) => item.track.id);
        if (isDev) {
          console.log(
            "%c Existing Apple Music Unmatched Tracks",
            "color:pink",
            existingAppleMusicUnmatchedTracks
          );
        }
        if (
          !existingAppleMusicUnmatchedTracks.includes(slurpedTrack.spotifyId)
        ) {
          return slurpedTrack.id;
        }
      }
      return null;
    } else if (primaryMusicStream === "spotify") {
      if (
        slurpedTrack.trackType ===
        SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC
      ) {
        const existingSpotifyTracks = spotifyTracks
          .filter((item) => {
            return (
              item.trackType ===
                SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC &&
              item.appleMusicInfo.addedBy === slurpedTrack.originalTrackAddedBy
            );
          })
          .map((item) => item.appleMusicInfo.appleMusicId);
        if (!existingSpotifyTracks.includes(slurpedTrack.appleMusicId)) {
          return slurpedTrack.id;
        }
      } else if (
        slurpedTrack.trackType ===
        SlugifyTrackType.SPOTIFY_UNMATCHED_FROM_APPLE_MUSIC
      ) {
        const existingSpotifyUnmatchedTracks = unmatchedSpotifyTracks
          .filter((item) => {
            return (
              item.trackType ===
                SlugifyTrackType.SPOTIFY_UNMATCHED_FROM_APPLE_MUSIC &&
              item.added_by.id === slurpedTrack.originalTrackAddedBy
            );
          })
          .map((item) => item.appleMusicInfo.appleMusicId);
        if (isDev) {
          console.log(
            "%cExisting Apple Music Unmatched Tracks",
            "color:pink",
            existingSpotifyUnmatchedTracks
          );
        }
        if (
          !existingSpotifyUnmatchedTracks.includes(slurpedTrack.appleMusicId)
        ) {
          return slurpedTrack.id;
        }
        return null;
      }
      return null;
    }
  };
  try {
    dispatch({ type: "CHECK_FOR_STALE_SLURPED_TRACKS_START" });
    console.warn(
      "This isn't handling unmatched tracks! Or is it?",
      slurpedTracks.filter(
        (item) =>
          item.owner === username && item.trackType.includes("unmatched")
      )
    );
    const mySlurpedTracks = slurpedTracks.filter(
      (item) => item.owner === username
    );

    const slurpedTracksToDelete = compact(
      mySlurpedTracks.map((track) => checkShouldDelete(track))
    );
    if (slurpedTracksToDelete.length) {
      if (slurpedTracksToDelete.length === 1) {
        if (isDev) {
          console.log(
            `🐷 There is ${slurpedTracksToDelete.length} stale slurped track to clean!`
          );
        }
      } else {
        if (isDev) {
          console.log(
            `🐷 There are ${slurpedTracksToDelete.length} stale slurped tracks to clean!`
          );
        }
      }
      await dispatch(deleteStaleSlugifySlurpedTracks(slurpedTracksToDelete));
    } else {
      if (isDev) {
        console.log(`🤷🏻‍♀️ No stale slurped tracks to clean`);
      }
    }
    dispatch({ type: "CHECK_FOR_STALE_SLURPED_TRACKS_SUCCESS" });
    return Promise.resolve();
  } catch (error) {
    dispatch({ type: "CHECK_FOR_STALE_SLURPED_TRACKS_FAIL", payload: error });
    return Promise.reject(error);
  }
};

export const getMusicStreamIds = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_MUSIC_STREAM_IDS_START" });
    const {
      auth: { username: currentUsername },
      newUser: { primaryMusicStream }
    } = getState();

    const response = await dispatch(getMySlugifyFriends());
    // Apple API doesn't let you fetch another users's playlists, unless you know the ID.
    // So I have to do this stuff...
    const appleMusicSlugifyPlaylists = response.map((user) =>
      user.appleMusicSlugifyPlaylist ? user.appleMusicSlugifyPlaylist : null
    );
    const appleMusicPublicPlaylists = flatten(
      compact(response.map((user) => user.publicPlaylists))
    )
      .filter((playlist) => {
        if (primaryMusicStream === "appleMusic") {
          return playlist.name.includes("Slugify Direct");
        }
        return playlist.name.includes(
          `${getTitleCase(currentUsername)} (Slugify Direct)`
        );
      })
      .map((item) => item.id + "__" + item.name);

    const appleMusicPlaylists = [
      ...appleMusicSlugifyPlaylists,
      ...appleMusicPublicPlaylists
    ];

    const spotifyUserIds = response.map((user) =>
      user.spotifyUserData ? user.spotifyUserData.id : null
    );

    const payload = {
      appleMusicPlaylists: compact(appleMusicPlaylists),
      spotifyUserIds: compact(spotifyUserIds)
    };

    const myFriendsPublicPlaylists = flatten(
      response
        .filter(
          (friend) =>
            friend.publicPlaylists && friend.username !== currentUsername
        )
        .map((friend) =>
          friend.publicPlaylists.map((item) => ({
            owner: friend.username,
            id: item.id,
            name: item.name
          }))
        )
    );

    dispatch({
      type: "UPDATE_MY_FRIENDS_PUBLIC_PLAYLISTS",
      payload: myFriendsPublicPlaylists
    });

    const myFriendsSharedPlaylists = flatten(
      response
        .filter(
          (friend) =>
            friend.sharedPlaylists && friend.username !== currentUsername
        )
        .map((friend) =>
          friend.sharedPlaylists.map((item) => ({
            owner: friend.username,
            id: item.id,
            name: item.name
          }))
        )
    );

    dispatch({
      type: "UPDATE_MY_FRIENDS_SHARED_PLAYLISTS",
      payload: myFriendsSharedPlaylists
    });

    dispatch({
      type: SlugifyActionType.GET_MUSIC_STREAM_IDS_SUCCESS,
      payload
    });
    return Promise.resolve(payload);
  } catch (error) {
    console.warn(error.response || error);
    dispatch({
      type: "GET_MUSIC_STREAM_IDS_FAIL",
      payload: error
    });
    return Promise.reject(error.response || error);
  }
};

export const cleanMySpotifyPlaylists = () => async (dispatch, getState) => {
  try {
    const {
      slugify: { spotifyTracks },
      newUser: { primaryMusicStream }
    } = getState();

    const cleaningResults = [];

    if (primaryMusicStream === "spotify") {
      dispatch({
        type: "CLEAN_MY_SPOTIFY_PLAYLISTS_START"
      });

      const tracksToClean = spotifyTracks.filter((item) => {
        if (
          item.slurpStatus === "unslurped" &&
          item.added_at &&
          dayjs(item.added_at).isBefore(dayjs().subtract(30, "day"))
        ) {
          console.warn("Track is expired!", item);
        }
        return (
          item.slurpStatus === "fullySlurped" ||
          (item.slurpStatus === "unslurped" &&
            item.added_at &&
            dayjs(item.added_at).isBefore(dayjs().subtract(30, "day")))
        );
      });

      const playlistsToClean = {};

      for (let track of tracksToClean) {
        if (playlistsToClean[track.playlist_id]) {
          playlistsToClean[track.playlist_id].push(track);
        } else {
          playlistsToClean[track.playlist_id] = [track];
        }
      }

      for (let playlistToClean in playlistsToClean) {
        const playlistToCleanTracks = playlistsToClean[playlistToClean];

        const trackUrisToClean = {
          tracks: [
            ...playlistToCleanTracks.map((item) => ({
              uri: item.track.uri
            }))
          ]
        };

        const response = await dispatch(
          removeTracksFromSpotifyPlaylist(playlistToClean, trackUrisToClean)
        );

        cleaningResults.push(response);

        dispatch({
          type: SpotifyActionType.CLEAN_SPOTIFY_PLAYLIST,
          payload: [...playlistToCleanTracks.map((item) => item.track.id)]
        });
      }
      if (cleaningResults.length) {
        dispatch({
          type: "SET_SLUGIFY_ALERT",
          payload: {
            alertType: "info",
            message: `${
              cleaningResults.length === 1
                ? "Slugify has cleaned up a Spotify playlist for you"
                : "Slugify has cleaned up " +
                  cleaningResults.length +
                  " of your Spotify playlists"
            }`,
            emoticon: "✨",
            title: "It's Automagic!"
          }
        });
      }
    }
    dispatch({ type: "CLEAN_MY_SPOTIFY_PLAYLISTS_SUCCESS" });
    return Promise.resolve(cleaningResults);
  } catch (error) {
    console.warn(error);
    dispatch({
      type: "CLEAN_MY_SPOTIFY_PLAYLISTS_FAIL",
      payload: error
    });
  }
};

export const getAllSpotifyPlaylists = () => async (dispatch, getState) => {
  dispatch({ type: SlugifyActionType.GET_ALL_SPOTIFY_USERS_PLAYLISTS_START });
  const {
    slugify: { spotifyUserIds }
  } = getState();
  if (!spotifyUserIds || !spotifyUserIds.length) {
    return Promise.resolve([]);
  }
  const getPlaylists = async (id, access_token) => {
    if (isDev) {
      console.log(
        `🐕 Fetching Spotify playlists for Spotify user ${
          spotifyUserIds.indexOf(id) + 1
        } of ${spotifyUserIds.length}`
      );
    }
    try {
      const options = {
        method: "GET",
        url: `https://api.spotify.com/v1/users/${id}/playlists?limit=50`,
        headers: { Authorization: "Bearer " + access_token },
        json: true
      };
      const { data } = await axios(options);
      return data;
    } catch (error) {
      console.warn(error.response || error);
    }
  };
  const accessToken = await dispatch(checkSpotifyClientCredentialsToken());
  dispatch({
    type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
    payload: "Retrieving Spotify playlists..."
  });
  const pendingPlaylists = spotifyUserIds.map((id) =>
    getPlaylists(id, accessToken)
  );
  if (isDev) {
    console.log("%c⏲️ Waiting for Spotify playlists...", "color:cyan");
  }
  const playlists = await Promise.all(pendingPlaylists);
  if (isDev) {
    console.log(
      "%c🧘🏽 All Spotify playlist promises fulfilled",
      "color:lime",
      playlists
    );
  }
  if (!playlists.includes(undefined)) {
    const playlistItems = flatMap(playlists, (playlist) => playlist.items);
    dispatch({
      type: SlugifyActionType.GET_ALL_SPOTIFY_USERS_PLAYLISTS_SUCCESS,
      payload: playlistItems
    });
    return Promise.resolve(playlistItems);
  } else {
    console.warn("Playlists are buggered!");
    dispatch({
      type: SlugifyActionType.GET_ALL_SPOTIFY_USERS_PLAYLISTS_FAIL,
      payload: { message: "Playlists are buggered" }
    });
    dispatch(handleUserAlert(["GET_ALL_SPOTIFY_USERS_PLAYLISTS"]));
    return Promise.resolve("Playlists are buggered");
  }
};

export const getAllTracksFromSpotifyPlaylists =
  (playlists) => async (dispatch, getState) => {
    const getTracks = async (playlist, accessToken) => {
      if (isDev) {
        console.log(
          `🐕 Fetching Spotify tracks from ${playlist.name} playlist by ${playlist.owner.display_name}`
        );
      }
      try {
        const options = {
          method: "GET",
          url: `https://api.spotify.com/v1/playlists/${playlist.id}/tracks`,
          headers: { Authorization: "Bearer " + accessToken },
          json: true
        };
        const { data } = await axios(options);
        if (isDev) {
          console.log(
            `🦴 ${playlist.owner.display_name}'s Spotify tracks fetched from ${playlist.name}!`,
            data.items
          );
        }
        data.playlist_id = playlist.id;
        data.playlist_name = playlist.name;
        return data;
      } catch (error) {
        console.warn(error.response || error);
        throw new Error(error.response || error);
      }
    };
    try {
      dispatch({ type: "GET_ALL_TRACKS_FROM_SPOTIFY_PLAYLISTS_START" });
      const {
        spotify: {
          clientCredentialsTokenData: { access_token: accessToken }
        }
      } = getState();
      const slugifyPlaylists = playlists.filter(
        (item) =>
          item.name === "Slugify" || item.name.includes("Slugify Direct")
      );
      const pendingTracks = slugifyPlaylists.map((playlist) =>
        getTracks(playlist, accessToken)
      );
      if (isDev) {
        console.log("%c⏲️ Waiting for Spotify tracks...", "color:cyan");
      }
      const tracks = await Promise.all(pendingTracks);
      if (isDev) {
        console.log(
          "%c🧘🏽 All Spotify track promises fulfilled",
          "color:lime",
          tracks
        );
      }

      const trackItems = flatMap(tracks, (track) =>
        track.items.map((item) => ({
          ...item,
          playlist_id: track.playlist_id,
          playlist_name: track.playlist_name,
          slugify_id: item.track.id + "_" + track.playlist_id + "_" + uuid(),
          trackType: SlugifyTrackType.SPOTIFY_NATIVE,
          slurpedBy: []
        }))
      );

      dispatch({
        type: SlugifyActionType.GET_ALL_TRACKS_FROM_SPOTIFY_PLAYLISTS_SUCCESS,
        payload: trackItems
      });
      return Promise.resolve(trackItems);
    } catch (error) {
      console.warn(error.response || error);
      dispatch({
        type: "GET_ALL_TRACKS_FROM_SPOTIFY_PLAYLISTS_FAIL",
        payload: error.response || error
      });
      dispatch(handleUserAlert("GET_ALL_TRACKS_FROM_SPOTIFY_PLAYLISTS"));
      return Promise.resolve(error.response || error);
    }
  };

export const getSlugifyPublicPlaylist = () => async (dispatch, getState) => {
  const sendHighlander = (stream) => {
    if (isDev) {
      console.log(`⚔️ There is more than one Slugify playlist on ${stream}!`);
    }
    dispatch({
      type: "CREATE_SPOTIFY_SLUGIFY_PLAYLIST_ALERT",
      payload: {
        alertType: "secondary",
        message: `There is more than one Slugify playlist on ${stream}!`,
        messageIsHtml: true,
        emoticon: "⚔️",
        title: "There can only be one Highlander!"
      }
    });
    dispatch({
      type: "GET_SLUGIFY_PUBLIC_PLAYLIST_FAIL",
      payload: new Error("There are multiple Slugify playlists!")
    });
  };

  const {
    appleMusic: { myPlaylists: myAppleMusicPlaylists },
    newUser: { primaryMusicStream },
    spotify: { myPlaylists: mySpotifyPlaylists },
    youtube: { myPlaylists: myYouTubePlaylists }
  } = getState();

  dispatch({ type: "GET_SLUGIFY_PUBLIC_PLAYLIST_START" });
  if (primaryMusicStream === "appleMusic") {
    const mySlugifyPlaylists = myAppleMusicPlaylists.filter(
      (item) => item.attributes.name.toLowerCase() === "slugify"
    );
    if (mySlugifyPlaylists.length > 1) {
      sendHighlander("Apple Music");
      return Promise.resolve(null);
    }
    if (mySlugifyPlaylists.length === 1) {
      const isPublic = !!mySlugifyPlaylists[0].attributes.playParams.globalId;
      const publicSlugifyPlaylist =
        mySlugifyPlaylists[0].attributes.playParams.globalId;
      if (isPublic) {
        dispatch({
          type: "GET_SLUGIFY_PUBLIC_PLAYLIST_SUCCESS",
          payload: mySlugifyPlaylists[0].attributes.playParams.globalId,
          primaryMusicStream
        });
        return Promise.resolve(publicSlugifyPlaylist);
      } else {
        dispatch({
          type: "GET_SLUGIFY_PUBLIC_PLAYLIST_FAIL",
          payload: new Error("Slugify playlist is not public")
        });
        return Promise.resolve(null);
      }
    } else {
      dispatch({
        type: "GET_SLUGIFY_PUBLIC_PLAYLIST_FAIL",
        payload: new Error("There is no Slugify playlist")
      });
      return Promise.resolve(null);
    }
  } else if (primaryMusicStream === "spotify") {
    const mySlugifyPlaylists = mySpotifyPlaylists.filter(
      (item) => item.name.toLowerCase() === "slugify"
    );
    if (mySlugifyPlaylists.length > 1) {
      sendHighlander("Spotify");
      return Promise.resolve(null);
    }
    if (mySlugifyPlaylists.length === 1) {
      const publicSlugifyPlaylist = mySlugifyPlaylists[0].public;
      if (publicSlugifyPlaylist) {
        dispatch({
          type: "GET_SLUGIFY_PUBLIC_PLAYLIST_SUCCESS",
          payload: publicSlugifyPlaylist,
          primaryMusicStream
        });
        return Promise.resolve(publicSlugifyPlaylist);
      } else {
        dispatch({
          type: "GET_SLUGIFY_PUBLIC_PLAYLIST_FAIL",
          payload: new Error("Slugify playlist is not public")
        });
        return Promise.resolve(null);
      }
    } else {
      dispatch({
        type: "GET_SLUGIFY_PUBLIC_PLAYLIST_FAIL",
        payload: new Error("There is no Slugify playlist")
      });
      return Promise.resolve(null);
    }
  } else if (primaryMusicStream === "youtube") {
    const mySlugifyPlaylists = myYouTubePlaylists.filter(
      (item) => item.name.toLowerCase() === "slugify"
    );
    if (mySlugifyPlaylists.length > 1) {
      if (isDev) {
        console.log("%cMake this work for youtube", "color:red");
      }
      sendHighlander("YouTube");
      return Promise.resolve(null);
    }
    if (mySlugifyPlaylists.length === 1) {
      const isPublic = mySlugifyPlaylists[0].privacyStatus === "public";
      if (isPublic) {
        // dispatch({
        //   type: "GET_SLUGIFY_PUBLIC_PLAYLIST_SUCCESS",
        //   payload: { publicSlugifyPlaylist },
        //   primaryMusicStream
        // });
        return Promise.resolve(isPublic);
      } else {
        dispatch({
          type: "GET_SLUGIFY_PUBLIC_PLAYLIST_FAIL",
          payload: new Error("Slugify playlist is not public")
        });
        return Promise.reject(new Error("Slugify playlist is not public"));
      }
    } else {
      dispatch({
        type: "GET_SLUGIFY_PUBLIC_PLAYLIST_FAIL",
        payload: new Error("There is no Slugify playlist")
      });
      return Promise.resolve(null);
    }
  } else {
    dispatch({
      type: "GET_SLUGIFY_PUBLIC_PLAYLIST_FAIL",
      payload: new Error("No primary music stream selected")
    });
    return Promise.resolve(null);
  }
};

export const removeFriendMusicData = (friendUsername) => async (dispatch) => {
  try {
    dispatch({ type: "REMOVE_FRIEND_MUSIC_DATA_START" });
    await dispatch(resetSlugifyTracks());
    await dispatch({ type: "REMOVE_FRIEND", payload: friendUsername });
    dispatch({ type: "REMOVE_FRIEND_MUSIC_DATA_SUCCESS" });
    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: "REMOVE_FRIEND_MUSIC_DATA_FAIL",
      payload: error.response || error
    });
    dispatch(handleUserAlert(["REMOVE_FRIEND_MUSIC_DATA"]));
    return Promise.resolve(error.response || error);
  }
};

export const getTrackSlurpStatus = (
  friends,
  primaryMusicStream,
  slurpedTracks,
  track
) => {
  try {
    const friendNames = friends.map((item) => item.username);
    const updatedTrack = { ...track };

    if (primaryMusicStream === "appleMusic") {
      if (updatedTrack.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE) {
        updatedTrack.slurpedBy = uniq(
          slurpedTracks
            .filter((item) => {
              return (
                friendNames.includes(item.owner) &&
                item.appleMusicId === updatedTrack.id
              );
            })
            .map((item) => item.owner)
        );
        if (
          // for slugify direct tracks
          updatedTrack.playlist.includes("Slugify Direct")
        ) {
          if (updatedTrack.slurpedBy.length === 1) {
            updatedTrack.slurpStatus = "fullySlurped";
          } else {
            updatedTrack.slurpStatus = "unslurped";
          }
          return updatedTrack;
        } else {
          // for slugify tracks
          const slurpCalc = friends.length - updatedTrack.slurpedBy.length;
          if (slurpCalc === friends.length) {
            updatedTrack.slurpStatus = "unslurped";
          } else if (slurpCalc === 0) {
            updatedTrack.slurpStatus = "fullySlurped";
          } else {
            updatedTrack.slurpStatus = "partiallySlurped";
          }
          return updatedTrack;
        }
      } else {
        if (
          updatedTrack.trackType ===
          SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY
        ) {
          updatedTrack.sharedByFriend = friends
            .filter((friend) => {
              return (
                // .id doesn't exist when track is in spotifyInfo
                friend.musicStreamId === updatedTrack.spotifyInfo.added_by
              );
            })
            .map((item) => item.username);
          updatedTrack.slurpStatus = "not my track";
        } else if (
          updatedTrack.trackType ===
          SlugifyTrackType.APPLE_MUSIC_UNMATCHED_FROM_SPOTIFY
        ) {
          updatedTrack.sharedByFriend = friends
            .filter(
              // .id doesn't exist when track is in spotifyInfo
              (friend) => friend.musicStreamId === updatedTrack.added_by
            )
            .map((item) => item.username);
          updatedTrack.slurpStatus = "not my track";
        }
        return updatedTrack;
      }
    } else if (primaryMusicStream === "spotify") {
      if (updatedTrack.trackType === SlugifyTrackType.SPOTIFY_NATIVE) {
        updatedTrack.slurpedBy = uniq(
          slurpedTracks
            .filter(
              (item) =>
                friendNames.includes(item.owner) &&
                item.spotifyId === updatedTrack.track.id
            )
            .map((item) => item.owner)
        );
        if (updatedTrack.playlist_name.includes("Slugify Direct")) {
          // for Slugify Direct Tracks
          if (updatedTrack.slurpedBy.length === 1) {
            updatedTrack.slurpStatus = "fullySlurped";
          } else {
            updatedTrack.slurpStatus = "unslurped";
          }
          return updatedTrack;
        } else {
          // for Slugify Tracks
          const slurpCalc = friends.length - track.slurpedBy.length;
          if (slurpCalc === friends.length) {
            updatedTrack.slurpStatus = "unslurped";
          } else if (slurpCalc === 0) {
            updatedTrack.slurpStatus = "fullySlurped";
          } else {
            updatedTrack.slurpStatus = "partiallySlurped";
          }
          return updatedTrack;
        }
      } else {
        if (
          updatedTrack.trackType ===
          SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC
        ) {
          updatedTrack.sharedByFriend = friends
            .filter(
              (friend) =>
                friend.musicStreamId === updatedTrack.appleMusicInfo.addedBy
            )
            .map((item) => item.username);
          updatedTrack.slurpStatus = "not my track";
        } else if (
          updatedTrack.trackType ===
          SlugifyTrackType.SPOTIFY_UNMATCHED_FROM_APPLE_MUSIC
        ) {
          updatedTrack.sharedByFriend = friends
            .filter((friend) => friend.musicStreamId === updatedTrack.addedBy)
            .map((item) => item.username);
          updatedTrack.slurpStatus = "not my track";
        }
        return updatedTrack;
      }
    } else if (primaryMusicStream === "youtube") {
      return updatedTrack;
    }
  } catch (error) {
    console.warn(error);
  }
};

export const resetSlugifyTracks = () => async (dispatch) => {
  try {
    await dispatch({ type: "RESET_SLUGIFY_TRACKS_START" });
    await dispatch({ type: "RESET_FILTERED_TRACKS" });
    await dispatch({ type: "RESET_APPLE_MUSIC_TRACKS" });
    await dispatch({ type: "RESET_SPOTIFY_TRACKS" });
    await dispatch({ type: "RESET_SLUGIFY_TRACKS_SUCCESS" });
    return Promise.resolve();
  } catch (error) {
    await dispatch({ type: "RESET_SLUGIFY_TRACKS_FAIL", payload: error });
    return Promise.reject(error);
  }
};

export const updateTrackSlurpStatuses = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: "UPDATE_TRACK_SLURP_STATUSES_START"
    });
    const {
      // appleMusic: { tracks: appleMusicTracks },
      // slugify: { spotifyTracks, slurpedTracks },
      slugify: { filteredTracks, slurpedTracks },
      newUser: { friends, primaryMusicStream }
    } = getState();

    // const tracks = [...appleMusicTracks, ...spotifyTracks];
    const tracks = filteredTracks;

    if (!tracks.length) {
      return Promise.resolve([]);
    }
    const pendingStatusUpdates = tracks.map((track) =>
      getTrackSlurpStatus(friends, primaryMusicStream, slurpedTracks, track)
    );
    if (isDev) {
      console.log("%c⏳ Awaiting Slurped Status updates...", "color:cyan");
    }
    const updatedTracks = await Promise.all(pendingStatusUpdates);
    if (isDev) {
      console.log(
        "%c🧘🏽 All Slurped Status update promises fulfilled",
        "color:lime"
      );
    }
    const updatedAppleMusicTracks = updatedTracks.filter(
      (item) => item.trackType && item.trackType.includes("apple")
    );
    const updatedSpotifyTracks = updatedTracks.filter(
      (item) => item.trackType && item.trackType.includes("spotify")
    );
    const updatedYouTubeTracks = updatedTracks.filter(
      (item) => item.kind && item.kind === "youtube#playlistItem"
    );
    if (isDev) {
      console.log("Updated Apple tracks", updatedAppleMusicTracks);
      console.log("Updated Spotify tracks", updatedSpotifyTracks);
      console.log("Updated YouTube tracks", updatedYouTubeTracks);
    }
    // dispatch({
    //   type: "UPDATE_TRACK_SLURP_STATUSES_SUCCESS",
    //   payload: updatedSpotifyTracks
    // });
    dispatch({
      type: SlugifyActionType.UPDATE_TRACK_SLURP_STATUSES_SUCCESS,
      payload: updatedTracks
    });
    return Promise.resolve(updatedTracks);
  } catch (error) {
    dispatch({
      type: "UPDATE_TRACK_SLURP_STATUSES_FAIL",
      payload: error
    });
    return Promise.reject(error);
  }
};

export const handleUserAlert =
  (
    actions,
    alertType = "secondary",
    emoticon = getRandomErrorEmoji(),
    message = "",
    title = "Something's not right..."
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "HANDLE_USER_ALERT_START" });
      if (!message) {
        const { errors } = getState();
        message = getError(actions, errors);
      }
      if (message) {
        dispatch({
          type: "HANDLE_USER_ALERT_SUCCESS",
          payload: {
            alertType,
            message,
            emoticon,
            title
          }
        });
      } else {
        dispatch({
          type: "HANDLE_USER_ALERT_SUCCESS",
          payload: {}
        });
      }
    } catch (error) {
      console.warn(error);
      dispatch({ type: "HANDLE_USER_ALERT_FAIL", payload: error });
    }
  };

export const invokeAutoSlurp = () => async () => {
  try {
    if (isDev) {
      console.log("Invoking Auto Slurp");
    }
    alert("I don't do anything! :)");
    return Promise.resolve();
  } catch (error) {
    console.warn(error);
    return Promise.reject(error);
  }
};
