import {
  getAlbumArt,
  getUserAvatar,
  handleDownload,
  isEmptyObject,
  trimText
} from "../functions";
import {
  handleLike,
  spotifySongSearch,
  appleMusicSongSearch
} from "../actions";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { Flipped } from "react-flip-toolkit";
import PropTypes from "prop-types";
import { useEffect } from "react";

const SongListItem = ({ file, index }) => {
  const {
    album,
    artistClearText: artist,
    titleClearText: title,
    // fileName,
    liked,
    s3FileName,
    uploadedBy
  } = file;

  const dispatch = useDispatch();
  const currentSpotifyTokenData = useSelector(
    (state) => state.user.spotifyTokenData
  );
  const currentAppleMusicUserData = useSelector(
    (state) => state.user.appleMusicUserData
  );
  const uploadProgress = useSelector(
    (state) => state.slugBucket.uploadProgress
  );

  useEffect(() => {
    $("[data-bs-toggle='popover']").popover({
      trigger: "focus"
    });
  }, []);

  return (
    <Flipped key={index} flipId={file.fileName}>
      <li className="list-group-item d-flex flex-column justify-contents-center w-100 bg-light mb-2">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center me-auto text-left">
                {getAlbumArt(file)}
                <div className="d-flex flex-column">
                  <div className="d-flex text-capitalize font-weight-bold">
                    {title ? trimText(35, title).toLowerCase() : ""}
                  </div>
                  <small className="d-flex flex-column text-center text-sm-left">
                    <div>
                      <span className="font-weight-normal text-capitalize">
                        {artist ? trimText(35, artist).toLowerCase() : ""}
                      </span>
                    </div>
                    {/* <div>
                      <span className="font-weight-bold">Album: </span>
                      <span className="font-weight-normal">{album}</span>
                    </div> */}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            {!isEmptyObject(currentAppleMusicUserData) ? (
              <i
                className="icon-applemusic slug-icon mx-sm-1"
                onClick={
                  uploadProgress > 0
                    ? () => {
                        console.log("nothing here");
                      }
                    : () => {
                        dispatch(appleMusicSongSearch(album, artist, title));
                      }
                }
                data-bs-toggle="modal"
                data-bs-target={
                  uploadProgress > 0 ? "" : "#slug-bucket-apple-music-modal"
                }
                style={{
                  cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`,
                  color: "black",
                  fontSize: "20px"
                }}
                title="Search on Apple Music"
              />
            ) : null}
            {!isEmptyObject(currentSpotifyTokenData) ? (
              <i
                className="icon-spotify slug-icon mx-sm-1 mb-1"
                onClick={
                  uploadProgress > 0
                    ? () => {
                        console.log("nothing here");
                      }
                    : () => {
                        dispatch(spotifySongSearch(album, artist, title));
                      }
                }
                data-bs-toggle="modal"
                data-bs-target={
                  uploadProgress > 0 ? "" : "#slug-bucket-spotify-modal"
                }
                style={{
                  cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`,
                  fontSize: "20px"
                }}
                title="Search on Spotify"
              />
            ) : null}
            <div className="mx-2 mx-sm-1 mb-1">{getUserAvatar(uploadedBy)}</div>
            <i
              onClick={() =>
                dispatch(handleLike(artist, title, s3FileName, "slugbucket"))
              }
              className={`mx-2 mx-sm-1 ${
                liked
                  ? "icon-favorite slug-icon text-danger"
                  : "icon-favorite_outline slug-icon text-body-secondary"
              }`}
              style={{ cursor: "pointer" }}
              title={liked ? "Unlike" : "Like"}
            />
            <i
              className="d-block d-sm-none icon-play_circle_outline slug-icon mx-2 mx-sm-1 text-secondary"
              onClick={
                uploadProgress > 0
                  ? () => {
                      console.log("nothing here");
                    }
                  : () => {
                      handleDownload(s3FileName);
                    }
              }
              style={{
                cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`
              }}
              title="Download"
            />
            <i
              className="d-none d-sm-block icon-cloud_download slug-icon mx-2 mx-sm-1 text-info"
              onClick={
                uploadProgress > 0
                  ? () => {
                      console.log("nothing here");
                    }
                  : () => {
                      handleDownload(s3FileName);
                    }
              }
              style={{
                cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`
              }}
              title="Download"
            />
            <i
              onClick={
                uploadProgress > 0
                  ? () => {
                      console.log("nothing here");
                    }
                  : () => {
                      dispatch({
                        type: "SET_DATA_FOR_DELETE_MODAL",
                        payload: {
                          fileToDelete: s3FileName,
                          modalAction: "delete",
                          artist,
                          title
                        }
                      });
                    }
              }
              className="icon-delete_forever slug-icon mx-2 mx-sm-1 text-danger"
              style={{
                cursor: `${uploadProgress > 0 ? "not-allowed" : "pointer"}`
              }}
              data-bs-toggle="modal"
              data-bs-target={uploadProgress > 0 ? "" : "#slug-modal"}
              title="Delete"
            />
          </div>
        </div>
      </li>
    </Flipped>
  );
};

SongListItem.propTypes = {
  file: PropTypes.object.isRequired
};

export default SongListItem;
