const bunnies = ["👯", "👯‍♀️", "👯‍♂️"];

const cleaners = [
  "🧚‍♀️",
  "🧼",
  "🛀",
  "🧽",
  "🧻",
  "🧹",
  "✨",
  "🙇🏽‍♀️",
  "🙇🏾‍♀️",
  "🙇🏿‍♀️",
  "🙇🏻‍♀️",
  "🙇🏼‍♀️"
];

const dancers = [
  "🕺",
  "🕺🏽",
  "🕺🏿",
  "💃",
  "🕺🏻",
  "🕺🏻",
  "💃🏽",
  "💃🏿",
  "💃🏻",
  "🕺🏾",
  "💃🏼",
  "🕺🏼",
  "💃🏾"
];

const errorEmojis = [
  "💥",
  "💣",
  "⚠️",
  "🚨",
  "⚡",
  "🚔",
  "👮‍♀️",
  "🤯",
  "🙈",
  "🐹",
  "🐔",
  "🦖",
  "🔥",
  "😭",
  "😿",
  "😱",
  "🕴️"
];

const nos = ["🙅🏿‍♀️", "🙅🏻‍♀️", "🙅🏽‍♀️", "🙅🏼‍♀️", "🙅🏾‍♀️"];
export const getRandomNo = () => {
  return nos[Math.floor(Math.random() * nos.length)];
};

const people = [
  "🦸‍♂️",
  "💂‍♂️",
  "🧝‍♂️",
  "👨‍🎨",
  "👨‍🌾",
  "🦸‍♀️",
  "💂‍♂️",
  "🧝‍♀️",
  "👩‍🎨",
  "👩‍🌾",
  "👱🏾‍♀️",
  "🧖🏾‍♀️",
  "👩🏾‍🦲",
  "🧘🏾‍♀️",
  "🧕🏾",
  "🧘🏼‍♀️",
  "👩🏼‍🦰",
  "🧕🏼",
  "🧛🏼‍♀️",
  "👩🏼‍🦳",
  "🕴️"
];

const rockers = ["👩‍🎤", "👩🏿‍🎤", "👩🏻‍🎤", "👩🏽‍🎤", "👩🏼‍🎤", "👩🏾‍🎤"];

const teachers = ["👩🏽‍🏫", "👩🏿‍🏫", "👩🏻‍🏫", "👩🏾‍🏫", "👩🏼‍🏫"];

const tippers = ["💁‍♀️", "💁🏽‍♀️", "💁🏻‍♀️", "💁🏼‍♀️", "💁🏾‍♀️", "💁🏿‍♀️"];

const shruggers = [
  "🤷‍♀️",
  "🤷‍♂️",
  "🤷🏿‍♂️",
  "🤷🏽‍♀️",
  "🤷🏻‍♂️",
  "🤷🏼‍♂️",
  "🤷🏿‍♀️",
  "🤷🏽‍♂️",
  "🤷🏾‍♀️",
  "🤷🏾‍♂️",
  "🤷🏻‍♀️",
  "🤷🏼‍♀️"
];

const errorMessages = ["Oh, Nos!", "Kaboom!", "Hmm...", "Lordy!"];
const slurpMessages = [
  "Time to Boogie!",
  "Let's Dance!",
  "Let's Rock!",
  "Let's Boogie!",
  "Slurptastic!"
];

export const getRandomBunny = () => {
  return bunnies[Math.floor(Math.random() * bunnies.length)];
};

export const getRandomCleaner = () => {
  return cleaners[Math.floor(Math.random() * cleaners.length)];
};

export const getRandomErrorMessage = () => {
  return errorMessages[Math.floor(Math.random() * errorMessages.length)];
};

export const getRandomErrorEmoji = () => {
  return errorEmojis[Math.floor(Math.random() * errorEmojis.length)];
};

export const getRandomDancers = () => {
  const dancerOne = dancers[Math.floor(Math.random() * dancers.length)];
  const dancerTwo = dancers[Math.floor(Math.random() * dancers.length)];
  return dancerOne + dancerTwo;
};

export const getRandomPerson = () => {
  return people[Math.floor(Math.random() * people.length)];
};

export const getRandomRocker = () => {
  return rockers[Math.floor(Math.random() * rockers.length)];
};

export const getRandomShrugger = () => {
  return shruggers[Math.floor(Math.random() * shruggers.length)];
};

export const getRandomTeacher = () => {
  return teachers[Math.floor(Math.random() * teachers.length)];
};

export const getRandomTipper = () => {
  return tippers[Math.floor(Math.random() * tippers.length)];
};

export const getRandomSlurpMessage = () => {
  return slurpMessages[Math.floor(Math.random() * slurpMessages.length)];
};
