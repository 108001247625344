export enum YouTubeActionType {
  ADD_TRACKS_TO_YOUTUBE_PLAYLIST_FAIL = "add_tracks_to_youtube_playlist_fail",
  ADD_TRACKS_TO_YOUTUBE_PLAYLIST_START = "add_tracks_to_youtube_playlist_start",
  ADD_TRACKS_TO_YOUTUBE_PLAYLIST_SUCCESS = "add_tracks_to_youtube_playlist_success",
  CREATE_NEW_YOUTUBE_PLAYLIST_FAIL = "create_new_youtube_playlist_fail",
  CREATE_NEW_YOUTUBE_PLAYLIST_START = "create_new_youtube_playlist_start",
  CREATE_NEW_YOUTUBE_PLAYLIST_SUCCESS = "create_new_youtube_playlist_success",
  CREATE_YOUTUBE_PLAYLIST_FAIL = "create_youtube_playlist_fail",
  CREATE_YOUTUBE_PLAYLIST_START = "create_youtube_playlist_start",
  CREATE_YOUTUBE_PLAYLIST_SUCCESS = "create_youtube_playlist_success",
  CREATE_YOUTUBE_USER_DATA_FAIL = "create_youtube_user_data_fail",
  CREATE_YOUTUBE_USER_DATA_START = "create_youtube_user_data_start",
  CREATE_YOUTUBE_USER_DATA_SUCCESS = "create_youtube_user_data_success",
  GAPI_AUTHENTICATE_FAIL = "gapi_authenticate_fail",
  GAPI_AUTHENTICATE_START = "gapi_authenticate_start",
  GAPI_AUTHENTICATE_SUCCESS = "gapi_authenticate_success",
  GET_ALL_YOUTUBE_PLAYLISTS_FAIL = "get_all_youtube_playlists_fail",
  GET_ALL_YOUTUBE_PLAYLISTS_START = "get_all_youtube_playlists_start",
  GET_ALL_YOUTUBE_PLAYLISTS_SUCCESS = "get_all_youtube_playlists_success",
  GET_SLUGIFY_PLAYLISTS_FROM_YOUTUBE_CHANNEL_FAIL = "get_slugify_playlists_from_youtube_channel_fail",
  GET_SLUGIFY_PLAYLISTS_FROM_YOUTUBE_CHANNEL_START = "get_slugify_playlists_from_youtube_channel_start",
  GET_SLUGIFY_PLAYLISTS_FROM_YOUTUBE_CHANNEL_SUCCESS = "get_slugify_playlists_from_youtube_channel_success",
  GET_TRACKS_FROM_YOUTUBE_PLAYLIST_FAIL = "get_tracks_from_youtube_playlist_fail",
  GET_TRACKS_FROM_YOUTUBE_PLAYLIST_START = "get_tracks_from_youtube_playlist_start",
  GET_TRACKS_FROM_YOUTUBE_PLAYLIST_SUCCESS = "get_tracks_from_youtube_playlist_success",
  GET_TRACKS_FROM_YOUTUBE_SLUGIFY_PLAYLIST_FAIL = "get_tracks_from_youtube_slugify_playlist_fail",
  GET_TRACKS_FROM_YOUTUBE_SLUGIFY_PLAYLIST_START = "get_tracks_from_youtube_slugify_playlist_start",
  GET_TRACKS_FROM_YOUTUBE_SLUGIFY_PLAYLIST_SUCCESS = "get_tracks_from_youtube_slugify_playlist_success",
  GET_YOUTUBE_PLAYLISTS_FROM_CHANNEL_FAIL = "get_youtube_playlists_from_channel_fail",
  GET_YOUTUBE_PLAYLISTS_FROM_CHANNEL_START = "get_youtube_playlists_from_channel_start",
  GET_YOUTUBE_PLAYLISTS_FROM_CHANNEL_SUCCESS = "get_youtube_playlists_from_channel_success",
  GET_YOUTUBE_USER_DATA_FAIL = "get_youtube_user_data_fail",
  GET_YOUTUBE_USER_DATA_START = "get_youtube_user_data_start",
  GET_YOUTUBE_USER_DATA_SUCCESS = "get_youtube_user_data_success",
  GAPI_LOAD_CLIENT_FAIL = "gapi_load_client_fail",
  GAPI_LOAD_CLIENT_START = "gapi_load_client_start",
  GAPI_LOAD_CLIENT_SUCCESS = "gapi_load_client_success",
  RESET_YOUTUBE_USER = "reset_youtube_user",
  SET_YOUTUBE_SIGN_IN_STATUS_FAIL = "set_youtube_sign_in_status_fail",
  SET_YOUTUBE_SIGN_IN_STATUS_START = "set_youtube_sign_in_status_start",
  SET_YOUTUBE_SIGN_IN_STATUS_SUCCESS = "set_youtube_sign_in_status_success",
  SET_YOUTUBE_USER = "set_youtube_user",
  SET_YOUTUBE_VIDEOS = "set_youtube_videos",
  GET_MY_YOUTUBE_CHANNEL_FAIL = "get_my_youtube_channel_fail",
  GET_MY_YOUTUBE_CHANNEL_START = "get_my_youtube_channel_start",
  GET_MY_YOUTUBE_CHANNEL_SUCCESS = "get_my_youtube_channel_success",
  GET_MY_YOUTUBE_PLAYLISTS_FAIL = "get_my_youtube_playlists_fail",
  GET_MY_YOUTUBE_PLAYLISTS_START = "get_my_youtube_playlists_start",
  GET_MY_YOUTUBE_PLAYLISTS_SUCCESS = "get_my_youtube_playlists_success",
  HANDLE_YOUTUBE_PLAYLISTS_FAIL = "handle_youtube_playlists_fail",
  HANDLE_YOUTUBE_PLAYLISTS_START = "handle_youtube_playlists_start",
  HANDLE_YOUTUBE_PLAYLISTS_SUCCESS = "handle_youtube_playlists_success",
  INIT_GAPI_FAIL = "init_gapi_fail",
  INIT_GAPI_START = "init_gapi_start",
  INIT_GAPI_SUCCESS = "init_gapi_success"
}

export interface YouTubeState {
  user: Partial<gapi.auth2.BasicProfile>;
  myPlaylists: YouTubePlaylist[];
  channel: gapi.client.youtube.Channel | null;
}

export interface YouTubePlaylist {
  id: string;
  name: string;
  privacyStatus: string;
}

export interface YouTubeSearchObject {
  artist: string;
  isrc: string;
  title: string;
}

export interface YouTubeUser {
  id: string;
  fullName: string;
  givenName: string;
  familyName: string;
  imageUrl: string;
  email: string;
}
