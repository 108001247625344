import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as subscriptions from "../graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";

const isDev = process.env.NODE_ENV === "development";

export const useSlurpedTrackSubscription = () => {
  const dispatch = useDispatch();
  const {
    auth: { username }
  } = useSelector((state) => state);

  // create subscription
  useEffect(() => {
    if (isDev) {
      console.log("📰 Subscribed to slurped track creation");
    }
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onCreateSlugifySlurpedTrack, {
        owner: username
      })
    ).subscribe({
      next: (data) => {
        const payload = { ...data.value.data.onCreateSlugifySlurpedTrack };
        if (isDev) {
          console.log(
            "✨ Created Subscription Item (Slurped track): ",
            payload
          );
        }
        dispatch({
          type: "ADD_SLURPED_TRACK",
          payload
        });
      }
    });
    return () => {
      subscription.unsubscribe();
      if (isDev) {
        console.log("📰 Unsubscribed from slurped track creation");
      }
    };
  }, [dispatch, username]);

  // delete subscription
  useEffect(() => {
    if (isDev) {
      console.log("📰 Subscribed to slurped track deletion");
    }
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onDeleteSlugifySlurpedTrack, {
        owner: username
      })
    ).subscribe({
      next: (data) => {
        if (isDev) {
          console.log(
            "🗑️ Deleted Subscription Item (Slurped track): ",
            data.value.data.onDeleteSlugifySlurpedTrack
          );
        }
        const {
          value: {
            data: { onDeleteSlugifySlurpedTrack }
          }
        } = data;
        dispatch({
          type: "DELETE_SLURPED_TRACK",
          payload: onDeleteSlugifySlurpedTrack
        });
      }
    });
    return () => {
      subscription.unsubscribe();
      if (isDev) {
        console.log("📰 Unsubscribed from slurped track deletion");
      }
    };
  }, [dispatch, username]);

  // update subscription
  useEffect(() => {
    if (isDev) {
      console.log("📰 Subscribed to slurped track updates");
    }
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onUpdateSlugifySlurpedTrack, {
        owner: username
      })
    ).subscribe({
      next: (data) => {
        if (isDev) {
          console.log(
            "✏️ Updated Subscription Item (Slurped track): ",
            data.value.data.onUpdateSlugifySlurpedTrack
          );
        }
        const {
          value: {
            data: { onUpdateSlugifySlurpedTrack }
          }
        } = data;
        dispatch({
          type: "UPDATE_SLURPED_TRACK",
          payload: onUpdateSlugifySlurpedTrack
        });
      }
    });
    return () => {
      subscription.unsubscribe();
      if (isDev) {
        console.log("📰 Unsubscribed from slurped track updates");
      }
    };
  }, [dispatch, username]);

  // return slurpedTracks;
};
