/* eslint-disable no-case-declarations */
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { Amplify, Auth, Hub } from "aws-amplify";
import AppRouter, { customHistory as history } from "./routers/AppRouter";
import awsconfigDev from "./aws-exports.dev";
import awsconfigProd from "./aws-exports.prod";
// import awsconfigStaging from "./aws-exports.staging";
// import config from "./aws-exports";
import store from "./store/store";

const isDev = process.env.NODE_ENV === "development";

console.log("%c💃🏼🕺🏽 Welcome to Slugify!", "color:cyan");
import {
  signIn,
  slugifyInit,
  slugifyPostInit,
  initYouTubeApi,
  handleUserAlert
} from "./actions";

import "./styles/custom.scss";
import "animate.css";
import "bootstrap/dist/js/bootstrap.bundle";
import ThemeProvider from "./contexts/ColorThemeProvider";
import { SlugifyActionType } from "./types";
// import { GoogleOAuthProvider } from "@react-oauth/google";

const container = document.getElementById("root");
if (!container) {
  throw new Error("No container!");
}
const root = createRoot(container);

root.render(
  <Provider store={store}>
    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
    {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}> */}
    <ThemeProvider>
      <AppRouter />
    </ThemeProvider>
    {/* </GoogleOAuthProvider> */}
  </Provider>
);

const handleSignIn = async (cognitoUser?: any) => {
  try {
    if (!cognitoUser) {
      cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    }
    const {
      username,
      attributes: { email, sub }
    } = cognitoUser;

    const payload = {
      email,
      username,
      userId: sub,
      attributes: { ...cognitoUser.attributes }
    };

    if (isDev) {
      console.log("%c🚚 Dispatching user data to Redux...", "color:cyan");
    }
    await store.dispatch(signIn(payload));
    console.log("%c👀 Checking if Google API is loaded...", "color:cyan");
    if (!gapi) {
      console.warn("%c 💩 Google API is not loaded!", "color:orange");
    } else {
      console.log("%c📺 Google API is loaded!", "color:lime");
      await store.dispatch(initYouTubeApi());
    }
    console.log("%c👀 Checking if Music Kit is loaded...", "color:cyan");
    if (!window.MusicKit) {
      console.warn("%c 💩 Music kit is not loaded!", "color:orange");
      store.dispatch({
        type: "LOADING_APPLE_MUSICKIT_FAIL",
        payload: new Error("Apple MusicKit failed to load.")
      });
      store.dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Ah, Houston, we've had a problem...."
      });
    } else {
      console.log("%c🎸 MusicKit is loaded!", "color:lime");
    }
    if (isDev) {
      console.log("🐌 Starting Slug Init...");
    }
    await store.dispatch(slugifyInit(payload));
    if (isDev) {
      console.log("🐌 Starting Slug Post Init...");
    }
    await store.dispatch(slugifyPostInit());
  } catch (error) {
    store.dispatch(
      handleUserAlert(
        [],
        "danger",
        undefined,
        "You may be having connectivity issues...",
        "Something's not right..."
      )
    );
    console.warn("Index handleSignIn error: ", error);
  }
};

Hub.listen("auth", async (data) => {
  if (isDev) {
    console.log("🎟️ HUB EVENT/PAYLOAD", data.payload.event, data.payload);
    console.log("📍 HISTORY LOCATION PATHNAME", history.location.pathname);
  }
  switch (data.payload.event) {
    case "configured":
      try {
        if (history.location.pathname.includes("tutorial")) {
          history.push("/loading");
        }
        if (history.location.pathname.includes("auth-callback")) {
          if (isDev) {
            console.log("🔍 AuthCallback detected!");
          }
        } else if (history.location.pathname.includes("spotify-callback")) {
          if (isDev) {
            console.log("🔍 SpotifyCallback detected!");
          }
        } else {
          const cognitoUser = await Auth.currentAuthenticatedUser({
            bypassCache: true
          });
          if (isDev) {
            console.log("✔️ User is already signed in");
          }
          if (
            history.location.pathname === "/" ||
            history.location.pathname.includes("tutorial")
          ) {
            if (isDev) {
              console.log(
                "🚦 User coming from sign in or tutorial. Pushing to loading..."
              );
            }
            history.push("/loading");
          }
          if (isDev) {
            console.log("%cWTF is this?", "color:hotpink");
          }
          handleSignIn(cognitoUser);
        }
      } catch (error) {
        if (error === "not authenticated") {
          if (isDev) {
            console.log("👮 User is not signed in");
          }
          if (
            history.location.pathname === "/aloha" ||
            history.location.pathname === "/loading"
          ) {
            if (isDev) {
              console.log("🚦 Pushing to sign-in page...");
            }
            history.push("/");
          }
        } else {
          if (isDev) {
            console.warn("💣 Error in Hub:", error);
          }
        }
      }
      break;
    case "signIn":
      if (isDev) {
        console.log("%cAttempting to sign in Hub", "color:orange");
      }
      handleSignIn();
      break;
    case "signIn_failure":
      const dataPayloadErrorMessage = data.payload.data.message;
      let errorMessage = data.payload.data.message;
      if (dataPayloadErrorMessage.includes("PreSignUp failed with error")) {
        errorMessage = data.payload.data.message.split(
          "PreSignUp failed with error"
        )[1];
      }
      store.dispatch({
        type: "COGNITO_USER_SIGNUP_FAIL",
        payload: new Error(errorMessage)
      });
      break;
    case "signOut":
      if (isDev) {
        console.log(
          "👮🏽‍♀️ Signed out. Router should push to sign-in page if on private page."
        );
      }
      break;
    default:
      if (isDev) {
        console.log("%cNo matching case!", "color:hotpink", data.payload.event);
      }
      break;
  }
});
// const awsconfig = awsconfigProd;
// Amplify.configure(awsconfig);
let awsconfig;
if (process.env.NODE_ENV === "development") {
  console.log("🌏 DEV");
  awsconfig = awsconfigDev;
  Amplify.configure(awsconfig);
} else {
  console.log("🌏 PROD");
  awsconfig = awsconfigProd;
  Amplify.configure(awsconfig);
}
if (isDev) {
  console.log(
    "%c Cognito Client: ",
    "color:pink",
    awsconfig.aws_user_pools_web_client_id
  );
  console.log(
    "%c Cognito Redirect SignIn URL: ",
    "color:pink",
    awsconfig.oauth.redirectSignIn
  );
  console.log(
    "%c Cognito Redirect SignOut URL: ",
    "color:pink",
    awsconfig.oauth.redirectSignOut
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
