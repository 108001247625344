import SignInForm from "./SignInForm";
import bgimage from "../images/dmitry-bayer-FhUcX30FAr4-unsplash-BW.jpg";

export const SignInPageNormal = ({
  formAction,
  setFormAction,
  // isScreenShort,
  isCodeSent,
  isMobile,
  setIsCodeSent,
  isCodeResent,
  setIsCodeResent
}) => {
  return (
    <div
      className={` d-flex flex-column justify-content-center align-items-center`}
      style={{
        backgroundImage: `url(${bgimage})`,
        backgroundPosition: "center",
        backgroundRepeat: "never",
        backgroundSize: "cover",
        height: "100vh"
      }}
    >
      <div className="d-flex flex-column align-items-center">
        <div style={{ minWidth: "300px" }}>
          <SignInForm
            formAction={formAction}
            setFormAction={setFormAction}
            // isScreenShort={isScreenShort}
            isCodeSent={isCodeSent}
            isMobile={isMobile}
            setIsCodeSent={setIsCodeSent}
            isCodeResent={isCodeResent}
            setIsCodeResent={setIsCodeResent}
          />
        </div>
      </div>
    </div>
  );
};
