/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreateNewSpotifyPlaylistModal } from "./CreateNewSpotifyPlaylistModal";
import { useContext, useEffect, useState } from "react";
import SlugifySpotifyMusicItem from "./SlugifySpotifyMusicItem";
import SlugifySpotifyMusicItemWithWidgets from "./SlugifySpotifyMusicItemWithWidgets";
import SlugifySpotifyUnmatchedItem from "./SlugifySpotifyUnmatchedItem";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { OverlayTrigger, Popover } from "react-bootstrap";
import type {
  AppleMusicUnmatchedTrack,
  SelectedPlaylist,
  SpotifyPlaylist
} from "../types";

interface Props {
  filteredTracks: any[];
  handleAddAllToSpotify: () => void;
  myNonForgottenUnmatchedTracks: any[];
  mySpotifyPlaylists: SpotifyPlaylist[];
  renderAddButtonTitle: () => string;
  renderPlaylistOptions: () => any[];
  selectedPlaylist: SelectedPlaylist | undefined;
  setSelectedPlaylist: (sp: SelectedPlaylist) => void;
  showMyTracks: boolean;
  showSlurpedTracks: boolean;
}
export default function SlugifySpotify({
  filteredTracks,
  handleAddAllToSpotify,
  myNonForgottenUnmatchedTracks,
  mySpotifyPlaylists,
  renderAddButtonTitle,
  renderPlaylistOptions,
  selectedPlaylist,
  setSelectedPlaylist,
  showMyTracks,
  showSlurpedTracks
}: Props) {
  const { isDark } = useContext(ThemeContext);
  const [playlists, setPlaylists] = useState<SpotifyPlaylist[]>();
  const [
    isCreateNewSpotifyPlaylistModalOpen,
    setIsCreateNewSpotifyPlaylistModalOpen
  ] = useState(false);

  useEffect(() => {
    const filteredPlaylists = mySpotifyPlaylists.filter(
      (item) => !item.name.includes("Slugify Direct") && item.name !== "Slugify"
    );
    setPlaylists(filteredPlaylists);
  }, [mySpotifyPlaylists]);

  const renderTracks = () => {
    return filteredTracks.map((item) => {
      if (filteredTracks.length <= 20) {
        return (
          <SlugifySpotifyMusicItemWithWidgets
            key={item.track.id}
            item={item}
            showMyTracks={showMyTracks}
            showSlurpedTracks={showSlurpedTracks}
          />
        );
      } else {
        return (
          <SlugifySpotifyMusicItem
            key={item.track.id}
            item={item}
            showButton
            showMyTracks={showMyTracks}
            showSlurpedTracks={showSlurpedTracks}
          />
        );
      }
    });
  };
  const renderUnmatchedTracks = () => {
    if (myNonForgottenUnmatchedTracks.length) {
      return myNonForgottenUnmatchedTracks.map(
        (item: AppleMusicUnmatchedTrack) => {
          return (
            <SlugifySpotifyUnmatchedItem
              key={item.appleMusicInfo.appleMusicId}
              item={item}
            />
          );
        }
      );
    }
  };

  const popover = (
    <Popover id="unmatched-track-popover">
      <Popover.Header>Unmatched Tracks</Popover.Header>
      <Popover.Body>
        <p>The following tracks could not be matched for some reason.</p>
        <p>
          You can try searching manually for them or just forget about them.
        </p>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <CreateNewSpotifyPlaylistModal
        isCreateNewSpotifyPlaylistModalOpen={
          isCreateNewSpotifyPlaylistModalOpen
        }
        setIsCreateNewSpotifyPlaylistModalOpen={
          setIsCreateNewSpotifyPlaylistModalOpen
        }
      />
      <div
        className="d-flex flex-column align-items-center w-100"
        style={{ maxWidth: 800 }}
      >
        {/* <div className="animate__animated animate__fadeIn"> */}
        {filteredTracks.length ? (
          <ul className="list-unstyled mt-3 w-100">{renderTracks()}</ul>
        ) : null}
        {myNonForgottenUnmatchedTracks.length ? (
          <div className="d-flex flex-column w-100">
            <h5
              className={`d-flex align-items-center mb-0 ${
                !filteredTracks.length ? "mt-2" : "mt-0"
              }`}
            >
              Unmatched Tracks
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={popover}
                rootClose
              >
                <i className="icon-info slug-icon text-info ms-2" />
              </OverlayTrigger>
            </h5>
            <ul className="list-group w-100" style={{ border: "none" }}>
              {renderUnmatchedTracks()}
            </ul>
          </div>
        ) : null}
        {filteredTracks.length && !showMyTracks && !showSlurpedTracks ? (
          <div className="d-flex flex-column w-100 mt-3">
            <div className="form-group mb-0">
              <select
                className="form-select"
                id="selected"
                onChange={(e: any) => {
                  const index = e.nativeEvent.target.selectedIndex;
                  const label = e.nativeEvent.target[index].text;
                  const value = e.target.value;
                  setSelectedPlaylist({ name: label, id: value });
                }}
              >
                <option value="">Select a Playlist</option>
                {renderPlaylistOptions()}
              </select>
            </div>
            {playlists && !playlists.length ? (
              <div className="d-flex flex-column w-100">
                <button
                  className={`d-flex justify-content-center align-items-center btn btn-${
                    isDark ? "outline-primary" : "primary"
                  } mt-2 w-100 `}
                  onClick={() => {
                    setIsCreateNewSpotifyPlaylistModalOpen(true);
                  }}
                  title="Create New Spotify Playlist"
                >
                  <span role="img" aria-label="Sparkles">
                    ✨
                  </span>
                  <span className="ms-2">Create New Spotify Playlist</span>
                </button>
              </div>
            ) : (
              <button
                className={`d-flex justify-content-center align-items-center btn btn-${
                  isDark ? "outline-primary" : "primary"
                } mt-2 w-100`}
                disabled={
                  !selectedPlaylist?.id ||
                  !filteredTracks.filter(
                    (item) => item.slurpStatus === "not my track"
                  ).length
                }
                style={{
                  cursor: `${
                    !filteredTracks.filter(
                      (item) => item.slurpStatus === "not my track"
                    ).length || !selectedPlaylist?.id
                      ? "not-allowed"
                      : "pointer"
                  }`
                }}
                onClick={() => {
                  handleAddAllToSpotify();
                }}
                title={renderAddButtonTitle()}
              >
                <span role="img" aria-label="a cute little snail">
                  🐌
                </span>
                <span className="d-flex d-sm-none ms-2">
                  Slurp All Tracks to Spotify
                </span>
                <span className="d-none d-sm-flex ms-2">Slurp All Tracks</span>
              </button>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}
