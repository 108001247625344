import { useHistory } from "react-router-dom";
import config from "../../config/config.json";

export default function PrivacyPolicy2() {
  const history = useHistory();
  const sendToAddress = process.env.REACT_APP_SMTP_SEND_TO;
  return (
    <div className="container d-flex justify-content-center w-100 my-3 my-sm-5">
      <div className="" style={{ maxWidth: 640 }}>
        {/* <button
          className="btn btn-link d-flex align-items-center justify-content-center ps-0"
          onClick={() => history.goBack()}
        >
          <small className="">back</small>
        </button> */}
        {/* <p className="text-danger">
          This document is in draft and is not legally binding
        </p> */}
        <h1>Our Privacy Policy</h1>

        <p className="font-italic">Last updated: September 2023</p>

        <h2>Legal Compliance</h2>

        <p>
          {config.SITE_NAME} makes every attempt to stay current with changing
          privacy legislation.
        </p>
        <h2>Your Information</h2>
        <p>
          We respect your privacy and do not use your information for any
          purpose other than allowing {config.SITE_NAME} to function.
        </p>

        <p>We will never sell or share your data for others to use.</p>

        <p>
          {config.SITE_NAME} gains access to your information in a few ways.
        </p>
        <ul>
          <li>When you contact us via email</li>
          <li>When you sign up</li>
          <li>
            When you connect {config.SITE_NAME} to Apple Music, Spotify, and/or
            YouTube
          </li>
        </ul>

        <p>
          You can delete your personal information at any time by deleting your{" "}
          {config.SITE_NAME} account.
        </p>

        <h3>Contact</h3>
        {/* <p>
          When you contact us through the {config.SITE_NAME} contact form, we
          receive the information you submit via email. As such, we will have
          received the information you provided on the form, including your
          email.
        </p> */}
        <p>
          When you contact us via email, we receive all of the information you
          send us, including your email address.
        </p>
        <p>
          Emails are stored on our mail server(s) and the apps used to access
          them. Emails are kept throughout the duration of the correspondence
          time required to fulfill your initial contact request. We delete all
          emails upon fulfilling each request.
        </p>
        <h3>Authentication</h3>
        <p>
          When you sign up for {config.SITE_NAME}, your email, a username, and a
          password are required. To facilitate Sign in With Apple and/or Sign in
          With Google, your email for those services are also required.
        </p>
        <p>
          When using Sign in with Apple or Sign in With Google, these services
          provide access to your email address and username.
        </p>
        <p>
          The sole purpose for requiring this information is to provide secure
          authentication for signing into Slugify. We use{" "}
          <a
            href="https://aws.amazon.com/cognito/"
            target="_blank"
            rel="noopener noreferrer"
          >
            AWS Cognito
          </a>{" "}
          for secure authentication.
        </p>
        <h3>Limited Use</h3>
        <p>
          {config.SITE_NAME}&apos;s use and transfer to any other app of
          information received from Google APIs will adhere to{" "}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements.
        </p>
        <h3>Connecting to Your Music Provider</h3>
        <p>
          When you first sign in to {config.SITE_NAME}, connecting to your music
          provider is an essential first step toward getting things up and
          running.
        </p>
        <p>
          When you connect to Apple Music, Spotify, and/or YouTube, you are
          prompted for approval to allow Slugify access to resources within
          those services.
        </p>
        <p>With your approval, {config.SITE_NAME} will be able to:</p>
        <ul>
          <li>Read your profile information</li>
          <li>Create, update, read, and delete playlists</li>
        </ul>
        <p>
          {config.SITE_NAME} does not use your profile information for any
          purpose other than app functionality.
        </p>
        <p>
          Upon connecting {config.SITE_NAME} to your music service, one or more
          playlists are created on your behalf, so as to streamline the
          connection process. These include:
        </p>
        <ul>
          <li>The Slugify playlist (required for app functionality)</li>
          <li>
            A Slugify destination playlist (required for app functionality)
          </li>
          <li>
            A Slugify video destination playlist (required for app functionality
            - YouTube only)
          </li>
        </ul>
        <p>
          Any update or deletion of playlists and/or playlist contents is
          initiated by you when using {config.SITE_NAME}. {config.SITE_NAME}{" "}
          will never update or delete your content without your initiating those
          actions.
        </p>

        {/* <h2>Cookie Policy</h2>

        <p>
          {config.SITE_NAME} uses cookies—-small pieces of data stored on your
          computer—-to make our website more efficient. We employ this
          data—provided from Google Analytics (see &#8220;Third-Party Products
          &amp; Services&#8221; below)—to create more useful website content and
          improve your website experience.
        </p>

        <p>
          You have the right to decide whether to accept or reject cookies. This
          can be done via the pop-up that appears when you first visit a page on
          our website.
        </p> */}

        <h2>Consent</h2>

        <p>
          When you provide us with personal information via our website forms,
          you consent to our collecting it and using it for that specific reason
          only.
        </p>

        <p>
          If we ask for your personal information for a secondary reason, like
          marketing (which we won&apos;t do), we will ask you directly for your
          expressed consent.
        </p>

        <h2>Access to Data</h2>

        <p>
          If you feel the data we have for you is incorrect or if you would like
          us to remove your data completely, you may withdraw consent for us to
          contact you, for the continued collection, use, or disclosure of your
          information, at anytime, by{" "}
          <a href={`mailto:${sendToAddress}`}> contacting us</a> or deleting
          your account.
        </p>

        <h2>Disclosure</h2>

        <p>
          We may disclose your personal information if we are required by law to
          do so or if you violate these Terms of Service.
        </p>

        <h2>Third-Party Products &amp; Services</h2>

        <p>
          {config.SITE_NAME} uses several third-party services on our website:
        </p>

        <ul>
          <li>
            <a
              href="https://www.apple.com/apple-music/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apple Music
            </a>
            {", "}
            <a
              href="https://www.spotify.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Spotify
            </a>
            {", "}
            <a
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              YouTube
            </a>{" "}
            are used to allow core app functionality.
          </li>
          <li>
            <a
              href="https://aws.amazon.com/cognito/"
              target="_blank"
              rel="noopener noreferrer"
            >
              AWS Cognito
            </a>{" "}
            is used for for secure authentication.
          </li>
          <li>
            <a
              href="https://aws.amazon.com/dynamodb"
              target="_blank"
              rel="noopener noreferrer"
            >
              AWS DynamoDB
            </a>{" "}
            is used to store your information persistently. This allows us to
            remember you and {config.SITE_NAME} to function without having to go
            through the setup process each time upon return visits. All personal
            information is deleted when you delete your {config.SITE_NAME}{" "}
            account.
          </li>
          <li>
            <a
              href="http://google.com/analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics
            </a>{" "}
            allows us to track site performance. We have{" "}
            <a
              href="https://support.google.com/analytics/answer/3379636"
              target="_blank"
              rel="noopener noreferrer"
            >
              updated our Analytics account
            </a>{" "}
            to comply with privacy legislation such as GDPR, CCPA, etc..
          </li>
        </ul>

        <p>
          In general, third-party providers used by {config.SITE_NAME} will only
          collect, use, and disclose your information to the extent necessary to
          allow them to perform the services they provide to us. If you are
          concerned about these services, we recommend that you read their
          privacy policies to better understand the manner in which your
          personal information will be handled by these providers.{" "}
        </p>

        <p>
          In particular, remember that certain providers may be located in or
          have facilities that are located in a different jurisdiction than
          either you or us. If you elect to proceed with an interaction that
          involves the services of a third-party service provider, your
          information may become subject to the laws of the jurisdiction(s) in
          which that service provider or its facilities are located.
        </p>

        <p>
          Once you leave our website or are redirected to a third-party website
          or application, you are no longer governed by this Privacy Policy.
          This is also true if/when you click on links that direct you away from
          our site. We are not responsible for the privacy practices of other
          sites and encourage you to read their privacy statements.
        </p>

        <h2>Security</h2>

        <p>
          To protect your personal information, we take reasonable precautions
          and follow industry best practices to make sure it is not
          inappropriately lost, misused, accessed, disclosed, altered or
          destroyed. Although no method of transmission over the Internet or
          electronic storage is 100% secure, we implement generally accepted
          industry standards.
        </p>

        <h2>Age Of Consent</h2>

        <p>
          By using this site, you represent that you are at least the age of
          majority in your state or province of residence, or that you are the
          age of majority in your state or province of residence and you have
          given us your consent to allow any of your minor dependents to use
          this site.
        </p>

        <h2>YouTube</h2>
        <p>
          Slugify uses the YouTube API for users that connect Slugify to
          YouTube.
        </p>
        <p>
          When providing access to the YouTube API to users, Slugify respects
          and is bound by the{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Privacy Policy
          </a>{" "}
          in addition to what has been explained herein.
        </p>
        <p>
          By using Slugify as a YouTube connected user you agree to be bound by
          the{" "}
          <a
            href="https://www.youtube.com/t/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube Terms of Service.
          </a>
        </p>
        <p>
          When you connect Slugify to YouTube, Slugify uses the YouTube API to
          gather your playlist information, which is used to facilitate the
          saving of videos to your playlists and sharing of playlist content
          with your friends.
        </p>
        <p>
          Slugify stores the following information to facilitate app
          functionality. This information includes:
        </p>
        <ul>
          <li>Your email</li>
          <li>Your name (given, family, and full)</li>
          <li>Your channel ID</li>
          <li>Specific playlist IDs (see below)</li>
        </ul>
        <p>
          Slugify stores the ID of the following playlists if/when they exist
          and only if Slugify is connected to YouTube:
        </p>
        <ul>
          <li>Slugify</li>
          <li>SlugTube Music</li>
          <li>SlugTube Videos</li>
        </ul>
        <p>
          This information is never shared with third parties and is permanently
          deleted when you disconnect YouTube from Slugify or you delete your
          Slugify account.{" "}
        </p>
        <p>
          At any time, you can revoke Slugify&apos;s access to YouTube data by
          performing the following actions:
          <ol>
            <li>
              Go to{" "}
              <a
                href="https://myaccount.google.com/connections"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google security settings
              </a>
            </li>
            <li>Click Slugify</li>
            <li>
              Click the <em>See Details</em> button(s)
            </li>
            <li>Remove or stop using as desired</li>
          </ol>
        </p>
        <h2>Privacy Policy Changes</h2>

        <p>
          We reserve the right to modify this privacy policy at any time, so
          please review it frequently. Changes and clarifications will take
          effect immediately upon posting to the website. If we make material
          changes to this policy, we will notify you here that it has been
          updated, so that you are aware of what information we collect, how we
          use it, and under what circumstances, if any, we use and/or disclose
          it.
        </p>

        <p>
          If {config.SITE_NAME} is acquired or merged with another company, your
          information may be transferred to the new owners so that we may
          continue to communicate with you.
        </p>

        <h2>Questions and Contact Information</h2>
        <p>
          If you would like to: access, correct, amend, or delete any personal
          information we have about you, register a complaint, or simply want
          more information, please{" "}
          <a href={`mailto:${sendToAddress}`}>contact us</a>.
        </p>
        {/* <p>
          If you would like to: access, correct, amend, or delete any personal
          information we have about you, register a complaint, or simply want
          more information, please{" "}
          <Link className="" to="/contact">
            contact us
          </Link>
          .
        </p> */}
        <div className="d-flex justify-content-center w-100">
          <button
            className="btn btn-link text-body-secondary text-decoration-none"
            onClick={() => history.goBack()}
          >
            <small>back</small>
          </button>
        </div>
      </div>
    </div>
  );
}
