import PropTypes from "prop-types";

const SlugTubePagination = ({
  currentPage,
  isSmall,
  setCurrentPage,
  totalPages
}) => {
  const renderInnerPages = () => {
    const pages = [];
    for (let i = 1; i < totalPages; i++) {
      pages.push(
        <li
          className={`page-item ${i === currentPage + 1 ? "active" : ""}`}
          onClick={() => setCurrentPage(i - 1)}
          key={i}
        >
          {/* eslint-disable-next-line */}
          <a className="page-link" href="#">
            {i}
          </a>
        </li>
      );
    }
    return pages.map((page) => page);
  };

  const renderPagination = () => {
    if (totalPages === 1) return null;
    return (
      <nav aria-label="SlugTube Pagination">
        <ul className={`pagination mt-3 ${isSmall ? "pagination-sm" : ""}`}>
          <li className={` page-item ${currentPage < 1 ? "disabled" : ""}`}>
            {/* eslint-disable-next-line */}
            <a
              className="page-link"
              href="#"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage < 1}
            >
              Previous
            </a>
          </li>
          {renderInnerPages()}
          <li
            className={`page-item ${
              currentPage + 2 === totalPages ? "disabled" : ""
            }`}
          >
            {/* eslint-disable-next-line */}
            <a
              className="page-link"
              href="#"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage + 2 === totalPages}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    );
  };

  return renderPagination();
};

SlugTubePagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  isSmall: PropTypes.bool.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired
};

export default SlugTubePagination;
