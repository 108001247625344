import { Auth } from "aws-amplify";
import {
  CognitoHostedUIIdentityProvider,
  SignUpParams
} from "@aws-amplify/auth";
import { ISignUpResult } from "amazon-cognito-identity-js";
import { FederatedSignInButton } from "./shared/FederatedSignInButton";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { isEmptyObject } from "../functions";
import { signInFormSchema, signUpFormSchema } from "../validators";
import { SignUpConfirmationForm } from "./SignUpConfirmationForm";
import { useDispatch } from "react-redux";
import { useState } from "react";
// import $ from "jquery";
import Button from "./shared/Button";
import NewPasswordModal from "./NewPasswordModal";
import GoogleSignInButton from "./shared/GoogleSignInButton";
import { useHistory } from "react-router-dom";

interface Props {
  isCodeSent: boolean;
  isMobile: boolean;
  setIsCodeSent: (b: boolean) => void;
  isCodeResent: boolean;
  setIsCodeResent: (b: boolean) => void;
  formAction: string;
  setFormAction: (s: string) => void;
}
export default function SignUpForm({
  isCodeSent,
  isMobile,
  setIsCodeSent,
  isCodeResent,
  setIsCodeResent,
  formAction,
  setFormAction
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [authUser, setAuthUser] = useState([]);
  const [confirmationUsername, setConfirmationUsername] = useState("");
  const [confirmationEmail, setConfirmationEmail] = useState("");
  // const [currentUsername, setCurrentUsername] = useState("");
  const [error, setError] = useState("");
  const [formStatus, setFormStatus] = useState("");
  const [isResending, setIsResending] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [resetType, setResetType] = useState("");
  const [show, setShow] = useState(false);

  async function handleResendCode(
    username: string,
    setFieldError: (field: string, message: string | undefined) => void
  ) {
    try {
      setIsResending(true);
      setError("");
      await Auth.resendSignUp(username);
      setConfirmationUsername(username);
      setIsCodeSent(true);
      setIsResending(false);
    } catch (error: any) {
      setIsResending(false);
      console.warn(error.message);
      setFieldError("username", error.message);
      // setFieldError("username", "Username does not exist");
      dispatch({ type: "COGNITO_USER_SIGNUP_FAIL", payload: error });
    }
  }

  function getSchema() {
    if (formAction === "signIn") {
      return signInFormSchema;
    } else {
      return signUpFormSchema;
    }
  }

  return (
    <>
      <NewPasswordModal
        authUser={authUser}
        resetType={resetType}
        // currentUsername={currentUsername}
        show={show}
        setShow={setShow}
      />
      <Formik
        initialValues={{
          email: "",
          username: "",
          password: "",
          phone: "",
          appleEmail: "",
          googleEmail: ""
        }}
        validationSchema={getSchema()}
        onSubmit={async (
          values,
          { setSubmitting, resetForm, setFieldError }
        ) => {
          if (formAction === "signUp") {
            try {
              const payload: Record<string, any> = {
                username: values.username,
                password: values.password,
                attributes: {
                  email: values.email,
                  preferred_username: values.email,
                  "custom:appleEmail": values.appleEmail,
                  "custom:googleEmail": values.googleEmail
                }
              };
              if (values.phone) {
                payload.attributes.phone_number = values.phone;
              }
              if (values.appleEmail) {
                payload.attributes["custom:appleEmail"] = values.appleEmail;
              }
              if (values.googleEmail) {
                payload.attributes["custom:googleEmail"] = values.googleEmail;
              }
              const { user, userConfirmed }: ISignUpResult = await Auth.signUp(
                payload as SignUpParams
              );
              if (user && !userConfirmed) {
                const username = user.getUsername();
                setConfirmationUsername(username);
                setConfirmationEmail(values.email);
                setIsCodeSent(true);
              }
            } catch (error: any) {
              setSubmitting(false);
              console.warn(error.response || error);
              if (error.message.includes("User already exists")) {
                setFieldError("username", error.message);
              }
              if (error.message.includes("A user with email")) {
                setFieldError("email", "Email in use already");
              }
            }
          } else {
            try {
              setSubmitting(true);
              const user = await Auth.signIn(values.username, values.password);
              // setCurrentUsername(values.username);
              setAuthUser(user);
              if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                setSubmitting(false);
                setResetType("New Password Required");
                setShow(true);
                return;
              }
              resetForm();
              dispatch({ type: "SLUGIFY_LOADING_START" });
              history.push("/loading");
            } catch (e: any) {
              setSubmitting(false);
              setError(
                `${
                  e.message.includes("does not exist")
                    ? "Can't find that user. Are you new here?"
                    : e.message
                }`
              );
              // open check your email page
              if (e.message === "Password reset required for the user") {
                try {
                  const userData = await Auth.forgotPassword(values.username);
                  console.log(userData);
                  setResetType("Password Reset");
                  setShow(true);
                } catch (e) {
                  console.log(e);
                }
              }
            }
          }
        }}
      >
        {({ dirty, errors, isSubmitting, setFieldError, values }) => (
          <div
            className={`d-flex card border-muted w-100`}
            style={{
              minWidth: isMobile ? 300 : 400,
              maxWidth: isMobile ? 400 : 480
            }}
          >
            <div className="card-body">
              {!confirmationUsername ? (
                <>
                  {formAction === "signIn" ? (
                    <h4 className="custom-lead card-title text-center text-body-secondary">
                      Slugify
                    </h4>
                  ) : null}
                  <Form>
                    <div className="mb-1 mb-sm-2">
                      <label
                        className={`form-label ${
                          formAction === "signUp" && isMobile
                            ? "form-label-sm"
                            : ""
                        }`}
                        htmlFor="username"
                      >
                        Username
                      </label>
                      <Field
                        autoComplete="username"
                        className={`form-control ${
                          formAction === "signUp" && isMobile
                            ? "form-control-sm"
                            : ""
                        }`}
                        id="username"
                        name="username"
                        placeholder="Slugbert"
                        type="text"
                      />
                      <div className="formikErrorMessage">
                        <ErrorMessage name="username" />
                      </div>
                    </div>
                    {formAction === "signUp" ? (
                      <>
                        <div className="mb-1 mb-sm-2">
                          <label
                            className={`form-label ${
                              formAction === "signUp" ? "form-label-sm" : ""
                            }`}
                            htmlFor="email"
                          >
                            Slugify Email
                          </label>
                          <Field
                            aria-describedby="emailHelp"
                            autoComplete="email"
                            className={`form-control ${
                              formAction === "signUp" && isMobile
                                ? "form-control-sm"
                                : ""
                            }`}
                            id="email"
                            name="email"
                            placeholder="slugbert@darpa.net"
                            type="text"
                          />
                          <div className="formikErrorMessage">
                            <ErrorMessage name="email" />
                          </div>
                        </div>
                        <div className="mb-1 mb-sm-2">
                          <label
                            className={`form-label ${
                              formAction === "signUp" && isMobile
                                ? "form-label-sm"
                                : ""
                            }`}
                            htmlFor="appleEmail"
                          >
                            Apple Email
                          </label>
                          <Field
                            aria-describedby="appleEmailHelp"
                            className={`form-control ${
                              formAction === "signUp" && isMobile
                                ? "form-control-sm"
                                : ""
                            }`}
                            id="appleEmail"
                            name="appleEmail"
                            placeholder="slugbert@icloud.com"
                            type="text"
                          />
                          {errors.googleEmail ? (
                            <div className="formikErrorMessage">
                              <ErrorMessage name="appleEmail" />
                            </div>
                          ) : (
                            <small
                              className="fieldHelperText"
                              id="googleEmailHelp"
                            >
                              Required for Apple ID sign-in
                            </small>
                          )}
                        </div>
                        <div className="mb-1 mb-sm-2">
                          <label
                            className={`form-label ${
                              formAction === "signUp" && isMobile
                                ? "form-label-sm"
                                : ""
                            }`}
                            htmlFor="googleEmail"
                          >
                            Google Email
                          </label>
                          <Field
                            aria-describedby="googleEmailHelp"
                            className={`form-control ${
                              formAction === "signUp" && isMobile
                                ? "form-control-sm"
                                : ""
                            }`}
                            id="googleEmail"
                            name="googleEmail"
                            placeholder="slugbert@gmail.com"
                            type="text"
                          />
                          {errors.googleEmail ? (
                            <div className="formikErrorMessage">
                              <ErrorMessage name="googleEmail" />
                            </div>
                          ) : (
                            <small
                              className="fieldHelperText"
                              id="googleEmailHelp"
                            >
                              Required for Google sign-in
                            </small>
                          )}
                        </div>
                        {/* <div className="form-group mb-1 mb-sm-2">
                          <label
                            className={`form-label ${
                              formAction === "signUp" ? "form-label-sm" : ""
                            }`}
                            htmlFor="phone"
                          >
                            Phone
                          </label>
                          <Field
                            aria-describedby="phoneHelp"
                            autoComplete="phone"
                            className={` form-control ${
                              formAction === "signUp" ? "form-control-sm" : ""
                            }`}
                            id="phone"
                            name="phone"
                            placeholder="+12345551234"
                            type="text"
                          />
                          {errors.phone ? (
                            <div className="formikErrorMessage">
                              <ErrorMessage name="phone" />
                            </div>
                          ) : (
                            <small
                              className="fieldHelperText"
                              id="phoneEmailHelp"
                            >
                              Optional
                            </small>
                          )}
                        </div> */}
                      </>
                    ) : null}
                    <div className="mb-1 mb-sm-2">
                      <label
                        className={`form-label ${
                          formAction === "signUp" && isMobile
                            ? "form-label-sm"
                            : ""
                        }`}
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <Field
                        aria-describedby="passwordHelp"
                        autoComplete="current-password"
                        className={`form-control ${
                          formAction === "signUp" && isMobile
                            ? "form-control-sm"
                            : ""
                        }`}
                        id="password"
                        name="password"
                        placeholder="P@$$w0rd123"
                        type="password"
                      />
                      <div className="formikErrorMessage">
                        <ErrorMessage name="password" />
                      </div>
                    </div>
                    <Button
                      block
                      btnText={`Sign ${
                        formAction === "signIn" ? "in with Password" : "up"
                      }`}
                      color="primary"
                      isDisabled={
                        isSigningIn ||
                        isSubmitting ||
                        !dirty ||
                        !isEmptyObject(errors)
                      }
                      height={formAction === "signIn" ? 44 : undefined}
                      icon={formAction === "signIn" ? "icon-https" : undefined}
                      iconSize={formAction === "signIn" ? 22 : 18}
                      isSpinning={isSubmitting}
                      outline
                      spinnerSize={formAction === "signIn" ? 22 : 18}
                      spinnerColor="primary"
                      spinnerType={"puff"}
                      size={isMobile ? "sm" : undefined}
                      type="submit"
                    />
                    {error ? (
                      <small className="text-danger">{error}</small>
                    ) : // <small className="text-danger mb-0">&nbsp;</small>
                    null}
                  </Form>
                  {formAction === "signIn" && (
                    <>
                      {/* <div className="text-center mt-1 mb-2">
                        &#8212; or &#8212;
                      </div> */}
                      {/* <FederatedSignInButton
                        btnText="Sign in with Apple"
                        isSigningIn={isSigningIn}
                        isSubmitting={isSubmitting}
                        provider={CognitoHostedUIIdentityProvider.Apple}
                        setFieldError={setFieldError}
                        setIsSigningIn={setIsSigningIn}
                      /> */}
                      <div className="d-flex justify-content-center w-100 mt-2">
                        {/* <GoogleSignInButton
                          isSigningIn={isSigningIn}
                          isSubmitting={isSubmitting}
                          setFieldError={setFieldError}
                          setIsSigningIn={setIsSigningIn}
                        /> */}
                        {/* <GoogleLogin
                          onSuccess={(response) => console.log(response)}
                          onError={(e) => alert(e.message)}
                        /> */}
                      </div>
                      {/* <FederatedSignInButton
                        btnText="Sign in With Google"
                        className="mt-2"
                        isSigningIn={isSigningIn}
                        isSubmitting={isSubmitting}
                        provider={CognitoHostedUIIdentityProvider.Google}
                        setFieldError={setFieldError}
                        setIsSigningIn={setIsSigningIn}
                      /> */}
                    </>
                  )}
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <Button
                      btnText={
                        formAction === "signIn" ? "New User" : "Existing User"
                      }
                      className={`${formAction === "signIn" ? "mb-2" : "mb-0"}`}
                      isDisabled={isSigningIn || isSubmitting}
                      fn={() => {
                        setError("");
                        setFormAction(
                          formAction === "signIn" ? "signUp" : "signIn"
                        );
                      }}
                      size="sm"
                      link
                    />
                    {formAction === "signIn" && (
                      <Button
                        btnText={"What is Slugify?"}
                        className={`${
                          formAction === "signIn" ? "mb-2" : "mb-0"
                        }`}
                        fn={() => {
                          history.push("/tutorial");
                        }}
                        isDisabled={isSigningIn || isSubmitting}
                        size="sm"
                        link
                      />
                    )}
                    {/* {values.username && !errors.username && (
                      <Button
                        btnText={isCodeResent ? "Code Resent" : "Resend Code"}
                        className={`${
                          formAction === "signIn" ? "mb-2" : "mb-0"
                        }`}
                        fn={() =>
                          handleResendCode(values.username, setFieldError)
                        }
                        isDisabled={
                          !values.username || isSigningIn || isSubmitting
                        }
                        isSpinning={isResending}
                        // formAction={formAction}
                        link
                        size="sm"
                        spinnerReplacesText
                        spinnerColor="dark"
                        spinnerSize="sm"
                        spinnerType="border"
                        title={!values.username ? "Enter your user name" : ""}
                      />
                    )} */}
                  </div>
                </>
              ) : (
                <SignUpConfirmationForm
                  confirmationUsername={confirmationUsername}
                  confirmationEmail={confirmationEmail}
                  setConfirmationUsername={setConfirmationUsername}
                  setConfirmationEmail={setConfirmationEmail}
                  formStatus={formStatus}
                  isCodeSent={isCodeSent}
                  // setIsCodeSent={setIsCodeSent}
                  isCodeResent={isCodeResent}
                  setIsCodeResent={setIsCodeResent}
                  setFormAction={setFormAction}
                  setFormStatus={setFormStatus}
                />
              )}
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}

// export default withRouter(SignUpForm);
