import { useEffect, useState } from "react";
import $ from "jquery";
import { Spinner } from "./shared/Spinner";
import { createNewSpotifyPlaylist, createNewYouTubePlaylist } from "../actions";
import StatusMessage from "./shared/StatusMessage";
import { useDispatch } from "react-redux";
import { getPrimaryMusicStreamName } from "../functions";

export const CreateNewMusicStreamPlaylistModal = ({ primaryMusicStream }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [newPlaylistName, setNewPlaylistName] = useState("");

  useEffect(() => {
    const cleanUpModal = () => {
      setNewPlaylistName("");
      setIsSpinning("");
      setIsDone(false);
    };

    $("#create-new-music-stream-playlist-modal").on("hidden.bs.modal", () => {
      cleanUpModal();
    });
  }, []);

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      setIsSpinning(true);
      if (primaryMusicStream === "appleMusic") {
        // await dispatch(createNewAppleMusicPlaylist(newPlaylistName));
      } else if (primaryMusicStream === "spotify") {
        await dispatch(createNewSpotifyPlaylist(newPlaylistName));
      } else if (primaryMusicStream === "youtube") {
        await dispatch(createNewYouTubePlaylist(newPlaylistName));
      }
      setIsSpinning(false);
      setIsDone(true);
    } catch (error) {
      setIsSpinning(false);
      setError();
    }
  };

  const renderBody = () => {
    if (isDone) {
      return (
        <StatusMessage
          title="Success"
          emoticon="🌟"
          subtitle={`${newPlaylistName} was created on ${getPrimaryMusicStreamName(
            primaryMusicStream
          )}`}
          subtitle2=" "
        />
      );
    }
    return (
      <form onSubmit={(e) => handleSubmit(e)}>
        {error ? (
          <StatusMessage
            titleAnimation="headShake"
            emoticon="👩🏻‍⚕️"
            title="Something's not right..."
            subtitle={`${error}`}
          />
        ) : (
          <StatusMessage
            emoticon="tipper"
            subtitle={`This will create a new playlist on ${getPrimaryMusicStreamName(
              primaryMusicStream
            )}`}
          />
        )}
        <div className="d-flex flex-column form-group w-100">
          <label htmlFor="new-playlist-name-input" id="new-playlist-name-label">
            Enter new playlist name:
          </label>
          <input
            className="form-control"
            type="text"
            id="new-playlist-name-input"
            placeholder="Slugify Slurped Tracks"
            aria-describedby="new-playlist-name-label"
            onChange={(e) => setNewPlaylistName(e.target.value)}
            value={newPlaylistName}
          />
        </div>
      </form>
    );
  };

  return (
    <div className="modal fade" id="create-new-music-stream-playlist-modal">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title lead">Create New Playlist</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="d-flex flex-column modal-body">{renderBody()}</div>
          <div className="d-flex justify-content-between modal-footer">
            <button
              type="button"
              className="btn btn-link text-body-secondary text-decoration-none"
              data-bs-dismiss="modal"
            >
              {isDone ? "close" : "cancel"}
            </button>
            <button
              className="d-flex align-items-center justify-content-center btn btn-primary"
              disabled={isSpinning || isDone}
              onClick={() => handleSubmit()}
              type="button"
              style={{ height: 40, width: 170 }}
            >
              {isSpinning ? (
                <div className="me-1">
                  <Spinner size={18} color="light" type="puff" />
                </div>
              ) : (
                <span role="img" aria-label="sparkles">
                  ✨
                </span>
              )}
              <>
                <span className="ms-2">Create Playlist</span>
              </>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
