import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserYouTubeAvatar } from "../../functions";
import { markAsWatched, markAsUnwatched } from "../../actions";

const SlugTubeActionBar = ({ item, location }) => {
  const dispatch = useDispatch();
  const { pathname } = location;
  const currentYoutubeData = useSelector((state) => state.youtube);
  const isWatched = useSelector((state) =>
    state.user.watchedSlugTubeVids.includes(item.id)
  );
  const userEmail = useSelector((state) => state.auth.email);
  return (
    <div
      className="d-flex justify-content-center mb-1"
      style={{ width: "300px" }}
    >
      <button
        className="btn btn-sm py-0"
        onClick={() => {
          alert("I don't do anything yet.");
        }}
      >
        {getUserYouTubeAvatar(item.user)}
      </button>
      {currentYoutubeData.myPlaylists.length ? (
        <button
          className="btn btn-sm px-2 py-0"
          onClick={() => {
            alert("I don't do anything yet.");
          }}
        >
          <span
            role="img"
            aria-label="cup with straw"
            title="Slurp to Playlist"
            style={{ fontSize: "20px" }}
          >
            🥤
          </span>
        </button>
      ) : null}
      <button
        className="btn btn-sm px-2 py-0"
        onClick={() =>
          isWatched
            ? dispatch(markAsUnwatched(item.id))
            : dispatch(markAsWatched(item.id))
        }
      >
        <span
          aria-label="eye-con"
          role="img"
          style={{ fontSize: "20px", opacity: `${isWatched ? ".3" : "1"}` }}
          title={` Mark as ${isWatched ? "Unwatched" : "Watched"}`}
        >
          👁️
        </span>
      </button>
      {!pathname.includes("slugtube-commentary") ? (
        <Link
          className="d-flex align-items-center btn btn-sm btn-outline-none px-2 py-0"
          to={`/slugtube-commentary/${item.id}`}
          title="Comments"
        >
          <span
            role="img"
            aria-label="Speech Balloon"
            style={{ fontSize: "20px" }}
          >
            💬
          </span>
          <title>Comment</title>
        </Link>
      ) : null}
      {item.user.includes(userEmail) ? (
        <button
          className="btn btn-sm px-2 py-0"
          onClick={() => {
            alert("I don't do anything yet.");
          }}
        >
          <span
            role="img"
            aria-label="wastebasket"
            title="Delete forever"
            style={{ fontSize: "20px" }}
          >
            🗑️
          </span>
        </button>
      ) : null}
    </div>
  );
};

export default withRouter(SlugTubeActionBar);
