// import { CreateNewSpotifyPlaylistModal } from "./CreateNewSpotifyPlaylistModal";
// import { getMyYoutubePlaylists, initYouTubeApi } from "../actions";
// import { useDispatch, useSelector } from "react-redux";
// import { withRouter } from "react-router-dom";
import { CreateNewMusicStreamPlaylistModal } from "./CreateNewMusicStreamPlaylistModal";
// import { isMobile } from "react-device-detect";
import { SelectedPlaylist } from "../types";
import { SlugTubeMusicModal } from "./SlugTubeMusicModal";
import { useAppSelector } from "../hooks";
import { useContext, useState } from "react";
import $ from "jquery";
import SlugifySpotifyUnmatchedItem from "./SlugifySpotifyUnmatchedItem";
import SlugifyYouTubeMusicItem from "./SlugifyYouTubeMusicItem";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface Props {
  filteredTracks: any[];
  handleAddAllToYouTube: () => void;
  myNonForgottenUnmatchedTracks: any[];
  primaryMusicStream: string;
  renderAddButtonTitle: () => string;
  renderPlaylistOptions: () => JSX.Element[];
  selectedMusicPlaylist: SelectedPlaylist | undefined;
  setSelectedMusicPlaylist: (p: SelectedPlaylist) => void;
  selectedVideosPlaylist: SelectedPlaylist | undefined;
  setSelectedVideosPlaylist: (p: SelectedPlaylist) => void;
  showMyTracks: boolean;
  showSlurpedTracks: boolean;
  hasMatchedTracks: boolean | undefined;
  hasVideos: boolean | undefined;
}
export default function SlugifyYouTube({
  filteredTracks,
  handleAddAllToYouTube,
  myNonForgottenUnmatchedTracks,
  primaryMusicStream,
  renderAddButtonTitle,
  renderPlaylistOptions,
  selectedMusicPlaylist,
  setSelectedMusicPlaylist,
  selectedVideosPlaylist,
  setSelectedVideosPlaylist,
  showMyTracks,
  showSlurpedTracks,
  hasMatchedTracks,
  hasVideos
}: Props) {
  const { isDark } = useContext(ThemeContext);
  const {
    youtube: { myPlaylists }
  } = useAppSelector((state) => state);

  // const [playlists, setPlaylists] = useState([]);
  const [isYtMusicModalOpen, setIsYtMusicModalOpen] = useState(false);
  const [videoData, setVideoData] = useState({});

  const renderTracks = () => {
    return filteredTracks.map((item, index) => {
      return (
        <SlugifyYouTubeMusicItem
          key={index}
          item={item}
          setVideoData={setVideoData}
          setIsYtMusicModalOpen={setIsYtMusicModalOpen}
          showButton
          showMyTracks={showMyTracks}
          showSlurpedTracks={showSlurpedTracks}
        />
      );
    });
  };
  const renderUnmatchedTracks = () => {
    if (myNonForgottenUnmatchedTracks.length) {
      return myNonForgottenUnmatchedTracks.map((item) => {
        return (
          <SlugifySpotifyUnmatchedItem
            key={item.appleMusicInfo.appleMusicId}
            item={item}
          />
        );
      });
    }
  };
  const musicPlaylistPopover = (
    <Popover id="musicPlaylistPopover">
      <Popover.Header>Music Destination Playlist</Popover.Header>
      <Popover.Body>
        {/* <p className="mb-2">
          Slugify automatically selects the <strong>SlugTube Music</strong>{" "}
          playlist by default.
        </p>
        <p className="mb-0">
          Videos matched from shared music tracks will be saved to this playlist
          on YouTube.
        </p> */}
        {selectedMusicPlaylist?.id ? (
          <p className="mb-0">
            Videos matched from shared music will be saved to{" "}
            <strong>{selectedMusicPlaylist.name}</strong> on YouTube.
          </p>
        ) : (
          <p className="mb-0">
            Videos matched from shared music tracks will be saved to the
            selected playlist on YouTube.
          </p>
        )}
      </Popover.Body>
    </Popover>
  );
  const videosPlaylistPopover = (
    <Popover id="videosPlaylistPopover">
      <Popover.Header>Videos Destination Playlist</Popover.Header>
      <Popover.Body>
        {/* <p className="mb-2">
          Slugify automatically selects the <strong>SlugTube Videos</strong>{" "}
          playlist by default.
        </p> */}
        {selectedVideosPlaylist?.id ? (
          <p className="mb-0">
            Shared videos will be saved to{" "}
            <strong>{selectedVideosPlaylist.name}</strong> on YouTube.
          </p>
        ) : (
          <p className="mb-0">
            Shared videos will be saved to the selected playlist on YouTube.
          </p>
        )}
      </Popover.Body>
    </Popover>
  );
  const unmatchedTracksPopover = (
    <Popover id="unmatchedTracksPopover">
      <Popover.Header>Unmatched Tracks</Popover.Header>
      <Popover.Body>
        <p className="mb-2">
          The following tracks could not be matched for some reason.
        </p>
        <p className="mb-0">
          You can try searching manually for them or simply forget them.
        </p>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div
        className="d-flex flex-column align-items-center w-100"
        style={{ maxWidth: 800 }}
      >
        {filteredTracks.length ? (
          <ul className="list-unstyled mt-3 w-100">{renderTracks()}</ul>
        ) : null}
        {myNonForgottenUnmatchedTracks.length > 0 && (
          <div className="d-flex flex-column w-100">
            <h5
              className={`d-flex align-items-center mb-0 ${
                !filteredTracks.length ? "mt-2" : "mt-0"
              }`}
            >
              <span className="me-1">Unmatched Tracks</span>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={unmatchedTracksPopover}
                rootClose
              >
                <i
                  className="icon-info slug-icon text-info"
                  style={{ fontSize: 20 }}
                />
              </OverlayTrigger>
            </h5>
            <ul className="list-group w-100" style={{ border: "none" }}>
              {renderUnmatchedTracks()}
            </ul>
          </div>
        )}
        {filteredTracks.length &&
        !showMyTracks &&
        !showSlurpedTracks &&
        (hasMatchedTracks || hasVideos) ? (
          <div className="d-flex flex-column w-100 mt-2">
            {hasMatchedTracks && (
              <div className="form-group mb-3">
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={musicPlaylistPopover}
                  // rootClose not working here!  maybe because it's in form group?
                  // rootClose
                >
                  <label
                    className="form-label"
                    htmlFor="selected-music-playlist"
                  >
                    <div className="d-flex align-items-center">
                      <span>Music Destination Playlist</span>
                      <span
                        className={`icon-info slug-icon text-${
                          selectedMusicPlaylist?.id ? "info" : "danger"
                        } ms-1 mb-0`}
                        style={{ cursor: "pointer", fontSize: 20 }}
                      />
                    </div>
                  </label>
                </OverlayTrigger>
                <select
                  className="form-select"
                  id="selected-music-playlist"
                  onChange={(e: any) => {
                    const index = e.nativeEvent.target.selectedIndex;
                    const label = e.nativeEvent.target[index].text;
                    const value = e.target.value;
                    setSelectedMusicPlaylist({ name: label, id: value });
                  }}
                  value={selectedMusicPlaylist?.id || ""}
                >
                  <option value="">Select...</option>
                  {renderPlaylistOptions()}
                </select>
              </div>
            )}
            {hasVideos && (
              <div className="form-group mb-1">
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={videosPlaylistPopover}
                  // rootClose not working here!  maybe because it's in form group?
                  // rootClose
                >
                  <label
                    className="form-label"
                    htmlFor="selected-music-playlist"
                  >
                    <div className="d-flex align-items-center">
                      <span>Videos Destination Playlist</span>
                      <span
                        className={`icon-info slug-icon text-${
                          selectedVideosPlaylist?.id ? "info" : "danger"
                        } ms-1 mb-0`}
                        style={{ cursor: "pointer", fontSize: 20 }}
                      />
                    </div>
                  </label>
                </OverlayTrigger>
                <select
                  className="form-select"
                  id="selected-video-playlist"
                  onChange={(e: any) => {
                    const index = e.nativeEvent.target.selectedIndex;
                    const label = e.nativeEvent.target[index].text;
                    const value = e.target.value;
                    setSelectedVideosPlaylist({ name: label, id: value });
                  }}
                  value={selectedVideosPlaylist?.id || ""}
                >
                  <option value="">Select...</option>
                  {renderPlaylistOptions()}
                </select>
              </div>
            )}
            {myPlaylists && !myPlaylists.length ? (
              <div className="d-flex flex-column w-100">
                <button
                  className="d-flex justify-content-center align-items-center btn btn-block btn-primary mt-2"
                  onClick={() => {
                    $("#create-new-music-stream-playlist-modal").modal("show");
                  }}
                  title="Create New YouTube Playlist"
                >
                  <span role="img" aria-label="Sparkles">
                    ✨
                  </span>
                  <span className="ms-2">Create New YouTube Playlist</span>
                </button>
              </div>
            ) : (
              <button
                className={`d-flex justify-content-center align-items-center btn btn-${
                  isDark ? "outline-primary" : "primary"
                } mt-2 w-100`}
                disabled={
                  !selectedMusicPlaylist?.id || !selectedVideosPlaylist?.id
                }
                // style={{
                //   cursor: `${
                //     !filteredTracks.filter(
                //       (item) => item.slurpStatus === "not my track"
                //     ).length || !selectedPlaylist.id
                //       ? "not-allowed"
                //       : "pointer"
                //   }`
                // }}
                onClick={() => {
                  handleAddAllToYouTube();
                }}
                title={renderAddButtonTitle()}
              >
                <span role="img" aria-label="a cute little snail">
                  🐌
                </span>
                <span className="d-flex d-sm-none ms-2">
                  Slurp All Tracks to Spotify
                </span>
                <span className="d-none d-sm-flex ms-2">Slurp All Tracks</span>
              </button>
            )}
          </div>
        ) : null}
      </div>
      <CreateNewMusicStreamPlaylistModal
        primaryMusicStream={primaryMusicStream}
      />
      <SlugTubeMusicModal
        videoData={videoData}
        setVideoData={setVideoData}
        isYtMusicModalOpen={isYtMusicModalOpen}
        setIsYtMusicModalOpen={setIsYtMusicModalOpen}
      />
    </>
  );
}
