import { useEffect, useState } from "react";
import { Auth, API } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { deleteUserAccount } from "../actions/slugifyGraphqlActions";
import { animateScroll as scroll } from "react-scroll";
import { getAppleMusicInstance } from "../actions";
import { Link, useHistory } from "react-router-dom";
import { SlugifyActionType } from "../types";
import { useAppDispatch, useAppSelector } from "../hooks";

interface Props {
  setShowDangerZone: (b: boolean) => void;
  isDeleting: boolean;
  setIsDeleting: (b: boolean) => void;
}
export const UserSettingsDangerZone = ({
  setShowDangerZone,
  isDeleting,
  setIsDeleting
}: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    auth: {
      attributes: { identities }
    },
    newUser: { primaryMusicStream },
    slugify: { loadingStatus: status }
  } = useAppSelector((state) => state);

  const [dangerZoneUserInput, setDangerZoneUserInput] = useState("");
  // const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    scroll.scrollToBottom();
    // scroller.scrollTo("dangerZone", {
    //   duration: 1500,
    //   delay: 100,
    //   smooth: true,
    //   isDynamic: true,
    //   containerId: "danger-zone"
    // });
  }, []);

  const handleRemoveUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsDeleting(true);
      dispatch({ type: "DELETE_USER_START" });
      const cognitoAppleMusicUsername = identities
        ? JSON.parse(identities)
            .filter(
              (item: Record<string, string>) =>
                item.providerName === CognitoHostedUIIdentityProvider.Apple
            )
            .map(
              (item: Record<string, string>) => "SignInWithApple_" + item.userId
            )[0]
        : null;

      const cognitoGoogleUsername = identities
        ? JSON.parse(identities)
            .filter(
              (item: Record<string, string>) =>
                item.providerName === CognitoHostedUIIdentityProvider.Google
            )
            .map((item: Record<string, string>) => "Google_" + item.userId)[0]
        : null;

      const providerUsernames = [];
      if (cognitoAppleMusicUsername) {
        providerUsernames.push(cognitoAppleMusicUsername);
      }
      if (cognitoGoogleUsername) {
        providerUsernames.push(cognitoGoogleUsername);
      }

      const cognitoAppleMusicUserId = identities
        ? JSON.parse(identities)
            .filter(
              (item: Record<string, string>) =>
                item.providerName === CognitoHostedUIIdentityProvider.Apple
            )
            .map((item: Record<string, string>) => item.userId)[0]
        : null;

      const cognitoGoogleUserId = identities
        ? JSON.parse(identities)
            .filter(
              (item: Record<string, string>) =>
                item.providerName === CognitoHostedUIIdentityProvider.Google
            )
            .map((item: Record<string, string>) => item.userId)[0]
        : null;

      const users: any[] = [];
      if (cognitoAppleMusicUserId) {
        users.push(cognitoAppleMusicUsername);
      }
      if (cognitoGoogleUserId) {
        users.push(cognitoGoogleUsername);
      }
      if (primaryMusicStream === "appleMusic") {
        const { music } = await dispatch(getAppleMusicInstance());
        if (music.isAuthorized) {
          await music.unauthorize();
        }
      }
      await dispatch(deleteUserAccount());
      // delete cognito account
      dispatch({
        type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
        payload: "Removing user account..."
      });
      const user = await Auth.currentAuthenticatedUser();
      user.deleteUser(async (error: any) => {
        if (error) {
          throw error;
        }
        if (users.length) {
          dispatch({
            type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
            payload: "Final cleanup..."
          });
          try {
            const response = await API.post("SlugBucketAPI", `/cognito`, {
              body: {
                users: JSON.stringify(users)
              }
            });
            console.log("🗑️", response);
          } catch (error) {
            dispatch({
              type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
              payload: "Something's not right..."
            });
            throw error;
          }
        }
        const stream = primaryMusicStream;
        Auth.signOut({ global: true });
        dispatch({ type: "DELETE_USER_SUCCESS" });
        // setIsDeleting(false);
        history.replace("/aloha", { primaryMusicStream: stream });
      });
      return;
    } catch (error: any) {
      setIsDeleting(false);
      console.warn(error.response ? error.response.data.error.message : error);
      if (error.Error) {
        dispatch({ type: "DELETE_USER_FAIL", payload: new Error(error.Error) });
      } else {
        dispatch({ type: "DELETE_USER_FAIL", payload: error });
      }
    }
  };

  return (
    <div className="border border-user-settings rounded my-3 p-3 px-4 text-body-secondary d-flex flex-column align-items-center w-100 animate__animated animate__fadeIn">
      <div className="container lead text-body-secondary d-flex flex-column align-items-center text-center">
        <div className="slug-subtitle mb-3 text-danger" id="danger-zone">
          Danger Zone
        </div>
        <p>
          If you&apos;d like to remove yourself completely<sup>*</sup> from
          Slugify, enter
          <strong>&quot;delete me&quot;</strong> below and click the red button.
        </p>
        <p>
          This will not affect your account on Apple Music or Spotify, except to
          remove the Slugify playlist.
        </p>
        <p>If you change you mind later, you can always sign up again.</p>
        <div
          className="d-flex flex-column align-items-center"
          style={{ width: "320px" }}
        >
          {isDeleting ? (
            <div className="d-flex justify-content-center my-3 w-100">
              <span
                className="animate__animated animate__flash animate__infinite"
                role="img"
                aria-label="fire"
              >
                🔥
              </span>
              <span className="font-weight-bold text-danger w-100">
                &nbsp; {status} &nbsp;
              </span>
              <span
                className="animate__animated animate__flash animate__infinite"
                role="img"
                aria-label="fire"
              >
                🔥
              </span>
            </div>
          ) : (
            <form action="" onSubmit={(e) => handleRemoveUser(e)}>
              <input
                autoFocus
                id="dangerZoneUserInput"
                type="text"
                className="form-control w-100"
                aria-label="delete user account"
                onChange={(e) => setDangerZoneUserInput(e.target.value)}
                value={dangerZoneUserInput}
              />
              <button
                className="btn btn-danger mt-3 d-flex justify-content-center align-items-center w-100"
                data-bs-toggle="modal"
                data-bs-target="#danger-zone-modal"
                disabled={dangerZoneUserInput !== "delete me" || isDeleting}
                style={{ height: "38px" }}
                type="submit"
              >
                <span>Delete Slugify Account</span>
              </button>
            </form>
          )}
        </div>
        <small className="mt-3">
          <sup>*</sup>See our{" "}
          <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
            privacy policy
          </Link>{" "}
          for details.
        </small>
        <button
          className="btn btn-link btn-sm text-body-secondary mt-3 text-decoration-none"
          onClick={() => {
            scroll.scrollToTop();
            setShowDangerZone(false);
          }}
        >
          cancel
        </button>
      </div>
    </div>
  );
};
