import { useSelector } from "react-redux";
import SlugBucket from "./components/SlugBucket";
import Slugify from "./components/Slugify";
import SlugTube from "./components/SlugTube/SlugTube";

const App = () => {
  const {
    newUser: { homeMenu }
  } = useSelector((state) => state);

  const renderComponent = () => {
    switch (homeMenu) {
      case "slugify":
        return <Slugify />;
      case "slugbucket":
        return <SlugBucket />;
      case "slugtube":
        return <SlugTube />;
      default:
        return <Slugify />;
    }
  };
  return renderComponent();
};

export default App;
