//eslint-disable jsx-a11y/anchor-is-valid
import { useCallback, useContext, useEffect, useState } from "react";
import { AutoSlurpPlaylistSelector } from "./AutoSlurpPlaylistSelector";
import { AutoSlurpScheduleSelector } from "./AutoSlurpScheduleSelector";
import { AutoSlurpTimeSelector } from "./AutoSlurpTimeSelector";
import { getTitleCase } from "../functions";
import { MyFriendsSharedPlaylists } from "./MyFriendsSharedPlaylists";
import { omit } from "lodash";
import { Spinner } from "./shared/Spinner";
import { useLocation, useHistory } from "react-router-dom";
import TimezoneSelect from "react-timezone-select";
import {
  createAppleMusicSlugifyDirectPlaylist,
  getCurrentUserAppleMusicPlaylists,
  createSpotifySlugifyDirectPlaylist,
  getCurrentUserSpotifyPlaylists,
  updateSlugifyUser
} from "../actions";
import { useAppDispatch, useAppSelector, useWindowSize } from "../hooks";
import { TimeZoneSelectorMobile } from "./TimeZoneSelectorMobile";
import { isMobile } from "react-device-detect";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { ThemeContext } from "../contexts/ColorThemeProvider";

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? "#FFF" : styles.color,
    backgroundColor: state.isSelected
      ? "rgba(208, 234, 226, 0.33)"
      : styles.color,
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
    borderRadius: "0.4rem",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.125)"
    }
  }),
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)" : 0,
    borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA",
    "&:hover": {
      borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA"
    }
  })
};

export const FriendDetails = () => {
  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);
  const location = useLocation();
  const history = useHistory();
  const [, width] = useWindowSize();
  const { friend } = location.state;
  const {
    auth: { username: currentUsername },
    slugify: { appleMusicPlaylists },
    spotify: { myPlaylists: mySpotifyPlaylists },
    newUser: {
      autoSlurpFriends,
      myFriendsPublicPlaylists,
      primaryMusicStream,
      publicPlaylists: myAppleMusicPlaylists
    }
  } = useAppSelector((state) => state);

  const [autoSlurpFriend, setAutoSlurpFriend] = useState("");

  useEffect(() => {
    if (autoSlurpFriends.length) {
      setAutoSlurpFriend(
        autoSlurpFriends.filter((item) => item.username === friend.username)[0]
      );
    } else {
      setAutoSlurpFriend("");
    }
  }, [autoSlurpFriends, friend.username]);

  const [isRefreshingPlaylists, setIsRefreshingPlaylists] = useState(false);
  const [
    isRefreshingPlaylistsIconSpinning,
    setIsRefreshingPlaylistsIconSpinning
  ] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const [isUpdatingAutoSlurp, setIsUpdatingAutoSlurp] = useState(false);
  const [
    isUpdatingAutoSlurpNotifications,
    setIsUpdatingAutoSlurpNotifications
  ] = useState(false);
  const [
    isUpdatingAutoSlurpNotificationTimezone,
    setIsUpdatingAutoSlurpNotificationTimezone
  ] = useState(false);
  const [isUpdatingAutoSlurpPlaylist, setIsUpdatingAutoSlurpPlaylist] =
    useState(false);
  const [isUpdatingAutoSlurpSchedule, setIsUpdatingAutoSlurpSchedule] =
    useState(false);
  const [
    isUpdatingAutoSlurpNotificationTime,
    setIsUpdatingAutoSlurpNotificationTime
  ] = useState(false);

  const handleRefreshPlaylists = useCallback(
    async (shouldISpin = true) => {
      try {
        console.log("REFRESHING PLAYLISTS");
        setIsRefreshingPlaylists(true);
        if (shouldISpin) {
          setIsRefreshingPlaylistsIconSpinning(true);
        }
        await dispatch(getCurrentUserSpotifyPlaylists());
        setIsRefreshingPlaylists(false);
        setIsRefreshingPlaylistsIconSpinning(false);
      } catch (error) {
        console.warn(error);
        setIsRefreshingPlaylistsIconSpinning(false);
        setIsRefreshingPlaylists(false);
      }
    },
    [dispatch]
  );

  const [currentSelectedSlurpToPlaylist, setCurrentSelectedSlurpToPlaylist] =
    useState("");

  useEffect(() => {
    if (autoSlurpFriend.playlists) {
      handleRefreshPlaylists(false)
        .then(() => {
          if (autoSlurpFriend.playlists.length) {
            const friendSlugifySlurpToPlaylist = autoSlurpFriend.playlists
              .filter(
                (item) =>
                  item.slurpFromPlaylist &&
                  item.slurpFromPlaylist.name === "Slugify"
              )
              .map((item) => item.slurpToPlaylist)[0];

            if (friendSlugifySlurpToPlaylist) {
              console.log(
                "SETTING CURRENT SELECTED PLAYLIST",
                friendSlugifySlurpToPlaylist
              );
              setCurrentSelectedSlurpToPlaylist({
                ...friendSlugifySlurpToPlaylist
              });
            } else {
              console.log("NO CURRENT SELECTED PLAYLIST");
              setCurrentSelectedSlurpToPlaylist("");
            }
          } else {
            console.log("FRIEND HAS NO AUTO SLURP PLAYLISTS");
            setCurrentSelectedSlurpToPlaylist("");
          }
        })
        .catch((error) => console.warn(error));
    } else {
      console.log("NO AUTOSLURP FRIEND PLAYLISTS");
    }
  }, [autoSlurpFriend.playlists, handleRefreshPlaylists]);

  useEffect(() => {
    const slurpToPlaylistIds =
      autoSlurpFriend &&
      autoSlurpFriend.playlists &&
      autoSlurpFriend.playlists.length
        ? autoSlurpFriend.playlists.map((item) => {
            return item.slurpToPlaylist.id;
          })
        : [];
    if (autoSlurpFriend) {
      if (autoSlurpFriend.playlists && autoSlurpFriend.playlists.length) {
        const mySpotifyPlaylistIds = mySpotifyPlaylists.map((item) => item.id);
        console.log("AutoSlurp destination playlist IDs", slurpToPlaylistIds);
        console.log("Spotify playlist IDs", mySpotifyPlaylistIds);
        console.log(
          "Current AutoSlurp Slurp To Playlist",
          currentSelectedSlurpToPlaylist.name
        );
        if (
          currentSelectedSlurpToPlaylist &&
          !mySpotifyPlaylistIds.includes(currentSelectedSlurpToPlaylist.id) &&
          slurpToPlaylistIds.includes(currentSelectedSlurpToPlaylist.id)
        ) {
          setIsUpdatingAutoSlurpPlaylist(true);
          console.log(
            `AutoSlurp playlist, ${currentSelectedSlurpToPlaylist.name}, no longer exists on Spotify`
          );
          console.log(
            `Removing ${currentSelectedSlurpToPlaylist.name} from AutoSlurp`
          );
          const updatedPlaylists = [
            ...autoSlurpFriend.playlists.filter((item) => {
              return (
                item.slurpToPlaylist.id !== currentSelectedSlurpToPlaylist.id
              );
            })
          ];
          const updatedAutoSlurpFriend = omit(autoSlurpFriend, "playlists");
          updatedAutoSlurpFriend.playlists = updatedPlaylists;
          const payload = [
            ...autoSlurpFriends.filter(
              (item) => item.username !== friend.username
            ),
            updatedAutoSlurpFriend
          ];
          dispatch(
            updateSlugifyUser({
              autoSlurpFriends: payload
            })
          )
            .then(() => {
              console.log(
                `${currentSelectedSlurpToPlaylist.name} removed from AutoSlurp`
              );
            })
            .catch((error) => console.warn("ERROR UPDATING AUTOSLURP", error));
        }
      } else {
        console.log("AutoSlurp Friend has no playlists!");
        if (currentSelectedSlurpToPlaylist.name) {
          setCurrentSelectedSlurpToPlaylist("");
          // setIsUpdatingAutoSlurpPlaylist(false);
        }
      }
    } else {
      console.log("No AutoSlurp Friend!");
      if (currentSelectedSlurpToPlaylist.name) {
        setCurrentSelectedSlurpToPlaylist("");
      }
    }
  }, [
    autoSlurpFriend,
    autoSlurpFriends,
    currentSelectedSlurpToPlaylist,
    dispatch,
    friend.username,
    mySpotifyPlaylists
  ]);

  const getFriendsSlugifyPlaylist = (playlist = "slugify") => {
    try {
      let slugifyPlaylist;
      if (primaryMusicStream === "spotify") {
        if (playlist === "slugify-direct") {
          slugifyPlaylist = myFriendsPublicPlaylists
            .filter(
              (item) =>
                item.owner === friend.username &&
                item.name ===
                  `${getTitleCase(currentUsername)} (Slugify Direct)`
            )
            .map((item) => ({ name: item.name, id: item.id }));
        } else {
          slugifyPlaylist = appleMusicPlaylists
            .filter((item) => item === friend.musicStreamId)
            .map((item) => ({ name: "Slugify", id: item }));
        }
        return slugifyPlaylist[0];
      }
      return null;
    } catch (error) {
      console.warn(error);
    }
  };

  const handleSelectAutoSlurpPlaylist = async (playlist) => {
    try {
      setIsUpdatingAutoSlurpPlaylist(true);
      const friendsSlugifyPlaylist = getFriendsSlugifyPlaylist("slugify");
      const friendsSlugifyDirectPlaylist =
        getFriendsSlugifyPlaylist("slugify-direct");
      let updatedFriendPlaylists = [];
      const playlistPayload = [
        {
          slurpToPlaylist: {
            name: playlist.label,
            id: playlist.value
          },
          slurpFromPlaylist: { ...friendsSlugifyPlaylist }
        }
      ];
      if (friendsSlugifyDirectPlaylist) {
        playlistPayload.push({
          slurpToPlaylist: {
            name: playlist.label,
            id: playlist.value
          },
          slurpFromPlaylist: { ...friendsSlugifyDirectPlaylist }
        });
      }
      if (
        autoSlurpFriend &&
        autoSlurpFriend.playlists &&
        autoSlurpFriend.playlists.length
      ) {
        if (currentSelectedSlurpToPlaylist) {
          console.log(
            "CURRENT SELECTED PLAYLIST EXISTS: ",
            currentSelectedSlurpToPlaylist
          );
          updatedFriendPlaylists = [
            ...autoSlurpFriend.playlists.filter((item) => {
              return (
                item.slurpToPlaylist.id !== currentSelectedSlurpToPlaylist.id
              );
            }),
            ...playlistPayload
          ];
        } else {
          console.log("NO CURRENT SELECTED PLAYLIST");
          updatedFriendPlaylists = [
            ...autoSlurpFriend.playlists.filter((item) => {
              return item.slurpToPlaylist.id !== playlist.id;
            }),
            ...playlistPayload
          ];
        }
      } else {
        console.log("NO FRIEND OR NO PLAYLISTS");
        updatedFriendPlaylists = [...playlistPayload];
      }
      const updatedAutoSlurpFriend = omit(autoSlurpFriend, "playlists");
      updatedAutoSlurpFriend.playlists = updatedFriendPlaylists;
      const payload = [
        ...autoSlurpFriends.filter((item) => item.username !== friend.username),
        updatedAutoSlurpFriend
      ];
      await dispatch(
        updateSlugifyUser({
          autoSlurpFriends: payload
        })
      );
      // setIsUpdatingAutoSlurpPlaylist(false);
    } catch (error) {
      setIsUpdatingAutoSlurpPlaylist(false);
      console.warn(error);
    }
  };

  const handleSelectAutoSlurpPlaylistMobile = async (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    const playlist = {
      label: e.nativeEvent.target[index].text,
      value: e.target.value
    };
    try {
      setIsUpdatingAutoSlurpPlaylist(true);
      const friendsSlugifyPlaylist = getFriendsSlugifyPlaylist();
      const friendsSlugifyDirectPlaylist =
        getFriendsSlugifyPlaylist("slugify-direct");
      let updatedFriendPlaylists = [];
      const playlistPayload = [
        {
          slurpToPlaylist: {
            name: playlist.label,
            id: playlist.value
          },
          slurpFromPlaylist: { ...friendsSlugifyPlaylist }
        }
      ];
      if (friendsSlugifyDirectPlaylist) {
        playlistPayload.push({
          slurpToPlaylist: {
            name: playlist.label,
            id: playlist.value
          },
          slurpFromPlaylist: { ...friendsSlugifyDirectPlaylist }
        });
      }
      if (
        autoSlurpFriend &&
        autoSlurpFriend.playlists &&
        autoSlurpFriend.playlists.length
      ) {
        if (currentSelectedSlurpToPlaylist) {
          console.log(
            "CURRENT SELECTED PLAYLIST EXISTS: ",
            currentSelectedSlurpToPlaylist
          );
          updatedFriendPlaylists = [
            ...autoSlurpFriend.playlists.filter((item) => {
              return (
                item.slurpToPlaylist.id !== currentSelectedSlurpToPlaylist.id
              );
            }),
            ...playlistPayload
          ];
        } else {
          console.log("NO CURRENT SELECTED PLAYLIST");
          updatedFriendPlaylists = [
            ...autoSlurpFriend.playlists.filter((item) => {
              return item.slurpToPlaylist.id !== playlist.id;
            }),
            ...playlistPayload
          ];
        }
      } else {
        console.log("NO FRIEND OR NO PLAYLISTS");
        updatedFriendPlaylists = [...playlistPayload];
      }
      const updatedAutoSlurpFriend = omit(autoSlurpFriend, "playlists");
      updatedAutoSlurpFriend.playlists = updatedFriendPlaylists;
      const payload = [
        ...autoSlurpFriends.filter((item) => item.username !== friend.username),
        updatedAutoSlurpFriend
      ];
      await dispatch(
        updateSlugifyUser({
          autoSlurpFriends: payload
        })
      );
      // setIsUpdatingAutoSlurpPlaylist(false);
    } catch (error) {
      setIsUpdatingAutoSlurpPlaylist(false);
      console.warn(error);
    }
  };
  const handleTimezoneSelect = async (tz) => {
    try {
      if (!autoSlurpFriend) {
        return;
      }
      setIsUpdatingAutoSlurpNotificationTimezone(true);
      const updatedAutoSlurpFriend = omit(autoSlurpFriend, "scheduleTimezone");
      updatedAutoSlurpFriend.scheduleTimezone = tz;
      const payload = [
        ...autoSlurpFriends.filter((item) => item.username !== friend.username),
        updatedAutoSlurpFriend
      ];
      await dispatch(
        updateSlugifyUser({
          autoSlurpFriends: payload
        })
      );
      setIsUpdatingAutoSlurpNotificationTimezone(false);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleAutoSlurpToggle = async () => {
    try {
      setIsUpdatingAutoSlurp(true);
      if (autoSlurpFriend) {
        await dispatch(
          updateSlugifyUser({
            autoSlurpFriends: autoSlurpFriends.filter(
              (item) => item.username !== friend.username
            )
          })
        );
      } else {
        await dispatch(
          updateSlugifyUser({
            autoSlurpFriends: [
              ...autoSlurpFriends,
              {
                isNotificationsEnabled: false,
                scheduleDays: [],
                scheduleTime: { label: "Anytime", value: "24" },
                scheduleTimezone: {},
                playlists: [],
                username: friend.username
              }
            ]
          })
        );
      }
      setIsUpdatingAutoSlurp(false);
    } catch (error) {
      console.warn(error);
      setIsUpdatingAutoSlurp(false);
    }
  };

  const handleAutoSlurpNotificationsToggle = async () => {
    try {
      setIsUpdatingAutoSlurpNotifications(true);
      const updatedAutoSlurpFriend = { ...autoSlurpFriend };
      if (
        updatedAutoSlurpFriend &&
        updatedAutoSlurpFriend.isNotificationsEnabled
      ) {
        updatedAutoSlurpFriend.isNotificationsEnabled = false;
      } else if (
        updatedAutoSlurpFriend &&
        !updatedAutoSlurpFriend.isNotificationsEnabled
      ) {
        updatedAutoSlurpFriend.isNotificationsEnabled = true;
      }
      const payload = [
        ...autoSlurpFriends.filter((item) => item.username !== friend.username),
        updatedAutoSlurpFriend
      ];

      await dispatch(
        updateSlugifyUser({
          autoSlurpFriends: payload
        })
      );
      setIsUpdatingAutoSlurpNotifications(false);
    } catch (error) {
      console.warn(error);
      setIsUpdatingAutoSlurpNotifications(false);
    }
  };

  const handleCreateAppleMusicSlugifyDirectPlaylist = async () => {
    try {
      setIsSpinning(true);
      await dispatch(createAppleMusicSlugifyDirectPlaylist(friend.username));
    } catch (error) {
      setIsSpinning(false);
      console.warn(error);
    }
  };

  const handleCreateSpotifySlugifyDirectPlaylist = async () => {
    try {
      setIsSpinning(true);
      await dispatch(createSpotifySlugifyDirectPlaylist(friend.username));
    } catch (error) {
      setIsSpinning(false);
      console.warn(error);
    }
  };

  const handleReload = async () => {
    try {
      setIsReloading(true);
      if (primaryMusicStream === "appleMusic") {
        await dispatch(getCurrentUserAppleMusicPlaylists());
      } else {
        await dispatch(getCurrentUserSpotifyPlaylists());
      }
      setIsReloading(false);
    } catch (error) {
      setIsReloading(false);
      console.warn(error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function renderAutoSlurp() {
    return (
      <li
        className={`d-flex flex-column justify-content-between list-group-item ${
          autoSlurpFriend &&
          (!autoSlurpFriend.scheduleDays ||
            !autoSlurpFriend.scheduleDays.length ||
            !autoSlurpFriend.scheduleTime ||
            !autoSlurpFriend.scheduleTime.value ||
            !autoSlurpFriend.scheduleTimezone ||
            !autoSlurpFriend.scheduleTimezone.value)
            ? "list-group-item-secondary"
            : ""
        } ${width < 576 ? "px-3" : ""}`}
      >
        <div className="d-flex align-items-center">
          <div
            className={`d-flex align-items-center me-auto ${
              autoSlurpFriend ? "mb-2" : ""
            }`}
          >
            <span className="me-1">AutoSlurp</span>
            {!autoSlurpFriend ? (
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={autoSlurpPopover}
                rootClose
              >
                <span className="icon-info slug-icon text-info me-1" />
              </OverlayTrigger>
            ) : null}
          </div>
          <div className="mb-0">
            <div className="d-flex align-items-center justify-content-center">
              <Form.Check
                type="checkbox"
                disabled={isUpdatingAutoSlurp}
                id="autoSlurp-input"
                checked={
                  autoSlurpFriends &&
                  autoSlurpFriends
                    .filter((item) => item.username === friend.username)
                    .map((item) => item.isNotificationsEnabled).length
                }
                label={
                  <label
                  // className="d-flex align-items-center justify-content-center form-label"
                  // htmlFor="autoSlurp-input"
                  // style={{ width: 62 }}
                  >
                    {isUpdatingAutoSlurp ? (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: 24 }}
                      >
                        <Spinner size={8} color="muted" type="beat" />
                      </div>
                    ) : autoSlurpFriends.filter(
                        (item) => item.username === friend.username
                      ).length ? (
                      "Enabled"
                    ) : (
                      "Disabled"
                    )}
                  </label>
                }
                onChange={() => {
                  handleAutoSlurpToggle();
                }}
              />
              {/* <input
                    type="checkbox"
                    className="custom-control-input"
                    disabled={isUpdatingAutoSlurp}
                    id="autoSlurp-input"
                    checked={
                      autoSlurpFriends &&
                      autoSlurpFriends
                        .filter((item) => item.username === friend.username)
                        .map((item) => item.isNotificationsEnabled).length
                    }
                    onChange={() => {
                      handleAutoSlurpToggle();
                    }}
                  /> */}
              {/* <label
                    className="d-flex align-items-center justify-content-center form-label"
                    htmlFor="autoSlurp-input"
                    style={{ width: 62 }}
                  >
                    {isUpdatingAutoSlurp ? (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: 24 }}
                      >
                        <Spinner size={8} color="muted" type="beat" />
                      </div>
                    ) : autoSlurpFriends.filter(
                        (item) => item.username === friend.username
                      ).length ? (
                      "Enabled"
                    ) : (
                      "Disabled"
                    )}
                  </label> */}
            </div>
          </div>
        </div>
        {autoSlurpFriend ? (
          <div
            className={`d-flex flex-column justify-content-center ${
              width < 576 ? "" : "ms-2"
            }`}
          >
            {!isMobile ? (
              <div className={`Lead mb-2 ${width < 576 ? "ms-2" : ""}`}>
                Schedule
              </div>
            ) : null}
            <div className="d-flex align-items-center mb-2">
              <div className="d-none d-sm-flex me-auto ms-2">Days</div>
              <div className="d-flex align-items-center justify-content-center justify-content-sm-end flex-fill">
                <div className="d-none d-sm-flex me-sm-2">
                  {isUpdatingAutoSlurpSchedule ? (
                    <Spinner size={18} color="dark" type="moon" />
                  ) : (
                    <Spinner size={18} color="light" type="moon" />
                  )}
                </div>
                <AutoSlurpScheduleSelector
                  autoSlurpFriend={autoSlurpFriend}
                  autoSlurpFriends={autoSlurpFriends}
                  friend={friend}
                  isUpdatingAutoSlurpSchedule={isUpdatingAutoSlurpSchedule}
                  isLoading={width < 576 && isUpdatingAutoSlurpSchedule}
                  setIsUpdatingAutoSlurpSchedule={
                    setIsUpdatingAutoSlurpSchedule
                  }
                  width={width}
                />
              </div>
            </div>
            <div className="d-flex align-items-center mb-2">
              <div className="d-none d-sm-flex me-auto ms-2">Time</div>
              <div className="d-flex align-items-center justify-content-center justify-content-sm-end flex-fill">
                <div className="d-none d-sm-flex me-sm-2">
                  {isUpdatingAutoSlurpNotificationTime ? (
                    <Spinner size={18} color="dark" type="moon" />
                  ) : (
                    <Spinner size={18} color="light" type="moon" />
                  )}
                </div>
                <AutoSlurpTimeSelector
                  autoSlurpFriend={autoSlurpFriend}
                  autoSlurpFriends={autoSlurpFriends}
                  friend={friend}
                  isLoading={width < 576 && isUpdatingAutoSlurpNotificationTime}
                  isUpdatingAutoSlurpNotificationTime={
                    isUpdatingAutoSlurpNotificationTime
                  }
                  setIsUpdatingAutoSlurpNotificationTime={
                    setIsUpdatingAutoSlurpNotificationTime
                  }
                  width={width}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div
                className={`d-flex align-items-center me-auto ${
                  width < 576 ? "" : "ms-2"
                }`}
              >
                <span className="d-none d-sm-flex me-1">Your Time Zone</span>
              </div>
              <div className="d-flex align-items-center justify-content-center justify-content-sm-end flex-fill">
                <div className="d-none d-sm-flex me-1 me-sm-2">
                  {isUpdatingAutoSlurpNotificationTimezone ? (
                    <Spinner size={18} color="dark" type="moon" />
                  ) : (
                    <Spinner size={18} color="light" type="moon" />
                  )}
                </div>
                <div className="flex-fill" style={{ maxWidth: 500 }}>
                  {isMobile ? (
                    <TimeZoneSelectorMobile
                      autoSlurpFriend={autoSlurpFriend}
                      autoSlurpFriends={autoSlurpFriends}
                      friend={friend}
                      isDisabled={isUpdatingAutoSlurpNotificationTimezone}
                      isUpdatingAutoSlurpNotificationTimezone={
                        isUpdatingAutoSlurpNotificationTimezone
                      }
                      setIsUpdatingAutoSlurpNotificationTimezone={
                        setIsUpdatingAutoSlurpNotificationTimezone
                      }
                    />
                  ) : (
                    <TimezoneSelect
                      styles={customStyles}
                      classNamePrefix="auto-slurp-timezone-select"
                      isDisabled={isUpdatingAutoSlurpNotificationTimezone}
                      isLoading={
                        width < 576 && isUpdatingAutoSlurpNotificationTimezone
                      }
                      isClearable={true}
                      labelStyle="original"
                      defaultInputValue={
                        autoSlurpFriend.scheduleTimezone &&
                        autoSlurpFriend.scheduleTimezone.label
                          ? autoSlurpFriend.scheduleTimezone.label
                          : ""
                      }
                      value={
                        autoSlurpFriend.scheduleTimezone &&
                        autoSlurpFriend.scheduleTimezone.value
                          ? autoSlurpFriend.scheduleTimezone.value
                          : ""
                      }
                      onChange={(tz) => handleTimezoneSelect(tz)}
                      placeholder={
                        width < 576 ? "Select your timezone..." : "Select"
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </li>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function renderAutoSlurpInner() {
    return (
      <>
        <li
          className={`d-flex align-items-center justify-content-between list-group-item ${
            width < 576 ? "px-3 w-100" : ""
          }`}
        >
          <div className="d-none d-sm-flex me-3">AutoSlurp-to Playlist</div>
          <div
            className={`d-flex align-items-center justify-content-center justify-content-sm-end flex-fill ${
              width < 576 ? "w-100" : ""
            }`}
          >
            {isUpdatingAutoSlurpPlaylist ? (
              <div className="d-none d-sm-flex me-2">
                <Spinner size={18} color="dark" type="moon" />
              </div>
            ) : null}
            {!isUpdatingAutoSlurpPlaylist ? (
              <div
                className={`d-none d-sm-flex icon-loop slug-icon ${
                  isRefreshingPlaylistsIconSpinning
                    ? "rotate-counter-clockwise"
                    : ""
                } me-2`}
                onClick={() => handleRefreshPlaylists()}
                title="Refresh Playlists"
                style={{ cursor: "pointer" }}
              />
            ) : null}
            <AutoSlurpPlaylistSelector
              autoSlurpFriend={autoSlurpFriend}
              autoSlurpFriends={autoSlurpFriends}
              currentSelectedSlurpToPlaylist={currentSelectedSlurpToPlaylist}
              friend={friend}
              handleRefreshPlaylists={handleRefreshPlaylists}
              handleSelectAutoSlurpPlaylist={handleSelectAutoSlurpPlaylist}
              handleSelectAutoSlurpPlaylistMobile={
                handleSelectAutoSlurpPlaylistMobile
              }
              isLoading={
                width < 576 &&
                (isUpdatingAutoSlurpPlaylist || isRefreshingPlaylists)
              }
              isRefreshingPlaylists={isRefreshingPlaylists}
              isRefreshingPlaylistsIconSpinning={
                isRefreshingPlaylistsIconSpinning
              }
              isUpdatingAutoSlurpPlaylist={isUpdatingAutoSlurpPlaylist}
              myAppleMusicPlaylists={myAppleMusicPlaylists}
              mySpotifyPlaylists={mySpotifyPlaylists}
              primaryMusicStream={primaryMusicStream}
              setIsUpdatingAutoSlurpPlaylist={setIsUpdatingAutoSlurpPlaylist}
              width={width}
            />
            {/* <div className="form-group mb-0 ms-2">
                  <select
                    className="form-select form-select-sm"
                    disabled={
                      isUpdatingAutoSlurpPlaylist || isRefreshingPlaylists
                    }
                    defaultValue={destinationPlaylistDefaultValue}
                    id="selected"
                    onChange={(e) =>
                      handleSelectAutoSlurpPlaylist(e.target.value)
                    }
                    style={{ minWidth: 140 }}
                    value={destinationPlaylistDefaultValue}
                  >
                    {destinationPlaylistDefaultValue ? null : (
                      <option value="">Select a Playlist</option>
                    )}
                    {renderPlaylistOptions()}
                  </select>
                </div> */}
          </div>
        </li>
        <li className="d-flex flex-column justify-content-center list-group-item">
          <div className="d-flex align-items-center">
            <div className="me-auto">AutoSlurp Notifications</div>
            <div className="form-group mb-0">
              <div className="d-flex align-items-center justify-content-center">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  disabled={isUpdatingAutoSlurpNotifications}
                  id="autoSlurpNotifications-input"
                  checked={
                    autoSlurpFriend && autoSlurpFriend.isNotificationsEnabled
                  }
                  onChange={(e) => {
                    handleAutoSlurpNotificationsToggle(e.target.value);
                  }}
                />
                <label
                  className="d-flex align-items-center justify-content-center custom-control-label"
                  htmlFor="autoSlurpNotifications-input"
                  style={{ width: 62 }}
                >
                  {isUpdatingAutoSlurpNotifications ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ height: 24 }}
                    >
                      <Spinner size={8} color="muted" type="beat" />
                    </div>
                  ) : autoSlurpFriend &&
                    autoSlurpFriend.isNotificationsEnabled ? (
                    "Enabled"
                  ) : (
                    "Disabled"
                  )}
                </label>
              </div>
            </div>
          </div>
        </li>
        {/* {autoSlurpFriend.isNotificationsEnabled ? (
              <li className="d-flex flex-column justify-content-center list-group-item">
                <div className="Lead mb-2">AutoSlurp Notification Schedule</div>
                <div className="d-flex align-items-center mb-2">
                  <div className="me-auto ms-3">Days</div>
                  <div className="d-flex align-items-center justify-content-end flex-fill">
                    <div className="d-none d-sm-flex me-sm-2">
                      {isUpdatingAutoSlurpNotificationTime ? (
                        <Spinner size={18} color="dark" type="moon" />
                      ) : (
                        <Spinner size={18} color="light" type="moon" />
                      )}
                    </div>
                    <AutoSlurpScheduleSelector
                      autoSlurpFriend={autoSlurpFriend}
                      autoSlurpFriends={autoSlurpFriends}
                      friend={friend}
                      setIsUpdatingAutoSlurpSchedule={
                        setIsUpdatingAutoSlurpSchedule
                      }
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <div className="me-auto ms-3">Time</div>
                  <div className="d-flex align-items-center justify-content-end flex-fill">
                    <div className="d-none d-sm-flex me-sm-2">
                      {isUpdatingAutoSlurpSchedule ? (
                        <Spinner size={18} color="dark" type="moon" />
                      ) : (
                        <Spinner size={18} color="light" type="moon" />
                      )}
                    </div>
                    <AutoSlurpTimeSelector
                      autoSlurpFriend={autoSlurpFriend}
                      autoSlurpFriends={autoSlurpFriends}
                      friend={friend}
                      isUpdatingAutoSlurpNotificationTime={isUpdatingAutoSlurpNotificationTime}
                      setIsUpdatingAutoSlurpNotificationTime={setIsUpdatingAutoSlurpNotificationTime}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-auto ms-3">Time Zone</div>
                  <div className="d-flex align-items-center justify-content-end flex-fill">
                    <div className="d-none d-sm-flex me-1 me-sm-2">
                      {isUpdatingAutoSlurpNotificationTimezone ? (
                        <Spinner size={18} color="dark" type="moon" />
                      ) : (
                        <Spinner size={18} color="light" type="moon" />
                      )}
                    </div>
                    <div
                      className="ms-3 ms-sm-0 flex-fill"
                      style={{ maxWidth: 400 }}
                    >
                      <TimezoneSelect
                        styles={customStyles}
                        classNamePrefix="timezone-select"
                        isDisabled={isUpdatingAutoSlurpNotificationTimezone}
                        isClearable={true}
                        labelStyle="original"
                        defaultInputValue={
                          autoSlurpFriend.scheduleTimezone &&
                          autoSlurpFriend.scheduleTimezone.label
                            ? autoSlurpFriend.scheduleTimezone.label
                            : ""
                        }
                        value={
                          autoSlurpFriend.scheduleTimezone &&
                          autoSlurpFriend.scheduleTimezone.value
                            ? autoSlurpFriend.scheduleTimezone.value
                            : ""
                        }
                        onChange={(tz) => handleTimezoneSelect(tz)}
                      />
                    </div>
                  </div>
                </div>
              </li>
            ) : null} */}
        {/* {autoSlurpFriend.isNotificationsEnabled ? (
              <li className="d-flex align-items-center list-group-item">
                <div className="me-auto">Time Zone</div>
                <div className="d-flex align-items-center justify-content-end flex-fill">
                  <div className="d-none d-sm-flex me-1 me-sm-2">
                    {isUpdatingAutoSlurpNotificationTimezone ? (
                      <Spinner size={18} color="dark" type="moon" />
                    ) : (
                      <Spinner size={18} color="light" type="moon" />
                    )}
                  </div>
                  <div
                    className="ms-3 ms-sm-0 flex-fill"
                    style={{ maxWidth: 400 }}
                  >
                    <TimezoneSelect
                      styles={customStyles}
                      classNamePrefix="timezone-select"
                      isDisabled={isUpdatingAutoSlurpNotificationTimezone}
                      isClearable={true}
                      labelStyle="original"
                      defaultInputValue={
                        autoSlurpFriend.scheduleTimezone &&
                        autoSlurpFriend.scheduleTimezone.label
                          ? autoSlurpFriend.scheduleTimezone.label
                          : ""
                      }
                      value={
                        autoSlurpFriend.scheduleTimezone &&
                        autoSlurpFriend.scheduleTimezone.value
                          ? autoSlurpFriend.scheduleTimezone.value
                          : ""
                      }
                      onChange={(tz) => handleTimezoneSelect(tz)}
                    />
                  </div>
                </div>
              </li>
            ) : null} */}
      </>
    );
  }

  const autoSlurpPopover = (
    <Popover id="slugify-direct-popover">
      <Popover.Header>AutoSlurp</Popover.Header>
      <Popover.Body>
        <p className="mb-0">
          Enable AutoSlurp to automagically slurp {friend.username}&apos;s
          Slugify playlist and/or sync tracks from any of their shared playlists
          on a schedule you define.
        </p>
      </Popover.Body>
    </Popover>
  );
  const slugifyDirectPopover = (
    <Popover id="slugify-direct-popover">
      <Popover.Header>Slugify Direct Playlist</Popover.Header>
      <Popover.Body>
        <p className="mb-0">
          Tracks you place in your{" "}
          <strong>{getTitleCase(friend.username)} (Slugify Direct)</strong>{" "}
          playlist will be shared exclusively with ${friend.username}.
        </p>
      </Popover.Body>
    </Popover>
  );
  const notPublicPopoverAppleMusic = (
    <Popover id="playlist-not-public-apple-musicpopover">
      <Popover.Header>Playlist is not public</Popover.Header>
      <Popover.Body>
        <p>
          Make this playlist is public on Apple Music so that {friend.username}{" "}
          can get your direct shares.
        </p>
        <p>
          See{" "}
          <a
            href="https://support.apple.com/en-us/HT207542#share"
            target="blank"
            rel="noopener noreferrer"
          >
            here{" "}
          </a>
          for instructions.
        </p>
      </Popover.Body>
    </Popover>
  );
  const notPublicPopoverSpotify = (
    <Popover id="playlist-not-public-spotify-popover">
      <Popover.Header>Playlist is not public</Popover.Header>
      <Popover.Body>
        <p>
          Make this playlist is public on Spotify so that {friend.username} can
          get your direct shares.
        </p>
        <p>
          See{" "}
          <a
            href="https://support.spotify.com/th-en/article/set-playlist-privacy/"
            target="blank"
            rel="noopener noreferrer"
          >
            here{" "}
          </a>
          for instructions.
        </p>
      </Popover.Body>
    </Popover>
  );
  const renderDirectSharePlaylist = () => {
    try {
      if (primaryMusicStream === "appleMusic") {
        const directSharePlaylist = myAppleMusicPlaylists.filter(
          (item) =>
            item.name === `${getTitleCase(friend.username)} (Slugify Direct)`
        )[0];
        if (directSharePlaylist) {
          return (
            <div className="d-flex align-items-center">
              {directSharePlaylist.id ? null : (
                <div className="d-flex align-items-center me-2">
                  <span
                    className={`icon-loop slug-icon text-body-secondary me-2 ${
                      isReloading ? "rotate-clockwise" : ""
                    }`}
                    onClick={() => {
                      handleReload();
                    }}
                    title="Refresh playlists"
                    style={{ cursor: "pointer" }}
                  />
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    overlay={notPublicPopoverAppleMusic}
                    rootClose
                  >
                    <span className="icon-info slug-icon text-danger me-2" />
                  </OverlayTrigger>
                </div>
              )}
              <span
                className={`${
                  directSharePlaylist.id ? "" : "text-danger"
                } animate__animated animate__fadeIn`}
              >
                <a
                  className={`btn btn-sm btn-outline-${
                    directSharePlaylist.id ? "success" : "danger"
                  }`}
                  href={
                    directSharePlaylist.id
                      ? `https://music.apple.com/us/playlist/${friend.username.toLowerCase()}-slugify-direct/${
                          directSharePlaylist.id
                        }`
                      : "https://music.apple.com"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Open playlist on Apple Music"
                >
                  {directSharePlaylist.id ? "Active" : "Inactive"}
                </a>
              </span>
            </div>
          );
        } else {
          return (
            <button
              className="d-flex align-items-center justify-content-center btn btn-sm btn-outline-primary"
              disabled={isSpinning}
              onClick={() => handleCreateAppleMusicSlugifyDirectPlaylist()}
              style={{ height: 32, width: 64 }}
            >
              {isSpinning ? (
                <div>
                  <Spinner size={10} color="primary" type="beat" />
                </div>
              ) : (
                <span>Create</span>
              )}
            </button>
          );
        }
      } else if (primaryMusicStream === "spotify") {
        const directSharePlaylist = mySpotifyPlaylists.filter(
          (item) =>
            item.name === `${getTitleCase(friend.username)} (Slugify Direct)`
        )[0];
        if (directSharePlaylist) {
          return (
            <div className="d-flex align-items-center">
              {directSharePlaylist.public ? null : (
                <div className="d-flex align-items-center me-2">
                  <span
                    className={`icon-loop slug-icon text-body-secondary me-2 ${
                      isReloading ? "rotate-clockwise" : ""
                    }`}
                    onClick={() => {
                      handleReload();
                    }}
                    title="Refresh playlists"
                    style={{ cursor: "pointer" }}
                  />
                  <OverlayTrigger
                    trigger="click"
                    position="left"
                    overlay={notPublicPopoverSpotify}
                    rootClose
                  >
                    <span className="icon-info slug-icon text-danger me-2" />
                  </OverlayTrigger>
                </div>
              )}
              <span
                className={`${
                  directSharePlaylist.public ? "" : "text-danger"
                } animate__animated animate__fadeIn`}
              >
                <a
                  className={`btn btn-sm btn-outline-${
                    directSharePlaylist.public ? "success" : "danger"
                  }`}
                  href={`https://open.spotify.com/playlist/${directSharePlaylist.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Open playlist on Apple Music"
                >
                  {directSharePlaylist.public ? "Active" : "Inactive"}
                </a>
              </span>
            </div>
          );
        } else {
          return (
            <button
              className={`d-flex align-items-center justify-content-center btn btn-sm btn-${
                isDark ? "outline-primary" : "primary"
              }`}
              disabled={isSpinning}
              onClick={() => handleCreateSpotifySlugifyDirectPlaylist()}
              style={{ height: 32, width: 64 }}
            >
              {isSpinning ? (
                <div>
                  <Spinner size={10} color="primary" type="beat" />
                </div>
              ) : (
                <span>Create</span>
              )}
            </button>
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  // const renderPlaylistOptions = () => {
  //   if (primaryMusicStream === "appleMusic" && myAppleMusicPlaylists.length) {
  //     return myAppleMusicPlaylists
  //       .filter(
  //         (playlist) => playlist.name !== "Slugify"
  //         // !playlist.name.includes("Slugify Direct")
  //       )
  //       .map((playlist) => {
  //         return (
  //           <option
  //             key={playlist.id}
  //             value={playlist.name + "__" + playlist.id}
  //           >
  //             {playlist.name}
  //           </option>
  //         );
  //       });
  //   } else if (primaryMusicStream === "spotify" && mySpotifyPlaylists.length) {
  //     return sortBy(mySpotifyPlaylists, "name")
  //       .filter(
  //         (playlist) =>
  //           playlist.name !== "Slugify" &&
  //           !playlist.name.includes("(Slugify Direct") &&
  //           !playlist.description.includes("via Slugify!")
  //       )
  //       .map((playlist) => (
  //         <option key={playlist.id} value={playlist.name + "__" + playlist.id}>
  //           {playlist.name}
  //         </option>
  //       ));
  //   }
  // };

  return (
    <div
      className="container d-flex flex-column align-items-center w-100 mt-3 mt-sm-5 animate__animated animate__fadeIn"
      style={{ maxWidth: 800 }}
    >
      <div className="slug-title text-body-secondary">{friend.username}</div>
      <ul className="list-group w-100 mt-3 mt-sm-5">
        <li
          className={`d-flex align-items-center justify-content-between list-group-item ${
            width < 576 ? "px-3" : ""
          }`}
        >
          <div className="d-flex align-items-center">
            <span className="me-2">Slugify Direct</span>
            <OverlayTrigger
              trigger="click"
              placement="right"
              overlay={slugifyDirectPopover}
              rootClose
            >
              <span className="icon-info slug-icon text-info me-1" />
            </OverlayTrigger>
          </div>
          <div>{renderDirectSharePlaylist()}</div>
        </li>
        {/* AutoSlurp will only work for Spotify users, because you can't get an Apple Music
        user token from within the AutoSlurp Lambda function, and this limits the ability
        to write to an Apple Music destination playlist.
         */}
        {/* {primaryMusicStream === "spotify" && renderAutoSlurp()} */}
        {/* {primaryMusicStream === "spotify" &&
          process.env.USER_BRANCH !== "prod" &&
          autoSlurpFriend &&
          autoSlurpFriend.scheduleDays &&
          autoSlurpFriend.scheduleDays.length &&
          autoSlurpFriend.scheduleTime &&
          autoSlurpFriend.scheduleTime.value &&
          autoSlurpFriend.scheduleTimezone &&
          autoSlurpFriend.scheduleTimezone.value &&
          renderAutoSlurpInner()} */}
      </ul>
      <div className="slug-subtitle text-body-secondary mt-3 mt-sm-5">
        Shared Playlists
      </div>
      <MyFriendsSharedPlaylists friendUsername={friend.username} />
      <button
        className="btn btn-link text-body-secondary mt-5 text-decoration-none"
        onClick={() => history.goBack()}
      >
        back
      </button>
    </div>
  );
};
