import { useContext, useEffect, useMemo, useState } from "react";
import { ErrorAlert } from "./shared/ErrorAlert";
import { Form } from "react-bootstrap";
import { PrivatePlaylistMessage } from "./shared/PrivatePlaylistMessage";
import { Spinner } from "./shared/Spinner";
import {
  updateUserSettings,
  handlePushNotification,
  getSlugifyPublicPlaylist
} from "../actions";
import { useAppDispatch, useAppSelector } from "../hooks/";
import { UserCustomization } from "./UserCustomization";
import { UserSettingsDangerZone } from "./UserSettingsDangerZone";
import { UserSettingsFriends } from "./UserSettingsFriends";
import * as hooks from "../hooks/";
import AddFriendModal from "./AddFriendModal";
import Connect from "./Connect";
import InviteFriendModal from "./InviteFriendModal";
import packageInfo from "../../package.json";
import Script from "react-load-script";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import UserSettingsYouTubeChannelInfo from "./UserSettingsYouTubeChannelInfo";
// import { version } from "../../package.json";

export default function UserSettings() {
  // hooks.useFriendSubscription();
  hooks.useSlurpedTrackSubscription();
  // hooks.useUserSubscription();

  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);

  const {
    appleMusic: {
      isConnected: isAppleMusicConnected,
      myPlaylists: myAppleMusicPlaylists
    },
    auth: { userId, username },
    newUser: {
      homeMenu: homeMenuState,
      friends,
      primaryMusicStream: primaryMusicStreamState
    },
    spotify: {
      isConnected: isSpotifyConnected,
      myPlaylists: mySpotifyPlaylists
    },
    slugify: { isLoading },
    youtube: {
      channel: youTubeChannel,
      myPlaylists: myYouTubePlaylists,
      user: { id: youTubeUserId }
    }
  } = useAppSelector((state) => state);

  const [, setOneSignalUserId] = useState("");
  const [, setScriptError] = useState("");
  const [homeMenuForm, setHomeMenuForm] = useState("");
  const [formIsDirty, setIsFormDirty] = useState(false);
  const [isDoingYouTubeAuth, setIsDoingYouTubeAuth] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isPushNotificationsEnabled, setIsPushNotificationsEnabled] =
    useState(false);
  const [isPushSupported, setIsPushSupported] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState("unchanged");
  const [notificationPermission, setNotificationPermission] = useState("");
  const [slugifyPlaylistIsPublic, setSlugifyPlaylistIsPublic] = useState(true);
  const [primaryMusicStreamForm, setPrimaryMusicStreamForm] = useState("");
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [showDangerZone, setShowDangerZone] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [spinningTestPush, setSpinningTestPush] = useState(false);
  const [isAddFriendModalOpen, setIsAddFriendModalOpen] = useState(false);
  const [isInviteFriendModalOpen, setIsInviteFriendModalOpen] = useState(false);

  const { hasYouTubeChannel, isYouTubeConnected } = useMemo(() => {
    let hasYouTubeChannel = false;
    let isYouTubeConnected = false;
    if (primaryMusicStreamState === "youtube" && youTubeUserId) {
      isYouTubeConnected = true;
      if (youTubeChannel) {
        hasYouTubeChannel = true;
      } else {
        hasYouTubeChannel = false;
      }
    } else {
      isYouTubeConnected = false;
      hasYouTubeChannel = false;
    }
    return { hasYouTubeChannel, isYouTubeConnected };
  }, [youTubeUserId, youTubeChannel]);

  useEffect(() => {
    dispatch(getSlugifyPublicPlaylist()).then((isPublic) =>
      setSlugifyPlaylistIsPublic(
        primaryMusicStreamState === "appleMusic" ? !!isPublic : isPublic
      )
    );
  }, [
    dispatch,
    myAppleMusicPlaylists,
    mySpotifyPlaylists,
    myYouTubePlaylists,
    primaryMusicStreamState
  ]);

  useEffect(() => {
    setPrimaryMusicStreamForm(primaryMusicStreamState);
    setHomeMenuForm(homeMenuState);
  }, [dispatch, homeMenuState, primaryMusicStreamState]);

  useEffect(() => {
    if (
      homeMenuForm === homeMenuState &&
      primaryMusicStreamForm === primaryMusicStreamState
    ) {
      setIsFormDirty(false);
    } else {
      setIsFormDirty(true);
    }
  }, [
    homeMenuForm,
    homeMenuState,
    primaryMusicStreamForm,
    primaryMusicStreamState
  ]);

  // useEffect(() => {
  //   if (showDangerZone) {
  //     // scroll.scrollToBottom();
  //     scroller.scrollTo("dangerZone", {
  //       duration: 1500,
  //       delay: 100,
  //       smooth: true,
  //       isDynamic: true,
  //       containerId: "danger-zone"
  //     });
  //   } else {
  //     // scroll.scrollToTop();
  //   }
  // }, [showDangerZone]);

  const doOneSignalButtonFunction = async () => {
    console.warn("Notification Permission: ", notificationPermission);
    try {
      if (notificationPermission && notificationPermission === "default") {
        window.OneSignal.showNativePrompt();
      } else if (notificationPermission === "granted") {
        await window.OneSignal.setSubscription(!isSubscribed);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleUpdateUserSettings(status = isSubscribed) {
    const settings = {
      homeMenu: homeMenuForm,
      isSubscribedOneSignal: status,
      primaryMusicStream: primaryMusicStreamForm
    };
    console.log("Fucking settings", settings);
    setSpinning(true);
    await dispatch(updateUserSettings(settings));
    setSpinning(false);
  }

  const handleSendSelfNotification = async () => {
    try {
      setSpinningTestPush(true);
      await dispatch(handlePushNotification("Push, push, in the bush!", true));
      setSpinningTestPush(false);
    } catch (error) {
      console.warn(error);
    }
  };

  const renderFriendOrPrivatePlaylistMessage = () => {
    console.log(
      "%cNeed to handle if apple or spotify user is Slugtuber",
      "color:orange"
    );
    if (
      primaryMusicStreamState &&
      (isSpotifyConnected || isAppleMusicConnected || !!youTubeUserId)
    ) {
      if (slugifyPlaylistIsPublic) {
        return (
          <UserSettingsFriends
            setIsAddFriendModalOpen={setIsAddFriendModalOpen}
            setIsInviteFriendModalOpen={setIsInviteFriendModalOpen}
          />
        );
      } else if (!slugifyPlaylistIsPublic && !isLoading) {
        return <PrivatePlaylistMessage />;
      }
    } else {
      return null;
    }
  };

  const handleScriptCreate = () => {
    setScriptLoaded("loading");
  };
  const handleScriptError = () => {
    setScriptError(true);
  };
  const handleScriptLoad = () => {
    setScriptLoaded(true);
  };

  useEffect(() => {
    if (scriptLoaded === true) {
      if (!window.OneSignal.initialized) {
        window.OneSignal.init({
          appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
          safari_web_id: process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID
        });
        setIsInitialized(true);
      } else {
        setIsInitialized(true);
        console.log("OneSignal Already initialized");
      }
    }
  }, [scriptLoaded]);

  useEffect(() => {
    const doUpdateUserSettings = (status = isSubscribed) => {
      const settings = {
        primaryMusicStreamForm,
        homeMenuForm,
        isSubscribedOneSignal: status
      };
      setSpinning(true);
      dispatch(updateUserSettings(settings, setSpinning));
    };
    const setNotificationStatus = async () => {
      try {
        const isEnabled = await window.OneSignal.isPushNotificationsEnabled();
        setIsPushNotificationsEnabled(isEnabled);
      } catch (error) {
        console.log(error);
      }
    };
    const setNotificationPermissionStatus = async () => {
      try {
        const status = await window.OneSignal.getNotificationPermission();
        setNotificationPermission(status);
      } catch (error) {
        console.log(error);
      }
    };
    const setOneSignalSubscriptionStatus = async () => {
      try {
        const status = await window.OneSignal.getSubscription();
        setIsSubscribed(status);
      } catch (error) {
        console.log(error);
      }
    };
    const getOneSignalUserId = async () => {
      try {
        const userId = await window.OneSignal.getUserId();
        setOneSignalUserId(userId);
      } catch (error) {
        console.log(error);
      }
    };
    if (isInitialized === true) {
      window.OneSignal.on("subscriptionChange", async (isSubscribedStatus) => {
        setIsSubscribed(isSubscribedStatus);
        window.OneSignal.sendTags({
          userId: userId,
          slugbucketUsername: username
        });
        setNotificationStatus();
        // TODO:  figure out why UPDATE_USER_SETTINGS_SUCCESS fires twice!
        if (isSubscribed !== isSubscribedStatus) {
          doUpdateUserSettings(isSubscribedStatus);
        }
      });

      window.OneSignal.on(
        "notificationPermissionChange",
        (permissionChange) => {
          const currentPermission = permissionChange.to;
          setNotificationPermission(currentPermission);
          setNotificationStatus();
        }
      );

      setIsPushSupported(window.OneSignal.isPushNotificationsSupported());

      setNotificationPermissionStatus();

      setNotificationStatus();

      getOneSignalUserId();

      setOneSignalSubscriptionStatus();
    }
  }, [
    dispatch,
    homeMenuForm,
    isInitialized,
    isSubscribed,
    primaryMusicStreamForm,
    userId,
    username
  ]);

  return (
    <>
      <Script
        url="https://cdn.onesignal.com/sdks/OneSignalSDK.js"
        onCreate={() => handleScriptCreate()}
        onError={() => handleScriptError()}
        onLoad={() => handleScriptLoad()}
      />
      <AddFriendModal
        isAddFriendModalOpen={isAddFriendModalOpen}
        setIsAddFriendModalOpen={setIsAddFriendModalOpen}
      />
      <InviteFriendModal
        isInviteFriendModalOpen={isInviteFriendModalOpen}
        setIsInviteFriendModalOpen={setIsInviteFriendModalOpen}
      />
      <div className="d-flex container flex-column align-items-center my-3 my-sm-5 slug-width">
        {alert.message ? <ErrorAlert /> : null}
        <div className="text-center mb-3">
          <div className="slug-subtitle mb-3 text-body-secondary animate__animated animate__fadeIn">
            Settings
          </div>
        </div>
        <ul className="list-group w-100 animate__animated animate__fadeIn">
          <li className="list-group-item">
            <div className="d-flex align-items-center justify-content-between">
              <span>Home Menu</span>
              <div className="d-flex flex-column flex-sm-row">
                {process.env.NODE_ENV !== "production" && (
                  <Form.Check
                    checked={homeMenuForm === "slugbucket"}
                    id="slugbucket-radio"
                    onChange={() => setHomeMenuForm("slugbucket")}
                    inline
                    label="Slug Bucket (fix me)"
                    name="slugbucketRadio"
                    type="radio"
                  />
                )}
                <Form.Check
                  checked={homeMenuForm === "slugify"}
                  id="slugify-radio"
                  inline
                  label="Slugify"
                  name="slugifyRadio"
                  onChange={() => setHomeMenuForm("slugify")}
                  type="radio"
                />
              </div>
            </div>
          </li>
          <li className="list-group-item">
            <div className="d-flex align-items-center justify-content-between">
              <span>Music Stream</span>
              <div className="d-flex flex-column flex-sm-row">
                <Form.Check
                  checked={primaryMusicStreamForm === "appleMusic"}
                  disabled={isSpotifyConnected || isYouTubeConnected}
                  onChange={() => setPrimaryMusicStreamForm("appleMusic")}
                  type="radio"
                  id="apple-music-radio"
                  inline
                  label="Apple Music"
                  name="appleMusicRadio"
                  title={
                    isSpotifyConnected
                      ? "Disconnect from Apple Music first."
                      : isYouTubeConnected
                      ? "Disconnect from YouTube first."
                      : ""
                  }
                />
                <Form.Check
                  checked={primaryMusicStreamForm === "spotify"}
                  disabled={isAppleMusicConnected || isYouTubeConnected}
                  id="spotify-radio"
                  inline
                  label="Spotify"
                  name="spotifyRadio"
                  onChange={() => setPrimaryMusicStreamForm("spotify")}
                  type="radio"
                  title={
                    isAppleMusicConnected
                      ? "Disconnect from Apple Music first."
                      : isYouTubeConnected
                      ? "Disconnect from YouTube first."
                      : ""
                  }
                />
                <Form.Check
                  inline
                  id="youtube-radio"
                  disabled={isAppleMusicConnected || isSpotifyConnected}
                  label={
                    <div>
                      <span>YouTube</span>
                      <span className="text-danger">
                        <sup style={{ fontSize: 10 }}> BETA</sup>
                      </span>
                    </div>
                  }
                  name="youtubeRadio"
                  onChange={() => setPrimaryMusicStreamForm("youtube")}
                  title={
                    isAppleMusicConnected
                      ? "Disconnect from Apple Music first."
                      : isSpotifyConnected
                      ? "Disconnect from Spotify first."
                      : ""
                  }
                  type="radio"
                  checked={primaryMusicStreamForm === "youtube"}
                />
              </div>
            </div>
          </li>
        </ul>
        <button
          className={`d-flex align-items-center justify-content-center btn btn-${
            isDark ? "outline-primary" : "primary"
          } my-3 w-100`}
          disabled={!primaryMusicStreamForm || !formIsDirty || spinning}
          onClick={() => handleUpdateUserSettings()}
        >
          {spinning ? (
            <div className="mx-1">
              <Spinner
                size={24}
                color={isDark ? "primary" : "light"}
                type="puff"
              />
            </div>
          ) : (
            <i className="icon-save slug-icon me-2" />
          )}
          Save Settings
        </button>

        {primaryMusicStreamState && (
          <Connect
            hasYouTubeChannel={hasYouTubeChannel}
            formIsDirty={formIsDirty}
            isDoingYouTubeAuth={isDoingYouTubeAuth}
            setIsDoingYouTubeAuth={setIsDoingYouTubeAuth}
          />
        )}

        {/* {authStatusMessage && (
          <StatusMessage title={authStatusMessage} emoticon="📺" size="xs" />
        )} */}

        {primaryMusicStreamState === "youtube" &&
          isYouTubeConnected &&
          !hasYouTubeChannel &&
          !isDoingYouTubeAuth && <UserSettingsYouTubeChannelInfo />}

        {primaryMusicStreamState === "youtube" &&
          !isDoingYouTubeAuth &&
          hasYouTubeChannel &&
          renderFriendOrPrivatePlaylistMessage()}

        {(primaryMusicStreamState === "appleMusic" ||
          primaryMusicStreamState === "spotify") &&
          // !isDoingYouTubeAuth &&
          // hasYouTubeChannel &&
          renderFriendOrPrivatePlaylistMessage()}

        {friends.length &&
        primaryMusicStreamState &&
        (isAppleMusicConnected || isSpotifyConnected) &&
        slugifyPlaylistIsPublic ? (
          isPushSupported ? (
            <div className="border border-user-settings rounded d-flex flex-column align-items-center animate__animated animate__fadeIn delay-1s p-2 my-3">
              <div className="slug-subtitle mb-3 text-body-secondary text-center">
                Notifications
                <p className="lead mt-3">
                  Enable notifications to inform and be informed when things
                  happen on Slugify, like when new tracks are shared or slurped.
                </p>
              </div>
              <button
                className={`d-flex align-items-center btn ${
                  isPushNotificationsEnabled && isSubscribed
                    ? `${isDark ? "btn-outline-danger" : "btn-danger"}`
                    : `${isDark ? "btn-outline-secondary" : "btn-secondary"}`
                } mb-2`}
                onClick={() => {
                  doOneSignalButtonFunction();
                }}
              >
                <i
                  className={`me-2 ${
                    isPushNotificationsEnabled && isSubscribed
                      ? "icon-alarm_off slug-icon"
                      : "icon-add_alarm slug-icon"
                  }`}
                />
                <span>
                  {isPushNotificationsEnabled && isSubscribed
                    ? "Unsubscribe from notifications"
                    : "Subscribe to notifications"}
                </span>
              </button>
              {isPushNotificationsEnabled &&
              (isSubscribed || isSubscribed === "unchanged") ? (
                <button
                  className="btn btn-link mb-3 text-body-secondary text-decoration-none"
                  onClick={() => {
                    handleSendSelfNotification();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {spinningTestPush ? (
                    <div
                      className="spinner-border spinner-border-sm text-body-secondary"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Send Test Notification"
                  )}
                </button>
              ) : null}
            </div>
          ) : null
        ) : null}
        {primaryMusicStreamState &&
          (isAppleMusicConnected || isSpotifyConnected || isYouTubeConnected) &&
          slugifyPlaylistIsPublic && <UserCustomization />}

        {showDangerZone && (
          <UserSettingsDangerZone
            setShowDangerZone={setShowDangerZone}
            isDeleting={isDeleting}
            setIsDeleting={setIsDeleting}
          />
        )}
        {!showDangerZone && (
          <div className="d-flex w-100 px-3">
            <button
              className={`btn btn-sm btn-${
                isDark ? "outline-danger" : "danger"
              } mt-3 w-100`}
              onClick={() => setShowDangerZone(true)}
            >
              Delete Slugify Account
            </button>
          </div>
        )}
        <small className="text-center text-body-secondary mt-5">
          Slugify v{packageInfo.version}
        </small>
      </div>
    </>
  );
}
