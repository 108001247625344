// eslint-disable jsx-a11y/anchor-is-valid
import { createSlugifySlurpedTracks } from "../actions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useState } from "react";
import dayjs from "dayjs";
import type { SlugifySlurpedTrack, AppleMusicUnmatchedTrack } from "../types";

interface Props {
  item: AppleMusicUnmatchedTrack;
}
export default function SlugifySpotifyUnmatchedItem({ item }: Props) {
  const dispatch = useAppDispatch();
  const {
    auth: { username },
    newUser: { friends }
  } = useAppSelector((state) => state);
  const [isForgetting, setIsForgetting] = useState(false);
  const getToolTipText = () => {
    if (item.sharedByFriend) {
      return `Shared by ${item.sharedByFriend[0]}`;
    }
  };
  
  return (
    <li
      className={`d-flex justify-content-center list-group-item animate__animated animate__fadeIn bg-body-tertiary rounded mt-2`}
      key={item.appleMusicInfo.appleMusicId}
      data-bs-toggle={
        friends.length > 1 && item.sharedByFriend && item.sharedByFriend.length
          ? "tooltip"
          : null
      }
      data-placement="top"
      title={
        friends.length > 1 && item.sharedByFriend && item.sharedByFriend.length
          ? getToolTipText()
          : ""
      }
    >
      <div className="d-flex flex-column w-100">
        <div className="d-flex align-items-center w-100">
          <img
            className="d-block d-sm-none"
            src={item.appleMusicInfo.albumArt?.replace(/{w}x{h}/, "48x48")}
            alt={`${item.appleMusicInfo.album} album art`}
            height="48px"
            width="48px"
          />
          <img
            className="d-none d-sm-block"
            src={item.appleMusicInfo.albumArt.replace(/{w}x{h}/, "64x64")}
            alt={`${item.appleMusicInfo.album} album art`}
            height="64px"
            width="64px"
          />
          <div className="d-flex flex-column ms-3 flex-grow-1">
            <small className="d-block d-sm-none font-weight-bold me-auto">
              {item.appleMusicInfo.title}
            </small>
            <div className="d-none d-sm-block font-weight-bold me-auto">
              {item.appleMusicInfo.title}
            </div>
            <small className="font-weight-normal">
              {item.appleMusicInfo.artist}
            </small>
          </div>
          <div className="d-flex d-sm-none">
            <a
              data-bs-toggle="collapse"
              data-bs-target={`#track-${item.appleMusicInfo.appleMusicId}`}
              aria-expanded="false"
              aria-controls={`track-${item.appleMusicInfo.appleMusicId}`}
            >
              <i className="icon-more_vert slug-icon" />
            </a>
          </div>
          <div className="d-none d-sm-flex">
            <span
              className={`me-2 ${
                isForgetting
                  ? "animate__animated animate__headShake animate__infinite"
                  : ""
              }`}
              role="img"
              aria-label="trash can"
              onClick={
                !isForgetting
                  ? () => {
                      try {
                        setIsForgetting(true);
                        const forgottenTrack: Partial<SlugifySlurpedTrack> = {};
                        forgottenTrack.originalTrackAddedBy =
                          item.added_by && item.added_by.id
                            ? item.added_by.id
                            : undefined;
                        forgottenTrack.owner = username;
                        forgottenTrack.appleMusicId =
                          item.appleMusicInfo.appleMusicId;
                        forgottenTrack.trackType = item.trackType;
                        forgottenTrack.toBeDeletedOn = dayjs()
                          .add(1, "month")
                          .toISOString();
                        forgottenTrack.username = username;
                        dispatch(createSlugifySlurpedTracks([forgottenTrack]));
                      } catch (error) {
                        setIsForgetting(false);
                        console.warn(error);
                      }
                    }
                  : () => {}
              }
              style={{
                fontSize: "28px",
                cursor: isForgetting ? "default" : "pointer"
              }}
              title="Forget this track"
            >
              🗑️
            </span>
            <a
              className="text-decoration-none ms-2"
              href={`http://open.spotify.com/search/${item.appleMusicInfo.title}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                role="img"
                aria-label="search"
                style={{ fontSize: "28px", cursor: "pointer" }}
                title="Search on Spotify"
              >
                🔍
              </span>
            </a>
          </div>
        </div>
        <div
          className="collapse w-100"
          id={`track-${item.appleMusicInfo.appleMusicId}`}
        >
          <div className="d-flex align-items-center justify-content-end w-100">
            <span
              className={`me-2 ${
                isForgetting
                  ? "animate__animated animate__headShake animate__infinite"
                  : ""
              }`}
              role="img"
              aria-label="trash can"
              onClick={() => {
                try {
                  setIsForgetting(true);
                  const forgottenTrack: Partial<SlugifySlurpedTrack> = {};
                  forgottenTrack.originalTrackAddedBy =
                    item.added_by && item.added_by.id
                      ? item.added_by.id
                      : undefined;
                  forgottenTrack.owner = username;
                  forgottenTrack.appleMusicId =
                    item.appleMusicInfo.appleMusicId;
                  forgottenTrack.trackType = item.trackType;
                  forgottenTrack.toBeDeletedOn = dayjs()
                    .add(1, "month")
                    .toISOString();
                  forgottenTrack.username = username;
                  dispatch(createSlugifySlurpedTracks([forgottenTrack]));
                } catch (error) {
                  setIsForgetting(false);
                  console.warn(error);
                }
              }}
              style={{ fontSize: "24px", cursor: "pointer" }}
              title="Forget this track"
            >
              🗑️
            </span>
            <a
              href={`http://open.spotify.com/search/${item.appleMusicInfo.title}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                role="img"
                aria-label="search"
                style={{ fontSize: "24px", cursor: "pointer" }}
                title="Search on Spotify"
              >
                🔍
              </span>
            </a>
          </div>
        </div>
      </div>
    </li>
  );
}
