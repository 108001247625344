import SignInForm from "./SignInForm";
import bgimage from "../images/slug_splash.png";
import trippinBallz from "../images/you_be_trippin_ballz2.jpg";
import { isAndroid, isIOS, withOrientationChange } from "react-device-detect";

let SignInPageMobile = ({
  formAction,
  setFormAction,
  // isScreenShort,
  isCodeSent,
  isMobile,
  setIsCodeSent,
  isCodeResent,
  isLandscape,
  setIsCodeResent
}) => {
  if (isLandscape && isIOS) {
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeIn w-100 vh-100"
        style={{
          backgroundImage: `url(${trippinBallz})`,
          backgroundPosition: "center",
          backgroundRepeat: "never",
          backgroundSize: "cover",
          padding: "env(safe-area-inset)",
          position: "fixed"
        }}
      >
        <div className="rotate-counter-clockwise-slow">
          <span role="img" aria-label="monkey face" style={{ fontSize: 72 }}>
            🐵
          </span>
        </div>
        <div
          className="display-4 mt-3 text-center text-light w-100"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
          Rotate Your Screen
        </div>
      </div>
    );
  } else if (isLandscape && isAndroid) {
    return (
      <div
        className={`d-flex flex-column justify-content-center align-items-center py-3`}
        style={{
          backgroundImage: `url(${trippinBallz})`,
          backgroundPosition: "center",
          backgroundRepeat: "never",
          backgroundSize: "cover"
          // height: "100vh"
        }}
      >
        <div className="container d-flex flex-column align-items-center animate__animated animate__fadeIn w-100">
          <SignInForm
            formAction={formAction}
            setFormAction={setFormAction}
            // isScreenShort={isScreenShort}
            isCodeSent={isCodeSent}
            isMobile={isMobile}
            setIsCodeSent={setIsCodeSent}
            isCodeResent={isCodeResent}
            setIsCodeResent={setIsCodeResent}
          />
        </div>
      </div>
    );
  }
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center`}
      style={{
        backgroundImage: `url(${bgimage})`,
        backgroundPosition: "center",
        backgroundRepeat: "never",
        backgroundSize: "cover",
        height: "100vh"
      }}
    >
      <div className="container d-flex flex-column align-items-center animate__animated animate__fadeIn w-100">
        <SignInForm
          formAction={formAction}
          setFormAction={setFormAction}
          // isScreenShort={isScreenShort}
          isCodeSent={isCodeSent}
          isMobile={isMobile}
          setIsCodeSent={setIsCodeSent}
          isCodeResent={isCodeResent}
          setIsCodeResent={setIsCodeResent}
        />
      </div>
    </div>
  );
};

SignInPageMobile = withOrientationChange(SignInPageMobile);
export { SignInPageMobile };
