import { useEffect, useRef } from "react";
import { createBrowserHistory } from "history";
import { doSignOutCognitoUser } from "../actions";
import { FriendDetails } from "../components/FriendDetails";
import { FriendsPlaylists } from "../components/FriendsPlaylists";
import { MyFriendsSharedPlaylistTracks } from "../components/MyFriendsSharedPlaylistTracks";
import { MySharedPlaylists } from "../components/MySharedPlaylists";
import { Router, Route, Switch } from "react-router-dom";
import { SignInPage } from "../components/SignInPage";
import { useAppDispatch } from "../hooks";
import Aloha from "../Aloha";
import App from "../App";
import AppStatus from "../components/AppStatus";
import AuthCallback from "../components/AuthCallback";
import IdleTimer from "react-idle-timer";
import LoadingPage from "../components/LoadingPage";
import NavBar from "../components/shared/NavBar";
import NotFoundPage from "../NotFoundPage";
import PrivacyPolicy from "../components/policies/PrivacyPolicy";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import SlugBucket from "../components/SlugBucket";
import Slugify from "../components/Slugify";
import SlugSearch from "../SlugSearch";
import SpotifyCallback from "../components/SpotifyCallback";
import TagManager from "react-gtm-module";
import Tutorial from "../components/tutorial/Tutorial";
import UserSettings from "../components/UserSettings";
import Home from "../pages/Home";
import Cookies from "js-cookie";
import { CookiesActionType } from "../types"; // enum
import config from "../config/config.json";
// import Contact from "../components/contact/Contact";
// import SlugTube from "../components/SlugTube/SlugTube";
// import SlugTubeItemCommentary from "../components/SlugTube/SlugTubeItemCommentary";

const isDev = process.env.NODE_ENV === "development";

export const customHistory = createBrowserHistory();

const AppRouter = () => {
  const dispatch = useAppDispatch();
  const idleTimer = useRef(null);
  const onIdle = (e) => {
    if (isDev) {
      console.log("user is idle", e);
      console.log("last active", idleTimer.current.getLastActiveTime());
    }
    dispatch(doSignOutCognitoUser());
  };
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-MR66F5C"
    };
    TagManager.initialize(tagManagerArgs);
    // window.dataLayer = window.dataLayer || [];
    // function gtag() {
    //   window.dataLayer.push(arguments);
    // }
    // gtag("consent", "default", {
    //   ad_storage: "denied",
    //   analytics_storage: "denied",
    //   wait_for_update: 500,
    //   region: ["all"]
    // });

    const cookie = Cookies.get(config.CONSENT_COOKIE_NAME);
    if (isDev) {
      console.log("%cRemove this after migration", "color:orange");
    }
    Cookies.set("G_AUTH2_MIGRATION", "informational", {
      expires: 365,
      path: "/"
    });
    if (cookie) {
      // const parsed: CookiesConsentState = JSON.parse(cookie);
      const parsed = JSON.parse(cookie);
      // const { isAdvertisingEnabled, isPerformanceEnabled } = parsed;
      // gtag("consent", "update", {
      //   ad_storage: isAdvertisingEnabled ? "granted" : "denied",
      //   analytics_storage: isPerformanceEnabled ? "granted" : "denied"
      // });
      dispatch({
        type: CookiesActionType.SET_ALL_COOKIE_CONSENT_STATES,
        payload: parsed
      });
      // setInitialCookieState(parsed);
      // setCookiesState({ ...cookiesState, ...parsed });
    }
  }, []);

  return (
    <Router history={customHistory}>
      <Switch>
        <PublicRoute exact path="/">
          <Home />
        </PublicRoute>
        <PublicRoute path="/signIn">
          <SignInPage />
        </PublicRoute>
        {/* <Route path="/contact" component={Contact} /> */}
        <Route path="/auth-callback" component={AuthCallback} />
        <Route path="/spotify-callback" component={SpotifyCallback} />
        <Route path="/loading" component={LoadingPage} />
        <Route path="/privacy-policy">
          <IdleTimer
            ref={idleTimer}
            element={document}
            onIdle={onIdle}
            debounce={500}
            timeout={1000 * 60 * 60}
          />
          <PrivacyPolicy />
        </Route>
        <Route path="/tutorial">
          <IdleTimer
            ref={idleTimer}
            element={document}
            onIdle={onIdle}
            debounce={500}
            timeout={1000 * 60 * 60}
          />
          <Tutorial />
        </Route>
        <PrivateRoute path="/aloha">
          <Aloha />
        </PrivateRoute>
        <PrivateRoute path="/app-status">
          <NavBar />
          <IdleTimer
            ref={idleTimer}
            element={document}
            onIdle={onIdle}
            debounce={500}
            timeout={1000 * 60 * 60}
          />
          <AppStatus />
        </PrivateRoute>
        <PrivateRoute path="/friend-details">
          <NavBar />
          <IdleTimer
            ref={idleTimer}
            element={document}
            onIdle={onIdle}
            debounce={500}
            timeout={1000 * 60 * 60}
          />
          <FriendDetails />
        </PrivateRoute>
        <PrivateRoute path="/search">
          <NavBar />
          <IdleTimer
            ref={idleTimer}
            element={document}
            onIdle={onIdle}
            debounce={500}
            timeout={1000 * 60 * 60}
          />
          <SlugSearch />
        </PrivateRoute>
        <PrivateRoute path="/settings">
          <NavBar />
          <IdleTimer
            ref={idleTimer}
            element={document}
            onIdle={onIdle}
            debounce={500}
            timeout={1000 * 60 * 60}
          />
          {/* <UserSettings musicKit={musicKit} /> */}
          <UserSettings />
        </PrivateRoute>
        <PrivateRoute path="/shared-playlists">
          <NavBar />
          <IdleTimer
            ref={idleTimer}
            element={document}
            onIdle={onIdle}
            debounce={500}
            timeout={1000 * 60 * 60}
          />
          <MySharedPlaylists />
        </PrivateRoute>
        <PrivateRoute path="/friends-playlists">
          <NavBar />
          <IdleTimer
            ref={idleTimer}
            element={document}
            onIdle={onIdle}
            debounce={500}
            timeout={1000 * 60 * 60}
          />
          <FriendsPlaylists />
        </PrivateRoute>
        <PrivateRoute path="/shared-playlist-tracks">
          <NavBar />
          <IdleTimer
            ref={idleTimer}
            element={document}
            onIdle={onIdle}
            debounce={500}
            timeout={1000 * 60 * 60}
          />
          <MyFriendsSharedPlaylistTracks />
        </PrivateRoute>
        <PrivateRoute path="/slugbucket">
          <NavBar />
          <IdleTimer
            ref={idleTimer}
            element={document}
            onIdle={onIdle}
            debounce={500}
            timeout={1000 * 60 * 60}
          />
          <SlugBucket />
        </PrivateRoute>
        <PrivateRoute path="/slugify">
          <NavBar />
          <Slugify />
        </PrivateRoute>
        {/* TODO:  Figure out why slugTube re-renders when used with PrivateRoute/PrivateRoute */}
        {/* <Route path="/slugtube" component={SlugTube} /> */}
        {/* <PrivateRoute
          path="/slugtube-commentary/:id"
          component={SlugTubeItemCommentary}
        /> */}
        {/* <PrivateRoute path="/spotify-callback">
          <SpotifyCallback />
        </PrivateRoute> */}
        <PrivateRoute exact path="/home">
          <NavBar />
          <IdleTimer
            ref={idleTimer}
            element={document}
            onIdle={onIdle}
            debounce={500}
            timeout={1000 * 60 * 60}
          />
          <App />
        </PrivateRoute>
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
