const StreamSongModal = () => {
  return (
    <div className="modal fade" id="info-modal">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title lead">How to Slug</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="d-flex flex-column modal-body">
            <p>
              When songs show up here, someone thinks you might like them.
              Sharing is caring!
            </p>
            <p>
              There&apos;s a few things you can do when there are tunes in the
              Slug Bucket:
            </p>
            <ul>
              <li>
                If you like a song and wanna keep it, click the download cloud
                thingy. If you&apos;re on mobile, this will probably play.
              </li>
              <li>
                If you just want to download everyting that is currently in the
                bucket, click on the &quot;Slurp the Whole Enchilada&quot;
                button. It will only download songs that aren&apos;t yours.
              </li>
              <li>
                Sidenote: Firefox tends to handle multiple file downloads more
                gracefully than other browsers.
              </li>
              <li>
                If you&apos;re on mobile, you should see a play thingy. Tap that
                to listen.
              </li>
              <li>
                If you really like a song, click the heart. That way, the Slug
                will know what kind of tunes you really like.
              </li>
              <li>
                Once you&apos;ve played, liked, and/or downloaded a song. Go
                ahead and delete it. The person who sent the file already has
                it, and a record of the upload has already been recorded so that
                dupe upload attempts are nipped in the bud.
              </li>
              <li>
                You can delete everyting from Slug Bucket in one fell swoop with
                the &quote;Flush the Slug Bucket&quot; button.
              </li>
              <li>
                Neither Slurp the Whole Enchilada or Flush the Slug Bucket work
                on songs that you have uploaded. You can, however, download
                and/or delete these individually.
              </li>
            </ul>
            <p>
              <span
                className="me-2"
                role="img"
                aria-label="unicorns and rainbows"
              >
                🦄🌈
              </span>
              That&quot;s it! Enjoy Slug Bucket.
              <span
                className="ms-2"
                role="img"
                aria-label="unicorns and rainbows"
              >
                🌈🦄
              </span>
            </p>
          </div>
          <div className="d-flex justify-content-center modal-footer">
            <button
              type="button"
              className="btn btn-link text-body-secondary text-decoration-none"
              data-bs-dismiss="modal"
            >
              close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamSongModal;
