import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSlugTubeLinks,
  // getMyYoutubePlaylists,
  // markAsWatched,
  // initYouTubeApi,
  saveVidToYouTubePlaylist
} from "../../actions";
import SlugTubeItem from "./SlugTubeItem";
import StatusMessage from "../shared/StatusMessage";
import SlugTubeNewVideoModal from "./SlugTubeNewVideoModal";
import SlugTubePagination from "./SlugTubePagination";
import NavBar from "../shared/NavBar";

const SlugTube = () => {
  const dispatch = useDispatch();
  const currentSlugTubeVideoList = useSelector((state) => state.slugTube);
  const currentYoutubeUserData = useSelector((state) => state.youtube);
  const currentUserEmail = useSelector((state) => state.auth.email);
  const currentUserWatchedVids = useSelector(
    (state) => state.user.watchedSlugTubeVids
  );

  const [gridWidth, setGridWidth] = useState(310);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPlaylist, setSelectedPlaylist] = useState("");
  const [showMyVids, setShowMyVids] = useState(false);
  const [showWatchedVids, setShowWatchedVids] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [status, setStatus] = useState("");

  const slugTubeGrid = useRef(null);

  // Pagination
  const VIDS_PER_PAGE = 4;
  const MAX_PAGINATION_PAGES = 10;
  const [filteredVids, setFilteredVids] = useState([]);
  const [paginatedVids, setPaginatedVids] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(filteredVids / VIDS_PER_PAGE + 1)
  );
  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0 + VIDS_PER_PAGE);

  const handleAddToPlaylist = () => {
    setSpinning(true);
    setStatus("");
    const vids = filteredVids.map((vid) => vid.url.slice(-11));

    let counter = 0;

    const vidLoop = async (videoId) => {
      dispatch(saveVidToYouTubePlaylist(selectedPlaylist, videoId));
      counter++;
      if (counter < vids.length) {
        vidLoop(vids[counter]);
      } else {
        setSpinning(false);
        setStatus("done");
      }
    };
    vidLoop(vids[0]);
  };

  useEffect(() => {
    // console.log(filteredVids.length);
    dispatch(getSlugTubeLinks(setIsLoading));
  }, [dispatch, filteredVids.length]);

  useEffect(() => {
    if (filteredVids.length > 1) {
      setGridWidth(620);
    } else {
      setGridWidth(310);
    }
  }, [filteredVids.length]);

  useEffect(() => {
    setRangeStart(VIDS_PER_PAGE * currentPage);
    setRangeEnd(VIDS_PER_PAGE * (currentPage + 1));
    // TODO: figure out how ranges get updated when filteredVids.length changes
    // when I don't have it the second parameter below!
  }, [currentPage]);

  useEffect(() => {
    if (filteredVids.length > VIDS_PER_PAGE) {
      setPaginatedVids(filteredVids.slice(rangeStart, rangeEnd));
      setTotalPages(Math.ceil(filteredVids.length / VIDS_PER_PAGE + 1));
    } else {
      setPaginatedVids(filteredVids);
      setTotalPages(1);
    }
  }, [filteredVids, rangeEnd, rangeStart]);

  // useEffect(() => {
  //   if (!currentYoutubeUserData.client) {
  //     dispatch(initYouTubeApi());
  //   } else {
  //     dispatch(getMyYoutubePlaylists());
  //   }
  // }, [currentYoutubeUserData.client, dispatch]);

  useEffect(() => {
    if (currentSlugTubeVideoList.length) {
      if (showMyVids && showWatchedVids) {
        setFilteredVids(currentSlugTubeVideoList);
      } else if (showMyVids && !showWatchedVids) {
        setFilteredVids(
          currentSlugTubeVideoList.filter(
            (item) => !currentUserWatchedVids.includes(item.id)
          )
        );
      } else if (!showMyVids && showWatchedVids) {
        setFilteredVids(
          currentSlugTubeVideoList.filter((item) => {
            return !item.user.includes(currentUserEmail);
          })
        );
      } else {
        setFilteredVids(
          currentSlugTubeVideoList.filter(
            (item) =>
              !item.user.includes(currentUserEmail) &&
              !currentUserWatchedVids.includes(item.id)
          )
        );
      }
    }
  }, [
    currentSlugTubeVideoList,
    currentUserEmail,
    currentUserWatchedVids,
    showMyVids,
    showWatchedVids
  ]);

  const renderAddButton = () => (
    <>
      <div className="d-flex d-sm-none justify-content-center align-items-center w-100">
        <button
          className="btn btn-sm btn-secondary btn-block d-flex justify-content-center align-items-center w-100"
          data-bs-toggle="modal"
          data-bs-target="#slugTubeNewVideoModal"
          style={{
            maxWidth: `${gridWidth}px`
          }}
        >
          <span className="me-2" role="img" aria-label="popcorn">
            🍿
          </span>
          <span>Add New Video</span>
        </button>
      </div>
      <div className="d-none d-sm-flex justify-content-center align-items-center w-100">
        <button
          className="btn btn-secondary btn-block d-flex justify-content-center align-items-center w-100"
          data-bs-toggle="modal"
          data-bs-target="#slugTubeNewVideoModal"
          style={{
            maxWidth: `${gridWidth}px`
          }}
        >
          <span className="me-2" role="img" aria-label="popcorn">
            🍿
          </span>
          <span>Add New Video</span>
        </button>
      </div>
    </>
  );

  const renderIcon = () => {
    if (spinning) {
      return (
        <div
          className="spinner-grow spinner-grow-sm text-light me-3"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      );
    } else if (!spinning && status === "done") {
      return <i className="icon-playlist_add_check slug-icon me-2" />;
    } else {
      return <i className="icon-playlist_add slug-icon me-2" />;
    }
  };
  const renderList = () => {
    if (isLoading) {
      return (
        <StatusMessage
          titleAnimation="animate__pulse"
          emoticon="📺"
          title="Loading..."
          isInfinite
        />
      );
    } else if (!isLoading && !filteredVids.length) {
      return (
        <>
          <StatusMessage
            titleAnimation="tada"
            emoticon="🙈"
            message="Nothing to see here!"
          />
          {renderAddButton()}
        </>
      );
    } else {
      const renderPlaylistOptions = () => {
        if (currentYoutubeUserData.myPlaylists.length) {
          return currentYoutubeUserData.myPlaylists.map((playlist) => (
            <option key={playlist.id} value={playlist.id}>
              {playlist.title}
            </option>
          ));
        }
      };
      return (
        <>
          <div
            className="d-flex justify-content-center flex-wrap animate__animated animate__fadeIn"
            ref={slugTubeGrid}
            style={{ border: "0" }}
          >
            {paginatedVids.map((item) => (
              <SlugTubeItem
                index={filteredVids.indexOf()}
                item={item}
                key={item.id}
                height="169"
                width="300"
              />
            ))}
          </div>
          <div className="d-block d-sm-none">
            <SlugTubePagination
              currentPage={currentPage}
              isSmall={true}
              maxPaginationPages={MAX_PAGINATION_PAGES}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
          <div className="d-none d-sm-block">
            <SlugTubePagination
              currentPage={currentPage}
              isSmall={false}
              maxPaginationPages={MAX_PAGINATION_PAGES}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
          {currentYoutubeUserData.myPlaylists.length ? (
            <>
              <div className="form-group mb-2">
                <select
                  className="form-select"
                  id="selected"
                  onChange={(e) => setSelectedPlaylist(e.target.value)}
                  style={{
                    width: `${gridWidth}px`
                  }}
                >
                  <option value="">Select a Playlist</option>
                  {renderPlaylistOptions()}
                </select>
              </div>
              <button
                className="d-flex align-items-center justify-content-center btn btn-info btn-block"
                disabled={!selectedPlaylist}
                onClick={() => handleAddToPlaylist()}
                style={{
                  maxWidth: `${gridWidth}px`
                }}
              >
                {renderIcon()}
                <span>
                  {filteredVids.length === 1
                    ? "Save to Playlist"
                    : "Save All to Playlist"}
                </span>
              </button>
            </>
          ) : null}
          {/* <button
            className="btn btn-primary btn-block"
            onClick={() => dispatch(markAsWatched())}
            style={{
              maxWidth: `${gridWidth}px`
            }}
          >
            <span className="me-2" role="img" aria-label="eye">
              👁️
            </span>
            <span>
              {filteredVids.length === 1
                ? "Mark As Watched"
                : "Mark All As Watched"}
            </span>
          </button> */}
          {renderAddButton()}
        </>
      );
    }
  };

  return (
    <>
      {/* TODO:  Figure out why slugTube re-renders when used with PrivateRoute/RouteWithNav */}
      <NavBar />
      <SlugTubeNewVideoModal />
      <div className="d-flex container flex-column align-items-center my-3 my-sm-5 slug-width">
        <div className="d-block d-sm-none text-center mb-3">
          <div className="slug-title mb-3 text-body-secondary">SlugTube!</div>
        </div>
        <div className="d-none d-sm-block text-center mb-3">
          <div className="display-4 mb-3 text-body-secondary">SlugTube!</div>
        </div>
        {currentSlugTubeVideoList.length ? (
          <>
            <div className="form-group">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="showMyVids"
                  checked={showMyVids}
                  onChange={() => {
                    setShowMyVids(!showMyVids);
                  }}
                />
                <label className="custom-control-label" htmlFor="showMyVids">
                  Show My SlugTube Submissions
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="showWatchedVids"
                  checked={showWatchedVids}
                  onChange={() => {
                    setShowWatchedVids(!showWatchedVids);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="showWatchedVids"
                >
                  Show Previously Watched Videos
                </label>
              </div>
            </div>
          </>
        ) : null}
        {renderList()}
      </div>
      {/* <SlugTubePagination
        totalVideos={filteredVids}
        pageLimit={18}
        pageNeighbours={1}
        onPageChanged={onPageChanged}
      /> */}
    </>
  );
};

export default SlugTube;
