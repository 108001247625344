import { useContext } from "react";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import FriendList from "./FriendList";

interface Props {
  setIsAddFriendModalOpen: (b: boolean) => void;
  setIsInviteFriendModalOpen: (b: boolean) => void;
}
export const UserSettingsFriends = ({
  setIsAddFriendModalOpen,
  setIsInviteFriendModalOpen
}: Props) => {
  const { isDark } = useContext(ThemeContext);
  return (
    <div className="my-3 w-100 animate__animated animate__fadeIn">
      <div className="slug-subtitle text-body-secondary d-flex flex-column align-items-center">
        <div>Friends</div>
        <div className="d-flex justify-content-center align-items-center mt-3">
          <button
            className={`btn btn-${
              isDark ? "outline-primary" : "primary"
            } btn-sm me-1 `}
            onClick={() => setIsAddFriendModalOpen(true)}
          >
            Add a Friend
          </button>
          <button
            className={`btn btn-${
              isDark ? "outline-secondary" : "secondary"
            } btn-sm ms-1`}
            onClick={() => setIsInviteFriendModalOpen(true)}
          >
            Invite a Friend
          </button>
        </div>
      </div>
      <FriendList />
    </div>
  );
};
