import { useHistory } from "react-router-dom";
import StatusMessage from "./components/shared/StatusMessage";

const NotFoundPage = () => {
  const history = useHistory();
  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-center w-100 vh-100">
      <StatusMessage
        title="Page Not Found"
        emoticon="👻"
        subtitle="You still haven't found what you're looking for..."
        size="sm"
      />
      <button
        className="btn btn-primary mt-2"
        onClick={() => history.push("/")}
      >
        Go Home
      </button>
    </div>
  );
};

export default NotFoundPage;
