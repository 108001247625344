import PropTypes from "prop-types";

const ProgressBar = (props) => {
  const { acceptedFilesLength, uploadCount, uploadProgress, uploadStatus } =
    props;
  const renderUploadStatus = () => {
    if (uploadStatus) {
      if (typeof uploadStatus === "number") {
        return (
          <small>
            {parseInt(uploadStatus)}% ({uploadCount} of {acceptedFilesLength}{" "}
            complete)
          </small>
        );
      } else if (typeof uploadStatus === "string") {
        return <small>{uploadStatus}</small>;
      }
      return null;
    }
  };

  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-center w-100 animate__animated ${
        parseInt(uploadProgress) === 0 ? "animate__fadeOut" : "animate__fadeIn"
      }`}
    >
      <div
        className="progress"
        style={{ maxWidth: "768px", minWidth: "300px" }}
      >
        <div
          className={`progress-bar progress-bar-striped ${
            uploadProgress < 100
              ? "progress-bar-animated bg-primary"
              : "bg-success"
          }`}
          role="progressbar"
          aria-valuenow={uploadProgress}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${uploadProgress}%` }}
        />
      </div>
      <div className="text-center lead text-body-secondary mb-3">
        <small>{uploadStatus ? renderUploadStatus() : null}</small>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  uploadProgress: PropTypes.number.isRequired,
  uploadStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};

export default ProgressBar;
