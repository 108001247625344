import { object, string } from "yup";

const phoneRegex = /^\+?[1-9]\d{1,14}$/;

export const contactFormSchema = object().shape({
  name: string().required("Name is required"),
  email: string()
    .email("Please use a valid email")
    .required("Email is required"),
  subject: string().required("Subject is required"),
  message: string()
    .required("Message is required")
    .min(8, "Message must be at least 20 characters.")
});

export const confirmationSchema = object({
  code: string()
    .length(6, "Must be at least exactly 6 characters")
    .required("Required"),
  username: string()
    .min(2, "Must be at least 2 characters")
    .max(15, "Must be 15 characters or less")
    .required("Required")
});

export const signInFormSchema = object().shape({
  username: string().required("Username is required"),
  password: string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters.")
});

export const signUpFormSchema = object().shape({
  appleEmail: string().email("Please use a valid email"),
  email: string()
    .email("Please use a valid email")
    .required("Email is required"),
  googleEmail: string().email("Please use a valid email"),
  phone: string().matches(phoneRegex, "Format should be +12345556789"),
  password: string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters."),
  username: string().required("Username is required")
});

export const userCustomizationSchema = object().shape({
  nickname: string()
    .required("Required")
    .min(1, "Must be at least 1 character")
    .max(15, "Must be no more than 15 characters"),
  appleEmail: string().email("Please use a valid email"),
  googleEmail: string().email("Please use a valid email")
});
