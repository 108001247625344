import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";
import { ColorScheme } from "../types";

export const ThemeContext = createContext({
  isDark: false,
  toggleTheme: () => {}
});

interface Props {
  children: React.ReactNode;
}
export default function ThemeProvider({ children }: Props) {
  const [isDark, setIsDark] = useState(false);

  function toggleTheme() {
    const el = document.querySelector("[data-bs-theme]");
    const toggledTheme = isDark ? "light" : "dark";
    el?.setAttribute("data-bs-theme", toggledTheme);
    console.log(
      "%cChanging theme to:",
      "color:cyan",
      toggledTheme === "dark" ? "🌝" : "🌞"
    );
    Cookies.set(COLOR_THEME_COOKIE_NAME, toggledTheme, {
      expires: 365,
      path: "/"
    });
    setIsDark(!isDark);
  }

  const COLOR_THEME_COOKIE_NAME = "slugify-color-theme";

  useEffect(() => {
    const cookieVal = Cookies.get(COLOR_THEME_COOKIE_NAME);
    if (cookieVal) {
      if (cookieVal === ColorScheme.DARK) {
        setIsDark(true);
      } else if (cookieVal === ColorScheme.LIGHT) {
        setIsDark(false);
      }
      const el = document.querySelector("[data-bs-theme]");
      el?.setAttribute("data-bs-theme", cookieVal);
    } else {
      const getPreferredTheme = () => {
        return window.matchMedia("(prefers-color-scheme: dark)").matches
          ? ColorScheme.DARK
          : ColorScheme.LIGHT;
      };
      const preferred = getPreferredTheme();
      Cookies.set(COLOR_THEME_COOKIE_NAME, preferred, {
        expires: 365,
        path: "/"
      });
      if (preferred === ColorScheme.DARK) {
        setIsDark(true);
      } else if (preferred === ColorScheme.LIGHT) {
        setIsDark(false);
      }
      const el = document.querySelector("[data-bs-theme]");
      el?.setAttribute("data-bs-theme", preferred);
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}
