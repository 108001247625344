import { API, Auth } from "aws-amplify";
import axios from "axios";
import { compact, omit } from "lodash";
import { isEmptyObject } from "../functions";
import { handleUserAlert } from "./slugifyActions";
import { updateSlugifyUser } from "./slugifyGraphqlActions";
import { getTitleCase } from "../functions/helpers";
import {
  SlugifyActionType,
  SlugifyTrackType,
  SpotifyActionType
} from "../types";

const isDev = process.env.NODE_ENV === "development";

export const getSpotifyToken = (code, setError) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SPOTIFY_TOKEN_DATA_TO_USER_START"
    });
    const response = await API.post("SlugBucketAPI", `/spotify/gettoken`, {
      body: {
        code,
        redirect_uri: process.env.REACT_APP_SPOTIFY_REDIRECT_URI
      }
    });
    await dispatch(getSpotifyUserData(response));
    dispatch({
      type: "GET_SPOTIFY_TOKEN_DATA_TO_USER_SUCCESS"
    });
    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: "GET_SPOTIFY_TOKEN_DATA_TO_USER_FAIL",
      payload: error
    });
    console.warn(error);
    setError(error);
  }
};

export const getSpotifyUserData = (response) => async (dispatch) => {
  const { access_token: accessToken } = response;

  try {
    dispatch({
      type: "GET_SPOTIFY_USER_DATA_TO_USER_START"
    });
    const options = {
      method: "GET",
      url: "https://api.spotify.com/v1/me",
      headers: { Authorization: "Bearer " + accessToken },
      json: true
    };
    const { data } = await axios(options);

    await dispatch(
      updateSlugifyUser({ spotifyUserData: data, spotifyTokenData: response })
    );

    dispatch({
      type: "GET_SPOTIFY_USER_DATA_TO_USER_SUCCESS",
      payload: { spotifyUserData: data, spotifyTokenData: response }
    });

    dispatch({
      type: SpotifyActionType.SET_SPOTIFY_CONNECTED_STATUS,
      payload: true
    });
    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: SpotifyActionType.SET_SPOTIFY_CONNECTED_STATUS,
      payload: false
    });
    dispatch({
      type: "GET_SPOTIFY_USER_DATA_TO_USER_FAIL",
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getUserDataFromDb = () => async (dispatch, getState) => {
  const currentUser = getState().auth.username;
  dispatch({ type: "GET_USER_DATA_FROM_DB_START" });
  try {
    const response = await API.get(
      "SlugBucketAPI",
      `/users/object/${currentUser}/`
    );
    if (isEmptyObject(response)) {
      dispatch({
        type: "GET_USER_DATA_FROM_DB_FAIL",
        payload: {
          message:
            "No user data in db, which is okay, they just don't have data yet."
        }
      });
    } else {
      dispatch({
        type: "GET_USER_DATA_FROM_DB_SUCCESS",
        payload: response
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: "GET_USER_DATA_FROM_DB_FAIL",
      payload: e
    });
  }
};

export const refreshSpotifyToken = () => async (dispatch, getState) => {
  dispatch({ type: "REFRESH_SPOTIFY_TOKEN_START" });
  try {
    const {
      newUser: {
        primaryMusicStream,
        spotifyTokenData: { refresh_token }
      }
    } = getState();

    if (primaryMusicStream === "spotify") {
      if (!refresh_token) {
        alert("No refresh token!");
      }
      const response = await API.post(
        "SlugBucketAPI",
        `/spotify/refreshtoken`,
        {
          body: {
            refresh_token
          }
        }
      );

      dispatch({ type: "REFRESH_SPOTIFY_TOKEN_SUCCESS", payload: response });
      dispatch({
        type: SpotifyActionType.SET_SPOTIFY_CONNECTED_STATUS,
        payload: true
      });

      response.refresh_token = refresh_token;
      await dispatch(updateSlugifyUser({ spotifyTokenData: response }));
      return Promise.resolve(response.access_token);
    } else {
      if (primaryMusicStream !== "spotify") {
        if (isDev) {
          console.log("⛔ Not a Spotify user");
        }
        dispatch({
          type: "REFRESH_SPOTIFY_TOKEN_FAIL",
          payload: "Not a Spotify user"
        });
        return Promise.resolve("Not a spotify user");
      } else {
        if (isDev) {
          console.log("⛔ User has not selected primary music stream yet");
        }
        dispatch({
          type: "REFRESH_SPOTIFY_TOKEN_FAIL",
          payload: "User has not selected primary music stream yet"
        });
        return Promise.resolve(
          "User has not selected primary music stream yet"
        );
      }
    }
  } catch (error) {
    console.warn(
      "Refresh Token Error:",
      error.response ? error.response : error
    );
    const errorMessage = error.response
      ? error.response.data.response.body.error_description
      : error.message;
    dispatch({
      type: "REFRESH_SPOTIFY_TOKEN_FAIL",
      payload: new Error(errorMessage)
    });
    dispatch({
      type: SpotifyActionType.SET_SPOTIFY_CONNECTED_STATUS,
      payload: false
    });
    dispatch(handleUserAlert(["REFRESH_SPOTIFY_TOKEN"]));
    return Promise.resolve(new Error(errorMessage));
  }
};

export const removeAppleMusicUserData = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "REMOVE_APPLE_MUSIC_USER_DATA_START" });
    const {
      newUser: { appleMusicSlugifyPlaylist }
    } = await getState();

    await dispatch(updateSlugifyUser({ appleMusicSlugifyPlaylist: null }));
    await dispatch({
      type: "REMOVE_APPLE_MUSIC_USER_DATA_SUCCESS",
      payload: appleMusicSlugifyPlaylist
    });
    return Promise.resolve();
  } catch (error) {
    dispatch({ type: "REMOVE_APPLE_MUSIC_USER_DATA_FAIL", payload: error });
    return Promise.reject(error);
  }
};

export const saveAppleMusicUserData =
  (publicPlaylist) => async (dispatch, getState) => {
    try {
      dispatch({ type: "SAVE_APPLE_MUSIC_USER_DATA_START" });
      const {
        newUser: { appleMusicSlugifyPlaylist }
      } = getState();
      if (publicPlaylist) {
        dispatch({
          type: "SAVE_APPLE_MUSIC_USER_DATA_SUCCESS",
          payload: publicPlaylist
        });
        await dispatch(
          updateSlugifyUser({ appleMusicSlugifyPlaylist: publicPlaylist })
        );
      } else {
        dispatch({
          type: "SAVE_APPLE_MUSIC_USER_DATA_SUCCESS",
          payload: appleMusicSlugifyPlaylist
        });
        await dispatch(updateSlugifyUser({ appleMusicSlugifyPlaylist }));
      }
      await dispatch({
        type: "SAVE_APPLE_MUSIC_USER_DATA_SUCCESS_NO_ACTION"
      });
      return Promise.resolve(appleMusicSlugifyPlaylist);
    } catch (error) {
      dispatch({ type: "SAVE_APPLE_MUSIC_USER_DATA_FAIL", payload: error });
      return Promise.reject(error);
    }
  };

export const updateUserSettings =
  (newSettings) => async (dispatch, getState) => {
    try {
      dispatch({ type: "UPDATE_USER_START" });
      const { newUser: currentUserData } = getState();
      const updatedUserSettings = {
        ...omit(
          currentUserData,
          "friends",
          "spotifyTokenData",
          "spotifyUserData",
          "slurpedTracks"
        ),
        ...newSettings
      };
      await dispatch(updateSlugifyUser(updatedUserSettings));
      return Promise.resolve();
    } catch (error) {
      console.warn(error);
      dispatch({
        type: "UPDATE_USER_FAIL",
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const updateFilteredTracks = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SlugifyActionType.UPDATE_FILTERED_TRACKS_START });
    const {
      appleMusic: { tracks: appleMusicTracks },
      auth: { username },
      slugify: {
        showMyTracks,
        showSlurpedTracks,
        spotifyTracks,
        slurpedTracks,
        youtubeTracks
      },
      newUser: {
        spotifyUserData: currentSpotifyUserData,
        primaryMusicStream,
        publicPlaylists
      },
      youtube: { myPlaylists: myYouTubePlaylists }
    } = getState();

    const appleMusicSlurpedTracks = slurpedTracks.filter((item) => {
      return (
        item.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE ||
        item.trackType ===
          SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY
      );
    });

    // if (isDev) {
    //   console.log("appleMusicSlurpedTracks", appleMusicSlurpedTracks);
    // }

    const spotifySlurpedTracks = slurpedTracks.filter(
      (item) =>
        item.trackType === SlugifyTrackType.SPOTIFY_NATIVE ||
        item.trackType ===
          SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC
    );

    // if (isDev) {
    //   console.log("Spotify Slurped Tracks", spotifySlurpedTracks);
    // }

    const youtubeSlurpedTracks = slurpedTracks.filter(
      (item) =>
        item.trackType === SlugifyTrackType.YOUTUBE_NATIVE ||
        item.trackType ===
          SlugifyTrackType.YOUTUBE_TRACK_MATCHED_FROM_APPLE_MUSIC ||
        item.trackType === SlugifyTrackType.YOUTUBE_TRACK_MATCHED_FROM_SPOTIFY
    );

    if (isDev) {
      console.log(
        "%cYouTube Slurped Tracks",
        "color:orange",
        youtubeSlurpedTracks
      );
    }

    if (primaryMusicStream === "appleMusic") {
      const myAppleMusicPublicPlaylistIds = publicPlaylists.length
        ? publicPlaylists.map((item) => item.id)
        : [];
      if (appleMusicTracks.length) {
        const myAppleMusicTracks = (track) =>
          track.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE &&
          myAppleMusicPublicPlaylistIds.includes(
            track.addedBy.includes("__")
              ? track.addedBy.split("__")[0]
              : track.addedBy
          );

        const myAppleMusicSlurpedTracks = (track) => {
          return appleMusicSlurpedTracks
            .filter((item) => item.username === username)
            .map((item) => item.appleMusicId)
            .includes(track.id);
        };

        const appleTracksIShouldSee = (track, username) => {
          return (
            (track.spotifyInfo &&
              track.spotifyInfo.playlist_name === "Slugify") ||
            (track.spotifyInfo &&
              track.spotifyInfo.playlist_name ===
                `${getTitleCase(username)} (Slugify Direct)`)
          );
        };
        if (showMyTracks && showSlurpedTracks) {
          dispatch({
            type: SlugifyActionType.UPDATE_FILTERED_TRACKS_SUCCESS,
            payload: appleMusicTracks.filter((track) => {
              if (track.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE) {
                return myAppleMusicTracks(track);
              } else if (
                track.trackType ===
                SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY
              ) {
                return myAppleMusicSlurpedTracks(track);
              } else {
                if (isDev) {
                  console.warn("Unhandled track type.  Fix this!");
                }
                return null;
              }
            })
          });
        } else if (showMyTracks && !showSlurpedTracks) {
          dispatch({
            type: SlugifyActionType.UPDATE_FILTERED_TRACKS_SUCCESS,
            payload: appleMusicTracks.filter((track) => {
              if (
                track.trackType ===
                SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY
              ) {
                return null;
              } else if (
                track.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE
              ) {
                return (
                  !myAppleMusicSlurpedTracks(track) && myAppleMusicTracks(track)
                );
              } else {
                if (isDev) {
                  console.warn("Unhandled track type.  Fix this!");
                }
                return null;
              }
            })
          });
        } else if (!showMyTracks && showSlurpedTracks) {
          dispatch({
            type: SlugifyActionType.UPDATE_FILTERED_TRACKS_SUCCESS,
            payload: appleMusicTracks.filter((track) => {
              return (
                myAppleMusicSlurpedTracks(track) &&
                // myAppleMusicSlurpedTracks(track) &&
                appleTracksIShouldSee(track, username)
              );
              // return myAppleMusicSlurpedTracks(track);
            })
          });
        } else {
          // !showMyTracks && !showSlurpedTracks
          dispatch({
            type: SlugifyActionType.UPDATE_FILTERED_TRACKS_SUCCESS,
            payload: appleMusicTracks.filter((track) => {
              if (
                track.trackType ===
                SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY
              ) {
                return (
                  // !myAppleMusicSlurpedTracks(track) &&
                  !myAppleMusicSlurpedTracks(track) &&
                  appleTracksIShouldSee(track, username)
                );
              } else if (
                track.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE
              ) {
                return (
                  !myAppleMusicTracks(track) &&
                  !myAppleMusicSlurpedTracks(track)
                );
              } else {
                return null;
              }
            })
          });
        }
      }
    } else if (primaryMusicStream === "spotify") {
      if (spotifyTracks.length) {
        const mySpotifyTracks = (item) =>
          item.trackType === SlugifyTrackType.SPOTIFY_NATIVE &&
          currentSpotifyUserData.id === item.added_by.id;

        const mySpotifySlurpedTracks = (item) =>
          item.trackType ===
            SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC &&
          spotifySlurpedTracks
            .filter((item) => item.username === username)
            .map((item) => item.appleMusicId)
            .includes(item.appleMusicInfo.appleMusicId);

        const spotifyTracksIShouldSee = (item) => {
          return (
            !item.appleMusicInfo.addedBy.includes("Slugify Direct") ||
            (item.appleMusicInfo.addedBy.includes("Slugify Direct") &&
              item.appleMusicInfo.addedBy.includes(getTitleCase(username)))
          );
        };

        if (showMyTracks && showSlurpedTracks) {
          dispatch({
            type: SlugifyActionType.UPDATE_FILTERED_TRACKS_SUCCESS,
            payload: spotifyTracks.filter((item) => {
              if (item.trackType === SlugifyTrackType.SPOTIFY_NATIVE) {
                return mySpotifyTracks(item);
              } else if (
                item.trackType ===
                SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC
              ) {
                return mySpotifySlurpedTracks(item);
              } else {
                if (isDev) {
                  console.warn("Unhandled type.  Fix this!");
                }
                return null;
              }
            })
          });
        } else if (showMyTracks && !showSlurpedTracks) {
          dispatch({
            type: SlugifyActionType.UPDATE_FILTERED_TRACKS_SUCCESS,
            payload: spotifyTracks.filter((item) => {
              return mySpotifyTracks(item);
            })
          });
        } else if (!showMyTracks && showSlurpedTracks) {
          dispatch({
            type: SlugifyActionType.UPDATE_FILTERED_TRACKS_SUCCESS,
            payload: spotifyTracks.filter((item) => {
              return mySpotifySlurpedTracks(item);
            })
          });
        } else {
          // don't show my tracks or slurped tracks
          dispatch({
            type: SlugifyActionType.UPDATE_FILTERED_TRACKS_SUCCESS,
            payload: spotifyTracks.filter((item) => {
              if (
                item.trackType ===
                SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC
              ) {
                return (
                  !mySpotifySlurpedTracks(item) && spotifyTracksIShouldSee(item)
                );
              } else if (item.trackType === SlugifyTrackType.SPOTIFY_NATIVE) {
                return (
                  currentSpotifyUserData.id !== item.added_by.id &&
                  !mySpotifySlurpedTracks(item)
                );
              } else {
                return null;
              }
            })
          });
        }
      }
    } else if (primaryMusicStream === "youtube") {
      if (isDev) {
        console.log(
          "%cProblem to solve:  slurped tracks user owner and userId, but these are different between email and federated logins.",
          "color:hotpink"
        );
        console.log(
          "%cFederated Log In is off until this is fixed!",
          "color:hotpink"
        );
        console.log(
          "%cMy YouTube Playlists",
          "color:hotpink",
          myYouTubePlaylists
        );
      }
      const myYouTubeSlugifyPlaylist = myYouTubePlaylists.find(
        (item) => item.name === "Slugify"
      );
      const myYouTubeDestinationPlaylists = myYouTubePlaylists
        .filter((item) => item.name.includes("SlugTube"))
        .map((thing) => thing.id);
      if (isDev) {
        console.log(
          "%cMy YouTube Destination Playlists",
          "color:hotpink",
          myYouTubeDestinationPlaylists
        );
      }

      const mySlurpedTracks = slurpedTracks.filter(
        // (item) => item.owner === username
        (item) => myYouTubeDestinationPlaylists.includes(item.slurpToPlaylistId)
      );
      if (isDev) {
        console.log("%cMy Slurped Tracks", "color:hotpink", mySlurpedTracks);
      }

      const slugTuberSlurpedTracks = slurpedTracks.filter(
        (item) => item.owner === "Slugtuber"
      );
      if (isDev) {
        console.log(
          "%cSlugTuber's Slurped Tracks",
          "color:hotpink",
          slugTuberSlurpedTracks
        );
      }

      const mySlurpedAppleMusicIds = mySlurpedTracks.map(
        (item) => item.appleMusicId
      );

      const mySlurpedSpotifyIds = mySlurpedTracks.map((item) => item.spotifyId);

      const appleMusicTracksIShouldSee = appleMusicTracks.filter((item) => {
        if (showMyTracks && showSlurpedTracks) {
          return mySlurpedAppleMusicIds.includes(item.id);
        } else if (!showMyTracks && showSlurpedTracks) {
          return mySlurpedAppleMusicIds.includes(item.id);
        } else if (showMyTracks && !showSlurpedTracks) {
          return !mySlurpedAppleMusicIds.includes(item.id);
        } else {
          // !showMyTracks && !showSlurpedTracks
          return !mySlurpedAppleMusicIds.includes(item.id);
        }
      });

      const spotifyTracksIShouldSee = spotifyTracks.filter((item) => {
        if (isDev) {
          console.log("spotify track", item);
        }
        if (showMyTracks && showSlurpedTracks) {
          return mySlurpedSpotifyIds.includes(item.track?.id);
        } else if (!showMyTracks && showSlurpedTracks) {
          return mySlurpedSpotifyIds.includes(item.track?.id);
        } else if (showMyTracks && !showSlurpedTracks) {
          return !mySlurpedSpotifyIds.includes(item.track?.id);
        } else {
          // !showMyTracks && !showSlurpedTracks
          return !mySlurpedSpotifyIds.includes(item.track?.id);
        }
      });

      const youtubeTracksIShouldSee = youtubeTracks.filter((item) => {
        if (showMyTracks) {
          return item.snippet.playlistId === myYouTubeSlugifyPlaylist.id;
        } else {
          const slurpedIds = youtubeSlurpedTracks.map(
            (item) => item.appleMusicId
          );
          return (
            item.snippet.playlistId !== myYouTubeSlugifyPlaylist.id &&
            !slurpedIds.includes(item.snippet.resourceId.videoId)
          );
          // not yet slurped
        }
      });

      const combinedTracks = compact([
        ...appleMusicTracksIShouldSee,
        ...spotifyTracksIShouldSee,
        ...youtubeTracksIShouldSee
      ]);

      dispatch({
        type: SlugifyActionType.UPDATE_FILTERED_TRACKS_SUCCESS,
        payload: combinedTracks
      });
      // }
    } else {
      // This probably shouldn't happen.
      if (isDev) {
        console.log(
          "%c⛔ User has not selected primary music stream yet",
          "color:yellow"
        );
      }
    }
    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: SlugifyActionType.UPDATE_FILTERED_TRACKS_FAIL,
      payload: error
    });
    console.warn(error);
  }
};

export const updateTutorialStatus = (status) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_TUTORIAL_STATUS", payload: status });
  } catch (error) {
    console.warn(error);
    dispatch({ type: "UPDATE_TUTORIAL_STATUS_FAIL" });
  }
};

export const updateUserNickname = (nickname) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_USER_NICKNAME_START" });
    const cognitoUser = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(cognitoUser, { nickname: nickname });
    dispatch({ type: "UPDATE_USER_NICKNAME_SUCCESS", payload: nickname });
    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: "UPDATE_USER_NICKNAME_FAIL",
      payload: error.response || error
    });
    return Promise.reject(error);
  }
};
