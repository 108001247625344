import { useContext, useMemo, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Spinner } from "./Spinner";
import { ThemeContext } from "../../contexts/ColorThemeProvider";

interface Props {
  btnText: string;
  className?: string;
  isSigningIn?: boolean;
  isSubmitting?: boolean;
  provider: CognitoHostedUIIdentityProvider;
  setFieldError?: (k: string, v: string) => void;
  setIsSigningIn?: (b: boolean) => void;
}
export const FederatedSignInButton = ({
  btnText,
  className,
  isSigningIn,
  isSubmitting,
  provider,
  setFieldError,
  setIsSigningIn
}: Props) => {
  const { isDark } = useContext(ThemeContext);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleFederatedSignIn = () => {
    try {
      if (setFieldError) {
        setFieldError("username", "");
        setFieldError("password", "");
      }
      setIsSpinning(true);
      if (setIsSigningIn !== undefined) {
        setIsSigningIn(true);
      }
      Auth.federatedSignIn({ provider: provider });
    } catch (error) {
      setIsSpinning(false);
      if (setIsSigningIn !== undefined) {
        setIsSigningIn(false);
      }
      console.warn(error);
    }
  };
  const { buttonClass, spinnerColor } = useMemo(() => {
    let buttonClass = "";
    let spinnerColor = "";
    if (provider === CognitoHostedUIIdentityProvider.Google) {
      buttonClass = "btn-outline-danger";
      spinnerColor = "danger";
    } else {
      if (isDark) {
        if (isHovered) {
          spinnerColor = "dark";
        } else {
          spinnerColor = "light";
        }
        buttonClass = "btn-outline-light";
      } else {
        if (isHovered) {
          spinnerColor = "light";
        } else {
          spinnerColor = "dark";
        }
        buttonClass = "btn-outline-dark";
      }
    }
    return { buttonClass, spinnerColor };
  }, [provider, isDark, isHovered]);

  return (
    <button
      className={`${className} d-flex align-items-center justify-content-center btn ${buttonClass} w-100`}
      disabled={isSigningIn || isSubmitting}
      onClick={() => {
        handleFederatedSignIn();
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      type="button"
      style={{ height: 44 }}
    >
      {isSpinning ? (
        <div style={{ marginRight: 12 }}>
          <Spinner
            color={spinnerColor}
            size={20}
            // color={
            //   provider === CognitoHostedUIIdentityProvider.Apple
            //     ? "light"
            //     : "danger"
            // }
            type="puff"
          />
        </div>
      ) : (
        <span
          className={`${
            provider === CognitoHostedUIIdentityProvider.Apple
              ? "icon-apple"
              : "icon-google"
          } slug-icon me-3`}
          style={{
            fontSize: 20,
            marginBottom: 0
          }}
        />
      )}
      <span className="">{btnText}</span>
    </button>
  );
};
