// eslint-disable jsx-a11y/anchor-is-valid
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import { useWindowSize } from "../hooks";

export const MyFriendsSharedPlaylists = ({ friendUsername }) => {
  const history = useHistory();
  const [, width] = useWindowSize();
  const {
    newUser: { myFriendsSharedPlaylists, sharedPlaylists }
  } = useSelector((state) => state);

  const playlists = sortBy(
    myFriendsSharedPlaylists.filter((item) => {
      return item.owner === friendUsername;
    }),
    "name"
  );

  const renderPlaylists = () => {
    return playlists.map((item) => (
      <li
        key={item.id}
        className={`list-group-item d-flex align-items-center justify-content-between ${
          width < 576 ? "ps-3 pe-2" : ""
        }`}
        onClick={() =>
          history.push("/shared-playlist-tracks", {
            friendUsername,
            playlist: item
          })
        }
        style={{ cursor: "pointer" }}
      >
        <div
          className={`text-${
            sharedPlaylists.map((playlist) => playlist.name).includes(item.name)
              ? "success"
              : ""
          }`}
        >
          {item.name}
        </div>
        <i className="icon-navigate_next slug-icon text-body-secondary" />
        {/* <div className="text-body-secondary">&gt;</div> */}
      </li>
    ));
  };

  return playlists.length ? (
    <div className="d-flex flex-column align-items-center w-100 mt-1 animate__animated animate__fadeIn">
      <ul className="list-group w-100 mt-3">{renderPlaylists()}</ul>
    </div>
  ) : (
    <p className="lead">Your friend hasn&apos;t shared any playlists</p>
  );
};
