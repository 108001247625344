import { useCallback, useContext, useMemo, useState } from "react";
import { getPrimaryMusicStreamName, getRandomTipper } from "../../functions";
import {
  getCurrentUserSpotifyPlaylists,
  getSlugifyPublicPlaylist,
  getCurrentUserAppleMusicPlaylists,
  getMyYoutubePlaylists
} from "../../actions";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ThemeContext } from "../../contexts/ColorThemeProvider";

export const PrivatePlaylistMessage = () => {
  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);
  const location = useLocation();
  const tipper = useCallback(() => getRandomTipper(), []);

  const {
    newUser: { primaryMusicStream }
  } = useAppSelector((state) => state);

  const [isSpinning, setIsSpinning] = useState(false);
  const [buttonColor, setButtonColor] = useState("primary");
  const [buttonText, setButtonText] = useState("Continue");
  const [buttonEmoji, setButtonEmoji] = useState("🚀");
  const [message, setMessage] = useState("");

  const btnColor = useMemo(() => {
    const btnColor = isDark ? `outline-${buttonColor}` : buttonColor;
    return btnColor;
  }, [buttonColor]);

  const checkPlaylists = async () => {
    if (primaryMusicStream === "appleMusic") {
      setIsSpinning(true);
      setMessage("");
      await dispatch(getCurrentUserAppleMusicPlaylists());
      const isPublic = await dispatch(getSlugifyPublicPlaylist());
      console.warn("Fix me!");
      if (isPublic) {
        console.log("🥒 Slugify Playlist on Apple Music is not shared!");
        showError();
      }
    } else if (primaryMusicStream === "spotify") {
      await dispatch(getCurrentUserSpotifyPlaylists());
      const isPublic = await dispatch(getSlugifyPublicPlaylist());
      console.warn("Fix me!");
      if (!isPublic) {
        console.log("🥒 Slugify Playlist on Spotify is secret!");
        showError();
      }
    } else if (primaryMusicStream === "youtube") {
      await dispatch(getMyYoutubePlaylists());
      const isPublic = await dispatch(getSlugifyPublicPlaylist());
      if (!isPublic) {
        console.log("🥒 Slugify Playlist on YouTube is private!");
        showError();
      }
    }
  };

  const getHelpUrl = () => {
    try {
      switch (primaryMusicStream) {
        case "appleMusic":
          return "https://support.apple.com/en-us/HT207542#share";
        case "spotify":
          return "https://support.spotify.com/th-en/article/set-playlist-privacy/";
        case "youtube":
          return "https://support.google.com/youtube/answer/3127309";
        default:
          return null;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const showError = () => {
    setIsSpinning(false);
    setButtonColor("danger");
    setButtonText("Try Again");
    setButtonEmoji("💣");
    setMessage(
      "Hmm... that didn't work. Did you make the Slugify playlist public?"
    );
  };

  return (
    <div
      className={
        "d-flex flex-column align-items-center text-center slug-width py-2 py-sm-3 animate__animated animate__fadeIn"
      }
    >
      <div
        className={`display ${
          !isSpinning ? "animate__animated animate__headShake" : ""
        }`}
      >
        <span
          role="img"
          aria-label="Person tipping hand"
          className={"status-emoji-sm"}
          style={{
            fontSize: location.pathname.includes("settings") ? "48px" : "72px"
          }}
        >
          {tipper()}
        </span>
      </div>
      <p className={"lead status-title-sm"}>Slugify Playlist</p>
      <p className={"lead px-1"}>
        {`When you connect to ${getPrimaryMusicStreamName(primaryMusicStream)}, Slugify automatically creates a playlist for you.
        `}
      </p>
      <p className={"lead px-1"}>
        {primaryMusicStream === "appleMusic"
          ? "You need to make it"
          : "It needs to be"}{" "}
        public for Slugify to work. See{" "}
        <strong>
          <a href={`${getHelpUrl()}`} target="_blank" rel="noopener noreferrer">
            here
          </a>
        </strong>{" "}
        for instructions.
      </p>
      <p className={"lead px-1"}>
        Once you&apos;ve done that, come back here to continue.
      </p>
      {primaryMusicStream !== "spotify" ? null : (
        <p className={"lead px-1"}>
          You can view your playlists online{" "}
          <strong>
            <a
              href="https://open.spotify.com/collection/playlists"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </strong>{" "}
          or in the Spotify app.
        </p>
      )}
      <button
        className={`btn btn-${btnColor} mt-3`}
        onClick={() => checkPlaylists()}
      >
        {isSpinning ? (
          <div
            className="spinner-grow spinner-grow-sm text-light me-2"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <span role="img" aria-label="Rocket">
            {buttonEmoji}
          </span>
        )}
        <span className="ms-2">{buttonText}</span>
      </button>
      {message ? <p className="text-danger mt-3">{message}</p> : null}
    </div>
  );
};
