/* eslint-disable no-case-declarations */
import { compact, uniq, uniqBy } from "lodash";
import { AnyAction } from "redux";
import {
  SlugifyActionType,
  SlugifyState,
  SlugifyTrackType,
  UserActionType,
  YouTubeActionType
} from "../types";
import {
  SpotifyActionType,
  SpotifyMatchedTrack,
  SpotifyUnmatchedTrack
} from "../types/spotify";

const initialState = {
  addDeleteFriendStatus: "",
  alert: {},
  appleMusicPlaylists: [],
  isInitializing: false,
  isDoneLoading: false,
  isMatching: false,
  isUpdatingFilteredTracks: false,
  filteredTracks: [],
  isLoading: false,
  loadingStatus: "",
  showMyTracks: false,
  showSlurpedTracks: false,
  showTutorial: false,
  slurpCounterCurrent: 0,
  slurpCounterTarget: 0,
  slurpedTracks: [],
  spotifyPlaylists: [],
  spotifyTracks: [],
  spotifyUserIds: [],
  unmatchedTracks: [],
  youtubePlaylists: [],
  youtubeTracks: []
} as SlugifyState;
export default (state = { ...initialState }, action: AnyAction) => {
  switch (action.type) {
    case SpotifyActionType.CLEAN_SPOTIFY_PLAYLIST:
      return {
        ...state,
        spotifyTracks: state.spotifyTracks.filter((item) => {
          return !action.payload.includes(item.track.id);
        })
      };
    case SlugifyActionType.CONNECT_APPLE_MUSIC_POST_INIT_START:
      return { ...state, isLoading: true };
    case SlugifyActionType.CONNECT_APPLE_MUSIC_POST_INIT_SUCCESS:
      return { ...state, isLoading: false };
    case SlugifyActionType.CREATE_NEW_SPOTIFY_SLUGIFY_PLAYLIST:
      return {
        ...state,
        spotifyPlaylists: uniqBy(
          [...state.spotifyPlaylists, ...action.payload],
          "id"
        )
      };
    case SlugifyActionType.GET_MUSIC_STREAM_IDS_SUCCESS:
      return {
        ...state,
        appleMusicPlaylists: uniq(
          state.appleMusicPlaylists.concat(action.payload.appleMusicPlaylists)
        ),
        spotifyUserIds: uniq([
          ...state.spotifyUserIds,
          ...action.payload.spotifyUserIds
        ])
      };
    case SlugifyActionType.GET_ALL_TRACKS_FROM_SPOTIFY_PLAYLISTS_SUCCESS:
      return {
        ...state,
        spotifyTracks: uniqBy(
          state.spotifyTracks.concat(action.payload),
          "track.id"
        )
      };
    case SpotifyActionType.GET_MATCHING_SPOTIFY_TRACKS_SUCCESS:
      const unmatchedTracks = action.payload.filter(
        (item: SpotifyMatchedTrack | SpotifyUnmatchedTrack) =>
          item.trackType === SlugifyTrackType.SPOTIFY_UNMATCHED_FROM_APPLE_MUSIC
      );
      const matchedTracks = action.payload.filter(
        (item: SpotifyMatchedTrack | SpotifyUnmatchedTrack) =>
          item.trackType ===
          SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC
      );
      return {
        ...state,
        spotifyTracks: uniqBy(
          state.spotifyTracks.concat(matchedTracks),
          "track.id"
        ),
        unmatchedTracks: uniqBy(
          state.unmatchedTracks.concat(unmatchedTracks),
          "appleMusicInfo.appleMusicId"
        )
      };
    case SlugifyActionType.GET_ALL_SPOTIFY_USERS_PLAYLISTS_SUCCESS:
      return { ...state, spotifyPlaylists: [...action.payload] };

    case YouTubeActionType.GET_ALL_YOUTUBE_PLAYLISTS_SUCCESS:
      return {
        ...state,
        youtubePlaylists: [...state.youtubePlaylists, ...action.payload]
      };

    case YouTubeActionType.GET_TRACKS_FROM_YOUTUBE_SLUGIFY_PLAYLIST_SUCCESS:
    case YouTubeActionType.SET_YOUTUBE_VIDEOS:
      return {
        ...state,
        youtubeTracks: uniqBy([...state.youtubeTracks, ...action.payload], "id")
      };
    case "HANDLE_USER_ALERT_SUCCESS":
      return { ...state, alert: { ...action.payload } };
    case "REMOVE_APPLE_MUSIC_USER_DATA_SUCCESS":
      return {
        ...state,
        appleMusicPlaylists: state.appleMusicPlaylists.filter(
          (item) => item !== action.payload
        )
      };

    case SlugifyActionType.SET_SLURP_COUNTER:
      return {
        ...state,
        slurpCounterCurrent: action.payload.current,
        slurpCounterTarget: action.payload.target
      };
    case SlugifyActionType.SET_SLURP_COUNTER_CURRENT:
      return { ...state, slurpCounterCurrent: { ...action.payload } };
    case SlugifyActionType.SET_SLURP_COUNTER_TARGET:
      return { ...state, slurpCounterTarget: { ...action.payload } };
    case SlugifyActionType.RESET_SLURP_COUNTER:
      return { ...state, slurpCounterCurrent: 0, slurpCounterTarget: 0 };
    case "SET_SLUGIFY_ALERT":
      return { ...state, alert: { ...action.payload } };
    case "RESET_SLUGIFY_ALERT":
      return { ...state, alert: {} };

    case "RESET_FILTERED_TRACKS":
      return { ...state, filteredTracks: [] };

    case "RESET_SPOTIFY_TRACKS":
      return { ...state, spotifyTracks: [], unmatchedTracks: [] };
    case "SAVE_APPLE_MUSIC_USER_DATA_SUCCESS":
      return {
        ...state,
        appleMusicPlaylists: uniq(
          state.appleMusicPlaylists.concat([action.payload])
        )
      };
    case UserActionType.SET_USER_DATA:
      return {
        ...state,
        appleMusicPlaylists: action.payload.appleMusicSlugifyPlaylist
          ? compact(
              uniq([
                ...state.appleMusicPlaylists,
                action.payload.appleMusicSlugifyPlaylist
              ])
            )
          : state.appleMusicPlaylists,
        spotifyUserIds:
          action.payload.spotifyUserData && action.payload.spotifyUserData.id
            ? compact(
                uniq([
                  ...state.spotifyUserIds,
                  action.payload.spotifyUserData.id
                ])
              )
            : state.spotifyUserIds
      };
    case "CREATE_APPLE_MUSIC_SLUGIFY_PLAYLIST_SUCCESS":
      return {
        ...state,
        appleMusicPlaylists: uniqBy(
          [
            ...state.appleMusicPlaylists.filter(
              (item) => item !== action.payload.id
            ),
            action.payload.id
          ],
          "id"
        )
      };
    case "CREATE_SPOTIFY_SLUGIFY_PLAYLIST_SUCCESS":
      return {
        ...state,
        spotifyPlaylists: [
          ...state.spotifyPlaylists.filter(
            (item) => item.id !== action.payload.id
          ),
          action.payload
        ]
      };
    case "CREATE_SPOTIFY_SLUGIFY_PLAYLIST_ALERT":
      return { ...state, alert: action.payload };
    case "SAVE_APPLE_MUSIC_USER_DATA_SUCCESS_ALERT":
      return { ...state, alert: action.payload };
    case "SLUGIFY_INIT_START":
      return { ...state, isInitializing: true };
    case "SLUGIFY_INIT_SUCCESS":
      return { ...state, isInitializing: false };
    case "SLUGIFY_LOADING_FAIL":
      return { ...state, isLoading: false };
    case "SLUGIFY_LOADING_START":
      return { ...state, isLoading: true };
    case "SLUGIFY_LOADING_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isDoneLoading: true,
        loadingStatus: "Ready to rock!!"
      };
    case "TOGGLE_UNMATCHED_SPOTIFY_TRACK_DELETED_SUCCESS":
      state.unmatchedTracks.map((item) => {
        if (item.appleMusicInfo.appleMusicId === action.payload.trackId) {
          item.isDeleted ? (item.isDeleted = false) : (item.isDeleted = true);
          return item;
        } else {
          return item;
        }
      });
      return { ...state, isLoading: false };
    case "TOGGLE_UNMATCHED_SPOTIFY_TRACK_FOUND_SUCCESS":
      state.unmatchedTracks.map((item) => {
        if (item.appleMusicInfo.appleMusicId === action.payload.trackId) {
          item.isFound ? (item.isFound = false) : (item.isFound = true);
          return item;
        } else {
          return item;
        }
      });
      return { ...state, isLoading: false };
    case "UPDATE_ADD_DELETE_FRIEND_STATUS":
      return {
        ...state,
        addDeleteFriendStatus: action.payload
      };
    case "UPDATE_FILTERED_TRACKS_START":
      return {
        ...state,
        isUpdatingFilteredTracks: true
      };
    case SlugifyActionType.UPDATE_FILTERED_TRACKS_SUCCESS:
    case SlugifyActionType.UPDATE_TRACK_SLURP_STATUSES_SUCCESS:
      return {
        ...state,
        isUpdatingFilteredTracks: false,
        filteredTracks: action.payload
      };
    case "UPDATE_SHOW_MY_TRACKS":
      return {
        ...state,
        showMyTracks: action.payload
      };
    case "UPDATE_SHOW_SLURPED_TRACKS":
      return {
        ...state,
        showSlurpedTracks: action.payload
      };
    case SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: action.payload
      };
    case SlugifyActionType.GET_SLURPED_TRACKS_SUCCESS:
    case "SET_SLURPED_TRACKS":
      return { ...state, slurpedTracks: action.payload };
    case "ADD_SLURPED_TRACK":
      return {
        ...state,
        slurpedTracks: [...state.slurpedTracks, action.payload]
      };
    case "DELETE_SLURPED_TRACK":
      return {
        ...state,
        slurpedTracks: state.slurpedTracks.filter(
          (item) => item.id !== action.payload.id
        )
      };
    case "DELETE_STALE_SLUGIFY_SLURPED_TRACKS_SUCCESS":
      return {
        ...state,
        slurpedTracks: state.slurpedTracks.filter(
          (item) => !action.payload.includes(item.id)
        )
      };
    case "UPDATE_SLURPED_TRACK":
      return {
        ...state,
        slurpedTracks: state.slurpedTracks.map((item) => {
          if (item.id === action.payload.id) {
            // TODO:  This is a hack because, for some reason, owner is lost when receiving a subscription
            return { ...action.payload, owner: item.owner };
            // return action.payload
          } else {
            return item;
          }
        })
      };
    case "UPDATE_TUTORIAL_STATUS":
      return {
        ...state,
        showTutorial: action.payload
      };
    default:
      return state;
  }
};
