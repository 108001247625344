import { UserActionType } from "../types";

const initialState = {
  appleMusicSlugifyPlaylist: null,
  autoSlurpFriends: [],
  expoPushTokenData: {},
  homeMenu: "slugify",
  friends: [],
  myFriendsSharedPlaylists: [],
  myFriendsPublicPlaylists: [],
  publicPlaylists: [],
  sharedPlaylists: [],
  spotifyTokenData: {},
  spotifyUserData: {},
  youTubeUserData: {},
  screenAppearance: "Automatic",
  slugifyMobileSlurpDestination: {}
  // watchedSlugTubeVids: []
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    // case "ADD_NEW_FRIEND_SUCCESS":
    //   return {
    //     ...state,
    //     friends: [
    //       ...state.friends.filter(
    //         item => item.username !== action.payload.username
    //       ),
    //       action.payload
    //     ]
    //   };

    case "GET_SLUGIFY_PUBLIC_PLAYLIST_SUCCESS":
      if (action.payload === "appleMusic") {
        return {
          ...state,
          appleMusicSlugifyPlaylist: action.payload.publicSlugifyPlaylist
        };
      } else {
        return {
          ...state
        };
      }
    // case "GET_SPOTIFY_USER_DATA_SUCCESS":
    //   return {
    //     ...state,
    //     spotifyUserData: {
    //       ...state.spotifyUserData,
    //       ...action.payload
    //     }
    //   };
    // case "GET_SPOTIFY_TOKEN_DATA_TO_USER_SUCCESS":
    //   return {
    //     ...state,
    //     spotifyTokenData: { ...state.spotifyTokenData, ...action.payload }
    //   };
    // case "MARK_VIDS_WATCHED_SUCCESS":
    //   return {
    //     ...state,
    //     watchedSlugTubeVids: [...action.payload]
    //   };
    // case "MARK_VIDS_UNWATCHED_SUCCESS":
    //   return {
    //     ...state,
    //     watchedSlugTubeVids: [...action.payload]
    //   };
    case "REFRESH_SPOTIFY_TOKEN_SUCCESS":
      return {
        ...state,
        spotifyTokenData: { ...state.spotifyTokenData, ...action.payload }
      };
    // case "REMOVE_SPOTIFY_TOKEN_DATA_FROM_USER":
    //   return {
    //     ...state,
    //     ...action.payload
    //   };
    case "REMOVE_APPLE_MUSIC_USER_DATA_SUCCESS":
      return {
        ...state,
        appleMusicSlugifyPlaylist: null
      };
    // case "REMOVE_FRIEND":
    //   return {
    //     ...state,
    //     friends: state.friends.filter(
    //       friend => friend.username !== action.payload
    //     )
    //   };
    case "SAVE_APPLE_MUSIC_USER_DATA_SUCCESS":
      return {
        ...state,
        appleMusicSlugifyplaylist: action.payload
      };
    // case "UPDATE_FRIENDS_IN_DB_SUCCESS":
    //   return {
    //     ...state,
    //     friends: [...state.friends, ...action.payload]
    //   };
    // case "UPDATE_USER_SUCCESS":
    //   console.warn("state")
    //   return {
    //     // ...state,
    //     ...action.payload
    //   };
    // graphql
    // case "SET_FRIENDS":
    //   return {
    //     ...state,
    //     friends: {
    //       items: action.payload.items,
    //       nextToken: state.friends.nextToken
    //     }
    //   };
    case "ADD_FRIEND":
    case "ADD_FRIEND_SUCCESS":
      return {
        ...state,
        friends: [...state.friends, action.payload]
      };
    case "DELETE_FRIEND":
    case "DELETE_FRIEND_SUCCESS":
      return {
        ...state,
        friends: state.friends.filter((item) => {
          return item.id !== action.payload.id;
        })
      };
    case "UPDATE_MY_FRIENDS_PUBLIC_PLAYLISTS":
      return {
        ...state,
        myFriendsPublicPlaylists: action.payload
      };
    case "UPDATE_MY_FRIENDS_SHARED_PLAYLISTS":
      return {
        ...state,
        myFriendsSharedPlaylists: action.payload
      };
    case "UPDATE_FRIEND":
      return {
        ...state,
        friends: state.friends.map((item) => {
          if (item.id === action.payload.id) {
            // TODO:  This is a hack because, for some reason, owner is lost when receiving a subscription
            // return { ...action.payload, owner: item.owner };
            return action.payload;
          } else {
            return item;
          }
        })
      };
    case "GET_SPOTIFY_USER_DATA_SUCCESS":
    case UserActionType.SET_USER_DATA:
    case UserActionType.GET_SPOTIFY_USER_DATA_SUCCESS:
    case UserActionType.UPDATE_USER:
    case UserActionType.UPDATE_USER_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
