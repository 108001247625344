/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id:
    "us-west-2:16deb3c5-115c-418f-96d1-1deae5aaf6b6",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_MInuA3y4i",
  aws_user_pools_web_client_id: "120cj0tr71a7d5q60tbog1cuvl",
  oauth: {
    domain: "auth.abts.io",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    // make sure the trailing slashes match that here:
    // https://us-west-2.console.aws.amazon.com/cognito/v2/idp/user-pools/us-west-2_MInuA3y4i/app-integration/clients/120cj0tr71a7d5q60tbog1cuvl/edit/hosted-ui-settings?region=us-west-2
    redirectSignIn: "https://slugify.abts.io/auth-callback/",
    redirectSignOut: "https://slugify.abts.io/",
    responseType: "code"
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: ["GOOGLE", "APPLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_dynamodb_all_tables_region: "us-west-2",
  aws_dynamodb_table_schemas: [
    {
      tableName: "SlugBucketSongList-prod",
      region: "us-west-2"
    },
    {
      tableName: "SlugBucketUsers-prod",
      region: "us-west-2"
    },
    {
      tableName: "slugTube-prod",
      region: "us-west-2"
    }
  ],
  aws_user_files_s3_bucket: "slugbucket-s3-storage161015-prod",
  aws_user_files_s3_bucket_region: "us-west-2",
  aws_cloud_logic_custom: [
    {
      name: "SlugBucketAPI",
      endpoint: "https://y923frwd9f.execute-api.us-west-2.amazonaws.com/prod",
      region: "us-west-2"
    }
  ],
  aws_appsync_graphqlEndpoint:
    "https://73kdwdydq5hd3bm23f63po2c7u.appsync-api.us-west-2.amazonaws.com/graphql",
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
};

export default awsmobile;
