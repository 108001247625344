/* eslint-disable @typescript-eslint/no-explicit-any */
import SlugBucketAppleMusicModal from "./SlugBucketAppleMusicModal";
import SlugifyAppleMusicItem from "./SlugifyAppleMusicItem";
import SlugifyAppleMusicItemWithWidgets from "./SlugifyAppleMusicItemWithWidgets";
import SlugifyAppleMusicUnmatchedItem from "./SlugifyAppleMusicUnmatchedItem";
import { AppleMusicTrack, SelectedPlaylist, SlugifyTrackType } from "../types";
import { useContext } from "react";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface Props {
  filteredTracks: any[];
  handleAddAllToAppleMusic: () => void;
  myNonForgottenUnmatchedTracks: any[];
  renderAddButtonTitle: () => string;
  renderPlaylistOptions: () => JSX.Element[];
  selectedPlaylist: SelectedPlaylist | undefined;
  setSelectedPlaylist: (p: SelectedPlaylist) => void;
  showMyTracks: boolean;
  showSlurpedTracks: boolean;
  appleMusicTracks: AppleMusicTrack[];
}
export default function SlugifyAppleMusic({
  // currentSpotifyUserData,
  filteredTracks,
  handleAddAllToAppleMusic,
  myNonForgottenUnmatchedTracks,
  renderAddButtonTitle,
  renderPlaylistOptions,
  selectedPlaylist,
  setSelectedPlaylist,
  showMyTracks,
  showSlurpedTracks,
  appleMusicTracks
}: Props) {
  const { isDark } = useContext(ThemeContext);

  const renderTracks = () => {
    if (appleMusicTracks.length) {
      const appleMusicTracks = filteredTracks.filter(
        (track) =>
          track.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE ||
          track.trackType ===
            SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY
      );

      return appleMusicTracks.map((item) => {
        return appleMusicTracks.length <= 10 ? (
          <SlugifyAppleMusicItemWithWidgets
            item={item}
            key={item.id}
            showMyTracks={showMyTracks}
            showSlurpedTracks={showSlurpedTracks}
          />
        ) : (
          <SlugifyAppleMusicItem
            item={item}
            key={item.id}
            showButton
            showMyTracks={showMyTracks}
            showSlurpedTracks={showSlurpedTracks}
          />
        );
      });
    }
  };

  const renderUnmatchedTracks = () => {
    if (myNonForgottenUnmatchedTracks.length) {
      return myNonForgottenUnmatchedTracks.map((item) => {
        return (
          <SlugifyAppleMusicUnmatchedItem key={item.spotifyId} item={item} />
        );
      });
    }
  };

  const popover = (
    <Popover id="unmatched-track-popover">
      <Popover.Header>Unmatched Tracks</Popover.Header>
      <Popover.Body>
        <p>The following tracks could not be matched for some reason.</p>
        <p>
          You can try searching manually for them or just forget about them.
        </p>
      </Popover.Body>
    </Popover>
  );
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <SlugBucketAppleMusicModal />
      <div
        className="d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeIn w-100"
        style={{ maxWidth: 800 }}
      >
        <ul className="list-unstyled mt-3 w-100" style={{ border: "none" }}>
          {renderTracks()}
        </ul>
        {myNonForgottenUnmatchedTracks.length ? (
          <div className="d-flex flex-column w-100">
            <h5 className="d-flex align-items-center">
              <span className="me-2">Unmatched Tracks</span>
              {/* eslint-disable-next-line */}
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={popover}
                rootClose
              >
                <i
                  className="icon-info slug-icon text-info"
                  style={{ cursor: "pointer" }}
                />
              </OverlayTrigger>
            </h5>
            <ul className="list-group w-100" style={{ border: "none" }}>
              {renderUnmatchedTracks()}
            </ul>
          </div>
        ) : null}
        {filteredTracks.length && !showMyTracks && !showSlurpedTracks ? (
          <div className="d-flex flex-column w-100 mt-3">
            <div className="form-group mb-0">
              <select
                className="form-select"
                id="selected"
                onChange={(e: any) => {
                  const index = e.nativeEvent.target.selectedIndex;
                  const label = e.nativeEvent.target[index].text;
                  const value = e.target.value;
                  setSelectedPlaylist({ name: label, id: value });
                }}
                // onChange={(e) => setSelectedPlaylist(e.target.value)}
              >
                <option value="">Select a Playlist</option>
                {renderPlaylistOptions()}
              </select>
            </div>
            <button
              className={`d-flex justify-content-center align-items-center btn btn-${
                isDark ? "outline-primary" : "primary"
              } mt-2 w-100`}
              disabled={
                !selectedPlaylist ||
                !filteredTracks.filter(
                  (item) => item.slurpStatus === "not my track"
                ).length
              }
              style={{
                cursor: `${
                  !filteredTracks.filter(
                    (item) => item.slurpStatus === "not my track"
                  ).length || !selectedPlaylist
                    ? "not-allowed"
                    : "pointer"
                }`
              }}
              onClick={() => {
                handleAddAllToAppleMusic();
              }}
              title={renderAddButtonTitle()}
            >
              <span role="img" aria-label="a cute little snail">
                🐌
              </span>
              <span className="d-flex d-sm-none ms-2">Slurp All Tracks</span>
              <span className="d-none d-sm-flex ms-2">Slurp All Tracks</span>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
