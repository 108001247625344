export default (state = {}, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return {
        ...state,
        ...action.payload
      };
    // case "SIGN_OUT":
    //   return {};
    case "SET_AUTH_USER_DATA":
      return {
        ...state,
        ...action.payload
      };
    case "UPDATE_USER_NICKNAME_SUCCESS":
      return {
        ...state,
        attributes: { ...state.attributes, nickname: action.payload }
      };
    case "UPDATE_USER_ATTRIBUTES":
      return {
        ...state,
        attributes: { ...state.attributes, ...action.payload }
      };
    default:
      return state;
  }
};
