import { ReactElement } from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";

interface Props {
  emoji: string;
  color: string;
  title: string;
  text: ReactElement<any, any>;
}
const OPACITY = 0.7;
export default function Feature({ color, emoji, text, title }: Props) {
  return (
    <>
      <div
        className="d-flex d-sm-none flex-column align-items-center justify-content-apart mt-2 mx-2"
        style={{ minWidth: 300, maxWidth: 300, minHeight: 200 }}
      >
        <Icon color={color} emoji={emoji} />
        <div
          className="lead mt-1 font-weight-bold text-light mt-3 mt-sm-0"
          style={{ opacity: OPACITY }}
        >
          {title}
        </div>

        {title === "Learn More" ? (
          <div className="text-light text-center" style={{ opacity: OPACITY }}>
            Check out the{" "}
            <Link className="force-bold" to={"/tutorial"}>
              {/* <span style={{ fontWeight: "600 !important" }}>Slugify Tutorial</span> */}
              Slugify Tutorial
            </Link>{" "}
            to get a better understanding of what slugify can do for you!
          </div>
        ) : (
          <div
            className="mt-1 text-light text-center mb-3 mb-sm-0"
            style={{ opacity: OPACITY }}
          >
            {text}
          </div>
        )}
      </div>
      <div
        className="d-none d-sm-flex flex-column justify-content-apart mt-2 mx-2"
        style={{ minWidth: 300, maxWidth: 300, minHeight: 200 }}
      >
        <Icon color={color} emoji={emoji} />
        <div
          className="lead mt-1 font-weight-bold text-light"
          style={{ opacity: OPACITY }}
        >
          {title}
        </div>

        {title === "Learn More" ? (
          <div className="text-light" style={{ opacity: OPACITY }}>
            Check out the <Link className="force-bold" to={"/tutorial"}>Slugify Tutorial</Link> to get
            a better understanding of what Slugify can do for you!
          </div>
        ) : (
          <div className="mt-1 text-light" style={{ opacity: OPACITY }}>
            {text}
          </div>
        )}
      </div>
    </>
  );
}
