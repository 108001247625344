import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import {
  getSpotifyToken,
  slugifyPostInit,
  signIn,
  slugifyInit
} from "../actions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { withRouter, useHistory } from "react-router-dom";
import StatusMessage from "./shared/StatusMessage";
// import { useUserSubscription } from "../hooks/useUserSubscription";

const SpotifyCallback = () => {
  // useUserSubscription();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    slugify: { isLoading, loadingStatus, isInitializing }
  } = useAppSelector((state) => state);

  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [state, setState] = useState("");
  const [status, setStatus] = useState("");
  const [isTryingAgain, setIsTryingAgain] = useState(false);
  const [isGoingHome, setIsGoingHome] = useState(false);

  useEffect(() => {
    // const params = qs.parse(document.location.search);
    const paramsString = window.location.search;
    const params = new URLSearchParams(paramsString);
    console.log("%cPARAMS in Spotify Callback", "color:orange", params);
    const paramsError = params.get("error");
    const paramsCode = params.get("code");
    const paramsState = params.get("state");
    if (paramsError) {
      setError(paramsError);
      setStatus("error");
    }
    if (paramsCode) {
      setCode(paramsCode);
      setStatus("working");
    }
    if (paramsState) {
      setState(paramsState);
    }
  }, [history, error, code, state]);

  useEffect(() => {
    async function init() {
      try {
        console.log("%cGot code:", "color:orange", code);
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const payload = {
          email: cognitoUser.attributes.email,
          username: cognitoUser.username,
          userId: cognitoUser.attributes.sub,
          attributes: { ...cognitoUser.attributes }
        };
        console.log("%cAttempting to sign in!", "color:orange");
        await dispatch(signIn(payload));
        console.log("%cAttempting to get Spotify token!", "color:orange");
        await dispatch(getSpotifyToken(code, setError, setStatus));
        setStatus("done");
        // here
      } catch (error) {
        if (error === "not authenticated") {
          console.warn("👮🏽‍♀️ User not signed in.  Redirecting to sign-in page.");
        } else {
          console.warn("‍💣 Error on Spotify Callback page: ", error);
        }
        console.warn("Error in Spotify Callback", error);
      }
    }
    if (code) {
      // Auth.currentAuthenticatedUser()
      //   .catch((error) => {
      //     if (error === "not authenticated") {
      //       console.warn(
      //         "👮🏽‍♀️ User not signed in.  Redirecting to sign-in page."
      //       );
      //     } else {
      //       console.warn("‍💣 Error on Spotify Callback page: ", error);
      //     }
      //     // history.push("/");
      //   })
      //   .then((cognitoUser) => {
      //     const payload = {
      //       email: cognitoUser.attributes.email,
      //       username: cognitoUser.username,
      //       userId: cognitoUser.attributes.sub,
      //       attributes: { ...cognitoUser.attributes }
      //     };
      //     return dispatch(signIn(payload));
      //   })
      //   .then(() => dispatch(getSpotifyToken(code, setError, setStatus)))
      //   .catch((error) => {
      //     console.warn(error);
      //     setError("%cCould not sign in:", "color:yellow", error.message);
      //   })
      //   .then(() => setStatus("done"))
      //   .catch((error) => {
      //     console.warn(error);
      //     setError(error.message);
      //   });
      if (code) {
        init();
      } else {
        console.log("%cNo code yet in Spotify callback", "color:cyan");
      }
    }
  }, [code, dispatch, history]);

  useEffect(() => {
    async function handleDone() {
      try {
        await dispatch(slugifyInit());
        await dispatch(slugifyPostInit());
      } catch (error) {
        console.warn(error);
      }
    }
    if (status === "done") {
      handleDone();
    } else {
      console.log("%cNot done yet in Spotify callback", "color:cyan");
    }
  }, [dispatch, status]);

  const renderBody = () => {
    if (status === "working" && !error) {
      return (
        <div className="d-flex flex-column align-items-center text-center">
          <StatusMessage
            subtitleAnimation="pulse"
            title="Calling Spotify"
            emoticon="📡"
            size="sm"
            subtitle="Wait for it..."
            isInfinite
          />
        </div>
      );
    }
    if (error) {
      console.warn("Error: ", error);
      return (
        <div className="d-flex flex-column align-items-center text-center animate__animated animate__fadeIn">
          <StatusMessage
            title="Something's not right"
            emoticon="💣"
            size="sm"
            subtitle="Slugify could not connect to Spotify"
          />
          <div className="lead status-subtitle-sm">
            The problem is related to this error message:{" "}
            <span className="text-danger">{error.message || error}</span>
          </div>
          <div className="d-flex my-5">
            <button
              className="btn btn-info me-2"
              onClick={async () => {
                setIsTryingAgain(true);
                await dispatch(slugifyInit());
                history.push("/settings");
              }}
              style={{ width: "148px" }}
            >
              {isTryingAgain ? (
                <div
                  className="spinner-grow spinner-grow-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <span>Try Again</span>
              )}
            </button>
            <button
              className="btn btn-primary ms-2"
              onClick={async () => {
                setIsGoingHome(true);
                await dispatch(slugifyInit());
                history.push("/home");
              }}
              style={{ width: "148px" }}
            >
              {isGoingHome ? (
                <div
                  className="spinner-grow spinner-grow-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <span>Go Home</span>
              )}
            </button>
          </div>
        </div>
      );
    } else if ((isLoading || isInitializing) && status === "done") {
      return (
        <div className="d-flex flex-column align-items-center text-center animate__animated animate__fadeIn">
          <StatusMessage
            title="Working on it!"
            emoticon="👷🏽‍♀️"
            size="sm"
            subtitle={loadingStatus}
          />
        </div>
      );
    }
    return (
      <div className="d-flex flex-column align-items-center text-center animate__animated animate__fadeIn">
        <StatusMessage
          title="Success"
          emoticon="🎉"
          size="sm"
          subtitle="You have successfully connected Slugify to your Spotify Account"
        />
        <br />
        <div className="lead status-subtitle-sm">
          You can remove this access at any time on{" "}
          <strong>
            <a
              href="http://spotify.com/account/apps/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Spotify
            </a>
          </strong>
          .
        </div>
        <button
          className="btn btn-sm btn-outline-primary mt-4"
          onClick={() => history.push("/home")}
        >
          Continue
        </button>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column container align-items-center justify-content-center vh-100">
      {status ? renderBody() : null}
    </div>
  );
};

export default withRouter(SpotifyCallback);
