import { useState } from "react";
import { Auth } from "aws-amplify";
import { confirmationSchema } from "../validators";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAppDispatch } from "../hooks";
import Button from "./shared/Button";
import StatusMessage from "./shared/StatusMessage";

export const SignUpConfirmationForm = ({
  confirmationUsername,
  setConfirmationUsername,
  confirmationEmail,
  setConfirmationEmail,
  isCodeSent,
  isCodeResent,
  setIsCodeResent,
  formStatus,
  setFormAction,
  setFormStatus
}) => {
  const dispatch = useAppDispatch();

  const [isConfirming, setIsConfirming] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const handleResendCode = async () => {
    try {
      setIsResending(true);
      await Auth.resendSignUp(confirmationUsername);
      setIsCodeResent(true);
      setIsResending(false);
    } catch (error) {
      setIsResending(false);
      dispatch({ type: "COGNITO_USER_SIGNUP_FAIL", payload: error });
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      {formStatus === "signUpSuccess" ? (
        <>
          <StatusMessage
            title="Success"
            subtitle="You have signed up!"
            emoticon="🎉"
            size="sm"
          />
          <Button
            btnText="Sign in with New Account"
            className="my-3"
            color="success"
            fn={() => {
              setFormAction("signIn");
              setConfirmationUsername("");
              setConfirmationEmail("");
            }}
          />
        </>
      ) : (
        <>
          <div className="d-flex flex-column align-items-center">
            <div className="display">
              <span role="img" aria-label="mailbox" className="status-emoji-xs">
                📬
              </span>
            </div>
            <p className={"lead mb-2 status-title-xs"}>Check your inbox</p>
            {confirmationEmail ? (
              <div className="d-flex flex-column align-items-center">
                <p className="lead status-subtitle-xs mb-1">
                  An email has been sent to:
                </p>
                <p className="lead status-subtitle-xs font-weight-bold mb-3">
                  {confirmationEmail}
                </p>
              </div>
            ) : null}
          </div>
          <Formik
            initialValues={{
              code: "",
              username: confirmationUsername
            }}
            validationSchema={confirmationSchema}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              try {
                setSubmitting(true);
                const data = await Auth.confirmSignUp(
                  values.username,
                  values.code
                );
                if (data === "SUCCESS") {
                  setFormStatus("signUpSuccess");
                }
                setIsConfirming(false);
              } catch (error) {
                setIsConfirming(false);
                dispatch({ type: "COGNITO_USER_SIGNUP_FAIL", payload: error });
                console.warn(error.response || error);
                setFieldError("code", "Invalid code");
              }
            }}
          >
            {({ errors, values }) => {
              return (
                <Form className="d-flex flex-column align-items-center mb-3">
                  <div className="d-flex flex-column form-group w-100">
                    <label htmlFor="codeInput">Verification Code</label>
                    <Field
                      aria-describedby="codeHelp"
                      className="form-control"
                      id="codeInput"
                      name="code"
                      placeholder="123456"
                      type="text"
                    />
                    <div className="formikErrorMessage">
                      <ErrorMessage
                        name="code"
                        id="codeHelp"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <Button
                    btnText="Confirm Signup"
                    className="mb-3"
                    color="secondary"
                    isDisabled={!values.code || errors.code}
                    isSpinning={isConfirming}
                    spinnerReplacesText
                    spinnerType="beat"
                    spinnerSize={10}
                    spinnerColor="light"
                    fn={() => {
                      setIsConfirming(true);
                    }}
                    // icon="password"
                    height={44}
                    type="submit"
                    width={240}
                  />
                  {isCodeSent ? (
                    <Button
                      btnText={<small>Resend Verification Code</small>}
                      // className="mb-2"
                      color="dark"
                      isSpinning={isResending}
                      spinnerColor="secondary"
                      spinnerReplacesText
                      spinnerType="propagate"
                      spinnerSize={12}
                      link
                      fn={() => {
                        handleResendCode();
                      }}
                      width={200}
                    />
                  ) : isCodeResent ? (
                    <small className="text-dark">Code has been resent</small>
                  ) : null}
                  <Button
                    btnText={<small>Back to Sign-in</small>}
                    className="mb-0 pb-0"
                    color="dark"
                    link
                    fn={() => {
                      setConfirmationUsername("");
                      setConfirmationEmail("");
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
};
