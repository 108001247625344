import { AppThunk } from "../store/store";
import Cookies from "js-cookie";
import { CookiesActionType, CookiesConsentState } from "../types";
import config from "../config/config.json";

export const acceptAllCookies = (): AppThunk => async (dispatch) => {
  try {
    dispatch({ type: CookiesActionType.ACCEPT_ALL_COOKIES_START });
    const updatedState = {
      isAdvertisingEnabled: true,
      isFunctionalEnabled: true,
      isPerformanceEnabled: true
    };
    setCookie(updatedState);
    dispatch({
      type: CookiesActionType.SET_ALL_COOKIE_CONSENT_STATES,
      payload: updatedState
    });
    dispatch({ type: CookiesActionType.ACCEPT_ALL_COOKIES_SUCCESS });
    return Promise.resolve();
  } catch (error) {
    console.warn(error);
    dispatch({
      type: CookiesActionType.ACCEPT_ALL_COOKIES_FAIL,
      payload: error
    });
    return Promise.reject(error);
  }
};

// export async function handleAcceptAll() {
//   setInitialCookieState(updatedState);
//   setCookie(updatedState);
//   dispatch({
//     type: CookiesActionType.SET_COOKIES_MODAL_VISIBILITY,
//     payload: false
//   });
//   // setIsCookiesModalOpen(false);
// }

export const saveCookiesPreferences = (): AppThunk => (dispatch, getState) => {
  const state = getState().cookies.draftCookiesConsent;
  if (!state.isFunctionalEnabled) {
    Cookies.remove("slugify-color-theme");
  }
  setCookie(state);
  dispatch({
    type: CookiesActionType.SET_ALL_COOKIE_CONSENT_STATES,
    payload: state
  });
};

export function setCookie(updatedState: CookiesConsentState) {
  const consent = JSON.stringify(updatedState);
  Cookies.set(config.CONSENT_COOKIE_NAME, consent, {
    expires: 365,
    path: "/"
  });
}
