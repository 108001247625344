import { SlugbucketActionType } from "../types";

/* eslint-disable no-case-declarations */
const initialState = {
  artist: "",
  title: "",
  beenThereDoneThat: {
    artist: "",
    title: "",
    uploadedBy: "",
    file: undefined,
    updatedMetadata: undefined
  },
  dropzoneDisabled: false,
  error: { emoticon: "", message: "", title: "" },
  fileToDelete: "",
  isLoading: false,
  modalAction: "",
  searchResults: [],
  uploads: {},
  uploadCount: 0,
  uploadProgress: 0,
  uploadStatus: ""
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_PROGRESS_BAR":
      return {
        ...state,
        ...action.payload
      };
    case "GET_TRACKS_METADATA_FROM_DB_SUCCESS":
      return {
        ...state,
        songs: [...action.payload],
        uploadCount: 0,
        uploadProgress: 0,
        uploadStatus: "",
        dropzoneDisabled: false
      };
    case "REMOVE_SONG_FROM_SLUGBUCKET_SUCCESS":
      return {
        ...state,
        artist: "",
        fileToDelete: "",
        modalAction: "",
        s3FileList: state.s3FileList.filter(
          (file) => file.key !== action.payload
        ),
        songs: state.songs.filter((song) => song.s3FileName !== action.payload),
        title: ""
      };
    case "REMOVE_ALL_SONGS_FROM_SLUGBUCKET_SUCCESS":
      return {
        ...state,
        s3FileList: [],
        songs: []
      };
    case "FEED_THE_SLUG_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    case "GET_SLUG_BUCKET_SEARCH_RESULTS_SUCCESS":
      return {
        ...state,
        searchResults: [...action.payload]
      };
    case "GET_S3_FILE_LIST_START":
      return {
        ...state,
        isLoading: true
      };
    case "GET_S3_FILE_LIST_SUCCESS":
      return {
        ...state,
        s3FileList: [...action.payload.s3FileList],
        isLoading: false
      };
    case "LIKE_SONG_TOGGLE_SUCCESS":
      return {
        ...state,
        songs: state.songs.map((song) => {
          if (song.s3FileName === action.payload.s3FileName) {
            return { ...song, liked: action.payload };
          } else {
            return song;
          }
        })
      };
    case "PREP_THE_SLUG_FOR_FEEDING":
      return {
        ...state,
        ...action.payload
      };
    case "RESET_SLUG_BUCKET_PROGRESS":
      return {
        ...state,
        ...action.payload
      };
    case SlugbucketActionType.RESET_SLUG_BUCKET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: []
      };

    case "SET_DATA_FOR_DELETE_MODAL":
      return {
        ...state,
        ...action.payload
      };
    case "SET_BEEN_THERE_DONE_THAT":
      return {
        ...state,
        beenThereDoneThat: { ...action.payload }
      };
    case "REMOVE_BEEN_THERE_DONE_THAT":
      return {
        ...state,
        beenThereDoneThat: { ...initialState.beenThereDoneThat }
      };
    case "SET_MODAL_ACTION":
      return {
        ...state,
        modalAction: action.payload
      };
    case "SET_UPLOAD_ERROR":
      return {
        ...state,
        ...action.payload
      };
    case "SET_UPLOAD_STATUS":
      return {
        ...state,
        uploadStatus: action.payload
      };
    case "SET_UPLOAD_PROGRESS_AND_STATUS":
      const uploadsMap = {
        ...state.uploads,
        [action.payload.key]: {
          loaded: action.payload.loaded,
          total: action.payload.total
        }
      };

      const uploadEntries = Object.values(uploadsMap);
      const uploadTotal = uploadEntries
        .map((item) => item.total)
        .reduce((a, c) => a + c, 0);
      const uploadLoaded = uploadEntries
        .map((item) => item.loaded)
        .reduce((a, c) => a + c, 0);
      const uploadProgress = (uploadLoaded / uploadTotal) * 100;
      const uploadCount =
        action.payload.loaded / action.payload.total === 1
          ? state.uploadCount + 1
          : state.uploadCount;
      return {
        ...state,
        uploads: { ...uploadsMap },
        uploadCount,
        uploadProgress,
        uploadStatus: uploadProgress
      };

    case "TOGGLE_SLUG_BUCKET_SEARCH_RESULTS_LIKE":
      console.log("LIKE:", action.payload);
      return {
        ...state,
        searchResults: state.searchResults.map((item) => {
          if (
            item.artist === action.payload.artist &&
            item.title === action.payload.title
          ) {
            return { ...item, liked: action.payload.liked };
          } else {
            return item;
          }
        })
      };

    default:
      return state;
  }
};
