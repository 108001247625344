import { useCallback } from "react";
import { getRandomRocker } from "../../functions";

export const TutorialPage6 = () => {
  const rocker = useCallback(getRandomRocker(), []);
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center text-center tutorial-page"
      style={{ height: "480px" }}
    >
      <div className="tutorial-emoji">{rocker}</div>
      <div className="tutorial-title">Enjoy the Music</div>
      <p className="tutorial-lead">
        Slugify brings people together through music across subscription
        boundaries.
      </p>
      <p className="tutorial-lead">We hope you dig it.</p>
    </div>
  );
};
