import { ThemeContext } from "../../contexts/ColorThemeProvider";
import { useContext } from "react";

interface Props {
  lightMoon?: boolean;
}
export default function DarkModeToggleButton({
  lightMoon
}: // theme,
// toggleTheme
Props) {
  const { isDark, toggleTheme } = useContext(ThemeContext);
  return (
    <button
      className="btn btn-link pe-0 text-decoration-none"
      onClick={() => toggleTheme()}
      style={{ textDecoration: "none" }}
    >
      {isDark ? (
        <i className="material-icons text-warning">light_mode</i>
      ) : (
        // <Sun color="var(--bs-warning-text)" />
        <i className={`material-icons text-${lightMoon ? "warning" : "dark"} `}>
          dark_mode
        </i>
        // <MoonStars color="var(--bs-info-text)" />
      )}
    </button>
  );
}
