import { compact, isNil, omitBy } from "lodash";
import { updateSlugifyUser } from "../actions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useHistory } from "react-router-dom";
import packageStatus from "../../package.json";
import StatusMessage from "./shared/StatusMessage";
import { SpotifyActionType } from "../types";
import { useContext } from "react";
import { ThemeContext } from "../contexts/ColorThemeProvider";

export default function AppStatus() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isDark } = useContext(ThemeContext);
  const {
    errors,
    newUser: { primaryMusicStream }
  } = useAppSelector((state) => state);

  const handleReconnectSpotify = async () => {
    await dispatch(
      updateSlugifyUser({ spotifyTokenData: {}, spotifyUserData: {} })
    );
    dispatch({
      type: SpotifyActionType.SET_SPOTIFY_CONNECTED_STATUS,
      payload: false
    });
    history.push("/");
  };

  const getErrorsArray = () => {
    const currentErrors = omitBy(errors, isNil);
    const errorsArray = Object.entries(currentErrors).map(([k, v]) => {
      if (v) {
        return {
          [k]: v
        };
      }
      return null;
    });
    return compact(errorsArray);
  };

  const renderErrors = () => {
    const errorsArray = getErrorsArray();
    return errorsArray.map((item, index) => {
      return (
        <li
          className="list-group-item d-flex justify-content-between"
          key={index}
        >
          <div>{Object.keys(item)[0].replace(/_/g, " ")}</div>
          <div>{Object.values(item)[0]}</div>
        </li>
      );
    });
  };

  return (
    <div
      className="d-flex flex-column align-items-center container text-center mb-5"
      style={{ maxWidth: 800 }}
    >
      <div className="slug-title mt-3 mt-sm-5">Slugify Status</div>
      {getErrorsArray().length ? (
        <>
          <div className="slug-subtitle mt-3">Errors</div>
          <ul className="list-group w-100 mt-3">{renderErrors()}</ul>
          <div className="slug-subtitle mt-3">Potential Fixes</div>
          <button
            className={`btn btn-${isDark ? "outline-danger" : "danger"} mt-3`}
            onClick={() => window.location.reload()}
            style={{ width: 275 }}
          >
            Reload Slugify
          </button>
          {primaryMusicStream === "spotify" ? (
            <button
              className={`btn btn-${
                isDark ? "outline-primary" : "primary"
              } mt-2`}
              onClick={() => handleReconnectSpotify()}
              style={{ width: 275 }}
            >
              Reconnect Spotify
            </button>
          ) : null}
        </>
      ) : (
        <StatusMessage
          title="All Systems Go"
          emoticon="🚀"
          subtitle="Everything appears to be operational"
        />
      )}
      <div className="slug-subtitle mt-3">Services</div>
      <div className="lead status-subtitle-sm mt-1" style={{ fontSize: 16 }}>
        Slugify is reliant on a a few cloud service providers. You can use the
        links below to check their status.
      </div>
      <div
        className="d-flex justify-content-center w-100 my-3"
        style={{ maxWidth: 640 }}
      >
        <a
          className="mx-3"
          href="https://health.aws.amazon.com/health/status"
          target="_blank"
          rel="noopener noreferrer"
        >
          AWS
        </a>
        <a
          className="mx-3"
          href="https://www.apple.com/support/systemstatus/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Apple
        </a>
        <a
          className="mx-3"
          href="https://status.spotify.dev/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spotify
        </a>
        <a
          className="mx-3"
          href="https://downdetector.com/status/youtube/"
          target="_blank"
          rel="noopener noreferrer"
        >
          YouTube
        </a>
      </div>
      <footer
        className="py-3 w-100 text-center bg-body-tertiary"
        style={{
          position: "fixed",
          left: 0,
          bottom: 0,
          backgroundColor: "#fff"
        }}
      >
        <small className="text-center text-body-secondary">
          Slugify v{packageStatus.version}
        </small>
      </footer>
    </div>
  );
}
