import { FormEvent, useContext, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { updateUserNickname } from "../actions";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { Spinner } from "./shared/Spinner";
import StatusMessage from "./shared/StatusMessage";

export const SetNickname = () => {
  const dispatch = useAppDispatch();
  const { isDark } = useContext(ThemeContext);
  const {
    auth: {
      username: currentUsername,
      attributes: { fullName, nickname: currentNickname }
    }
  } = useAppSelector((state) => state);

  const renderName = () => {
    if (currentNickname) {
      return currentNickname;
    }
    if (fullName) {
      return fullName.split(" ")[0];
    }
    return currentUsername;
  };

  const [error, setError] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [nickname, setNickname] = useState(renderName());

  const handleChange = (text: string) => {
    setError("");
    if (text.length && text.replace(/[^a-zA-Z]/g, "").length !== text.length) {
      setError("One word.  Letters only, please.");
    }
    setNickname(text.trim().replace(/[^a-zA-Z]/g, ""));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement> ) => {
    e.preventDefault();
    try {
      setIsSpinning(true);
      dispatch(updateUserNickname(nickname));
    } catch (error: any) {
      console.warn(error);
      setError(error.message);
      setIsSpinning(false);
    }
  };

  return (
    <div
      className="d-flex container flex-column align-items-center"
      style={{ maxWidth: 480 }}
    >
      <StatusMessage
        title="Hi, there!"
        emoticon="tipper"
        subtitle={
          nickname.length
            ? `May I call you, ${nickname ? nickname : fullName.split(" ")[0]}?`
            : "What's your preferred name?"
        }
        size="sm"
      />
      <form onSubmit={(e)=>handleSubmit(e)} className="w-100">
        <div className="form-group mb-0">
          <label className="col-form-label" htmlFor="username">
            What&apos;s your preferred name?
          </label>
          <input
            autoFocus
            autoComplete="nickname"
            className="form-control"
            id="nickname"
            name="nickname"
            onChange={(e) => handleChange(e.target.value)}
            placeholder={renderName()}
            type="text"
            value={nickname}
          />
          <small className="text-danger">{error ? error : ""}</small>
        </div>
        <div className="d-flex justify-content-center">
          <button
            type="submit"
            className={`d-flex align-items-center justify-content-center btn btn-${
              isDark ? "outline-primary" : "primary"
            } mt-2 w-100`}
          >
            {isSpinning ? (
              <div className="mx-0">
                <Spinner
                  size={18}
                  color={isDark ? "primary" : "light"}
                  type="puff"
                />
              </div>
            ) : (
              <i className="icon-save slug-icon me-2 mb-1" />
            )}
            <span>Save</span>
          </button>
        </div>
      </form>
    </div>
  );
};
