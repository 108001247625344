import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

export default function Footer() {
  const history = useHistory();

  return (
    <div
      className="d-flex w-100 fixed-bottom bg-dark"
      style={{ height: 56 }}
      // style={{ height: 56, backgroundColor: "#343a40" }}
      // style={{ height: 56, position: "absolute", bottom: 0 }}
    >
      <div className="container d-flex align-items-center justify-content-between w-100">
        <small className="text-light" style={{ opacity: .5 }}>&copy; ABTS Limited {dayjs().format("YYYY")}</small>
        <button
          className="btn btn-link text-primary text-decoration-none"
          onClick={() => history.push("/privacy-policy")}
        >
          <small>Privacy Policy</small>
        </button>
      </div>
    </div>
  );
}
