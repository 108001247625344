import { isEmptyObject, trimText } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import axios from "axios";
import { useEffect, useState } from "react";
import StatusMessage from "./shared/StatusMessage";

const SlugBucketSpotifyModal = () => {
  const dispatch = useDispatch();

  const currentSpotifyTokenData = useSelector(
    (state) => state.user.spotifyTokenData
  );

  const trackSearch = useSelector((state) => state.spotify.trackSearch);
  const playlists = useSelector((state) => state.spotify.myPlaylists);

  const [isLoading, setIsLoading] = useState(true);
  const [playlistsAffected, setPlaylistsAffected] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [tracksAddSuccess, setTracksAddSuccess] = useState(false);

  useEffect(() => {
    if (!isEmptyObject(trackSearch)) {
      setIsLoading(false);
      $("#slug-bucket-spotify-modal").modal("handleUpdate");
    }
  }, [trackSearch, setIsLoading]);

  const addTracksToPlaylist = async (playlistId) => {
    setPlaylistsAffected([...playlistsAffected, playlistId]);
    const uris = { uris: [] };
    selectedItems.forEach((trackId) => {
      uris.uris.push(`spotify:track:${trackId}`);
    });
    console.log("Adding tracks to playlist", uris);
    const options = {
      method: "POST",
      url: `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
      headers: {
        Authorization: "Bearer " + currentSpotifyTokenData.access_token,
        "Content-Type": "application/json"
      },
      data: { ...uris }
    };
    try {
      const { data } = await axios(options);
      console.log("Tracks added to playlist", data);
      setTracksAddSuccess(true);
      setSelectedItems([]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const cleanUpModal = () => {
      setTracksAddSuccess(false);
      setSelectedItems([]);
      setPlaylistsAffected([]);
      dispatch({ type: "DELETE_CURRENT_SPOTIFY_SEARCH_RESULTS" });
    };
    $("#slug-bucket-spotify-modal").on("hidden.bs.modal", () => {
      cleanUpModal();
    });
  }, [dispatch]);

  const renderPlaylists = () => {
    if (playlists.length) {
      return (
        <>
          <div className="mt-3 mb-1 font-weight-bold">Playlists</div>
          <ul className="list-group flush animate__animated animate__fadeIn">
            {playlists
              .filter((item) => item.name.toLowerCase() !== "slugify")
              .map((item) => (
                <li
                  key={item.id}
                  className="d-flex align-items-center list-group-item"
                >
                  <div className="me-auto">{item.name}</div>
                  {tracksAddSuccess && playlistsAffected.includes(item.id) ? (
                    <button
                      className="d-flex align-items-center justify-content-center btn btn-success"
                      style={{ cursor: "default" }}
                    >
                      <i className="icon-playlist_add_check" />
                    </button>
                  ) : (
                    <button
                      className="d-flex align-items-center justify-content-center btn btn-secondary"
                      disabled={!selectedItems.length}
                      onClick={() => addTracksToPlaylist(item.id)}
                    >
                      <i className="icon-playlist_add slug-icon" />
                    </button>
                  )}
                </li>
              ))}
          </ul>
        </>
      );
    }
  };

  const renderTracks = () => {
    if (isLoading) {
      return (
        <div className="d-flex flex-column align-items-center text-center">
          <StatusMessage
            emoticon="🐌"
            isInfinite
            title="Slurping Spotify..."
            titleAnimation="animate__pulse"
          />
        </div>
      );
    }
    if (trackSearch.searchResults && !trackSearch.searchResults.length) {
      return (
        <div className="d-flex flex-column align-items-center text-center">
          <span
            role="img"
            aria-label="Couldn't find shit!"
            className="display-4"
          >
            💩
          </span>
          <p className="lead mt-3">
            The Slug couldn&apos;t find shit on Spotify!
          </p>
        </div>
      );
    }
    if (trackSearch.searchResults && trackSearch.searchResults.length) {
      return (
        <>
          <div className="mb-1 font-weight-bold">
            Matched {trickOrTracks(trackSearch.searchResults)}
          </div>
          <ul className="list-group flush animate__animated animate__fadeIn">
            {trackSearch.searchResults.map((item) => (
              <li
                key={item.id}
                className={`d-flex align-items-center list-group-item ${
                  selectedItems.includes(item.id)
                    ? "selected-list-item"
                    : "bg-light"
                }`}
                onClick={() => toggleSelect(item.id)}
              >
                <div className="d-flex align-items-center selectable-list-item w-100">
                  <div className="d-flex align-items-center me-auto w-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <a
                        className="d-block d-sm-none"
                        title="Play on Spotify"
                        href={item.external_urls.spotify}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="d-block d-sm-none"
                          src={item.album.images[2].url}
                          alt={`${item.album.name} album cover`}
                          height="48px"
                          width="48px"
                        />
                      </a>
                      <a
                        className="d-none d-sm-block"
                        title="Play on Spotify"
                        href={item.external_urls.spotify}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="d-none d-sm-block"
                          src={item.album.images[2].url}
                          alt={`${item.album.name} album cover`}
                          height="64px"
                          width="64px"
                        />
                      </a>
                    </div>
                    <div className="d-flex flex-column align-items-sm-start ms-3">
                      <small className="d-block d-sm-none font-weight-bold">
                        {item.name ? trimText(25, item.name) : null}
                      </small>
                      <span className="d-none d-sm-block font-weight-bold">
                        {item.name ? trimText(50, item.name) : null}
                      </span>
                      <small>
                        <div className="d-block d-sm-none">
                          {item.album.artists[0].name
                            ? trimText(25, item.album.artists[0].name)
                            : null}
                        </div>
                        <div className="d-none d-sm-block">
                          {item.album.artists[0].name
                            ? trimText(50, item.album.artists[0].name)
                            : null}
                        </div>
                        <div className="d-block d-sm-none">
                          {item.album.name
                            ? trimText(25, item.album.name)
                            : null}
                        </div>
                        <div className="d-none d-sm-block">
                          {item.album.name
                            ? trimText(50, item.album.name)
                            : null}
                        </div>
                      </small>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      );
    }
  };

  const toggleSelect = (id) => {
    if (selectedItems.includes(id)) {
      const temp = [...selectedItems];
      const index = temp.indexOf(id);
      temp.splice(index, 1);
      setSelectedItems([...temp]);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const trickOrTracks = (arr) => {
    if (arr && arr.length) {
      switch (arr.length) {
        case 0:
          return "tracks";
        case 1:
          return "track";
        default:
          return "tracks";
      }
    }
  };

  return (
    <div className="modal fade" id="slug-bucket-spotify-modal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="d-flex align-items-center modal-title">
              <div className="d-flex flex-column">
                <span className="d-block d-sm-none font-weight-bold">
                  {trackSearch.title ? trimText(23, trackSearch.title) : ""}
                  &nbsp;
                </span>
                <span className="d-none d-sm-block font-weight-bold">
                  {trackSearch.title ? trimText(40, trackSearch.title) : ""}
                  &nbsp;
                </span>
                <span className="d-block d-sm-none font-weight-normal">
                  {trackSearch.artist ? trimText(25, trackSearch.artist) : ""}
                </span>
                <span className="d-none d-sm-block font-weight-normal">
                  {trackSearch.artist ? trimText(40, trackSearch.artist) : ""}
                </span>
              </div>
              {/* <img
                className="d-none d-sm-block ms-2"
                src={spotifyLogo}
                alt="Spotify"
                height="28.8px"
                width="96px"
              /> */}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="d-flex flex-column modal-body">
            {renderTracks()}
            {trackSearch.searchResults && trackSearch.searchResults.length
              ? renderPlaylists()
              : null}
          </div>
          <div className="d-flex justify-content-center modal-footer">
            <button
              type="button"
              className="btn btn-link text-body-secondary text-decoration-none"
              data-bs-dismiss="modal"
            >
              close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlugBucketSpotifyModal;
