import { useEffect, useState } from "react";
import { sortBy } from "lodash";
import Select from "react-select";
import { isMobile } from "react-device-detect";

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? "#FFF" : styles.color,
    backgroundColor: state.isSelected
      ? "rgba(208, 234, 226, 0.33)"
      : styles.color,
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.125)"
    }
  }),
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)" : 0,
    borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA",
    borderRadius: "0.4rem",
    "&:hover": {
      borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA"
    }
  })
};

export const AutoSlurpPlaylistSelector = ({
  currentSelectedSlurpToPlaylist,
  handleRefreshPlaylists,
  handleSelectAutoSlurpPlaylist,
  handleSelectAutoSlurpPlaylistMobile,
  isUpdatingAutoSlurpPlaylist,
  isRefreshingPlaylists,
  isRefreshingPlaylistsIconSpinning,
  isLoading,
  myAppleMusicPlaylists,
  mySpotifyPlaylists,
  primaryMusicStream,
  setIsUpdatingAutoSlurpPlaylist,
  width
}) => {
  const [selectedPlaylist, setSelectedPlaylist] = useState("");
  useEffect(() => {
    if (
      currentSelectedSlurpToPlaylist &&
      currentSelectedSlurpToPlaylist.id &&
      currentSelectedSlurpToPlaylist.name
    ) {
      setSelectedPlaylist({
        label: currentSelectedSlurpToPlaylist.name,
        value: currentSelectedSlurpToPlaylist.id
      });
    } else {
      setSelectedPlaylist("");
    }
    setIsUpdatingAutoSlurpPlaylist(false);
  }, [currentSelectedSlurpToPlaylist, setIsUpdatingAutoSlurpPlaylist]);

  const playlistOptions = () => {
    if (primaryMusicStream === "appleMusic" && myAppleMusicPlaylists.length) {
      return myAppleMusicPlaylists
        .filter((playlist) => playlist.name !== "Slugify")
        .map((playlist) => ({
          label: playlist.name,
          value: playlist.id
        }));
    } else if (primaryMusicStream === "spotify" && mySpotifyPlaylists.length) {
      return sortBy(mySpotifyPlaylists, "name")
        .filter(
          (playlist) =>
            playlist.name !== "Slugify" &&
            !playlist.name.includes("(Slugify Direct") &&
            !playlist.description.includes("via Slugify!")
        )
        .map((playlist) => ({
          label: playlist.name,
          value: playlist.id
        }));
    }
  };

  const renderOptionsMobile = () => {
    if (primaryMusicStream === "appleMusic" && myAppleMusicPlaylists.length) {
      return myAppleMusicPlaylists
        .filter((playlist) => playlist.name !== "Slugify")
        .map((playlist) => (
          <option key={playlist.id} value={playlist.id}>
            {playlist.name}
          </option>
        ));
    } else if (primaryMusicStream === "spotify" && mySpotifyPlaylists.length) {
      return sortBy(mySpotifyPlaylists, "name")
        .filter(
          (playlist) =>
            playlist.name !== "Slugify" &&
            !playlist.name.includes("(Slugify Direct)") &&
            !playlist.description.includes("via Slugify!")
        )
        .map((playlist) => (
          <option key={playlist.id} value={playlist.id}>
            {playlist.name}
          </option>
        ));
    }
  };

  if (isMobile) {
    return (
      <div className="form-group mb-0 flex-fill">
        <div className="d-flex align-items-center">
          <label htmlFor="timezone">AutoSlurp-to Playlist</label>
          <span
            className={`icon-loop slug-icon ${
              isRefreshingPlaylistsIconSpinning
                ? "rotate-counter-clockwise"
                : ""
            } ms-2`}
            onClick={() => handleRefreshPlaylists()}
            title="Refresh Playlists"
            style={{ cursor: "pointer", fontSize: 18, marginBottom: 8 }}
          />
        </div>
        <select
          className="form-select form-select-sm"
          disabled={isUpdatingAutoSlurpPlaylist || isRefreshingPlaylists}
          id="auto-slurp-to-playlist"
          onChange={(e) => handleSelectAutoSlurpPlaylistMobile(e)}
          value={currentSelectedSlurpToPlaylist.id}
        >
          {currentSelectedSlurpToPlaylist.name ? null : (
            <option value="">Select...</option>
          )}
          {renderOptionsMobile()}
        </select>
      </div>
    );
  }
  return (
    <div className="flex-fill" style={{ maxWidth: width < 576 ? 500 : 400 }}>
      <Select
        isDisabled={isUpdatingAutoSlurpPlaylist || isRefreshingPlaylists}
        isLoading={isLoading}
        className="select-single"
        classNamePrefix="auto-slurp-playlist-select"
        name="playlist-selector"
        noOptionsMessage={() => "No valid playlists on Spotify"}
        onChange={(playlist) => {
          handleSelectAutoSlurpPlaylist(playlist);
        }}
        options={playlistOptions()}
        placeholder={
          isLoading
            ? "Talking to the Cloud..."
            : width < 576
            ? "Select AutoSlurp-to Playlist"
            : "Select..."
        }
        styles={customStyles}
        value={selectedPlaylist}
      />
    </div>
  );
};
