/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSlugifySlurpedTrack = /* GraphQL */ `
  subscription OnCreateSlugifySlurpedTrack {
    onCreateSlugifySlurpedTrack {
      id
      username
      appleMusicId
      appleMusicTrackAlbum
      appleMusicTrackArtist
      appleMusicTrackIsrc
      appleMusicTrackTitle
      matchedBy
      originalTrackAddedBy
      owner
      slurpToPlaylistId
      spotifyId
      spotifyTrackAlbum
      spotifyTrackArtists
      spotifyTrackIsrc
      spotifyTrackTitle
      toBeDeletedOn
      trackType
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateSlugifySlurpedTrack = /* GraphQL */ `
  subscription OnUpdateSlugifySlurpedTrack {
    onUpdateSlugifySlurpedTrack {
      id
      username
      appleMusicId
      appleMusicTrackAlbum
      appleMusicTrackArtist
      appleMusicTrackIsrc
      appleMusicTrackTitle
      matchedBy
      originalTrackAddedBy
      owner
      slurpToPlaylistId
      spotifyId
      spotifyTrackAlbum
      spotifyTrackArtists
      spotifyTrackIsrc
      spotifyTrackTitle
      toBeDeletedOn
      trackType
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteSlugifySlurpedTrack = /* GraphQL */ `
  subscription OnDeleteSlugifySlurpedTrack {
    onDeleteSlugifySlurpedTrack {
      id
      username
      appleMusicId
      appleMusicTrackAlbum
      appleMusicTrackArtist
      appleMusicTrackIsrc
      appleMusicTrackTitle
      matchedBy
      originalTrackAddedBy
      owner
      slurpToPlaylistId
      spotifyId
      spotifyTrackAlbum
      spotifyTrackArtists
      spotifyTrackIsrc
      spotifyTrackTitle
      toBeDeletedOn
      trackType
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const onCreateSlugifyFriend = /* GraphQL */ `
  subscription OnCreateSlugifyFriend {
    onCreateSlugifyFriend {
      id
      userId
      username
      owner
      primaryMusicStream
      musicStreamId
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateSlugifyFriend = /* GraphQL */ `
  subscription OnUpdateSlugifyFriend {
    onUpdateSlugifyFriend {
      id
      userId
      username
      owner
      primaryMusicStream
      musicStreamId
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteSlugifyFriend = /* GraphQL */ `
  subscription OnDeleteSlugifyFriend {
    onDeleteSlugifyFriend {
      id
      userId
      username
      owner
      primaryMusicStream
      musicStreamId
      createdAt
      updatedAt
      user {
        id
        username
        appleMusicSlugifyPlaylist
        autoSlurpFriends
        expoPushTokenData
        homeMenu
        isSubscribedOneSignal
        owner
        primaryMusicStream
        publicPlaylists
        sharedPlaylists
        screenAppearance
        friends {
          nextToken
        }
        slugifyMobileSlurpDestination
        spotifyTokenData
        spotifyUserData
        youTubeUserData
        createdAt
        updatedAt
        slurpedTracks {
          nextToken
        }
      }
    }
  }
`;
export const onCreateSlugifyUser = /* GraphQL */ `
  subscription OnCreateSlugifyUser {
    onCreateSlugifyUser {
      id
      username
      appleMusicSlugifyPlaylist
      autoSlurpFriends
      expoPushTokenData
      homeMenu
      isSubscribedOneSignal
      owner
      primaryMusicStream
      publicPlaylists
      sharedPlaylists
      screenAppearance
      friends {
        items {
          id
          userId
          username
          owner
          primaryMusicStream
          musicStreamId
          createdAt
          updatedAt
        }
        nextToken
      }
      slugifyMobileSlurpDestination
      spotifyTokenData
      spotifyUserData
      youTubeUserData
      createdAt
      updatedAt
      slurpedTracks {
        items {
          id
          username
          appleMusicId
          appleMusicTrackAlbum
          appleMusicTrackArtist
          appleMusicTrackIsrc
          appleMusicTrackTitle
          matchedBy
          originalTrackAddedBy
          owner
          slurpToPlaylistId
          spotifyId
          spotifyTrackAlbum
          spotifyTrackArtists
          spotifyTrackIsrc
          spotifyTrackTitle
          toBeDeletedOn
          trackType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateSlugifyUser = /* GraphQL */ `
  subscription OnUpdateSlugifyUser {
    onUpdateSlugifyUser {
      id
      username
      appleMusicSlugifyPlaylist
      autoSlurpFriends
      expoPushTokenData
      homeMenu
      isSubscribedOneSignal
      owner
      primaryMusicStream
      publicPlaylists
      sharedPlaylists
      screenAppearance
      friends {
        items {
          id
          userId
          username
          owner
          primaryMusicStream
          musicStreamId
          createdAt
          updatedAt
        }
        nextToken
      }
      slugifyMobileSlurpDestination
      spotifyTokenData
      spotifyUserData
      youTubeUserData
      createdAt
      updatedAt
      slurpedTracks {
        items {
          id
          username
          appleMusicId
          appleMusicTrackAlbum
          appleMusicTrackArtist
          appleMusicTrackIsrc
          appleMusicTrackTitle
          matchedBy
          originalTrackAddedBy
          owner
          slurpToPlaylistId
          spotifyId
          spotifyTrackAlbum
          spotifyTrackArtists
          spotifyTrackIsrc
          spotifyTrackTitle
          toBeDeletedOn
          trackType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteSlugifyUser = /* GraphQL */ `
  subscription OnDeleteSlugifyUser {
    onDeleteSlugifyUser {
      id
      username
      appleMusicSlugifyPlaylist
      autoSlurpFriends
      expoPushTokenData
      homeMenu
      isSubscribedOneSignal
      owner
      primaryMusicStream
      publicPlaylists
      sharedPlaylists
      screenAppearance
      friends {
        items {
          id
          userId
          username
          owner
          primaryMusicStream
          musicStreamId
          createdAt
          updatedAt
        }
        nextToken
      }
      slugifyMobileSlurpDestination
      spotifyTokenData
      spotifyUserData
      youTubeUserData
      createdAt
      updatedAt
      slurpedTracks {
        items {
          id
          username
          appleMusicId
          appleMusicTrackAlbum
          appleMusicTrackArtist
          appleMusicTrackIsrc
          appleMusicTrackTitle
          matchedBy
          originalTrackAddedBy
          owner
          slurpToPlaylistId
          spotifyId
          spotifyTrackAlbum
          spotifyTrackArtists
          spotifyTrackIsrc
          spotifyTrackTitle
          toBeDeletedOn
          trackType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
