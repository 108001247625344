import { API } from "aws-amplify";
import { compact, flatten } from "lodash";
import { handleUserAlert } from "./slugifyActions";
import { isEmptyObject, getTitleCase } from "../functions";
import { refreshSpotifyToken } from "./userActions";
import axios from "axios";
import dayjs from "dayjs";
import qs from "qs";
import { SpotifyActionType } from "../types/spotify";
import { SlugifyActionType, SlugifyTrackType } from "../types";

const isDev = process.env.NODE_ENV === "development";

export const addTracksToSpotifyPlaylist =
  (playlistId, tracks) => async (dispatch) => {
    try {
      const urisToSlurp = compact(
        tracks.map((item) => {
          return item && item.uri;
        })
      );
      const postData = { uris: urisToSlurp };
      const accessToken = await dispatch(checkSpotifyToken());
      const addTracksOptions = {
        method: "POST",
        url: `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: postData
      };
      const result = await axios(addTracksOptions);
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createAppleMusicPlaylistOnSpotify =
  (friendUsername, playlistName, tracks) => async (dispatch, getState) => {
    dispatch({
      type: SpotifyActionType.CREATE_APPLE_MUSIC_PLAYLIST_ON_SPOTIFY_START
    });
    console.log(
      "️%c🥚 Creating Spotify Playlist from Apple Music Playlist",
      "color:cyan"
    );
    const {
      newUser: { primaryMusicStream }
    } = getState();

    if (primaryMusicStream && primaryMusicStream !== "spotify") {
      if (isDev) {
        console.log("%c🤷🏻‍♀️ Nothing to do.  Not a Spotify user.", "color:orange");
      }
      dispatch({
        type: SpotifyActionType.CREATE_APPLE_MUSIC_PLAYLIST_ON_SPOTIFY_FAIL,
        payload: new Error("Not a Spotify user")
      });
      return Promise.resolve([]);
    }

    const clientCredentialsToken = await dispatch(
      checkSpotifyClientCredentialsToken()
    );
    // True in the below bypasses the check for a spotify user when checking token
    const accessToken = await dispatch(checkSpotifyToken(true));

    const getPlaylistsOptions = {
      method: "GET",
      url: "https://api.spotify.com/v1/me/playlists",
      headers: { Authorization: "Bearer " + accessToken },
      json: true
    };
    const {
      data: { items: myPlaylists }
    } = await axios(getPlaylistsOptions);

    const existingPlaylists = myPlaylists.filter(
      (item) =>
        item.name === playlistName &&
        item.description ===
          `Created by ${getTitleCase(friendUsername)} via Slugify!`
    );

    const existingTracks = [];
    if (existingPlaylists.length) {
      try {
        const result = await dispatch(
          getTracksFromSpotifyPlaylist(existingPlaylists[0].id)
        );
        existingTracks.push(...result);
      } catch (error) {
        console.warn(error);
        dispatch({
          type: SpotifyActionType.CREATE_APPLE_MUSIC_PLAYLIST_ON_SPOTIFY_FAIL,
          payload: error
        });
        return Promise.reject(error);
      }
    }

    const getMatchingTrack = async (track) => {
      try {
        const isrcQstring = {
          type: "track",
          q: `isrc:${track.attributes.isrc}`,
          limit: 1
        };
        const isrcTracks = await dispatch(
          getMatchingSpotifyTrack(clientCredentialsToken, isrcQstring)
        );

        if (isDev) {
          console.log("%cISRC Tracks", "color:cyan", isrcTracks);
        }

        if (isrcTracks.length) {
          return isrcTracks;
        }
        if (isDev) {
          console.log(
            "%c🤷🏻‍♀️ No matching ISRC on Spotify.  Searching with Artist/Title...",
            "color:yellow"
          );
        }
        const titleQstring = {
          type: "track",
          q: `track:${track.attributes.name} artist:${track.attributes.artistName}`,
          limit: 1
        };
        const artistTitleTracks = await dispatch(
          getMatchingSpotifyTrack(clientCredentialsToken, titleQstring)
        );
        if (isDev) {
          console.log("%c Artist/Title Tracks", "color:cyan", isrcTracks);
        }
        if (artistTitleTracks.length) {
          return artistTitleTracks;
        } else {
          if (isDev) {
            console.log(
              `%c🤷🏻‍♀️ No matching Artist/Title on Spotify for ${track.attributes.artistName} / ${track.attributes.name} `,
              "color:yellow"
            );
          }
          return null;
        }
      } catch (error) {
        console.warn(error);
      }
    };
    try {
      const pendingTracks = tracks.map((track) => getMatchingTrack(track));
      if (isDev) {
        console.log(
          "%c⏲️ Awaiting Spotify Matched Track promises...",
          "color:cyan"
        );
      }
      const resolvedTracks = await Promise.all(pendingTracks);
      if (isDev) {
        console.log(
          "%c🧘🏽 Spotify Matched Track Promises fulfilled",
          "color:lime",
          resolvedTracks
        );
      }
      const matchedTracks = compact(flatten(resolvedTracks));
      if (isDev) {
        console.log("%cMATCHED TRACKS", "color:cyan", matchedTracks);
        console.log("%cEXISTING TRACKS", "color:cyan", existingTracks);
      }
      const matchedTrackIds = matchedTracks.map((item) => item.id);
      const existingTrackIds = existingTracks.map((item) => item.track.id);

      const nonExistingTracks = matchedTracks.filter(
        (item) => !existingTrackIds.includes(item.id)
      );
      const deletedTracks = existingTracks.filter(
        (item) => !matchedTrackIds.includes(item.track.id)
      );
      if (isDev) {
        console.log("%cDELETED TRACKS", "color:cyan", deletedTracks);
        console.log("%cNON-EXISTING TRACKS", "color:cyan", nonExistingTracks);
      }

      if (!nonExistingTracks.length) {
        if (deletedTracks.length) {
          const urisToDelete = {
            tracks: [
              ...deletedTracks.map((item) => ({
                uri: item.track.uri
              }))
            ]
          };
          const response = await dispatch(
            removeTracksFromSpotifyPlaylist(
              existingPlaylists[0].id,
              urisToDelete
            )
          );
          if (isDev) {
            console.log(
              "%c 🔥 Tracks Deleted from Spotify Playlist",
              "color:pink",
              response
            );
          }
        }

        dispatch({
          type: "CREATE_APPLE_MUSIC_PLAYLIST_ON_SPOTIFY_SUCCESS_NO_ACTION"
        });
        return Promise.resolve({
          result: "noNewTracks",
          deletedTracks: deletedTracks.length,
          addedTracks: nonExistingTracks.length
        });
      }

      if (existingPlaylists.length) {
        if (existingPlaylists[0].id && deletedTracks.length) {
          const urisToDelete = {
            tracks: [
              ...deletedTracks.map((item) => ({
                uri: item.track.uri
              }))
            ]
          };
          const response = await dispatch(
            removeTracksFromSpotifyPlaylist(
              existingPlaylists[0].id,
              urisToDelete
            )
          );
          if (isDev) {
            console.log(
              "%c 🔥 Tracks Deleted from Spotify Playlist",
              "color:pink",
              response
            );
          }
          const result = await dispatch(
            addTracksToSpotifyPlaylist(
              existingPlaylists[0].id,
              nonExistingTracks
            )
          );
          if (isDev) {
            console.log("%c Tracks added to playlist", "color:pink", result);
          }
          dispatch({
            type: "CREATE_APPLE_MUSIC_PLAYLIST_ON_SPOTIFY_SUCCESS_NO_ACTION"
          });
          return Promise.resolve({
            playlist: existingPlaylists[0],
            unmatchedTrackCount: tracks.length - matchedTracks.length
          });
        } else {
          const result = await dispatch(
            addTracksToSpotifyPlaylist(
              existingPlaylists[0].id,
              nonExistingTracks
            )
          );
          if (isDev) {
            console.log("%c Tracks added to playlist", "color:pink", result);
          }
          const unmatchedTrackCount = tracks.length - matchedTracks.length;
          dispatch({
            type: "CREATE_APPLE_MUSIC_PLAYLIST_ON_SPOTIFY_SUCCESS_NO_ACTION"
          });
          return Promise.resolve({
            playlist: existingPlaylists[0],
            unmatchedTrackCount
          });
        }
      } else {
        const response = await dispatch(
          createSpotifyPlaylistWithTracks(
            accessToken,
            friendUsername,
            playlistName,
            nonExistingTracks
          )
        );
        console.log(
          "%c🐣 New Spotify playlist created!",
          "color:lime",
          response
        );
        const unmatchedTrackCount = tracks.length - response.matchedTrackCount;
        dispatch({
          type: SpotifyActionType.CREATE_APPLE_MUSIC_PLAYLIST_ON_SPOTIFY_SUCCESS,
          payload: response.newPlaylist
        });
        return Promise.resolve({
          playlist: response.newPlaylist,
          unmatchedTrackCount
        });
      }
    } catch (error) {
      console.warn(error);
      return Promise.reject(error);
    }
  };

export const createSpotifyPlaylistWithTracks =
  (accessToken, friendUsername, playlistName, tracksToAdd) =>
  async (dispatch, getState) => {
    try {
      const {
        newUser: {
          spotifyUserData: { id: spotifyUserId }
        }
      } = getState();
      const createPlaylistOptions = {
        method: "POST",
        url: `https://api.spotify.com/v1/users/${spotifyUserId}/playlists`,
        headers: { Authorization: "Bearer " + accessToken },
        json: true,
        data: {
          name: playlistName,
          description: `Created by ${getTitleCase(
            friendUsername
          )} via Slugify!`,
          public: false
        }
      };
      const { data: newPlaylist } = await axios(createPlaylistOptions);
      const urisToSlurp = compact(
        tracksToAdd.map((item) => {
          return item && item.uri;
        })
      );
      const postData = { uris: urisToSlurp };
      const addTracksOptions = {
        method: "POST",
        url: `https://api.spotify.com/v1/playlists/${newPlaylist.id}/tracks`,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: postData
      };
      await axios(addTracksOptions);
      return Promise.resolve({
        newPlaylist,
        matchedTrackCount: urisToSlurp.length
      });
    } catch (error) {
      console.warn(error.response);
      return Promise.reject(error);
    }
  };

export const getMatchingSpotifyTrack = (accessToken, qString) => async () => {
  try {
    const getOptions = {
      method: "GET",
      url: `https://api.spotify.com/v1/search?${qs.stringify(qString)}`,
      headers: { Authorization: "Bearer " + accessToken },
      json: true
    };
    const { data } = await axios(getOptions);
    return Promise.resolve(data.tracks.items);
  } catch (error) {
    return Promise.resolve(error);
  }
};

export const createNewSpotifyPlaylist =
  (playlistName) => async (dispatch, getState) => {
    const {
      newUser: { primaryMusicStream, spotifyUserData }
    } = getState();

    if (primaryMusicStream !== "spotify") {
      return Promise.resolve();
    }
    try {
      dispatch({ type: "CREATE_NEW_SPOTIFY_PLAYLIST_START" });
      const accessToken = await dispatch(checkSpotifyToken());
      const postOptions = {
        method: "POST",
        url: `https://api.spotify.com/v1/users/${spotifyUserData.id}/playlists`,
        headers: { Authorization: "Bearer " + accessToken },
        json: true,
        data: {
          name: playlistName,
          description: "Created by Slugify!",
          public: false
        }
      };
      const { data } = await axios(postOptions);
      dispatch({
        type: "CREATE_NEW_SPOTIFY_PLAYLIST_SUCCESS",
        payload: data
      });
      console.log("%c🌟 New playlist created on Spotify", "color:lime");
      return Promise.resolve(data);
    } catch (error) {
      console.warn(error.response || error);
      dispatch({
        type: "CREATE_SPOTIFY_SLUGIFY_DIRECT_PLAYLIST_FAIL",
        payload: error.response || error
      });
      return Promise.resolve(error.response || error);
    }
  };
export const createSpotifySlugifyDirectPlaylist =
  (friendUsername) => async (dispatch, getState) => {
    const {
      newUser: { primaryMusicStream, spotifyUserData }
    } = getState();

    if (primaryMusicStream !== "spotify") {
      return Promise.resolve();
    }
    try {
      dispatch({ type: "CREATE_SPOTIFY_SLUGIFY_DIRECT_PLAYLIST_START" });
      const accessToken = await dispatch(checkSpotifyToken());
      console.warn(accessToken);
      const postOptions = {
        method: "POST",
        url: `https://api.spotify.com/v1/users/${spotifyUserData.id}/playlists`,
        headers: { Authorization: "Bearer " + accessToken },
        json: true,
        data: {
          name: `${getTitleCase(friendUsername)} (Slugify Direct)`,
          description: "Created by Slugify!"
        }
      };
      const { data } = await axios(postOptions);
      console.warn(data);
      dispatch({
        type: "CREATE_SPOTIFY_SLUGIFY_DIRECT_PLAYLIST_SUCCESS",
        payload: data
      });
      console.log(
        "%c🌟 Slugify Direct playlist created on Spotify",
        "color:lime"
      );
      return Promise.resolve(data);
    } catch (error) {
      console.warn(error.response || error);
      dispatch({
        type: "CREATE_SPOTIFY_SLUGIFY_DIRECT_PLAYLIST_FAIL",
        payload: error.response || error
      });
      return Promise.resolve(error.response || error);
    }
  };
export const createSpotifySlugifyPlaylist =
  () => async (dispatch, getState) => {
    const {
      newUser: {
        primaryMusicStream,
        spotifyUserData,
        spotifyTokenData: { access_token: accessToken }
      },
      spotify: { myPlaylists: mySpotifyPlaylists }
    } = getState();

    if (primaryMusicStream !== "spotify") {
      return Promise.resolve();
    }
    try {
      dispatch({ type: "CREATE_SPOTIFY_SLUGIFY_PLAYLIST_START" });
      const mySpotifySlugifyPlaylists = mySpotifyPlaylists.filter(
        (item) => item.name.toLowerCase() === "slugify"
      );
      if (mySpotifySlugifyPlaylists.length > 1) {
        if (isDev) {
          console.log(
            "%c🥒 There is more than one Slugify Playlist on Spotify!",
            "color:hotpink"
          );
        }
        dispatch({
          type: "CREATE_SPOTIFY_SLUGIFY_PLAYLIST_ALERT",
          payload: {
            alertType: "secondary",
            message: `<p>There is more than one Slugify Playlist on Apple Music!</p>
          <p>Check your playlists <strong><a href="https://open.spotify.com/collection/playlists" 
          target="_blank" rel="noopener noreferrer">here</a></strong></p>`,
            messageIsHtml: true,
            emoticon: "⚔️",
            title: "There can only be one Highlander!"
          }
        });
      }
      if (mySpotifySlugifyPlaylists.length === 1) {
        const slugifyPlaylistIsPublic = mySpotifySlugifyPlaylists.map(
          (item) => item.public
        );
        if (slugifyPlaylistIsPublic) {
          if (isDev) {
            console.log(
              "%c✅ Slugify Playlist on Spotify is public!",
              "color:lime"
            );
          }
          return Promise.resolve(null);
        } else {
          if (isDev) {
            console.log(
              "%c🥒 Slugify Playlist on Spotify is secret!",
              "color:hotpink"
            );
          }
          return Promise.resolve(null);
        }
      }
      if (!mySpotifySlugifyPlaylists.length) {
        dispatch({
          type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
          payload: "Creating Slugify Playlist on Spotify..."
        });
        const postOptions = {
          method: "POST",
          url: `https://api.spotify.com/v1/users/${spotifyUserData.id}/playlists`,
          headers: { Authorization: "Bearer " + accessToken },
          json: true,
          data: {
            name: "Slugify",
            description: "Created by Slugify!",
            public: true
          }
        };
        const { data } = await axios(postOptions);
        dispatch({
          type: "CREATE_SPOTIFY_SLUGIFY_PLAYLIST_SUCCESS",
          payload: data
        });
        console.log("%c🌟 Slugify playlist created on Spotify", "color:lime");
        return Promise.resolve();
      } else {
        dispatch({ type: "CREATE_SPOTIFY_SLUGIFY_PLAYLIST_NO_ACTION" });
        if (isDev) {
          console.log(
            "%c✅ Slugify playlist already exists on Spotify",
            "color:lime"
          );
        }
        return Promise.resolve(null);
      }
    } catch (error) {
      console.warn(error.response || error);
      dispatch({
        type: "CREATE_SPOTIFY_SLUGIFY_PLAYLIST_FAIL",
        payload: error.response || error
      });
      return Promise.resolve(error.response || error);
    }
  };

export const checkSpotifyClientCredentialsToken =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: "CHECK_SPOTIFY_CLIENT_CREDENTIALS_TOKEN_START" });
      const { access_token, expires } =
        getState().spotify.clientCredentialsTokenData;
      if (dayjs().isAfter(dayjs(expires))) {
        if (isDev) {
          console.log("⚰️ Spotify client credentials token is expired!");
          console.log("🛀🏿 Refreshing Spotify client credentials token!");
        }
        const newToken = await dispatch(getSpotifyClientCredentialsToken());
        if (isDev) {
          console.log("✨ Spotify client credentials token refreshed!");
        }
        dispatch({ type: "CHECK_SPOTIFY_CLIENT_CREDENTIALS_TOKEN_SUCCESS" });
        return Promise.resolve(newToken.access_token);
      } else {
        if (isDev) {
          console.log(
            "%c✅ Spotify client credentials token is good!",
            "color:lime"
          );
        }
        dispatch({
          type: "CHECK_SPOTIFY_CLIENT_CREDENTIALS_TOKEN_SUCCESS"
        });
        return Promise.resolve(access_token);
      }
    } catch (error) {
      console.warn(error.response || error);
      dispatch({
        type: "CHECK_SPOTIFY_CLIENT_CREDENTIALS_TOKEN_FAIL",
        payload: error.response || error
      });
      dispatch(handleUserAlert(["CHECK_SPOTIFY_CLIENT_CREDENTIALS_TOKEN"]));
      return Promise.resolve(error.response || error);
    }
  };

export const checkSpotifyIsConnected = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: "CHECK_SPOTIFY_CONNECTED_STATUS_START"
    });
    const {
      newUser: { spotifyTokenData, primaryMusicStream }
    } = getState();

    if (primaryMusicStream !== "spotify") {
      if (!primaryMusicStream) {
        if (isDev) {
          console.log("⛔ User has not selected primary music stream yet");
        }
      } else if (primaryMusicStream === "appleMusic") {
        if (isDev) {
          console.log("⛔ Not a Spotify user");
        }
      }
      dispatch({
        type: "CHECK_SPOTIFY_CONNECTED_STATUS_SUCCESS",
        payload: false
      });
      return Promise.resolve(false);
    }
    dispatch({
      type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
      payload: "Checking Spotify connection..."
    });
    const token = await dispatch(checkSpotifyToken());
    if (!token) {
      dispatch({
        type: "CHECK_SPOTIFY_CONNECTED_STATUS_SUCCESS",
        payload: false
      });
      return Promise.resolve(false);
    } else if (primaryMusicStream === "spotify") {
      if (!isEmptyObject(spotifyTokenData)) {
        const playlists = await dispatch(getCurrentUserSpotifyPlaylists());
        dispatch({
          type: "CHECK_SPOTIFY_CONNECTED_STATUS_SUCCESS",
          payload: playlists ? true : false
        });
        return Promise.resolve(!!playlists);
      } else {
        dispatch({
          type: "CHECK_SPOTIFY_CONNECTED_STATUS_SUCCESS",
          payload: false
        });
        return Promise.resolve(false);
      }
    } else {
      dispatch({
        type: "CHECK_SPOTIFY_CONNECTED_STATUS_SUCCESS",
        payload: false
      });
      return Promise.resolve(false);
    }
  } catch (error) {
    dispatch({
      type: "CHECK_SPOTIFY_CONNECTED_STATUS_FAIL",
      payload: error
    });
    console.warn(error);
    return Promise.reject(error);
  }
};

export const checkSpotifyToken =
  (bypassSpotifyUserCheck = false) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "CHECK_SPOTIFY_TOKEN_START" });
      const {
        newUser: { primaryMusicStream, spotifyTokenData }
      } = getState();

      if (primaryMusicStream === "spotify" || bypassSpotifyUserCheck) {
        if (isEmptyObject(spotifyTokenData)) {
          if (isDev) {
            console.warn(
              "No token data.  User probably not connected to Spotify."
            );
          }
          dispatch({
            type: "CHECK_SPOTIFY_TOKEN_SUCCESS",
            payload: "No token data.  User probably not connected to Spotify."
          });
          return Promise.resolve(null);
        } else if (dayjs().isAfter(dayjs(spotifyTokenData.expires))) {
          if (isDev) {
            console.log("%c⚰️ Spotify token is expired!", "color:yellow");
            console.log("%c🛀🏿 Refreshing Spotify token!", "color:cyan");
          }
          const accessToken = await dispatch(refreshSpotifyToken());
          if (isDev) {
            console.log("%c✨ Spotify token refreshed!", "color:cyan");
          }
          dispatch({ type: "CHECK_SPOTIFY_TOKEN_SUCCESS" });
          return Promise.resolve(accessToken);
        } else {
          if (isDev) {
            console.log("%c✅ Spotify token is good!", "color:lime");
          }
          dispatch({ type: "CHECK_SPOTIFY_TOKEN_SUCCESS" });
          return Promise.resolve(spotifyTokenData.access_token);
        }
      } else {
        dispatch({
          type: "CHECK_SPOTIFY_TOKEN_SUCCESS",
          payload: "Not a spotify user"
        });
        return Promise.resolve(null);
      }
    } catch (error) {
      console.warn("error", error);
      console.warn("error response", error.response);
      console.warn("error data response", error.data.response);
      console.warn("error", error);
      const message = error.response
        ? error.response.body.error_description
        : error.message;
      dispatch({
        type: "CHECK_SPOTIFY_TOKEN_FAIL",
        payload: new Error(message)
      });
      dispatch(handleUserAlert(["CHECK_SPOTIFY_TOKEN"]));
      return Promise.reject(new Error(message));
    }
  };

export const getCurrentUserSpotifyPlaylists =
  () => async (dispatch, getState) => {
    try {
      const {
        newUser: { primaryMusicStream }
      } = getState();

      if (primaryMusicStream === "spotify") {
        dispatch({ type: "GET_SPOTIFY_CURRENT_USER_PLAYLISTS_START" });
        const accessToken = await dispatch(checkSpotifyToken());
        if (!accessToken) {
          if (isDev) {
            console.warn("No access token!");
          }
          return Promise.resolve(null);
        }
        const options = {
          method: "GET",
          url: "https://api.spotify.com/v1/me/playlists",
          headers: { Authorization: "Bearer " + accessToken },
          json: true
        };
        const { data } = await axios(options);
        dispatch({
          type: "GET_SPOTIFY_CURRENT_USER_PLAYLISTS_SUCCESS",
          payload: data.items
        });
        return Promise.resolve(data.items);
      } else {
        if (primaryMusicStream === "appleMusic") {
          if (isDev) {
            console.log("%c⛔ Not a Spotify user", "color:cyan");
          }
          dispatch({
            type: "GET_SPOTIFY_CURRENT_USER_PLAYLISTS_FAIL",
            payload: "Not a spotify user"
          });
          return Promise.resolve(null);
        } else {
          if (isDev) {
            console.log(
              "%c⛔ User has not selected primary music stream yet",
              "color:yellow"
            );
          }
          dispatch({
            type: "GET_SPOTIFY_CURRENT_USER_PLAYLISTS_FAIL",
            payload: "User has not selected primary music stream yet"
          });
          return Promise.resolve(null);
        }
      }
    } catch (error) {
      console.warn(error.response || error);
      dispatch({
        type: "GET_SPOTIFY_CURRENT_USER_PLAYLISTS_FAIL",
        payload: error.response || error
      });
      return Promise.reject(error.response || error);
    }
  };

export const getMatchingSpotifyTracks = () => async (dispatch, getState) => {
  // A matched Spotify track is the resulting Spotify track that has been matched from a source Apple Music track.
  dispatch({ type: SpotifyActionType.GET_MATCHING_SPOTIFY_TRACKS_START });
  const {
    appleMusic: { tracks: appleMusicTracks },
    newUser: {
      primaryMusicStream,
      spotifyUserData: { country: spotifyUserMarket }
    },
    slugify: { appleMusicPlaylists }
  } = getState();
  if (!appleMusicPlaylists.length) {
    if (isDev) {
      console.log(
        "%c🤷 No Apple Music playlists.  Skipping track matching.",
        "color:cyan"
      );
    }
    dispatch({
      type: SpotifyActionType.GET_MATCHING_SPOTIFY_TRACKS_SUCCESS,
      payload: []
    });
    return Promise.resolve(null);
  }
  if (primaryMusicStream !== "spotify") {
    if (!primaryMusicStream) {
      if (isDev) {
        console.log(
          "%c🤷 User has not selected primary music stream yet.  Skipping Spotify track matching.",
          "color:cyan"
        );
      }
    } else if (primaryMusicStream === "appleMusic") {
      if (isDev) {
        console.log(
          "%c🤷 Not Spotify user.  Skipping Spotify track matching.",
          "color:cyan"
        );
      }
    }
    dispatch({
      type: SpotifyActionType.GET_MATCHING_SPOTIFY_TRACKS_SUCCESS,
      payload: []
    });
    return Promise.resolve(null);
  }
  dispatch({
    type: SlugifyActionType.UPDATE_SLUGIFY_LOADING_STATUS,
    payload: "Matching Spotify tracks..."
  });
  const appleMusicNativeTracks = appleMusicTracks
    .filter((item) => item.trackType === SlugifyTrackType.APPLE_MUSIC_NATIVE)
    .map((item) => ({
      album: item.attributes.albumName,
      albumArt: item.attributes.artwork.url,
      artist: item.attributes.artistName,
      title: item.attributes.name,
      id: item.id,
      isrc: item.attributes.isrc,
      addedBy: item.addedBy
    }));
  const accessToken = await dispatch(checkSpotifyClientCredentialsToken());
  const getMatchingTrack = async (track) => {
    const addAppleDataToSpotifyTrack = (data, track, matchedBy) => {
      try {
        data.addedBy = track.addedBy;
        data.appleMusicId = track.id;
        data.appleMusicIsrc = track.isrc;
        data.album = track.album;
        data.albumArt = track.albumArt;
        data.artist = track.artist;
        data.matchedBy = matchedBy;
        data.title = track.title;
        return data;
      } catch (error) {
        throw new Error(
          `Cannot add Apple Music Data to matched Spotify track: ${error.message}`
        );
      }
    };
    try {
      const isrcQstring = {
        market: spotifyUserMarket,
        type: "track",
        q: `isrc:${track.isrc}`,
        limit: 1
      };
      const getOptions = {
        method: "GET",
        url: `https://api.spotify.com/v1/search?${qs.stringify(isrcQstring)}`,
        headers: { Authorization: "Bearer " + accessToken },
        json: true
      };
      const { data } = await axios(getOptions);
      if (data.tracks.items.length) {
        return addAppleDataToSpotifyTrack(data, track, "isrc");
      } else {
        if (isDev) {
          console.log(
            `%c⛔ No matching ISRC on Spotify for ${track.isrc}.  Searching with Artist/Title: ${track.artist}/${track.title}`,
            "color:cyan"
          );
        }
        const titleQstring = {
          market: spotifyUserMarket,
          type: "track",
          q: `track:${track.title} artist:${track.artist}`,
          limit: 1
        };
        const getOptions = {
          method: "GET",
          url: `https://api.spotify.com/v1/search?${qs.stringify(
            titleQstring
          )}`,
          headers: { Authorization: "Bearer " + accessToken },
          json: true
        };
        const { data } = await axios(getOptions);
        if (!data.tracks.items.length) {
          if (isDev) {
            console.log(
              `%c⛔ No matching track on Spotify for Artist/Title: ${track.artist}/${track.title}`,
              "color:cyan"
            );
          }
        }
        return addAppleDataToSpotifyTrack(data, track, "artist/title");
      }
    } catch (error) {
      dispatch({
        type: SpotifyActionType.GET_MATCHING_SPOTIFY_TRACKS_FAIL,
        payload: error.response || error
      });
      console.warn(error.response || error);
    }
  };
  try {
    const pendingTracks = appleMusicNativeTracks.map((track) =>
      getMatchingTrack(track)
    );
    if (isDev) {
      console.log(
        "%c⏲️ Awaiting Spotify Matched Track promises...",
        "color:cyan"
      );
    }
    const resolvedTracks = await Promise.all(pendingTracks);
    if (isDev) {
      console.log(
        "%c🧘🏽 Spotify Matched Track Promises fulfilled",
        "color:lime",
        resolvedTracks
      );
    }
    let matchedItems = !resolvedTracks.length
      ? []
      : resolvedTracks
          .filter((filterItem) => filterItem.tracks.items.length)
          .map((item) => {
            if (item.tracks.items.length) {
              return item.tracks.items.map((tracksItem) => ({
                track: { ...tracksItem },
                trackType:
                  SlugifyTrackType.SPOTIFY_TRACK_MATCHED_FROM_APPLE_MUSIC,
                added_by: { id: item.addedBy },
                matchedBy: item.matchedBy,
                appleMusicInfo: {
                  albumArt: item.albumArt,
                  appleMusicId: item.appleMusicId,
                  appleMusicIsrc: item.appleMusicIsrc,
                  addedBy: item.addedBy,
                  album: item.album,
                  artist: item.artist,
                  title: item.title
                }
              }));
            } else {
              return [];
            }
          });
    matchedItems = flatten(matchedItems);
    // An unmatched Spotify track is an Apple Music track that could not be matched from a Spotify source track.
    let unmatchedItems = resolvedTracks
      .filter((filterItem) => !filterItem.tracks.items.length)
      .map((item) => {
        return {
          trackType: SlugifyTrackType.SPOTIFY_UNMATCHED_FROM_APPLE_MUSIC,
          added_by: { id: item.addedBy },
          appleMusicInfo: {
            albumArt: item.albumArt,
            appleMusicId: item.appleMusicId,
            appleMusicIsrc: item.appleMusicIsrc,
            addedBy: item.addedBy,
            album: item.album,
            artist: item.artist,
            title: item.title
          }
        };
      });
    unmatchedItems = flatten(unmatchedItems);
    const trackItems = [...matchedItems, ...unmatchedItems];
    dispatch({
      type: SpotifyActionType.GET_MATCHING_SPOTIFY_TRACKS_SUCCESS,
      payload: trackItems
    });
    return Promise.resolve(trackItems);
  } catch (error) {
    console.warn(error.response || error);
    dispatch({
      type: SpotifyActionType.GET_MATCHING_SPOTIFY_TRACKS_FAIL,
      payload: error.response || error
    });
    return Promise.reject(error.response || error);
  }
};

export const getTracksFromSpotifyPlaylist =
  (playlistId) => async (dispatch) => {
    try {
      dispatch({ type: "GET_TRACKS_FROM_SPOTIFY_PLAYLIST_START" });
      const accessToken = await dispatch(checkSpotifyClientCredentialsToken());
      const getOptions = {
        method: "GET",
        url: `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json"
        }
      };
      const { data } = await axios(getOptions);
      dispatch({ type: "GET_TRACKS_FROM_SPOTIFY_PLAYLIST_SUCCESS" });
      return Promise.resolve(data.items);
    } catch (error) {
      dispatch({
        type: "GET_TRACKS_FROM_SPOTIFY_PLAYLIST_FAIL",
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const removeTracksFromSpotifyPlaylist =
  (playlistId, tracks) => async (dispatch) => {
    try {
      dispatch({ type: "REMOVE_TRACKS_FROM_SPOTIFY_PLAYLIST_START" });
      const accessToken = await dispatch(checkSpotifyToken());
      const options = {
        method: "DELETE",
        url: `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json"
        },
        data: tracks
      };
      const { data } = await axios(options);
      dispatch({ type: "REMOVE_TRACKS_FROM_SPOTIFY_PLAYLIST_SUCCESS" });
      return Promise.resolve(data);
    } catch (error) {
      dispatch({
        type: "REMOVE_TRACKS_FROM_SPOTIFY_PLAYLIST_FAIL",
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const getSpotifyClientCredentialsToken = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SPOTIFY_CLIENT_CREDENTIALS_TOKEN_DATA_TO_USER_START"
    });
    const response = await API.post(
      "SlugBucketAPI",
      "/spotify/clientcredentialsauth",
      {
        body: {}
      }
    );
    dispatch({
      type: "GET_SPOTIFY_CLIENT_CREDENTIALS_TOKEN_DATA_TO_USER_SUCCESS",
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    console.error(error.response || error);
    dispatch({
      type: "GET_SPOTIFY_CLIENT_CREDENTIALS_TOKEN_DATA_TO_USER_FAIL",
      payload: error.response || error
    });
    dispatch(
      handleUserAlert(["GET_SPOTIFY_CLIENT_CREDENTIALS_TOKEN_DATA_TO_USER"])
    );
    return Promise.resolve(error.response || error);
  }
};

export const spotifySongSearch = (album, artist, title) => async (dispatch) => {
  try {
    const accessToken = await dispatch(checkSpotifyToken());
    const query = {
      type: "track",
      q: `track:${title} artist:${artist}`,
      market: "from_token",
      limit: 3
    };
    const options = {
      method: "GET",
      url: `https://api.spotify.com/v1/search?${qs.stringify(query)}`,
      headers: { Authorization: "Bearer " + accessToken },
      json: true
    };
    dispatch({
      type: "GET_SPOTIFY_TRACK_SEARCH_RESULTS_START"
    });
    const { data } = await axios(options);
    dispatch({
      type: "GET_SPOTIFY_TRACK_SEARCH_RESULTS_SUCCESS",
      payload: { artist, album, title, searchResults: data.tracks.items }
    });
  } catch (e) {
    dispatch({
      type: "GET_SPOTIFY_TRACK_SEARCH_RESULTS_FAIL",
      payload: e
    });
    console.log(e);
  }
};
