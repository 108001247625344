/* eslint-disable no-case-declarations */
import uniqBy from "lodash/uniqBy";
import { AnyAction } from "redux";
import chalk from "chalk";
import {
  AppleMusicActionType,
  AppleMusicMatchedTrack,
  AppleMusicState,
  AppleMusicUnmatchedTrack,
  SlugifyTrackType
} from "../types";

const initialState = {
  appleMusicTokenData: {},
  isConnected: false,
  myPlaylists: [],
  tracks: [],
  trackSearch: [],
  unmatchedTracks: []
} as AppleMusicState;
export default (state = { ...initialState }, action: AnyAction) => {
  switch (action.type) {
    case "CREATE_APPLE_MUSIC_SLUGIFY_DIRECT_PLAYLIST_SUCCESS":
    case "CREATE_APPLE_MUSIC_SLUGIFY_PLAYLIST_SUCCESS":
      return {
        ...state,
        myPlaylists: uniqBy(
          [
            ...state.myPlaylists.filter(
              (item) => item.id !== action.payload.id
            ),
            action.payload
          ],
          "id"
        )
      };
    case "DELETE_CURRENT_APPLE_MUSIC_SEARCH_RESULTS":
      return {
        ...state,
        trackSearch: {}
      };
    case "GET_APPLE_MUSIC_DEV_TOKEN_SUCCESS":
      return {
        ...state,
        appleMusicTokenData: { ...action.payload }
      };
    case AppleMusicActionType.GET_MATCHING_APPLE_MUSIC_TRACKS_SUCCESS:
      const matchedTracks = action.payload.filter(
        (item: AppleMusicMatchedTrack | AppleMusicUnmatchedTrack) =>
          item.trackType ===
          SlugifyTrackType.APPLE_MUSIC_TRACK_MATCHED_FROM_SPOTIFY
      );
      if (process.env.NODE_ENV === "development") {
        console.log(chalk.red("fix type"));
      }
      const unmatchedTracks = action.payload.filter(
        (item: AppleMusicMatchedTrack | AppleMusicUnmatchedTrack) =>
          item.trackType === SlugifyTrackType.APPLE_MUSIC_UNMATCHED_FROM_SPOTIFY
      );
      return {
        ...state,
        tracks: uniqBy(state.tracks.concat(matchedTracks), "id"),
        unmatchedTracks: uniqBy(
          state.unmatchedTracks.concat(unmatchedTracks),
          "spotifyId"
        )
      };
    case "GET_ALL_TRACKS_FROM_APPLE_MUSIC_PLAYLISTS_SUCCESS":
      return {
        ...state,
        tracks: uniqBy(state.tracks.concat(...action.payload), "id")
      };
    case "GET_CURRENT_USER_APPLE_MUSIC_PLAYLISTS_SUCCESS":
      return {
        ...state,
        myPlaylists: [...action.payload]
      };
    case "GET_APPLE_MUSIC_TRACK_SEARCH_RESULTS_SUCCESS":
      return {
        ...state,
        trackSearch: { ...action.payload }
      };
    case "RESET_APPLE_MUSIC_TRACKS":
      return {
        ...state,
        tracks: [],
        unmatchedTracks: []
      };
    case "SET_APPLE_MUSIC_CONNECTED_STATUS":
      return {
        ...state,
        isConnected: action.payload
      };
    default:
      return state;
  }
};
