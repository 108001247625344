import { AnyAction } from "redux";
import { YouTubeActionType, YouTubeState } from "../types";

const initialState: YouTubeState = {
  channel: null,
  myPlaylists: [],
  user: {}
};

export default (state = { ...initialState }, action: AnyAction) => {
  switch (action.type) {
    case YouTubeActionType.CREATE_YOUTUBE_PLAYLIST_SUCCESS:
    case YouTubeActionType.CREATE_NEW_YOUTUBE_PLAYLIST_SUCCESS:
      return {
        ...state,
        myPlaylists: [...state.myPlaylists, action.payload]
      };
    case "INITIALIZE_YOUTUBE_API_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    case YouTubeActionType.GET_MY_YOUTUBE_PLAYLISTS_SUCCESS:
      return {
        ...state,
        myPlaylists: [...action.payload]
      };
    case YouTubeActionType.GET_YOUTUBE_USER_DATA_SUCCESS:
    case YouTubeActionType.SET_YOUTUBE_SIGN_IN_STATUS_SUCCESS:
      return { ...state, user: { ...action.payload } };
    case YouTubeActionType.GET_MY_YOUTUBE_CHANNEL_SUCCESS:
      return { ...state, channel: action.payload };
    case "SIGN_OUT_YOU_TUBE_USER_SUCCESS":
      return { ...initialState };
    case YouTubeActionType.RESET_YOUTUBE_USER:
      return initialState;
    default:
      return state;
  }
};
