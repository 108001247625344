// import { isMobile } from "react-device-detect";
import { useContext, useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ThemeContext } from "../contexts/ColorThemeProvider";
import { trimText } from "../functions";
// import { useAppSelector } from "../hooks";
import appleMusicLogo from "../images/apple_music_icon_color_small.svg";
import appleMusicLight from "../images/listen_on_apple_music_black_text.svg";
import appleMusicDark from "../images/listen_on_apple_music_white_text.svg";

interface Props {
  item: any;
  showMyTracks?: boolean;
  showSlurpedTracks?: boolean;
  showButton?: boolean;
}
export default function SlugifyAppleMusicItem({
  item,
  showMyTracks = false,
  showSlurpedTracks = false,
  showButton = false
}: Props) {
  const { isDark } = useContext(ThemeContext);
  // const {
  //   newUser: { friends }
  // } = useAppSelector((state) => state);

  const { bgColor, toolTipText } = useMemo(() => {
    let bgColor = "bg-body-tertiary";
    let slurpedBy = null;
    let toolTipText = null;
    switch (item.slurpStatus) {
      case "fullySlurped":
        bgColor = "bg-success-subtle";
        break;
      case "partiallySlurped":
        bgColor = "bg-danger-subtle";
        break;
      default:
        bgColor = "bg-body-tertiary";
        break;
      // return "bg-apple-music-grey";
    }
    if (item.slurpedBy && item.slurpedBy.length) {
      if (item.slurpedBy.length === 1) {
        slurpedBy = item.slurpedBy[0];
      } else {
        const entries = item.slurpedBy.slice(0, -1);
        const lastEntry = item.slurpedBy.slice(-1);
        slurpedBy = entries.join(", ") + ` & ${lastEntry}`;
      }
    }
    if (
      (!showMyTracks && showSlurpedTracks) ||
      (!showMyTracks && !showSlurpedTracks)
    ) {
      if (item.sharedByFriend && item.sharedByFriend.length) {
        toolTipText = `Shared by ${item.sharedByFriend[0]}`;
      }
    } else if (
      (showMyTracks && showSlurpedTracks) ||
      (showMyTracks && !showSlurpedTracks)
    ) {
      toolTipText = slurpedBy ? `Slurped by ${slurpedBy}` : "unslurped";
    }

    return { bgColor, toolTipText };
  }, [item, showMyTracks, showSlurpedTracks]);

  return (
    <OverlayTrigger
      key={item.id}
      placement="top"
      overlay={<Tooltip id={`tooltip-${item.id}`}>{toolTipText}</Tooltip>}
    >
      <li
        className={`d-flex justify-content-center list-group-item animate__animated animate__fadeIn mb-2 px-3 py-2
      ${`rounded ${bgColor}`}
      `}
        key={item.id}
        style={{ border: "none" }}
      >
        <div className="d-flex align-items-center w-100">
          {showButton ? (
            <>
              <a
                className="d-block d-sm-none"
                href={item.attributes.url}
                target="_blank"
                title="Play on Apple Music"
                rel="noopener noreferrer"
              >
                <img
                  src={item.attributes.artwork.url
                    .replace(/{w}/, "48")
                    .replace(/{h}/, "48")}
                  alt={`${item.attributes.albumName} album art`}
                  height="48px"
                  width="48px"
                  style={{ borderRadius: "8px" }}
                />
              </a>
              <a
                className="d-none d-sm-block"
                href={item.attributes.url}
                target="_blank"
                title="Play on Spotify"
                rel="noopener noreferrer"
              >
                <img
                  src={item.attributes.artwork.url
                    .replace(/{w}/, "64")
                    .replace(/{h}/, "64")}
                  alt={`${item.attributes.albumName} album art`}
                  height="64px"
                  width="64px"
                  style={{ borderRadius: "8px" }}
                />
              </a>
            </>
          ) : (
            <>
              <div className="d-block d-sm-none">
                <img
                  src={item.attributes.artwork.url
                    .replace(/{w}/, "48")
                    .replace(/{h}/, "48")}
                  alt={`${item.attributes.albumName} album art`}
                  height="48px"
                  width="48px"
                  style={{ borderRadius: "8px" }}
                />
              </div>
              <div className="d-none d-sm-block">
                <img
                  src={item.attributes.artwork.url
                    .replace(/{w}/, "64")
                    .replace(/{h}/, "64")}
                  alt={`${item.attributes.albumName} album art`}
                  height="64px"
                  width="64px"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </>
          )}
          <div className="d-flex flex-column ms-3 flex-grow-1">
            <small className="d-block d-sm-none font-weight-bold me-auto">
              {trimText(20, item.attributes.name)}
            </small>
            <div className="d-none d-sm-block d-md-none font-weight-bold me-auto">
              {trimText(45, item.attributes.name)}
            </div>
            <div className="d-none d-sm-none d-md-block font-weight-bold me-auto">
              {item.attributes.name}
            </div>
            <small className="d-flex d-sm-none font-weight-normal">
              {trimText(20, item.attributes.artistName)}
            </small>
            <small className="d-none d-sm-flex d-md-none font-weight-normal">
              {trimText(45, item.attributes.artistName)}
            </small>
            <small className="d-none d-sm-none d-md-flex font-weight-normal">
              {item.attributes.artistName}
            </small>
          </div>
          {showButton ? (
            <>
              <div className="d-none d-sm-flex">
                <a
                  className="ms-3"
                  href={item.attributes.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={isDark ? appleMusicDark : appleMusicLight}
                    alt="Listen on Apple Music"
                  />
                </a>
              </div>
              <div className="d-flex d-sm-none">
                <a
                  className="ms-2"
                  href={item.attributes.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appleMusicLogo}
                    alt="Apple Music logo"
                    style={{ height: 32, width: 32 }}
                  />
                </a>
              </div>
            </>
          ) : null}
        </div>
      </li>
    </OverlayTrigger>
  );
}
